import { Checkbox } from '@gmg/gmg-react-components';
import { FC } from 'react';
import styled from 'styled-components';
import { ColorItem } from './Spider';

interface ColorSelectorProps {
    colors: Array<ColorItem>;
    onChangeSelection: (selectedColors: Array<ColorItem>) => void;
}

const ColorSelector: FC<ColorSelectorProps> = props => {

    const hasOverprints = props.colors.find(color => color.isOverprint);

    const handleColorSelectionChange = (clickedColor: string) => {
        props.onChangeSelection(props.colors.map(color => {

            const isColorToggled = clickedColor === 'Overprints'
                ? color.isOverprint === true
                : color.name === clickedColor;

            return {
                ...color,
                isSelected: isColorToggled
                    ? !color.isSelected
                    : color.isSelected,
            };
        }));
    };
    return (
        <Div>
            {[...props.colors].sort((a, b) => a.sortIndex - b.sortIndex)
                .filter(color => !color.isOverprint)
                .map(color => (
                    <div key={color.name}>
                        <Checkbox
                            id={color.name}
                            label={color.name}
                            checked={color.isSelected}
                            onChange={() => { handleColorSelectionChange(color.name); }}
                            name="colors"
                        />
                    </div>),
                )}
            <div>
                {hasOverprints && (
                    <Checkbox
                        id="Overprints"
                        label="Overprints"
                        checked={props.colors.find(color => color.isOverprint)!.isSelected}
                        onChange={() => { handleColorSelectionChange('Overprints'); }}
                        name="Overprints"
                    />
                )}
            </div>
        </Div>
    );
};

const Div = styled.div`
    display: flex;

    >div {
        margin-left: 14px;
    }
`;

export default ColorSelector;