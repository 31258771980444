import { Box, Flex, GmgTheme } from '@gmg/gmg-react-components';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { ReactComponent as PictoDark } from '../../../assets/graphic_document-eye-dark-255.svg';
import { ReactComponent as PictoLight } from '../../../assets/graphic_document-eye-light-255.svg';

const StructureIncompatible = () => {
    const { t } = useTranslation();
    const theme: GmgTheme = useTheme();

    return (
        <Flex flexDirection="column" alignItems="center">
            <Box>
                {theme.type === 'dark'
                    ? <PictoDark width={255} height={255} />
                    : <PictoLight width={255} height={255} />}
            </Box>
            <Box className="px14_600_contrast">
                {t('Chart.notCompatibleTitle', 'Oh no, you cannot compare the selected files!')}
            </Box>
            <Flex
                className="px12_400_normal"
                flexDirection="column"
                alignItems="center"
                pt="24px"
                gap="4px"
            >
                <Box>
                    {t('Chart.notCompatibleSub1', 'Please make sure that the files are based on the same test chart.')}
                </Box>
                <Box>
                    {t('Chart.notCompatibleSub2', 'Also rescaling the file could fix the problem.')}
                </Box>
            </Flex>
        </Flex>
    );
};

export default StructureIncompatible;