import { InkValue, MeasurementViewModel, OverprintColors, Patch, PatchType } from '../ViewModels';
import { MeasurementRequest } from '../customHooks/useMeasurement';
import * as Api from '../generated/types';

export const mapToMeasurementViewModel = (response: Api.TableView, request: Omit<MeasurementRequest, 'id'>): MeasurementViewModel => {
    const rowLabels = Array.from(new Set(response.RowLabels!.map(rowLabel => rowLabel!)));
    const columnLabels = Array.from(new Set(response.ColumnLabels!.map(columnLabel => columnLabel!)));
    const inks = createInkMap(response.InkSet!);
    const patches = response.Patches!.map(patch => createPatch(patch, rowLabels, columnLabels, Array.from(inks.keys())));

    const versionLabel = `#${response.Version}`;

    const vm: MeasurementViewModel = {
        id: response.Id,
        versionedId: `${response.Id}-${versionLabel}`,
        name: response.Name,
        processParamsDefined: response.HasProcessParamsDefined,
        inks,
        overPrints: createOverPrints(patches),
        pageCount: response.Patches!.length > 0
            ? (Math.max(...response.Patches!.map(patch => patch.Index === null ? 0 : patch.Index![0]!)) + 1)
            // set page count to 0 if there are no patches, for example, because of condition mismatch
            : 0,
        rowLabels,
        columnLabels,
        patches,
        variant: request.variant,
        condition: request.condition,
        availableConditions: response.Conditions,
        version: response.Version,
        versionLabel,
        isLatestVersion: request.outdatedVersion === undefined,
        isEditable: request.isEditable,
        folderId: request.folderId,
    };

    return vm;
};

const createOverPrints = (patches: Array<Patch>) => {
    const result: MeasurementViewModel['overPrints'] = new Map();

    const overPrintColors: Array<OverprintColors> = ['Red (M + Y)', 'Green (C + Y)', 'Blue (C + M)'];

    overPrintColors.forEach(color => {
        const overPrintPaches = patches.filter(patch => patch.characteristic?.type === 'symmetricOverprint' && patch.characteristic?.colorName === color);
        if (overPrintPaches.length !== 0) {
            result.set(color, overPrintPaches[overPrintPaches.length - 1].value.hex);
        }
    });

    return result;
};

const createInkMap = (inkSet: Api.Maybe<Api.Maybe<Api.Ink>[]>) => {
    const result = new Map();
    inkSet!.forEach(item => {
        result.set(item!.Name, item!.Color);
    });
    return result;
};

const createPatch = (patch: Api.Patch, rowLabels: Array<string>, columnLabels: Array<string>, inks: Array<string>): Patch => {

    const pageIndex = patch.Index === null ? undefined : patch.Index![0]!;
    const rowIndex = patch.Index === null ? undefined : patch.Index![1]!;
    const columnIndex = patch.Index === null ? undefined : patch.Index![2]!;
    const inkValues = patch.InkValues!.map((value, i): InkValue => ({ name: inks[i], value: value! }));

    const isCalculatedAverage = rowIndex === undefined && columnIndex === undefined;

    let characteristic: Patch['characteristic'] = undefined;

    const numberOfInkValuesGreaterThanZero = patch.InkValues!.filter(inkValue => inkValue! > 0).length;

    if (numberOfInkValuesGreaterThanZero === 0) {
        characteristic = {
            colorName: 'Media',
            type: 'media',
            sortIndex: 0,
        };
    }

    if (numberOfInkValuesGreaterThanZero === 1) {
        const inkValueIndexGreaterThanZero = patch.InkValues!.findIndex(inkValue => inkValue! > 0);

        let sortedIndex: number;
        switch (inks[inkValueIndexGreaterThanZero]) {
            case 'Cyan':
                sortedIndex = 1;
                break;
            case 'Magenta':
                sortedIndex = 2;
                break;
            case 'Yellow':
                sortedIndex = 3;
                break;
            case 'Black':
                sortedIndex = 4;
                break;
            default:
                sortedIndex = 5;
        }

        characteristic = {
            colorName: inks[inkValueIndexGreaterThanZero],
            type: 'singleColor',
            sortIndex: sortedIndex,
        };
    }
    if (numberOfInkValuesGreaterThanZero === 2) {
        const processColors = patch.InkValues!
            .map((inkValue, i) => inkValue > 0 ? inks[i] : undefined)
            .filter(processColor => processColor !== undefined);

        const hasEqualOrSingleInkValues = new Set(patch.InkValues!.filter(v => v > 0)).size === 1;
        const type: PatchType = hasEqualOrSingleInkValues ? 'symmetricOverprint' : 'asymmetricOverprint';

        if (processColors.includes('Magenta') && processColors.includes('Yellow')) {
            characteristic = {
                colorName: 'Red (M + Y)',
                type,
                sortIndex: 6,
            };
        }
        if (processColors.includes('Cyan') && processColors.includes('Yellow')) {
            characteristic = {
                colorName: 'Green (C + Y)',
                type,
                sortIndex: 7,
            };
        }
        if (processColors.includes('Magenta') && processColors.includes('Cyan')) {
            characteristic = {
                colorName: 'Blue (C + M)',
                type,
                sortIndex: 8,
            };
        }
    }

    const result: Patch = {
        id: generatePatchId(isCalculatedAverage, pageIndex, rowIndex, columnIndex, inkValues),
        pageIndex,
        rowIndex,
        rowLabel: !isCalculatedAverage ? rowLabels[rowIndex!] : '',
        columnIndex,
        columnLabel: !isCalculatedAverage ? columnLabels[columnIndex!] : '',
        characteristic,
        isCalculatedAverage,
        inkValues,
        value: {
            hex: patch.Values![0].Hex,
            lab: patch.Values![0].Lab as [number, number, number],
            lch: patch.Values![0].LCh as [number, number, number],
            density: patch.Values![0].Density!,
            toneValue: patch.Values![0].ToneValue!,
        },
    };

    return result;
};

export const generatePatchId = (
    isCalculatedAverage: boolean,
    pageIndex: number | undefined,
    rowIndex: number | undefined,
    columnIndex: number | undefined,
    inkValues: Array<InkValue>) => {

    // for "real" patches e.g. '0-3-12'
    if (!isCalculatedAverage) {
        return `${pageIndex}-${rowIndex}-${columnIndex}`;
    }
    // for "virtual" patches e.g. 'Cyan0Magenta0Yellow100Black0'
    let result = '';
    inkValues.forEach(inkValue => {
        result += `${inkValue.name}-${inkValue.value}|`;
    });
    return result;
};

// const createDeltas = (patch: Api.Patch, isCalculatedAverage: boolean, condition: string): Delta | undefined => {
//     if (isCalculatedAverage || !requestedConditions || requestedConditions.length !== 2) return undefined;

//     return {
//         e: patch.DeltaE!,
//         lab: [patch.DeltaL!, patch.Deltaa!, patch.Deltab!]
//     };
// };
