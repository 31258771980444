import { Button, InputTypeTextReduced, toast } from '@gmg/gmg-react-components';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from 'src/AppContext';
import { useAutoOptimization } from 'src/graphql/customHooks/optimize/useAutoOptimize';
import { MeasurementViewModel } from 'src/graphql/ViewModels';
import { getAutoOptimizationEvent } from 'src/tracking';
import styled from 'styled-components';
import { ActionBtnContainer, FlyOutContent } from './style';

interface AutoOptimizeProps {
    measurements: Array<Pick<MeasurementViewModel, 'id' | 'name'>>;
    folderId: string;
    onStart: () => void;
    onFinish: (id: string | undefined, newMeasurementName?: string) => void;
}

const AutoOptimize: FunctionComponent<AutoOptimizeProps> = props => {
    const { trackEvent } = useContext(AppContext);
    const { t } = useTranslation();
    const [newFilename, setNewFilename] = useState('');
    const autoOptimize = useAutoOptimization(props.folderId, props.onFinish);

    const isSingleFileOptimization = props.measurements.length < 2;

    useEffect(() => {
        setNewFilename(`${props.measurements[0].name}_averaged`);
    }, [props.measurements]);

    const handleAutoOptimizeButtonClick = async () => {
        trackEvent(getAutoOptimizationEvent(props.measurements.length));
        props.onStart();
        const loadingToastId = toast.loading(t('AutoOptimizeToasts.loading_desc', 'We are optimizing the data...'));

        try {
            await autoOptimize.mutateAsync({
                measurements: props.measurements,
                targetName: isSingleFileOptimization ? undefined : newFilename,
                loadingToastId,
            });
        } catch (error) {
            toast.dismiss(loadingToastId);
            toast.error('Sorry, We could not start the optimization!');// TODO: text is requested from Ch. Endres
        }
    };

    return (
        <>
            <FlyOutContent>
                <span className="px10_400_weak">
                    {t('ToolBar.AutoOptimize.content_desc',
                        'Effortlessly optimize your data with our auto-correction tool. Experience precision and refined results with our expert sequence of optimization actions with just one click.')}
                </span>
                {
                    !isSingleFileOptimization &&
                    <InputContainer>
                        <span className="px10_400_weak">
                            {t('ToolBar.AutoOptimize.new_filename_title', 'Name of the average file')}
                        </span>
                        <InputTypeTextReduced
                            width="100%"
                            error={!newFilename && t('ToolBar.AutoOptimize.missing_filename_error_desc', 'Please enter a file name.')}
                            value={newFilename}
                            onChange={(e) => setNewFilename(e.target.value)}
                            aria-label={t('ToolBar.AutoOptimize.input_label_title', 'Optimize filename input')}
                            placeholder={t('ToolBar.AutoOptimize.input_placeholder_title', 'Please enter a filename')}
                        />
                    </InputContainer>
                }
            </FlyOutContent>
            <ActionBtnContainer>
                <Button
                    variant="secondary"
                    size="small"
                    onClick={handleAutoOptimizeButtonClick}
                    aria-label="Auto-Optimize button"
                >
                    {t('Common.ButtonLabels.applyBtn_title', 'Apply')}
                </Button>
            </ActionBtnContainer>
        </>
    );
};

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    > span {
        margin-bottom: 4px;
    }
`;

export default AutoOptimize;
