import { Flex, GmgTheme, Toggle, Tooltip, toast } from '@gmg/gmg-react-components';
import { FunctionComponent, MutableRefObject, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UnsavedChangesModal from 'src/components/optimize/UnsavedChangesModal';
import { getErrorMsgForToast } from 'src/components/toastErrorHandler';
import { DotgainMutationInput, useDotgainValueCorrection } from 'src/graphql/customHooks/colorCollection/useDotgainValueCorrection';
import { diagramFactorCalculator } from 'src/shared/diagramFactorCalculator';
import useFormatter from 'src/shared/useFormatter';
import { useUnSavedChangesmodal } from 'src/shared/useUnSavedChangesModal';
import { getEditDotGainEvent } from 'src/tracking';
import styled, { useTheme } from 'styled-components';
import AppContext from '../../../AppContext';
import { ReactComponent as IconAverage } from '../../../assets/icon_average.svg';
import SimpleColorSelector from '../SimpleColorSelector';
import { ColorCorrection, Measurements } from '../VisualizationContainer';
import ActionBar from '../spider/ActionBar';
import DotGainGrid from './DotGainGrid';
import DotgainTable from './DotGainTable';
import { DotGainTableRow, getDotGainData, getFormattedAndSortedDotGainTableRows } from './calculationsForDotgain';

interface DotgainProps {
    measurementContext: Measurements;
    onHighlightMeasurement: (versionedId?: string) => void;
    activeColorCorrection: ColorCorrection;
    diagramContainerRef: MutableRefObject<any>;
    onCloseColorCorrection: (correctedMeasurementId?: string) => void;
    onStartColorCorrection: () => void;
    isHistoryMode: boolean;
}

export class EditedPoint {
    index: number;
    ink: string;
    percentageText: string;
    constructor(point: { ink: string; index: number; percentageText: string }) {
        this.ink = point.ink;
        this.index = point.index;
        this.percentageText = point.percentageText;
    }
    public get percentage(): number {
        return Math.round(parseFloat(this.percentageText.replace(',', '.')) * 100) / 100;
    }
}

const Dotgain: FunctionComponent<DotgainProps> = props => {
    const theme: GmgTheme = useTheme();
    const { t } = useTranslation();
    const { formatNumber } = useFormatter();
    const { measurementSettings: measurementParams, trackEvent } = useContext(AppContext);
    const [isDuplicatesVisible, setIsDuplicatesVisible] = useState<boolean>(true);
    const [manuallySelectedColors, setManuallySelectedColors] = useState<Array<string> | undefined>(undefined);
    const [editedPoints, setEditedPoints] = useState<Array<EditedPoint>>([]);
    const updateDotgainValueMutation = useDotgainValueCorrection(props.measurementContext.condition);
    const deafultDiagramSize = 360; // this is the most appropriete size for diagram in the most resulution;
    const scalingFactor = useMemo(() => diagramFactorCalculator(deafultDiagramSize), []);
    //when SCTV is selected either for CMYK or Spotcolors, change the range of the y-axis
    const isMethodSctv = measurementParams?.methodCMYK === 'SCTV' || measurementParams?.methodSpot === 'SCTV';
    const yAxisOffset = isMethodSctv ? 6 : 9;
    const availableColors = Array.from(new Set(props.measurementContext.viewmodels
        .flatMap(measurement => Array.from(measurement.inks.keys())),
    ));

    useUnSavedChangesmodal(<UnsavedChangesModal />, handleApplyClick, props.diagramContainerRef, editedPoints.length === 0);
    if (!props.measurementContext.viewmodels.length) return null;
    // as long as selectedColors is undefined we don't want to start the calculation
    const diagramWidth = deafultDiagramSize * scalingFactor;
    const diagramHeight = diagramWidth * 2 / 3;

    const dotGainData = getDotGainData({
        measurements: props.measurementContext.viewmodels,
        editedPoints: editedPoints,
        selectedColors: manuallySelectedColors || availableColors,
        diagramWidth,
        diagramHeight,
        yAxisOffset,
        theme,
    });

    const sortedRows = getFormattedAndSortedDotGainTableRows(
        dotGainData.map(data => data.tableData), editedPoints, formatNumber);

    function handleApplyClick() {
        if (editedPoints.length === 0) return;

        trackEvent(getEditDotGainEvent());

        const graphs: DotgainMutationInput['graphs'] = [];
        const mutatedInks = new Set(editedPoints.map(point => point.ink));

        mutatedInks.forEach(ink => {
            const values: Array<{ index: number; percentage: number }> = [];
            sortedRows.forEach((row: DotGainTableRow) => {
                const measurement = row.measurements[0]; // only 1 measurement/version in case of correction
                const inkValue = measurement.inkValues.find(item => item.inkName === ink);
                if (inkValue && inkValue.value !== '-') {
                    values.push({
                        index: row.indexPercentage,
                        percentage: new EditedPoint({ ink, index: row.indexPercentage, percentageText: inkValue.value }).percentage,
                    });
                }
            });
            graphs.push({
                ink,
                values,
            });
        });

        const pendingPromise = updateDotgainValueMutation.mutateAsync({
            measurementId: props.activeColorCorrection!.measurementId,
            graphs,
        });

        props.onStartColorCorrection();

        void toast.promise(pendingPromise as Promise<any>, {
            loading: t('Common.Toast.applying_correction', 'Your corrections are being applied ...'),
            success: () => {
                setEditedPoints([]);
                props.onCloseColorCorrection(props.activeColorCorrection!.measurementId);
                return t('Common.Toast.success_saved_changes', 'Your changes have been saved successfully.');
            },
            error: (response: any) => {
                setEditedPoints([]);
                props.onCloseColorCorrection(undefined);
                return getErrorMsgForToast(response, t);
            },
        });
    };

    const handleChangeInkValue = (editedPoint: EditedPoint) => {
        setEditedPoints(prev => [
            ...prev.filter(item => item.ink !== editedPoint.ink || item.index !== editedPoint.index),
            editedPoint,
        ]);
    };

    const handleResetClick = () => {
        setEditedPoints([]);
    };

    const handleClickClose = () => {
        handleResetClick();
        props.onCloseColorCorrection();
    };
    const isActionBarVisible = props.activeColorCorrection?.isLoading === false;

    return (
        <Flex flexDirection="column" alignItems="flex-start" mt="6px">
            <SimpleColorSelector
                colors={new Map(availableColors.map(color => [
                    color,
                    manuallySelectedColors
                        ? manuallySelectedColors.indexOf(color) > -1
                        : true,
                ]))}
                onChangeSelection={setManuallySelectedColors}
            />
            <Flex alignItems="flex-start">
                <GridContainer>
                    <Flex pl="28px" alignItems="end">
                        <Toggle
                            onClick={() => { setIsDuplicatesVisible(!isDuplicatesVisible); }}
                            isOn={isDuplicatesVisible}
                        >
                            <Tooltip message={t('Dotgain.iconAverage_tip', 'Toggle view')}>
                                <IconAverage aria-label={t('Dotgain.iconAverage_tip', 'Toggle view')} />
                            </Tooltip>
                        </Toggle>
                    </Flex>
                    <DotGainGrid
                        diagramWidth={diagramWidth}
                        diagramHeight={diagramHeight}
                        scalingFactor={scalingFactor}
                        data={dotGainData.map(data => data.gridData)}
                        highlightedMeasurement={props.measurementContext.highlightedId}
                        isPointsVisible={isDuplicatesVisible}
                        isMethodSctv={isMethodSctv}
                        yAxisOffset={yAxisOffset}
                        onChangeInkValue={handleChangeInkValue}
                        isColorCorrectionActive={props.activeColorCorrection?.mode === 'dotgain'}
                    />
                </GridContainer>
                <GridContainer>
                    {isActionBarVisible && (
                        <Flex alignItems="center" justifyContent="center">
                            <ActionBar
                                onResetClick={handleResetClick}
                                onCloseClick={handleClickClose}
                                onApplyClick={handleApplyClick}
                                haveInksBeenEdited={editedPoints.length > 0}
                            />
                        </Flex>)}
                    <DotgainTable
                        measurements={props.measurementContext.viewmodels.map(m => ({
                            name: props.isHistoryMode ? m.versionLabel : m.name,
                            versionedId: m.versionedId,
                            inkSet: m.inks,
                        }))}
                        data={sortedRows}
                        onHoverMeasurement={props.onHighlightMeasurement}
                        onChangeInkValue={handleChangeInkValue}
                        activeColorCorrection={props.activeColorCorrection}
                        tableDimensions={{
                            offset: diagramWidth + 611, // calc of 611 see in Spider.tsx
                            height: diagramHeight,
                        }}
                        hasReference={props.isHistoryMode ? false : props.measurementContext.hasReference}
                    />
                </GridContainer>
            </Flex>
        </Flex>
    );
};

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 48px 1fr;
`;


export default Dotgain;