import { IconArrowLoop, IconClose, IconCopy, IconDelete, IconExport, IconMoveFile, IconOpen, IconRename, ImageButton, Tooltip, toast } from '@gmg/gmg-react-components';
import { useQueryClient } from '@tanstack/react-query';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AppContext from 'src/AppContext';
import { MeasurementListItemViewModel } from 'src/graphql/ViewModels';
import { useDeleteItems } from 'src/graphql/customHooks/useDeleteItems';
import { useMultipleFileDownload } from 'src/graphql/customHooks/useMultipleFileDownload';
import { getExportEvent } from 'src/tracking';
import styled, { css } from 'styled-components';
import { getErrorMsgForToast } from '../toastErrorHandler';
import FileExplorerModalContainer from '../visualization/FileExplorerModal/FileExplorerModalContainer';
import { PathItem } from './BreadCrumb';
import CreateOrRenameFolderModalContainer from './CreateOrRenameFolderModalContainer';
import RenameFileModalContainer from './RenameFileModalContainer';

interface ExplorerActionsProps {
    onResetSelection: () => void;
    selectedFiles: Array<Pick<MeasurementListItemViewModel, 'id' | 'name' | 'version'>>;
    selectedFolders: Map<string, string>;
    unselectedFileNames: Array<string>;
    unselectedFolderNames: Array<string>;
    path: Array<PathItem>;
    onChangePath: (path: Array<PathItem>) => void;
    isFolderContentEditable: boolean;
}

const ExplorerActions: FunctionComponent<ExplorerActionsProps> = props => {
    const isRootFolder = props.path.length === 1;
    const currentFolder = props.path[props.path.length - 1];
    const firstSelectedFile = props.selectedFiles[0];
    const exportInProgressToastId = 'Export_In_Progress';
    const { t } = useTranslation();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { trackEvent, onShowModal, isUnlimitedLicenseAvailable } = useContext(AppContext);
    const [isExportBtnClicked, setIsExportBtnClicked] = useState(false);
    const deleteMultipleItems = useDeleteItems(currentFolder.id!);

    const { error, isSuccess, fetchStatus } = useMultipleFileDownload(
        isExportBtnClicked
            ? props.selectedFiles.map(x => x.id)
            : undefined,
        props.selectedFiles.length === 1
            ? firstSelectedFile.name
            : currentFolder.name,
    );

    useEffect(() => {
        // after export has been triggered and the user navigates into another folder
        // or start any other action which requires
        // a rerender or an unmount of the Container Component
        // we dismiss the loading toast to prevent infinite appearance
        return () => {
            if (isExportBtnClicked) {
                toast.dismiss(exportInProgressToastId);
            };
        };
    }, [isExportBtnClicked]);

    useEffect(() => {

        if (isSuccess && fetchStatus !== 'fetching') {
            toast.dismiss(exportInProgressToastId);
            setIsExportBtnClicked(false);
        }

        if (error) {
            toast.dismiss(exportInProgressToastId);
            toast.error(getErrorMsgForToast(error, t));
            setIsExportBtnClicked(false);
        }

    }, [error, t, isSuccess, fetchStatus]);

    const handleExportFile = (ev: any) => {
        ev.stopPropagation();
        setIsExportBtnClicked(true);
        toast.loading(t('FileOrFolderAction.spinner_export_prog', 'Exporting...'), { id: exportInProgressToastId });
        trackEvent(getExportEvent());
    };

    const handleMoveItems = () => {
        onShowModal('inputModal', {
            title: t('FileOrFolderAction.modals.move.title', 'Move'),
            titleIcon: <IconMoveFile />,
            content: <FileExplorerModalContainer
                operationType='move'
                baseSelection={[
                    ...props.selectedFiles.map(({ id, name }) => ({ id, name, isFolder: false })),
                    ...Array.from(props.selectedFolders.entries()).map(([id, name]) => ({ id, name, isFolder: true })),
                ]}
                path={props.path}
                onChangePath={props.onChangePath}
                sectionTitle={t('FileOrFolderAction.modals.move.section_title', 'Choose Destination')}
            />,
        });
    };

    const handleRefresh = async () => {
        await queryClient.invalidateQueries(['folderContent']);
    };

    const handleDeleteClick = () => {
        onShowModal('infoModal', {
            title: t('FileOrFolderAction.modals.delete.title', 'Delete'),
            content: <span>{t('ExplorerActions.delete_modal_title', 'Are you sure you want to delete the selected items?')}</span>,
            onOkClick: () => {
                const pendingPromise = deleteMultipleItems.mutateAsync({
                    measurementIds: (props.selectedFiles.map(m => m.id)),
                    folderIds: Array.from(props.selectedFolders.keys()),
                });

                void toast.promise(pendingPromise, {
                    loading: t('ExplorerActions.toast_loading_desc', 'Your selection is being deleted ...'),
                    success: () => {
                        props.onResetSelection();
                        return t('ExplorerActions.toast_success_desc', 'Your selection has been successfully deleted.');
                    },
                    error: response => getErrorMsgForToast(response, t),
                });
            },
            btnOkCaption: t('Common.ButtonLabels.delete', 'Delete'),
        });
    };

    const handleRenameClick = () => {
        const isFileRename = props.selectedFiles.length === 1;
        const id = isFileRename
            ? firstSelectedFile.id
            : props.selectedFolders.keys().next().value;
        const name = isFileRename
            ? firstSelectedFile.name
            : props.selectedFolders.values().next().value;
        onShowModal('inputModal', {
            title: isFileRename
                ? t('RenameFileModal.modal_title', 'Rename File')
                : t('FileOrFolderAction.button_text_rename', 'Rename'),
            titleIcon: <IconRename />,
            content: isFileRename
                ? <RenameFileModalContainer
                    measurementId={id}
                    measurementName={name}
                    measurementVersion={firstSelectedFile.version}
                    folderId={currentFolder.id!}
                    allOtherMeasurementNamesInFolder={props.unselectedFileNames}
                />
                : <CreateOrRenameFolderModalContainer
                    folderId={id}
                    currentName={name}
                    parentFolderId={currentFolder.id!}
                    siblingFolderNames={props.unselectedFolderNames}
                />,
        });
    };

    const handleCopyClick = () => {
        onShowModal('inputModal', {
            title: t('FileOrFolderAction.modals.copy.title', 'Copy file'),
            titleIcon: <IconMoveFile />,
            content: <FileExplorerModalContainer
                operationType='copyFile'
                baseSelection={[{ id: firstSelectedFile.id, name: firstSelectedFile.name, isFolder: false }]}
                path={props.path}
                onChangePath={props.onChangePath}
                sectionTitle={t('FileOrFolderAction.modals.move.section_title', 'Choose Destination')}
            />,
        });
    };

    return (
        <ExplorerActionsContainer isRootFolder={isRootFolder}>
            {!isRootFolder && (<>
                <ImageButton
                    icon={<IconClose aria-label="Reset file selection" />}
                    onClick={props.onResetSelection}
                    disabled={props.selectedFiles.length + props.selectedFolders.size === 0}
                />
                <span className="px12_400_normal">
                    {props.selectedFiles.length + props.selectedFolders.size} {t('Common.Suffixes.selected_title', 'selected')}
                </span>
                <div>
                    <Tooltip message={t('ExplorerActionsContainer.btn_openfiles', 'Open files')}>
                        <ImageButton
                            icon={<IconOpen />}
                            aria-label={t('ExplorerActionsContainer.btn_openfiles', 'Open files')}
                            disabled={props.selectedFiles.length === 0 || props.selectedFolders.size > 0}
                            onClick={() => { navigate(isUnlimitedLicenseAvailable ? 'spider' : 'tonalvalue'); }}
                        />
                    </Tooltip>
                    <Tooltip message={t('ExplorerActionsContainer.btn_rename', 'Rename file or folder')}>
                        <ImageButton
                            icon={<IconRename />}
                            aria-label={t('ExplorerActionsContainer.btn_rename', 'Rename file or folder')}
                            disabled={!props.isFolderContentEditable || props.selectedFiles.length + props.selectedFolders.size !== 1}
                            onClick={handleRenameClick}
                        />
                    </Tooltip>
                    <Tooltip message={t('FileOrFolderAction.button_text_copy', 'Copy')}>
                        <ImageButton
                            icon={<IconCopy />}
                            onClick={handleCopyClick}
                            aria-label="copy file"
                            disabled={!props.isFolderContentEditable || props.selectedFiles.length !== 1 || props.selectedFolders.size !== 0 }
                        />
                    </Tooltip>
                    <Tooltip message={t('ExplorerActionsContainer.btn_move', 'Move')}>
                        <ImageButton
                            icon={<IconMoveFile />}
                            aria-label={t('ExplorerActionsContainer.btn_move_aria_label', 'Move file or folder')}
                            disabled={!props.isFolderContentEditable || props.selectedFiles.length + props.selectedFolders.size === 0}
                            onClick={handleMoveItems}
                        />
                    </Tooltip>
                    <Tooltip message={t('FileOrFolderAction.button_text_export', 'Export')}>
                        <ImageButton
                            icon={<IconExport />}
                            onClick={handleExportFile}
                            aria-label="export multiple files"
                            disabled={props.selectedFiles.length === 0 || props.selectedFolders.size > 0 || isExportBtnClicked}
                        />
                    </Tooltip>
                    <Tooltip message={t('Common.ButtonLabels.delete', 'Delete')}>
                        <ImageButton
                            icon={<IconDelete />}
                            aria-label={t('Common.ButtonLabels.delete', 'Delete')}
                            disabled={!props.isFolderContentEditable || props.selectedFiles.length + props.selectedFolders.size === 0}
                            onClick={handleDeleteClick}
                        />
                    </Tooltip>
                </div>
            </>)}
            <div>
                <Tooltip message={t('ButtonsContainer.btn_refresh', 'Refresh table')}>
                    <ImageButton
                        icon={<IconArrowLoop />}
                        aria-label={t('ButtonsContainer.btn_refresh', 'Refresh table')}
                        onClick={handleRefresh}
                    />
                </Tooltip>
            </div>
        </ExplorerActionsContainer>
    );
};

const ExplorerActionsContainer = styled.div<{ isRootFolder: boolean }>`
    padding-left: ${props => !props.isRootFolder ? 2 : 10}px;
    height: 28px;
    display: flex;
    align-items: center;
    gap: 8px;
    button {
        width: 16px;
        height: 16px;
    }

    ${props => !props.isRootFolder && css` 
        > div {
            height: 28px;
            margin-left: 8px;
            padding-left: 16px;
            border-left: 1px solid ${p => p.theme.colors.greyContrastMediumLower};
            display: flex;
            align-items: center;
            gap: 16px;
        }
    `}
`;

export default ExplorerActions;