import { Checkbox, Flex, IconFolderFilled, IconMeasurementFilled } from '@gmg/gmg-react-components';
import { FunctionComponent } from 'react';
import EmptyFolderPage from 'src/components/EmptyFolderPage';
import { Folder, MeasurementListItemViewModel } from 'src/graphql/ViewModels';
import WrappedSpinner from 'src/shared/WrappedSpinner';
import styled, { css } from 'styled-components';
import { FileExplorerModalContainerProps } from './FileExplorerModalContainer';

export interface FileExplorerProps {
    operationType: FileExplorerModalContainerProps['operationType'];
    availableFiles: Array<MeasurementListItemViewModel>;
    availableFolders: Array<Folder>;
    selectedIdsInModal: Array<string>;
    unselectableFileIds: Array<string>;
    unselectableFolderIds: Array<string>;
    onSelectFiles: (item: MeasurementListItemViewModel) => void;
    onSelectFolder: (folder: Folder) => void;
    isLoading: boolean;
};

const FileExplorer: FunctionComponent<FileExplorerProps> = (props) => {

    if (props.isLoading) {
        return <WrappedSpinner label="Loading..." labelPosition="bottom" />;
    }
    if (props.availableFiles.length === 0 && props.availableFolders.length === 0) {
        return <EmptyFolderPage />;
    }

    return (
        <Container className='vertical-scrollbar'>

            {props.availableFolders?.map(folder => {
                const isDeactivated = props.unselectableFolderIds.includes(folder.id);
                return (
                    <Row key={folder.id} isDeactivated={isDeactivated}>
                        <Flex gap="8px" alignItems="center" ml={props.operationType === 'addToFilePicker' ? '32px' : ''} >
                            <IconFolderFilled width={16} height={16} />
                            <FolderName
                                className={`px12_400_${isDeactivated ? 'weak' : 'normal'}`}
                                onClick={() => {
                                    if (!isDeactivated) {
                                        props.onSelectFolder(folder);
                                    }
                                }}
                            >
                                {folder.name}
                            </FolderName>
                        </Flex>
                    </Row>
                );
            })}

            {props.availableFiles.map(file => {
                const isPreSelected = props.unselectableFileIds.includes(file.id);
                const isDeactivated = isPreSelected || props.operationType === 'move';
                const isSelected = isPreSelected || props.selectedIdsInModal.includes(file.id);

                return (
                    <Row key={file.id} isDeactivated={isDeactivated}>
                        {props.operationType === 'addToFilePicker' &&
                            <Checkbox
                                variant="circled"
                                id={`checkbox for file ${file.id}`}
                                checked={isSelected}
                                onChange={() => {
                                    if (!isPreSelected) {
                                        props.onSelectFiles(file);
                                    }
                                }}
                                isDisabled={isPreSelected}
                            />
                        }
                        <Flex gap="8px" alignItems="center">
                            <IconMeasurementFilled width={16} height={16} />
                            <Span
                                className={`px12_400_${isDeactivated ? 'weak' : 'normal'}`}
                                aria-selected={isSelected}
                            >
                                {file.name}
                            </Span>
                        </Flex>
                    </Row>
                );
            })}
        </Container>
    );
};


const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    border-bottom: 1px solid ${props => props.theme.colors.greyContrastMediumLower} ;

    svg {
        color: ${props => props.theme.colors.greyContrastSemiHigh};
    }
`;

const Row = styled.div<{ isDeactivated: boolean }>`
    display: flex;
    align-items: center;
    padding: 8px 20px 8px 16px;
    gap: 16px;
    min-height: 44px;
    box-sizing: border-box;

    &:nth-child(odd) {
        background-color: ${props => props.theme.colors.greyContrastLow};
        min-width: 16px;
    }

    ${props => !props.isDeactivated && css` 
        &:hover {
            background-color: ${prop => prop.theme.colors.greyContrastLowest};
        }
    `}
`;

const Span = styled.span`
    max-width: 432px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1.4;
`;

const FolderName = styled(Span)`
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

export default FileExplorer;