import { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

export interface FlyOutMenuProps {
    headline: string;
    children: ReactNode;
};

const FlyOutMenu: FunctionComponent<FlyOutMenuProps> = props => (
    <DIV>
        <SectionHeader>
            <span className="px10_600_normal" role="banner">
                {props.headline}
            </span>
        </SectionHeader>
        {props.children}
    </DIV>
);

const DIV = styled.div`
    width: 240px;
    height: 100%;
    background-color: ${props => props.theme.colors.backgroundPaper};
    border-radius: ${props => props.theme.radii.default}px;
    box-shadow: 0px 1px 8px rgba(0,0,0,0.3);
`;

const SectionHeader = styled.div`
    height: 32px;
    background-color: ${props => props.theme.colors.greyContrastLowest};
    padding: 9px 0 9px 17px;
    width: 100%;
    box-sizing: border-box;
`;

export default FlyOutMenu;