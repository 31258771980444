type TrackingEventName =
    'Smoothing'
    | 'RemoveOutliers'
    | 'Rescale'
    | 'CalculateAverage'
    | 'Download'
    | 'Export'
    | 'Upload'
    | 'RenameFile'
    | 'ChangeSettings'
    | 'AutoOptimization'
    | 'EditMediaLab'
    | 'EditSolidsLab'
    | 'EditDensity'
    | 'EditTonalValue'
    | 'EditDotGain'
    | 'CreateReport'
    | 'FileHistoryClick'
    | 'MoveFile'
    | 'MoveFolder'
    | 'CopyFile'
    | 'AddFilesToFilePicker'
    | 'TonalValueAutoCorrectionInDiagram'
    | 'TonalValueAutoCorrectionInTable'
    | 'DotGainAutoCorrectionInDiagram'
    | 'DotGainAutoCorrectionInTable';

export interface TrackingEvent {
    name: TrackingEventName;
    additionalProperties?: Record<string, string | number | boolean>;
}

export function checkDisableTracking(email: string | undefined, ignore: Array<string>) {
    if (!email) {
        return;
    }
    if (ignore.find(ignorePattern => email.indexOf(ignorePattern) > -1)) {
        localStorage.setItem('plausible_ignore', 'true');
    } else {
        localStorage.removeItem('plausible_ignore');
    }
}
export function getAutoOptimizationEvent(filesCount: number): TrackingEvent {
    return {
        name: 'AutoOptimization',
        additionalProperties: {
            filesCount,
        },
    };
}

export function getSmoothingEvent(strength: number): TrackingEvent {
    return {
        name: 'Smoothing',
        additionalProperties: {
            strength,
        },
    };
};

export function getRemoveOutliersEvent(): TrackingEvent {
    return {
        name: 'RemoveOutliers',
    };
};

export function getRescaleEvent(): TrackingEvent {
    return {
        name: 'Rescale',
    };
};

export function getCalculateAverageEvent(filesCount: number, useSmartAverage: boolean): TrackingEvent {
    return {
        name: 'CalculateAverage',
        additionalProperties: {
            filesCount,
            useSmartAverage,
        },
    };
};

export function getChangeSettingsEvent(name: string): TrackingEvent {
    return {
        name: 'ChangeSettings',
        additionalProperties: {
            name,
        },
    };
};

export function getUploadEvent(filesCount: number): TrackingEvent {
    return {
        name: 'Upload',
        additionalProperties: {
            filesCount,
        },
    };
};

export function getExportEvent(): TrackingEvent {
    return {
        name: 'Export',
    };
};

export function getRenameFileEvent(): TrackingEvent {
    return {
        name: 'RenameFile',
    };
};

export function getEditMediaLabEvent(): TrackingEvent {
    return {
        name: 'EditMediaLab',
    };
};

export function getEditSolidsLabEvent(): TrackingEvent {
    return {
        name: 'EditSolidsLab',
    };
};

export function getEditDensitiesEvent(): TrackingEvent {
    return {
        name: 'EditDensity',
    };
};

export function getEditTonalValueEvent(): TrackingEvent {
    return {
        name: 'EditTonalValue',
    };
};

export function getEditDotGainEvent(): TrackingEvent {
    return {
        name: 'EditDotGain',
    };
};
export function getCreateReportEvent(): TrackingEvent {
    return {
        name: 'CreateReport',
    };
};
export function getFileHistoryClickEvent(): TrackingEvent {
    return {
        name: 'FileHistoryClick',
    };
};
export function getMoveFileEvent(): TrackingEvent {
    return {
        name: 'MoveFile',
    };
};
export function getMoveFolderEvent(): TrackingEvent {
    return {
        name: 'MoveFolder',
    };
};
export function getCopyFileEvent(): TrackingEvent {
    return {
        name: 'CopyFile',
    };
};
export function getAddFilesToFilePickerEvent(): TrackingEvent {
    return {
        name: 'AddFilesToFilePicker',
    };
};
export function getTonalValueAutoCorrectionEvent(component: 'diagram' | 'table'): TrackingEvent {
    return {
        name: component === 'diagram'
            ? 'TonalValueAutoCorrectionInDiagram'
            : 'TonalValueAutoCorrectionInTable',
    };
};
export function getDotGainAutoCorrectionEvent(component: 'diagram' | 'table'): TrackingEvent {
    return {
        name: component === 'diagram'
            ? 'DotGainAutoCorrectionInDiagram'
            : 'DotGainAutoCorrectionInTable',
    };
};

