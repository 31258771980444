import { Box, Flex } from '@gmg/gmg-react-components';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';


const UnsavedChangesModal: FunctionComponent<{}> = () => {
    const { t } = useTranslation();

    return (
        <Flex
            flexDirection="column"
            maxWidth="360px"
            gap="8px"
        >
            <Box>
                {t('UnsavedChangesModal.summary', 'You have unsaved corrections.')}
            </Box>
            <StyledBox className="px10_400_weak">
                {t('UnsavedChangesModal.details', 'You are about to leave the current color correction without applying your changes. You can either apply your changes or cancel to continue editing. If you choose to cancel you can afterwards discard your changes by clicking \'Close\'.')}
            </StyledBox>
        </Flex>

    );
};

const StyledBox = styled(Box)`
    line-height: normal;
`;

export default UnsavedChangesModal;