import { FC, MutableRefObject, useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import styled from 'styled-components';
import { ReactComponent as Divider } from '../../assets/icon_column-divider.svg';

export interface ResizerProps {
    onResizeColumn: (distance: number) => void;
    prevDefinedWidth: number | undefined;
    minWidthOfColumn: number;
    targetElementRef: MutableRefObject<HTMLTableCellElement | null>;
};

const ResizerIcon = styled(Divider)`
    cursor: col-resize;
    height: 40px;
    margin-top: auto; 
    opacity: 0;
    fill: ${props => props.theme.colors.greyContrastMediumHigher};
    transition: opacity .2s ease-in-out;
`;

const Resizer: FC<ResizerProps> = (props) => {
    const nodeRef = useRef<null | HTMLSpanElement>(null);
    const [posX, setPosX] = useState(0);

    const appFrame = 72;
    const leftDistanceToColumn = 53;
    const rightPadding = 15;
    const maxWidth = window.innerWidth * 0.45;

    useEffect(() => {

        props.prevDefinedWidth
            ? setPosX(props.prevDefinedWidth + rightPadding)
            : setPosX(props.minWidthOfColumn + rightPadding);

    }, [props.prevDefinedWidth, props.minWidthOfColumn]);

    const calculateColumnSize = (distance: number) => {
        const resizer = nodeRef.current!;
        const calculatedSize = distance - appFrame - leftDistanceToColumn - rightPadding - resizer.clientWidth / 2;

        return calculatedSize < props.minWidthOfColumn
            ? props.minWidthOfColumn
            : calculatedSize;
    };

    const handleOnDrag = (posInX: number) => {
        posInX < maxWidth
            ? props.targetElementRef.current!.style.width = `${calculateColumnSize(posInX)}px`
            : props.targetElementRef.current!.style.width = `${calculateColumnSize(maxWidth)}px`;

    };
    const handleOnStop = (posInX: number) => {
        posInX < maxWidth
            ? props.onResizeColumn(calculateColumnSize(posInX))
            : props.onResizeColumn(calculateColumnSize(maxWidth));
    };

    return (
        <Draggable
            nodeRef={nodeRef}
            axis="x"
            position={{ x: posX, y: 0 }}
            bounds={{
                left: props.minWidthOfColumn,
                right: (maxWidth - appFrame - leftDistanceToColumn),
            }}
            onDrag={(e: any) => { handleOnDrag(e.clientX); }}
            onStop={(e: any) => { handleOnStop(e.clientX); }}
        >
            <span ref={nodeRef}>
                <ResizerIcon aria-label="Resizer" />
            </span>
        </Draggable >
    );
};

export default Resizer;

// INFO about draggable API under : https://github.com/react-grid-layout/react-draggable#draggable