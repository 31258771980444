import { Select } from '@gmg/gmg-react-components';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { SingleVal } from 'src/graphql/ViewModels';
import styled from 'styled-components';

export interface PrintProcessSectionProps {
    availablePrintingProcesses: Array<SingleVal>;
    availableSurfaceFinishings: Array<SingleVal>;
    selectedPrintingProcess: SingleVal | undefined;
    selectedSurfaceFinishing: SingleVal | undefined;
    onChangePrintingParam: (param: 'printingProcess' | 'printFinishing', value: SingleVal) => void;
};

const PrintProcessSection: FunctionComponent<PrintProcessSectionProps> = props => {
    const { t } = useTranslation();

    return (
        <Grid>
            <SelectWrapper>
                <p className="px10_400_weak">{t('PrintProcessSection.printing_process_title', 'Printing process')}</p>
                <Select
                    name="PrintProcessSelection"
                    options={props.availablePrintingProcesses}
                    value={props.selectedPrintingProcess ?? null}
                    width="100%"
                    isSearchable={true}
                    onChange={newValue => { props.onChangePrintingParam('printingProcess', (newValue as SingleVal)); }}
                    error={props.selectedPrintingProcess === undefined}
                />
            </SelectWrapper>

            <SelectWrapper>
                <p className="px10_400_weak">{t('PrintProcessSection.print_finishing_title', 'Print finishing')}</p>
                <Select
                    name="PrintFinishingSelection"
                    options={props.availableSurfaceFinishings}
                    value={props.selectedSurfaceFinishing ?? null}
                    width="100%"
                    isSearchable={true}
                    onChange={newValue => { props.onChangePrintingParam('printFinishing', newValue as SingleVal); }}
                    error={props.selectedSurfaceFinishing === undefined}
                />
            </SelectWrapper>
        </Grid>
    );
};


const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    row-gap: 24px;
    max-height: 100%;
`;

const SelectWrapper = styled.div`
    display: flex;
    flex-direction: column;

    p {
        margin: 0 0 8px;
    }
`;

export default PrintProcessSection;