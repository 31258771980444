import { directionStatus } from '@gmg/gmg-react-components';

export const getSortingValue = (result: number, direction: directionStatus) => {
    if (result === 0) return 0;
    return direction === 'ASC' ? result : result * -1;
};

//getSortByPropertyName
export const getSortByPropertyName = (propertyName: string, direction: directionStatus, currentLanguage: 'en' | 'de') => {
    return (a: any, b: any) => {

        const aValue = a[propertyName];
        const bValue = b[propertyName];
        const result = aValue?.toString().localeCompare(bValue?.toString(), currentLanguage);
        return getSortingValue(result, direction);
    };
};

//sortStringsFn or sortPrimitivesByName
export const getSortStringsFn = (direction: directionStatus, currentLanguage: 'en' | 'de') => {
    return (a: string, b: string) => {

        const result = a.localeCompare(b, currentLanguage);
        return getSortingValue(result, direction);

    };
};

//getSortByDate
export const getSortByDate = (datePropertyName: string, direction: directionStatus) => {
    return (a: any, b: any) => {
        let date1 = new Date(a[datePropertyName]);
        let date2 = new Date(b[datePropertyName]);

        return direction === 'ASC'
            ? date1.getTime() - date2.getTime()
            : date2.getTime() - date1.getTime();
    };
};
