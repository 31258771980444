import { Button } from '@gmg/gmg-react-components';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import WrappedSpinner from 'src/shared/WrappedSpinner';
import styled from 'styled-components';
import FileNameInput from './FileNameInput';

export interface RenameFileModalProps {
    onClose: () => void;
    onSubmit: (data: string) => void;
    isLoading: boolean;
    allOtherFileNamesInFolder: Array<string>;
    currentMeasurementName: string;
};

const RenameFileModal: FunctionComponent<RenameFileModalProps> = props => {
    const { t } = useTranslation();

    return (
        <FileNameInput
            onSubmit={props.onSubmit}
            allOtherFileNamesInFolder={props.allOtherFileNamesInFolder}
            initialFileName={props.currentMeasurementName}
            alreadyExistsErrorMessage={t('Validation.forms.invalidFileName_val', 'Oh no, a file with this name already exists. Please use a different name.')}
        >
            <Wrapper className='px10_400_weak'>
                {props.isLoading &&
                        <WrappedSpinner
                            label={t('RenameFileModal.spinner_validating_prog', 'Validating file name...')}
                            labelPosition='right'
                            svgSize="16px"
                        />
                }
            </Wrapper>
            <ButtonContainer>
                <Button
                    variant="primary"
                    type="submit"
                >
                    {t('Common.ButtonLabels.renameBtn_title', 'Rename')}
                </Button>
                <Button
                    variant="secondary"
                    onClick={props.onClose}
                >
                    {t('Common.ButtonLabels.cancelBtn_title', 'Cancel')}
                </Button>
            </ButtonContainer>
        </FileNameInput>
    );
};

const ButtonContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding-right: 23px;
    & button {
        margin-left: 10px;
    }
`;
const Wrapper = styled.div`
    display: flex;
    align-items: flex-start;
    height: 16px;
    padding-left: 25px;
`;

export default RenameFileModal;