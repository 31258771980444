import { GmgTheme } from '@gmg/gmg-react-components';
import { Characteristic, MeasurementViewModel, OverprintColors, Patch, PatchType } from 'src/graphql/ViewModels';
import { Point } from '../Point';
import { Connector } from './SpiderGrid';
import { ColorItem } from './Spider';
import { colord } from 'colord';
import { EditedInk } from './LegendContainer';
import { Editables, LegendInkRow } from './Legend';


export const getColorsByCharacteristics = (measurements: Array<MeasurementViewModel>): Array<Characteristic> => {

    const result = measurements
        .flatMap(m => m.patches.flatMap(p => p.characteristic))
        .reduce((aggregator: Array<Characteristic>, characteristic) => {
            if (characteristic?.type === 'singleColor' || characteristic?.type === 'symmetricOverprint') {
                const isCharacteristicNotYetAdded = !aggregator.find(c => c!.colorName === characteristic!.colorName);
                if (isCharacteristicNotYetAdded) {
                    aggregator.push(characteristic);
                }
            }
            return aggregator;
        }, []);
    return result;
};

export const calculateDataForSpiderGrid = (
    measurements: MeasurementViewModel[],
    colors: ColorItem[],
    isDuplicatesVisible: boolean,
    isConnectorsVisible: boolean | undefined,
    theme: GmgTheme,
) => {
    const result = measurements.map(viewModel => {

        const mediaPatch = viewModel.patches.find(patch => patch.characteristic?.type === 'media' && patch.isCalculatedAverage);
        const mediaPoint: Point | undefined = mediaPatch
            ? {
                x: mediaPatch.value!.lab[1],
                y: mediaPatch.value!.lab[2],
                hex: mediaPatch.value!.hex,
                id: mediaPatch.id,
            }
            : undefined;

        const legs = colors !== undefined
            ? colors
                .filter(item => item.isSelected)
                .map(({ name }) => ({
                    ink: name,
                    hex: name !== 'Black'
                        ? viewModel.inks.get(name) || viewModel.overPrints.get(name as OverprintColors)
                        : theme.colors.greyContrastMedium,

                    calculatedAveragePatches: viewModel.patches
                        .filter(patch => patch.isCalculatedAverage
                            && patch.characteristic?.colorName === name
                            && patch.characteristic?.type !== 'asymmetricOverprint'),

                    measuredPatches: isDuplicatesVisible
                        ? viewModel.patches
                            .filter(patch => !patch.isCalculatedAverage
                                && patch.characteristic?.colorName === name
                                && patch.characteristic?.type !== 'asymmetricOverprint')
                        : [],
                }))
            : [];

        const connectors: Array<Connector> = [];

        if (isConnectorsVisible) {

            const list = [
                { processColor1: 'Yellow', overprintColor: 'Red (M + Y)', processColor2: 'Magenta' },
                { processColor1: 'Magenta', overprintColor: 'Red (M + Y)', processColor2: 'Yellow' },
                { processColor1: 'Magenta', overprintColor: 'Blue (C + M)', processColor2: 'Cyan' },
                { processColor1: 'Cyan', overprintColor: 'Blue (C + M)', processColor2: 'Magenta' },
                { processColor1: 'Cyan', overprintColor: 'Green (C + Y)', processColor2: 'Yellow' },
                { processColor1: 'Yellow', overprintColor: 'Green (C + Y)', processColor2: 'Cyan' },
            ];

            list.forEach((item => {
                const processColor1Leg = legs.find(l => l.ink === item.processColor1);
                const overprintLeg = legs.find(l => l.ink === item.overprintColor);
                if (processColor1Leg && overprintLeg) {
                    processColor1Leg.calculatedAveragePatches.forEach(startPatch => {
                        // do we find a corresponding "endpoint" on the overprint leg?
                        const processColor1Value = startPatch.inkValues.find(v => v.name === item.processColor1)!.value;
                        const endPatch = overprintLeg.calculatedAveragePatches.find(p => p.inkValues.find(v => v.value === processColor1Value));
                        if (endPatch) {
                            const newConnector = {
                                calculatedAveragePatches: [startPatch],
                            };
                            const patchesNotOnLeg = viewModel.patches
                                .filter(p =>
                                    p.isCalculatedAverage &&
                                    p.inkValues.find(v => v.name === item.processColor1 && v.value === processColor1Value) &&
                                    p.inkValues.find(v => v.name === item.processColor2 && v.value > 0 && v.value < processColor1Value),
                                );

                            newConnector.calculatedAveragePatches.push(endPatch);
                            connectors.push({
                                calculatedAveragePatches: [startPatch, ...patchesNotOnLeg, endPatch],
                                measuredPatches: isDuplicatesVisible
                                    ? viewModel.patches.filter(p =>
                                        !p.isCalculatedAverage &&
                                        p.inkValues.find(v => v.name === item.processColor1 && v.value === processColor1Value) &&
                                        p.inkValues.find(v => v.name === item.processColor2 && v.value > 0 && v.value < processColor1Value),
                                    ) : [],
                            });
                        }
                    });
                }
            }));
        }

        return {
            versionedMeasurementId: viewModel.versionedId,
            mediaPoint,
            legs,
            connectors,
        };
    });

    return result;
};

interface InkRow {
    name: string;
    hex: string;
    mixed: string;
    sortIndex: number;
    type: PatchType;
    values: Map<string, {
        lab: [number, number, number];
        density: number;
    }>;
}

const getInkRowsForSpiderLegend = (measurements: Array<MeasurementViewModel>, theme: GmgTheme) => {

    const inkRows: Array<InkRow> = [];

    const writeColorInRow = (patches: Array<Patch>, colorName: string, versionedId: string) => {
        const lastPatchOfInk = patches[patches.length - 1];

        if (lastPatchOfInk && lastPatchOfInk.value) {
            const isColorFound = inkRows.find((item) => item.name === colorName);
            if (!isColorFound) {
                const hex = lastPatchOfInk.value.hex;
                const mixed = colord(theme.colors.background).mix(hex, 0.1).toHex();
                inkRows.push({
                    name: colorName,
                    hex,
                    mixed,
                    values: new Map(),
                    sortIndex: lastPatchOfInk.characteristic?.sortIndex!,
                    type: lastPatchOfInk.characteristic!.type,
                });
            }
            inkRows.find((item) => item.name === colorName)?.values
                .set(versionedId, {
                    lab: lastPatchOfInk.value.lab,
                    density: lastPatchOfInk.value.density,
                });
        }
    };

    measurements.forEach(measurement => {
        const averagedMediaPatch = measurement.patches.filter(patch => patch.characteristic?.type === 'media' && patch.isCalculatedAverage)[0];

        if (averagedMediaPatch && averagedMediaPatch.value) {
            const isColorFound = inkRows.find(item => item.name === 'Media');
            if (!isColorFound) {
                const hex = averagedMediaPatch.value.hex;
                const mixed = colord(theme.colors.background).mix(hex, 0.1).toHex();
                inkRows.push({
                    name: averagedMediaPatch.characteristic!.colorName,
                    hex,
                    mixed,
                    values: new Map(),
                    sortIndex: averagedMediaPatch.characteristic!.sortIndex,
                    type: averagedMediaPatch.characteristic!.type,
                });
            }
            inkRows.find((item) => item.name === 'Media')?.values.set(measurement.versionedId, {
                lab: averagedMediaPatch.value.lab,
                density: averagedMediaPatch.value.density,
            });
        }

        Array.from(measurement.inks.keys())
            .forEach((ink, index) => {
                const patchesOfInk = measurement.patches
                    .filter(patch => patch.isCalculatedAverage
                        && patch.characteristic?.type === 'singleColor'
                        && patch.inkValues[index].value > 0);

                writeColorInRow(patchesOfInk, ink, measurement.versionedId);

            });

        if (measurement.overPrints) {
            Array.from(measurement.overPrints.keys())
                .forEach(overprintColor => {
                    const overprintPatches = measurement.patches
                        .filter(patch => patch.characteristic?.type === 'symmetricOverprint' && patch.characteristic?.colorName === overprintColor);

                    writeColorInRow(overprintPatches, overprintColor, measurement.versionedId);
                });
        }
    });

    return inkRows;
};

interface GetSortedRowsForSpiderTable {
    measurements: Array<MeasurementViewModel>;
    editedInksState: Array<EditedInk>;
    formatNumber: (num: number | null | undefined) => string | undefined;
    deltaCalculator: (lab1: number[], lab2: number[]) => number;
    theme: GmgTheme;
}

export const getSortedRowsForSpiderTable = (
    { measurements, editedInksState, formatNumber, deltaCalculator, theme }: GetSortedRowsForSpiderTable,
): Array<LegendInkRow> => {

    const unsortedInkRows = getInkRowsForSpiderLegend(measurements, theme);

    const getEditedValueFromState = (inkName: string, valueType: keyof Editables) => {
        return editedInksState.find(ink => ink.inkName === inkName)!.values[valueType];
    };

    const calculateDelta = (inkName: string) => {
        const row = unsortedInkRows.find(colorRow => colorRow.name === inkName)!;
        const lab1 = row.values?.get(measurements[0].versionedId);
        const lab2 = row.values?.get(measurements[1].versionedId);

        return lab1 && lab2
            ? formatNumber(deltaCalculator(lab1.lab, lab2.lab))
            : '-';
    };

    const result = unsortedInkRows
        .sort((a, b) => a.sortIndex - b.sortIndex)
        .map(row => {
            const hasInkChanged = !!editedInksState.find(ink => ink.inkName === row.name);
            return {
                inkName: row.name,
                type: row.type,
                hex: row.hex,
                mixed: row.mixed,
                hasInkBeenChanged: measurements.length !== 1
                    ? undefined
                    : hasInkChanged,
                values: measurements.map(vm => {

                    const values = row.values.get(vm.versionedId); // FIXME
                    const l = hasInkChanged ? getEditedValueFromState(row.name, 'l') : { value: formatNumber(values?.lab[0]) ?? '-', hasChanged: false };
                    const a = hasInkChanged ? getEditedValueFromState(row.name, 'a') : { value: formatNumber(values?.lab[1]) ?? '-', hasChanged: false };
                    const b = hasInkChanged ? getEditedValueFromState(row.name, 'b') : { value: formatNumber(values?.lab[2]) ?? '-', hasChanged: false };
                    const d = hasInkChanged ? getEditedValueFromState(row.name, 'd') : { value: formatNumber(values?.density) ?? '-', hasChanged: false };

                    return {
                        versionedId: vm.versionedId, l, a, b, d,
                    };
                }),
                delta: measurements.length === 2
                    ? calculateDelta(row.name)
                    : undefined,
            };
        });

    return result;
};