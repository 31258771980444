import { FunctionComponent, useContext } from 'react';
import { GmgTheme } from '@gmg/gmg-react-components';
import { useTranslation } from 'react-i18next';
import AppContext from 'src/AppContext';
import { MeasurementViewModel } from 'src/graphql/ViewModels';
import useFormatter from 'src/shared/useFormatter';
import DotgainTable from '../visualization/dotgain/DotGainTable';
import { getDotGainData, getFormattedAndSortedDotGainTableRows } from '../visualization/dotgain/calculationsForDotgain';
import Footer from './shared/Footer';
import InfoBlock from './shared/InfoBlock';
import Section from './shared/Section';
import { Divider, InfoGrid, Page } from './shared/SharedComponents';
import { useTheme } from 'styled-components';

export interface DotGainTablePageProps {
    measurement: MeasurementViewModel;
    header: JSX.Element;
};

const DotGainTablePage: FunctionComponent<DotGainTablePageProps> = (props) => {
    const { formatNumber } = useFormatter();
    const theme: GmgTheme = useTheme();
    const { t } = useTranslation();
    const { measurementSettings } = useContext(AppContext);
    const diagramWidth = 440;
    const diagramHeight = diagramWidth * 2 / 3;
    const isMethodSctv = measurementSettings?.methodCMYK === 'SCTV' || measurementSettings?.methodSpot === 'SCTV';
    const yAxisOffset = isMethodSctv ? 6 : 9;


    const contents = getDotGainData({
        measurements: [props.measurement],
        editedPoints: [],
        selectedColors: Array.from(props.measurement.inks.keys()),
        diagramWidth,
        diagramHeight,
        yAxisOffset,
        theme,
    }).map(data => data.tableData);

    const sortedDotGainRows = getFormattedAndSortedDotGainTableRows(contents, [], formatNumber);

    return (
        <>
            <Page flexDirection="column">
                {props.header}
                <Section title="Dot Gain">
                    <InfoGrid>
                        <InfoBlock
                            title={t('Report.dotgain_calcMethod_cymk_title', 'Tone value calculation method for CMYK')}
                            content={t(
                                `Settings.methodSpotAndCMYK_menu.${measurementSettings.methodCMYK}`,
                                measurementSettings.methodCMYK,
                            )}
                        />
                        <InfoBlock
                            title={t('Report.dotgain_calcMethod_spot_title', 'Tone value calculation method for spot colors')}
                            content={t(
                                `Settings.methodSpotAndCMYK_menu.${measurementSettings.methodSpot}`,
                                measurementSettings.methodSpot,
                            )}
                        />
                        <InfoBlock title={t('Report.spider_densityFilter_cymk_title', 'Density filter for CMYK')}
                            content={t(
                                `Settings.filterCMYK_menu.${measurementSettings.filterCMYK}`,
                                measurementSettings.filterCMYK,
                            )}
                        />
                        <InfoBlock title={t('Report.spider_densityFilter_spot_title', 'Density filter for spot colors')}
                            content={'OpenColor Filter'}
                        />
                    </InfoGrid>
                    <DotgainTable
                        data={sortedDotGainRows}
                        measurements={[props.measurement].map(m => ({
                            name: m.name,
                            versionedId: m.versionedId,
                            inkSet: m.inks,
                        }))}
                        tableDimensions={{ offset: 0, height: 800 }}
                        activeColorCorrection={undefined}
                        onHoverMeasurement={() => { }}
                        onChangeInkValue={() => { }}
                        hasReference={false}
                    />
                </Section>
                <Footer />
            </Page>
            <Divider />
        </>
    );
};


export default DotGainTablePage;