import { Box, Checkbox, Flex, IconAvatar, IconClose, IconDelete, IconHistory, TextWithIcon } from '@gmg/gmg-react-components';
import { FunctionComponent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from 'src/AppContext';
import { MeasurementVersionViewModel } from 'src/graphql/ViewModels';
import styled from 'styled-components';

export interface HistoryProps {
    measurementName: string;
    history: Array<MeasurementVersionViewModel>;
    onClose: () => void;
    onCheckboxClick: (version: number, isLatestVersion: boolean, action: 'select' | 'deselect') => void;
    onFileVersionClick: (version: number, isLatestVersion: boolean) => void;
    selectedVersions: Array<number> | undefined;
    onDeleteVersion: (version: number) => void;
    onMouseMove: (id?: { version: number; versionedId?: string }) => void;
};

const History: FunctionComponent<HistoryProps> = props => {
    const { t } = useTranslation();
    const context = useContext(AppContext);

    const handleDeleteClick = (e: MouseEvent, version: number) => {
        e.stopPropagation();
        context.onShowModal('infoModal', {
            title: t('Common.ButtonLabels.delete', 'Delete'),
            content:
                <Flex
                    flexDirection="column"
                    maxWidth="360px"
                    gap="8px"
                >
                    <Box>
                        {t('History.delete_confirmation', 'Are you sure you want to delete the history step?')}
                    </Box>
                    <StyledBox className="px10_400_weak">
                        {t('History.delete_hint', 'This action will not only remove the particular history step but also the ones which were applied after it.')}
                    </StyledBox>
                </Flex>,
            onOkClick: () => { props.onDeleteVersion(version); },
            btnOkCaption: t('Common.ButtonLabels.delete', 'Delete'),
        });
    };

    return (
        <>
            <Header>
                <Flex justifyContent="flex-end">
                    <StyledCloseIcon
                        aria-label={t('History.close_history_btn', 'Close history')}
                        onClick={props.onClose}
                    />
                </Flex>
                <StyledTextWithIcon
                    text={t('History.history_title', 'History')}
                    icon={<IconHistory />}
                    isHeading
                />
                <span className="px12_600_normal">{props.measurementName}</span>
            </Header>
            <HistoryScrollContainer className="vertical-scrollbar">

                <ul className="px12_400_normal" aria-label='History list'>
                    {props.history.map(({ reason, params, authorName, timestamp, version, isInitialVersion, isLatestVersion, versionedId }, i) => {
                        const isSelected = props.selectedVersions?.includes(version);
                        return (
                            <StyledLi
                                key={`${timestamp}-${i}`}
                                isActive={isSelected}
                                onMouseEnter={() => { props.onMouseMove({ version, versionedId }); }}
                                onMouseLeave={() => { props.onMouseMove(); }}
                                aria-label="History item"
                                role="listitem"
                            >
                                <FirstColumn>
                                    <Left>
                                        <Checkbox
                                            variant="circled"
                                            id={`${version}`}
                                            checked={isSelected}
                                            onChange={(e) => {
                                                e.stopPropagation();
                                                props.onCheckboxClick(version, isLatestVersion, isSelected ? 'deselect' : 'select');
                                            }}
                                        />
                                    </Left>
                                    <Right>
                                        <TopLine shouldHide={i === 0} />
                                        <Dot />
                                        <BottomLine shouldHide={props.history.length - 1 === i} />
                                    </Right>
                                </FirstColumn>
                                <SecondColumn
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        props.onFileVersionClick(version, isLatestVersion);
                                    }}
                                >
                                    <TopSection>
                                        <span className="px12_600_normal" >
                                            {`#${version} ${t(`History.reasons.${reason}_desc`, reason)}`}
                                        </span>
                                        {!isInitialVersion && (
                                            <IconDelete
                                                width="16px"
                                                height="16px"
                                                onClick={(e: any) => { handleDeleteClick(e, version); }}
                                                aria-label={`${t('Common.ButtonLabels.delete', 'Delete')} #${version}`}
                                            />
                                        )}
                                    </TopSection>
                                    <Main>
                                        {params.map(({ key, value }, index) => (
                                            <Flex gap="2px" key={`${key}-${value}`}>
                                                <Key className="px10_400_normal">
                                                    {t(`History.keys.${key}_desc` as any, key)}{key === 'sourceFile' && index > 0 ? ` ${index}:` : ': '}
                                                </Key>
                                                <Value className="px10_400_normal">
                                                    {value}
                                                </Value>
                                            </Flex>
                                        ))}
                                    </Main>
                                    <BottomSection>
                                        <Flex alignItems="center" gap="4px">
                                            <IconAvatar />
                                            <Span className="px10_400_weak">{authorName}</Span>
                                        </Flex>
                                        <span className="px10_400_weak">{timestamp}</span>
                                    </BottomSection>
                                </SecondColumn>
                            </StyledLi>
                        );
                    })}
                </ul>
            </HistoryScrollContainer>
        </>
    );
};

const Left = styled.span`
    display: flex;
    justify-content: flex-end;
    padding: 12px 11px  0 0;
`;
const Right = styled.span`
    display: grid;
    grid-template-rows: 16px 8px auto;
`;

const StyledCloseIcon = styled(IconClose)`
    width:16px ;
    height:16px;
    cursor: pointer;
    color: ${props => props.theme.colors.greyContrastHigh};
    transition: all 0.2s ease-out;

    &:hover {
        color: ${props => props.theme.colors.textContrast};
    }
`;
const StyledTextWithIcon = styled(TextWithIcon)`
    h3 {
        color: ${props => props.theme.colors.textWeak}
    }

`;
const Header = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 12px 12px 16px 16px;
    background-color: ${props => props.theme.colors.backgroundPaper};

    span {
        padding: 0 16px 0 24px;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
`;

const HistoryScrollContainer = styled.div`
    height: calc(100vh - 168px);
    max-height: calc(100vh - 168px);
    background-color: ${props => props.theme.colors.backgroundPaper};
`;
const StyledLi = styled.li<{ isActive?: boolean }>`
    display: grid;
    grid-template-columns: 48px calc(100% - 48px);
    box-sizing: border-box;
    width: 100%;
    list-style-type: none;
    cursor: ${props => props.isActive ? 'default' : 'pointer'};
    border: 1px solid  ${props => props.isActive
        ? props.theme.colors.highlight
        : props.theme.colors.backgroundPaper};
    background-color: ${props => props.isActive
        ? props.theme.colors.greyContrastLowest
        : props.theme.colors.backgroundPaper};

    &:hover {
        background-color: ${props =>
        props.isActive
            ? props.theme.colors.background
            : props.theme.colors.greyContrastLowest};
        border-color:  ${props => props.isActive ? props.theme.colors.highlight : props.theme.colors.background}; 

        label  { /*set custom circled checkbox's border-color */
            border-color:  ${props => props.theme.colors.greyContrastHigh};
        }
    }
   
    svg {
        cursor: pointer;
        color: ${props => props.theme.colors.greyContrastHigh};
        transition: all 0.2s ease-out;

        &:hover {
            color: ${props => props.theme.colors.textContrast};
        }
    }
`;
const FirstColumn = styled.div`
    display: grid;
    grid-template-columns: 1fr 8px;
`;
const LineDefinition = styled.span<{ shouldHide: boolean }>`
    height: 100%;
    width: 2px;
    justify-self: flex-end;
    margin-right: 3px;
    background-color: ${props => props.theme.colors.greyContrastSemiHigh};
    opacity: ${props => props.shouldHide && 0};
`;
const TopLine = styled(LineDefinition)`
    position: relative;

    &::after {
        position: absolute;
        content: '';
        background-color: ${props => props.theme.colors.greyContrastSemiHigh};
        width: 2px;
        height: 3px;
        top: -3px;
    }
`;
const BottomLine = styled(LineDefinition)``;

const Dot = styled.span`
        grid-row: 2 / 3;
        justify-self: flex-end;

        width: 4px;
        height: 4px;
        border-radius: 100px;
        background-color: ${props => props.theme.colors.greyContrastSemiHigh};
        border: 2px solid ${props => props.theme.colors.greyContrastHigh};
`;
const SecondColumn = styled.div`
    padding: 12px;
`;

const TopSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
`;
const Main = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 100%;
    overflow: hidden;
    line-height: 1.2;
`;
const Span = styled.span`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 150px;
`;
const Value = styled(Span)`
    padding-right: 16px;
`;
const Key = styled.span`
    white-space: nowrap;
`;
const BottomSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
`;

const StyledBox = styled(Box)`
    line-height: normal;
`;

export default History;