import { toast } from '@gmg/gmg-react-components';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFileUploadUrl } from 'src/graphql/customHooks/useFileUploadUrl';
import { useMultipleFileUpload } from 'src/graphql/customHooks/useMultipleFileUpload';
import styled from 'styled-components';
import UploadMeasurementButton from './UploadMeasurementButton';

export interface UploadMeasurementFileProps {
    folderId: string;
    isUploadAllowed: boolean;
}

const UploadMeasurementFile: FC<UploadMeasurementFileProps> = props => {
    const { t } = useTranslation();

    const [contentToBeUploaded, setContentToBeUploaded] = useState<Map<string, string> | undefined>(undefined);

    const { data: uploadUrls, error: uploadUrlError } = useFileUploadUrl(
        contentToBeUploaded
            ? Array.from(contentToBeUploaded.keys())
            : undefined,
        props.folderId);

    const { mutateAsync, isLoading } = useMultipleFileUpload();

    useEffect(() => {
        if (!props.isUploadAllowed || !uploadUrls || !contentToBeUploaded || isLoading) return;

        const pendingPromise = mutateAsync({ uploadUrls, contentToBeUploaded, folderId: props.folderId });

        void toast.promise(pendingPromise, {
            loading: t('UploadFile.toast_loading_desc', 'Your measurement data is being imported …'),
            success: () => {
                setContentToBeUploaded(undefined);
                return t('UploadFile.toast_succes_desc', 'Your measurement data has been successfully imported.');
            },
            error: (err) => {
                setContentToBeUploaded(undefined);
                return <Container>
                    <span>
                        {t('UploadFile.toast_error_part1_desc', 'Sorry, we could not import the following measurement data:')}
                    </span>
                    {err.message.split(',').map((fileName: string) =>
                        <span key={fileName} className='px10_400_normal'>
                            {`"${fileName}"`}
                        </span>,
                    )}
                    <span className='px10_400_normal'>
                        {t('UploadFile.toast_error_part2_desc', 'Please make sure that you have chosen valid measurement data.')}
                    </span>
                </Container>;
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadUrls]);

    useEffect(() => {
        if (!uploadUrlError) return;

        toast.error(t('UploadFile.toast_error_uploadurl_desc',
            'Oh no, we are currently facing problems with file uploads! Please try again later.'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadUrlError]);

    const handleFileSelection = (contents: Map<string, string>) => {
        if (!props.isUploadAllowed) return;
        setContentToBeUploaded(contents);
    };

    return (
        <UploadMeasurementButton
            disabled={!props.isUploadAllowed || isLoading}
            onSelectFile={handleFileSelection}
        />
    );
};


const Container = styled.div`
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    align-items: flex-start;
    gap: 8px;
`;

export default UploadMeasurementFile;