import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import AppContext from 'src/AppContext';
import { EditFolderInput, CreateOrEditFolderPayload } from '../generated/types';

const mutation = `mutation EditFolder($input: EditFolderInput!) {
    editFolder(input: $input) {
        id
    }
}`;

export const useEditFolder = (parentFolderId: string) => {
    const context = useContext(AppContext);
    const queryClient = useQueryClient();

    return useMutation(
        async (params: { id: string; name: string }) => {
            await context.populateCacheForAppSynch?.();

            const input: EditFolderInput = {
                id: params.id,
                name: params.name,
            };

            const response = await (context.api.graphql({
                query: mutation,
                variables: { input },
            }) as Promise<{
                data: {
                    editFolder: CreateOrEditFolderPayload;
                };
            }>);

            return !!response.data.editFolder.id;
        },
        {
            onSettled: async () => { // also in case of errors, reload the list to avoid confusion
                await queryClient.invalidateQueries(['folderContent', parentFolderId]);
            },
        },
    );
};