import { Box, Flex, IconCloud, TextWithIcon, notificationToast, toast } from '@gmg/gmg-react-components';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Folder } from 'src/graphql/ViewModels';
import { useDeleteItems } from 'src/graphql/customHooks/useDeleteItems';
import { useFolderContent } from 'src/graphql/customHooks/useFolderContent';
import styled from 'styled-components';
import { FileAction } from '../Inspect';
import StandardCharacterizationNotification, { isStandardCharacterizationNotificationSkipped } from '../notifications/StandardCharacterizationNotification';
import { getErrorMsgForToast } from '../toastErrorHandler';
import BreadCrumb, { PathItem } from './BreadCrumb';
import ButtonsContainer from './ButtonsContainer';
import Explorer from './Explorer';
import ExplorerActions from './ExplorerActions';
import ExplorerSearchBar from './ExplorerSearchBar';

export interface ExplorerContainerProps {
    path: Array<PathItem>;
    setPath: (itemOrItems: Array<PathItem> | PathItem) => void;
    selectedFilesIds: Array<string>;
    onFileAction: (action: FileAction) => void;
}

const ExplorerContainer: FunctionComponent<ExplorerContainerProps> = props => {
    const folderId = props.path[props.path.length - 1].id;
    const { t } = useTranslation();
    const deleteItemsMutation = useDeleteItems(folderId);
    const [filterInput, setFilterInput] = useState('');
    const [selectedFolderIds, setSelectedFolders] = useState<Array<string>>([]);

    const { data, isLoading, isFetching, error } = useFolderContent(folderId || '');

    useEffect(() => {
        if (folderId !== 'STANDARDCHARACTERIZATIONS') return;

        if (!isStandardCharacterizationNotificationSkipped()) {
            notificationToast({
                content: <StandardCharacterizationNotification />,
            });
        }
    }, [folderId]);


    useEffect(() => { // only files and folders that are available in the currently selected folder can be selected (use cases: switch folder, delete item ...)
        if (data === undefined) return;

        for (const measurementId of props.selectedFilesIds) {
            if (!data.measurements.find(m => m.id === measurementId)) {
                props.onFileAction({ type: 'deselect', id: measurementId });
            }
        }
        for (const selectedFolderId of selectedFolderIds) {
            if (!data.subfolders.find(m => m.id === selectedFolderId)) {
                setSelectedFolders(prev => prev.filter(id => id !== selectedFolderId));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const handleDeleteItem = (type: 'folder' | 'measurement', id: string, name: string) => {
        const pendingPromise = deleteItemsMutation.mutateAsync({
            measurementIds: type === 'measurement' ? [id] : [],
            folderIds: type === 'folder' ? [id] : [],
        });

        void toast.promise(pendingPromise, {
            loading: t(
                'FileOrFolderAction.toast_loading_desc',
                'Deleting {{name}}...', {
                    name,
                }),
            success: () => {
                if (type === 'measurement') {
                    props.onFileAction({ type: 'deselect', id });
                }
                return t(
                    'FileOrFolderAction.toast_succes_desc',
                    '{{name}} has been successfully deleted.', {
                        name,
                    });
            },
            error: resp => getErrorMsgForToast(resp, t),
        });
    };

    const handleOnInputChange = (value: string) => {
        if (props.selectedFilesIds.length > 0) {
            props.onFileAction({ type: 'reset' });
        }
        setFilterInput(value);
    };

    useEffect(() => {
        setFilterInput('');
    }, [props.path]);

    useEffect(() => {
        if (error) {
            toast.error(getErrorMsgForToast(error, t));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const handleSelectedFoldersChange = (clickedFolder: Folder) => {
        setSelectedFolders(prev => {
            if (prev.includes(clickedFolder.id)) {
                return prev.filter(id => id !== clickedFolder.id);
            }
            return [...prev, clickedFolder.id];
        });
    };

    const handleResetSelection = () => {
        props.onFileAction({ type: 'reset' });
        setSelectedFolders([]);
    };

    return (
        <Wrapper>
            <ButtonsContainer
                folderId={folderId}
                isFolderEditable={!!data && data.isEditable}
                isNoFileSelected={props.selectedFilesIds.length === 0}
                folderNames={data?.subfolders?.map(folder => folder.name) ?? []}
                measurements={data?.measurements?.map(m => ({ id: m.id, version: m.version })) ?? []}
            />
            <Box bg="backgroundPaper">
                <Box pt="20px" pb="10px" pl="20px" mt="2px">
                    <TextWithIcon
                        text={t('Explorer.TextWithIcon_title', 'cloud files')}
                        icon={<IconCloud />}
                        isHeading
                    />
                </Box>
                <Box pr="20px" pb="26px" pl="43px">
                    <BreadCrumb
                        path={props.path}
                        setPath={props.setPath}
                    />
                </Box>
                {!isLoading && !isFetching && (
                    <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        pr="18px"
                        pb="10px"
                        pl="18px"
                    >
                        <ExplorerActions
                            onResetSelection={handleResetSelection}
                            selectedFiles={data
                                ? props.selectedFilesIds
                                    .filter(id => data.measurements.find(m => m.id === id) !== undefined)
                                    .map(id => {
                                        const { version, name } = data.measurements.find(m => m.id === id)!;
                                        return { id, version, name };
                                    })
                                : []
                            }
                            selectedFolders={new Map(data
                                ? selectedFolderIds
                                    .filter(id => data.subfolders.find(f => f.id === id) !== undefined)
                                    .map(id => [id, data.subfolders.find(f => f.id === id)!.name])
                                : [])}
                            path={props.path}
                            onChangePath={props.setPath}
                            isFolderContentEditable={Boolean(data?.isEditable)}
                            unselectedFileNames={data
                                ? data.measurements!
                                    .filter(m => !props.selectedFilesIds.includes(m.id))
                                    .map(m => m.name)
                                : []}
                            unselectedFolderNames={data
                                ? data.subfolders!
                                    .filter(f => !selectedFolderIds.includes(f.id))
                                    .map(f => f.name)
                                : []}
                        />
                        <ExplorerSearchBar
                            onInputChange={handleOnInputChange}
                            filterInput={filterInput}
                        />
                    </Flex>)}
                <Explorer
                    isLoading={isLoading || isFetching}
                    folderContent={data}
                    path={props.path}
                    setPath={props.setPath}
                    selectedFileIds={props.selectedFilesIds}
                    onFileAction={props.onFileAction}
                    onDeleteItem={handleDeleteItem}
                    filterInput={filterInput}
                    selectedFolderIds={selectedFolderIds}
                    onSelectedFoldersChange={handleSelectedFoldersChange}
                />
            </Box>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    max-width: calc(100vw - 72px);
    max-height: calc(100vh - 150px);
`;

export default ExplorerContainer;
