import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as ReportGraphic } from '../../../assets/graphic_report.svg';

export interface PageHeaderProps {
    reportName: string;
    type?: 'reference' | 'comparison';
};

const Header: FunctionComponent<PageHeaderProps> = (props) => {
    const { t } = useTranslation();

    return (
        <Container>
            <NameWrapper>
                <span className='px10_400_weak'>{t('Report.report_headline_title', 'Print Control Report')}</span>
                <span>{props.reportName}</span>
            </NameWrapper>
            {props.type &&
                <TypeWrapper>
                    <span className='px10_400_weak'>{t('Report.file_type_title', 'File type')}</span>
                    <span>{props.type === 'comparison'
                        ? t('Report.comparison_file_title', 'Comparison')
                        : t('Report.reference_file_title', 'Reference file')}
                    </span>
                </TypeWrapper>
            }
            <StyledGraphic />
        </Container>
    );
};

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 33%);
    align-items: center;
    padding: 0 16px;
    margin-bottom: 24px;
`;

const NameWrapper = styled.div`
    grid-column: 1 / 2;
    display: flex;
    flex-direction: column;
    gap: 4px;
    
    span:nth-of-type(2) {
        font-size: 14px;
        font-weight: 600;
        color: ${props => props.theme.colors.greyContrastHighest};
    }
    `;
const TypeWrapper = styled(NameWrapper)`  
    grid-column: 2 / 3;
    padding-left: 16px;
`;

const StyledGraphic = styled(ReportGraphic)`
    width: 48px;
    height: 56px;
   grid-column: 3 / 4;
   justify-self: end;
`;

export default Header;