import { Box, Flex, SortButton, Tooltip } from '@gmg/gmg-react-components';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SortingOrder } from './Table';
import { getPropertyNameFromMeasurement } from './createPropertyName';
import { ReactComponent as ReferenceIcon } from '../../../assets/icon_referenceChecked.svg';

export interface HeaderProps {
    measurementNames: Map<string, string>;
    inks: Array<string>;
    onSortBtnClick: (btnLabel: string) => void;
    sortingStatus: SortingOrder;
    deltaEAverage: string;
    deltaEMax: string;
    hasReference: boolean;
}

const Header: FunctionComponent<HeaderProps> = props => {
    const { t } = useTranslation();

    const showOnlySingleMeasurement = props.measurementNames.size === 1;
    const showDeltaValues = props.measurementNames.size === 2;

    const getDirection = (column: string) => props.sortingStatus.propertyName === column
        ? props.sortingStatus.direction
        : undefined;

    return (
        <Flex className="px10_400_weak" minWidth='100%'>
            <Flex flexDirection="column" alignItems="center" minWidth="max-content" justifyContent='space-between' >
                {props.measurementNames.size < 3 && (
                    <HeaderCellOuter>{t('Table.chart_title', 'Chart')}</HeaderCellOuter>
                )}
                <HeaderCellOuter className="px10_400_normal">
                    {showOnlySingleMeasurement && <HeaderCellInner className="px10_400_normal" role="columnheader">
                        <SortButton
                            buttonLabel={t('Table.ChartTitles.page_title', 'Page')}
                            onClick={() => props.onSortBtnClick('page')}
                            direction={getDirection('page')}
                        />
                    </HeaderCellInner>}
                    {showOnlySingleMeasurement && <HeaderCellInner role="columnheader">
                        <SortButton
                            buttonLabel={t('Table.ChartTitles.row_title', 'Row')}
                            onClick={() => props.onSortBtnClick('rowIndex')}
                            direction={getDirection('rowIndex')}
                        />
                    </HeaderCellInner>}
                    {showOnlySingleMeasurement && <HeaderCellInner role="columnheader">
                        <SortButton
                            buttonLabel={t('Table.ChartTitles.column_title', 'Column')}
                            onClick={() => props.onSortBtnClick('columnIndex')}
                            direction={getDirection('columnIndex')}
                        />
                    </HeaderCellInner>}
                    {props.measurementNames.size < 3 && (
                        <HeaderCellInner role="columnheader">{t('Table.ChartTitles.patch_title', 'Patch')}</HeaderCellInner>
                    )}
                </HeaderCellOuter>
            </Flex>
            <FlexBorderLeft flexDirection="column" alignItems="center">
                <HeaderCellOuter>{t('Table.index_title', 'Index')}</HeaderCellOuter>
                <HeaderCellOuter className="px10_400_normal">
                    {props.inks.map(ink => (
                        <HeaderCellInnerColor key={ink} role="columnheader">
                            <SortButton
                                buttonLabel={ink.replace(/Pantone/i, 'P.')}
                                onClick={() => props.onSortBtnClick(ink)}
                                direction={getDirection(ink)}
                            />
                        </HeaderCellInnerColor>
                    ))}
                </HeaderCellOuter>
            </FlexBorderLeft>

            {Array.from(props.measurementNames, ([id, name]) => ({ id, name })).map(({ name, id }, index) => (
                <FlexBorderLeft flexDirection="column" alignItems="center" key={id}>
                    <Tooltip message={name} >
                        <HeaderCellOuterName>
                            <NameBox>
                                {index === 0 && props.hasReference &&
                                    <ReferenceIcon aria-label="Reference indicator" />
                                }
                                <span>
                                    {name}
                                </span>
                            </NameBox>
                        </HeaderCellOuterName>
                    </Tooltip>
                    <HeaderCellOuter className="px10_400_normal">
                        <HeaderCellInner margin="left" role="columnheader">
                            <SortButton
                                buttonLabel="L"
                                onClick={() => props.onSortBtnClick(getPropertyNameFromMeasurement('l', id))}
                                direction={getDirection(getPropertyNameFromMeasurement('l', id))}
                            />
                        </HeaderCellInner>
                        <HeaderCellInner role="columnheader">
                            <SortButton
                                buttonLabel="a"
                                onClick={() => props.onSortBtnClick(getPropertyNameFromMeasurement('a', id))}
                                direction={getDirection(getPropertyNameFromMeasurement('a', id))}
                            />
                        </HeaderCellInner>
                        <HeaderCellInner margin="right" role="columnheader">
                            <SortButton
                                buttonLabel="b"
                                onClick={() => props.onSortBtnClick(getPropertyNameFromMeasurement('b', id))}
                                direction={getDirection(getPropertyNameFromMeasurement('b', id))}
                            />
                        </HeaderCellInner>
                    </HeaderCellOuter>
                </FlexBorderLeft>
            ))}

            {showDeltaValues && <Flex flexDirection="column" alignItems="center" minWidth="max-content">
                <FlexBorderLeft flexDirection="column" alignItems="center" minWidth='unset'>
                    <Box>
                        <HeaderCellOuter>{t('Table.deltaColumn.differences_title', 'Differences')}</HeaderCellOuter>
                        <Flex gap='24px' className="px10_400_normal">
                            <HeaderCellOuter>
                                {`ΔE ${t('Table.deltaColumn.average_title', 'Average')}: ${props.deltaEAverage}`}
                            </HeaderCellOuter>
                            <HeaderCellOuter>
                                {`ΔE ${t('Table.deltaColumn.max_title', 'Max')}: ${props.deltaEMax}`}
                            </HeaderCellOuter>
                        </Flex>
                    </Box>
                    <HeaderCellOuter className="px10_400_normal">
                        <HeaderCellInner margin="left" className="px10_400_normal" role="columnheader">
                            <SortButton
                                buttonLabel="ΔE"
                                onClick={() => props.onSortBtnClick('deltaE')}
                                direction={getDirection('deltaE')}
                            />
                        </HeaderCellInner>
                        <HeaderCellInner className="px10_400_normal" role="columnheader">
                            <SortButton
                                buttonLabel="ΔL"
                                onClick={() => props.onSortBtnClick('dL')}
                                direction={getDirection('dL')}
                            />
                        </HeaderCellInner>
                        <HeaderCellInner className="px10_400_normal" role="columnheader">
                            <SortButton
                                buttonLabel="Δa"
                                onClick={() => props.onSortBtnClick('da')}
                                direction={getDirection('da')}
                            />
                        </HeaderCellInner>
                        <HeaderCellInner margin="right" className="px10_400_normal" role="columnheader">
                            <SortButton
                                buttonLabel="Δb"
                                onClick={() => props.onSortBtnClick('db')}
                                direction={getDirection('db')}
                            />
                        </HeaderCellInner>
                    </HeaderCellOuter>
                </FlexBorderLeft>
            </Flex>}
        </Flex >
    );
};

export const definitionsOfwidth = {
    border: 1,
    margin: 5,
    inkCell: 80,
    sortBtnCell: 64,
};

const FlexBorderLeft = styled(Flex)`
    border-left: solid ${definitionsOfwidth.border}px ${props => props.theme.colors.greyContrastSemiLower};
    min-width: unset;
    justify-content: space-between;
`;

const HeaderCellOuter = styled.div`
    display: flex;
    justify-content: center;
    padding: 8px 0;
`;

const NameBox = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
    span {
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 1.6;
    }
`;

const HeaderCellOuterName = styled(HeaderCellOuter)`
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 110px;
    padding-left: 10px;
    padding-right: 10px;

    svg {
        min-height: 16px;
        min-width: 16px;
        max-height: 16px;
        max-width: 16px;
    }
`;

const HeaderCellInner = styled.div<{ margin?: 'left' | 'right' }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${definitionsOfwidth.sortBtnCell}px;
    flex-shrink: 0;
    margin-left: ${props => props.margin === 'left'
        ? `${definitionsOfwidth.margin}px`
        : '0'};
    margin-right: ${props => props.margin === 'right'
        ? `${definitionsOfwidth.margin}px`
        : '0'};
`;

const HeaderCellInnerColor = styled.div`
    display: inline-block;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    width: ${definitionsOfwidth.inkCell}px;
    flex-shrink: 0;
    padding: 0 4px;
`;

export default Header;