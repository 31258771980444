import { Button, Flex, IconArrowLeftSmall, IconDotgain, IconDotgainFill, IconEditFileSettings, IconEqualizer, IconSpider, IconSpiderFill, IconTable, IconTableFill, IconTestchart, IconTestchartFill, IconTonalValue, IconTonalValueFill, ImageButton, NavBar, notificationToast, toast } from '@gmg/gmg-react-components';
import { FC, useContext, useState } from 'react';
import { isChrome as IsChromeUser } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import AppContext, { MeasurementSettings } from 'src/AppContext';
import styled from 'styled-components';
import { ReactComponent as ReportIcon } from '../../assets/icon_report.svg';
import Settings from '../Settings';
import NonChromeUserNotification, { isNonChromeUserNotificationSkipped } from '../notifications/NonChromeUserNotification';
import ReportConfigurationModal from '../report/ReportConfigurationModal';
import { ReportData } from './VisualizationContainer';

interface SecondaryNavProps {
    onChangeMeasurementParams: (type: keyof MeasurementSettings, value: string) => void;
    onClickCloudFileBtn: () => void;
    onShowProcessParamsModal: (ev: any) => void;
    isMeasurementLoading: boolean;
    numberOfMarkedFilesInFilePicker: number;
    reportData: ReportData;
}

const SecondaryNav: FC<SecondaryNavProps> = (props) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { onShowModal, isUnlimitedLicenseAvailable, featureFlags } = useContext(AppContext);
    const [isCreationNotificationDisabled, setIsCreationNotificationDisabled] = useState(isNonChromeUserNotificationSkipped());

    const handleNavigate = (subResource: string) => {
        if (pathname.endsWith(subResource)) return;
        navigate(subResource);
    };

    const onClickCloudFileBtn = () => {
        toast.dismiss('nonChromeUserNotification');
        props.onClickCloudFileBtn();
    };

    return (
        <>
            <Flex bg="backgroundPaper" pl='8px' width='340px'> {/*width = sidebar + padding + frame/2 + 4 Toolbar pl */}
                <ImageButton
                    icon={<IconArrowLeftSmall />}
                    label={t('SecondaryNav.icon_title', 'Cloud Files')}
                    aria-label="Cloud files"
                    onClick={onClickCloudFileBtn}
                />
            </Flex>
            <NavBar
                navItems={[
                    {
                        title: t('SecondaryNav.spider_title', 'Spider'),
                        iconInactive: <IconSpider />,
                        iconActive: <IconSpiderFill />,
                        handleClick: () => { handleNavigate('spider'); },
                        isActive: pathname.endsWith('spider'),
                        isHidden: !isUnlimitedLicenseAvailable,
                    },
                    {
                        title: t('SecondaryNav.tonalValue_title', 'Tonal Value'),
                        iconInactive: <IconTonalValue />,
                        iconActive: <IconTonalValueFill />,
                        handleClick: () => { handleNavigate('tonalvalue'); },
                        isActive: pathname.endsWith('tonalvalue'),
                    },
                    {
                        title: t('SecondaryNav.dotGain_title', 'Dot Gain'),
                        iconInactive: <IconDotgain />,
                        iconActive: <IconDotgainFill />,
                        handleClick: () => { handleNavigate('dotgain'); },
                        isActive: pathname.endsWith('dotgain'),
                        isHidden: !isUnlimitedLicenseAvailable,
                    },
                    {
                        title: t('SecondaryNav.chart_title', 'Chart'),
                        iconInactive: <IconTestchart />,
                        iconActive: <IconTestchartFill />,
                        handleClick: () => { handleNavigate('chart'); },
                        isActive: pathname.endsWith('chart'),
                    },
                    {
                        title: t('SecondaryNav.table_title', 'Table'),
                        iconInactive: <IconTable />,
                        iconActive: <IconTableFill />,
                        handleClick: () => { handleNavigate('table'); },
                        isActive: pathname.endsWith('table'),
                    },
                ]}
            />
            <Flex gap='16px' alignItems='center' mr='20px' ml='auto'>
                {!featureFlags.disableColorCorrections && (
                    <HideableButton
                        variant="primary"
                        onClick={(e) => { props.onShowProcessParamsModal(e); }}
                        aria-label={t('ProcessParam.set_processparams_btn', 'Process Parameters')}
                        icon={<IconEditFileSettings />}
                        disabled={props.numberOfMarkedFilesInFilePicker !== 1 || props.isMeasurementLoading}
                        isHidden={!isUnlimitedLicenseAvailable}
                    >
                        {t('ProcessParam.set_processparams_btn', 'Process Parameters')}
                    </HideableButton>
                )}


                <HideableButton
                    variant='secondary'
                    icon={<ReportIcon />}
                    aria-label="Create report button"
                    onClick={() => {
                        if (IsChromeUser) {
                            onShowModal('inputModal', {
                                title: t('SecondaryNav.create_report_btn', 'Create report'),
                                content: <ReportConfigurationModal
                                    reportData={props.reportData}
                                />,
                            });
                        }
                        if (!IsChromeUser && !isNonChromeUserNotificationSkipped()) {
                            notificationToast({
                                id: 'nonChromeUserNotification',
                                content: <NonChromeUserNotification
                                    onDisableNotification={(v) => {
                                        setIsCreationNotificationDisabled(v);
                                    }}
                                />,
                                variant: 'warning',
                            });
                        }
                    }}
                    disabled={props.isMeasurementLoading || (!IsChromeUser && isCreationNotificationDisabled)}
                    isHidden={!isUnlimitedLicenseAvailable}
                >
                    {t('SecondaryNav.create_report_btn', 'Create report')}
                </HideableButton>

                <SettingsButton
                    aria-label='Advanced Settings'
                    onClick={() => {
                        onShowModal('inputModal', {
                            title: 'Advanced Settings',
                            titleIcon: <IconEqualizer />,
                            content: <Settings onChangeMeasurementParams={props.onChangeMeasurementParams} />,
                        });
                    }}
                />
            </Flex>
        </>
    );
};

const SettingsButton = styled(IconEqualizer)`
    width: 16px;
    height: 16px;
    cursor: pointer;
    color: ${props => props.theme.colors.greyContrastHigh};
    transition: 0.2s ease-in-out;
    &:hover {
        color: ${props => props.theme.colors.textContrast};
    }
`;

const HideableButton = styled(Button) <{ isHidden?: boolean }>`
    display: ${props => props.isHidden ? 'none' : 'inherit'};
`;

export default SecondaryNav;