import { ProcessParamViewModel } from 'src/graphql/ViewModels';

export interface ProcessParamsValidator {
    hasProcessSectionError: boolean;
    hasMediaSectionError: boolean;
    hasInksSectionError: boolean;
    hasError: boolean;
};

const getValidator = (processParams: ProcessParamViewModel | undefined): ProcessParamsValidator => {
    if (processParams === undefined) {
        return {
            hasProcessSectionError: false,
            hasMediaSectionError: false,
            hasInksSectionError: false,
            hasError: false,
        };
    }

    const hasProcessSectionError = processParams.printingProcess === undefined || processParams.printFinishing === undefined;

    const hasMediaSectionError =
        processParams.substrateCategory === undefined ||
        processParams.substrateType === undefined ||
        (processParams.substrateCategory.value.toLowerCase().includes('film') && (
            processParams.filmMaterial === undefined ||
            (processParams.substrateType.value.toLowerCase().includes('transparent') && processParams.printDirection === undefined)
        ));


    const allInkNames: Array<string> = processParams.inks.map(ink => !!ink.newName
        ? ink.newName.toLowerCase()
        : ink.inkName.toLowerCase(),
    );
    const hasInksSectionError =
        allInkNames.length !== new Set(allInkNames).size ||
        processParams.inks.some(ink => ink.inkName === '') ||
        processParams.inks.some(ink => ink.screeningType === undefined) ||
        processParams.inks.some(ink => ink.screeningRulingValue === '') ||
        (processParams.inks.some(ink => !ink.screeningType!.value.toLowerCase().includes('fm') && ink.screeningConfiguration === undefined));

    return {
        hasProcessSectionError,
        hasMediaSectionError,
        hasInksSectionError,
        hasError: hasProcessSectionError || hasMediaSectionError || hasInksSectionError,
    };
};

export { getValidator };
