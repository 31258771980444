import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import DoNotShowAgainCheckBox, { isNotificationSkipped } from 'src/shared/DoNotShowAgainCheckBox';
import styled from 'styled-components';
import { ReactComponent as WarningTriangle } from '../../assets/icon_warning-24.svg';

interface TeaserContextProps {
    onDisableNotification: (v: boolean) => void;
}
const id = 'isRestrictedReportCreationNotificationSkipped';
export const isNonChromeUserNotificationSkipped = () => isNotificationSkipped(id);

const NonChromeUserNotification: FunctionComponent<TeaserContextProps> = (props) => {
    const { t } = useTranslation();

    return (
        <Main>
            <WarningTriangle width={22} height={22} />
            <Content>
                <span className="px12_600_normal">
                    {t('Teaser.NonChromeUserNotification.notification_title',
                        'The report function is not available for your browser')}
                </span>
                <span className="px10_400_normal">
                    {t('Teaser.NonChromeUserNotification.notification_content_desc',
                        'Currently, you cannot create reports on Safari and Mozilla Firefox. Our technical team works behind the scenes to ensure seamless compatibility across all browsers.')}
                </span>
                <DoNotShowAgainCheckBox
                    onClick={(e: any) => {
                        props.onDisableNotification(e.target.checked === true);
                    }}
                    localStorageId={id}
                />
            </Content>
        </Main>
    );
};

const Main = styled.div`
    display: flex;
    margin: auto 0;
    gap: 16px;
    padding: 0px 24px 16px 8px;
`;

const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
`;

export default NonChromeUserNotification;