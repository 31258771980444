import { MeasurementMetadata } from '../../graphql/listMeasurementsMetaData';
import { Maybe, MeasurementMeta } from '../generated/types';
import { MeasurementListItemViewModel } from '../ViewModels';

export const mapToMeasurementListViewModel = (
    source: Array<Maybe<MeasurementMeta>>,
    folderId: string,
    isEditable: boolean): Array<MeasurementListItemViewModel> => {

    return source.map<MeasurementListItemViewModel>((item: Maybe<MeasurementMeta>) => {
        const metadata: MeasurementMetadata = JSON.parse(item!.metadata);
        const d: Date = new Date(item!.versionInformation.timestamp);
        const processParamsDefined = metadata.HasProcessParamsDefined ?? false;

        return {
            id: item!.id,
            name: item!.name || '',
            creationDate: d,
            version: item!.versionInformation.version,
            testchart: metadata.TestchartName,
            device: metadata.MeasuringDevices.join(', '),
            conditions: metadata.MeasurementConditions.join(', '),
            conditionsArray: metadata.MeasurementConditions,
            inks: metadata.InkNames.map(ink => ink.Name).join(', '),
            processParamsDefined,
            isStandardCharacterization: item!.folderId === 'STANDARDCHARACTERIZATIONS',
            folderId,
            isEditable,
        };
    });
};