import { Box, Button, Checkbox, Flex, InputTypeTextReduced, Select } from '@gmg/gmg-react-components';
import { FunctionComponent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import AppContext from 'src/AppContext';
import { getCreateReportEvent } from 'src/tracking';
import styled from 'styled-components';
import { useImmer } from 'use-immer';
import { ReportData } from '../visualization/VisualizationContainer';

export interface ReportConfigurationModalProps {
    reportData: ReportData;
    onClose?: () => void;
};

interface Selection { value: string; label: string }

const ReportConfigurationModal: FunctionComponent<ReportConfigurationModalProps> = props => {
    const { t } = useTranslation();
    const { trackEvent } = useContext(AppContext);
    const [reportName, setReportName] = useState('GMG_Report');
    const [measurementToCompare, setmeasurementToCompare] = useState<Selection | undefined>(undefined);
    const [includedFeaturesInReport, setIncludedFeaturesInReport] = useImmer<Map<string, boolean>>(new Map([
        ['fileHistory', true],
        ['spider', true],
        ['tonalValue', true],
        ['dotGain', true],
    ]));

    const measurementOptions = props.reportData.items.map(item => ({ label: item.name, value: item.id }));
    const selectedMeasurements = props.reportData.items.filter(item => item.isSelected);
    const preSelectedReference = props.reportData.items.find(item => item.isReference);
    const defaultReferenceState = preSelectedReference
        ? { value: preSelectedReference.id, label: preSelectedReference.name }
        : { value: selectedMeasurements[0].id, label: selectedMeasurements[0].name };

    const [referenceMeasurement, setreferenceMeasurement] = useState<Selection>(defaultReferenceState);
    const [isComparisonIncluded, setIsComparisonIncluded] = useState(selectedMeasurements.length > 1);

    const compareMesId = `${isComparisonIncluded && measurementToCompare
        ? measurementToCompare.value
        : ''}`;

    const omittedPageNames = Array.from(includedFeaturesInReport.entries())
        .filter(([_, isVisible]) => isVisible === false)
        .map(([toBeOmittedPageName, _]) => toBeOmittedPageName)
        .join(',');

    const reportPath = `/report/?name=${reportName}&referenceId=${referenceMeasurement.value}&compareId=${compareMesId}&condition=${props.reportData.selectedCondition}&omit=${omittedPageNames}`;

    const handleClickFeatureCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIncludedFeaturesInReport(prev => {
            prev.set(e.target.name, e.target.checked);
        });
    };

    const handleClickAll = (includedAll: boolean) => {
        setIncludedFeaturesInReport(prev => {
            prev.forEach((_, key) => prev.set(key, includedAll));
        });
    };

    return (
        <ModalContainer isSmall={props.reportData.items.length === 1}>
            <Flex flexDirection="column" gap="8px">
                <TitleBox>
                    <span className="px12_600_normal">
                        {t('ReportConfigurationModal.report_name_title', 'Report name')}
                    </span>
                </TitleBox>

                <ContentBox>
                    <InputTypeTextReduced
                        width="100%"
                        aria-label={t('ReportConfigurationModal.create_report_input_title', 'Create report input field')}
                        error={false}
                        value={reportName}
                        onChange={e => {
                            setReportName(e.target.value);
                        }}
                    />
                </ContentBox>
            </Flex>

            {props.reportData.items.length > 1 &&
                <>
                    <Flex flexDirection="column" gap="8px">
                        <TitleBox >
                            <span className="px12_600_normal">
                                {t('ReportConfigurationModal.reference_title', 'Reference')}
                            </span>
                        </TitleBox>
                        <ContentBox>
                            <Select
                                width="100%"
                                name="Reference file"
                                value={referenceMeasurement}
                                options={measurementOptions}
                                placeholder={t('ReportConfigurationModal.dropDown_placeholder.reference_title', 'Select a reference file')}
                                onChange={newValue => {
                                    const selection = newValue as Selection;

                                    if (selection.value === measurementToCompare?.value) {
                                        setmeasurementToCompare(undefined);
                                    }
                                    setreferenceMeasurement(selection);
                                }}
                            />
                        </ContentBox>
                    </Flex>

                    <Flex flexDirection="column" gap="8px">
                        <TitleBox >
                            <span className="px12_600_normal">
                                {t('ReportConfigurationModal.comparison_title', 'Comparison')}
                            </span>
                        </TitleBox>

                        <ContentBox>
                            <Checkbox
                                label={t('ReportConfigurationModal.checkboxes_title.include_comparions_cb', 'Include Comparision')}
                                id="Include Comparision"
                                checked={isComparisonIncluded}
                                onChange={e => { setIsComparisonIncluded(e.target.checked); }}
                            />
                            <Span className="px10_400_weak">
                                {t('ReportConfigurationModal.comparison_cb_desc',
                                    'You have the option to include the comparison with a comparison file in your report.')}
                            </Span>

                            <ComparisonDropDownBox isVisible={isComparisonIncluded}>
                                <span>Comparison file</span>
                                <Select
                                    width="100%"
                                    name="Comparison file"
                                    value={measurementToCompare ?? []}
                                    options={measurementOptions.filter(item => item.value !== referenceMeasurement.value)}
                                    onChange={newVale => {
                                        setmeasurementToCompare(newVale as Selection);
                                    }}
                                    placeholder={t('ReportConfigurationModal.dropDown_placeholder.compariosn_title', 'Select a comparison file')}
                                />
                            </ComparisonDropDownBox>

                        </ContentBox>
                    </Flex>
                </>}
            <Box>
                <TitleBox>
                    <span className="px12_600_normal">
                        {t('ReportConfigurationModal.secondary_label_title', 'Graphics and tables')}
                    </span>
                </TitleBox>

                <Flex flexDirection="column" gap="16px" mt="8px" pl="12px">

                    <Checkbox
                        label={t('ReportConfigurationModal.checkboxes_title.all_cb', 'All')}
                        id="All"
                        onChange={e => handleClickAll(e.target.checked)}
                        checked={Array.from(includedFeaturesInReport.values()).every(checked => checked === true)}
                    />

                    <Flex flexDirection="column" gap="16px" pl="24px">
                        <Checkbox
                            label={t('ReportConfigurationModal.checkboxes_title.spider_cb', 'Spider Web')}
                            id="Spider Web"
                            name="spider"
                            checked={includedFeaturesInReport.get('spider')}
                            onChange={e => {
                                handleClickFeatureCheckbox(e);
                            }}
                        />
                        <Checkbox
                            label={t('ReportConfigurationModal.checkboxes_title.tonal_value_cb', 'Tonal Value')}
                            id="Tonal Value"
                            name="tonalValue"
                            checked={includedFeaturesInReport.get('tonalValue')}
                            onChange={e => {
                                handleClickFeatureCheckbox(e);
                            }}
                        />
                        <Checkbox
                            label={t('ReportConfigurationModal.checkboxes_title.dot_gain_cb', 'Dot Gain')}
                            id="Dot Gain"
                            name="dotGain"
                            checked={includedFeaturesInReport.get('dotGain')}
                            onChange={e => {
                                handleClickFeatureCheckbox(e);
                            }}
                        />
                        <Checkbox
                            label={t('ReportConfigurationModal.checkboxes_title.file_history_cb', 'File history')}
                            id="File history"
                            name="fileHistory"
                            checked={includedFeaturesInReport.get('fileHistory')}
                            onChange={e => {
                                handleClickFeatureCheckbox(e);
                            }}
                        />
                    </Flex>
                </Flex>
            </Box>

            <CTA>
                <NavLink
                    to={reportPath}
                    target="_blank"
                >
                    <Button
                        variant="primary"
                        type="submit"
                        onClick={() => {
                            trackEvent(getCreateReportEvent());
                            props.onClose?.();
                        }}
                    >
                        {t('Common.ButtonLabels.create_btn_title', 'Create')}
                    </Button>
                </NavLink>
                <Button
                    variant="secondary"
                    onClick={() => { props.onClose?.(); }}
                >
                    {t('Common.ButtonLabels.cancelBtn_title', 'Cancel')}
                </Button>
            </CTA>
        </ModalContainer>
    );
};

const ModalContainer = styled.div<{ isSmall: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    min-height: ${props => props.isSmall ? 'min-content' : 576}px;
`;
const ComparisonDropDownBox = styled.div<{ isVisible: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;
    height: min-content;
    max-height: ${props => props.isVisible ? 46 : 0}px; 
    opacity: ${props => props.isVisible ? 1 : 0};
    transition: 0.5s ease max-height, 0.25s ease opacity;
    overflow:  ${props => props.isVisible ? 'visible' : 'hidden'};
`;
const TitleBox = styled(Box)`
    padding: 0 0 4px 12px;
    border-bottom: 1px solid ${props => props.theme.colors.greyContrastSemiLower};
`;

const ContentBox = styled(Box)`
    padding: 0 12px;
`;

const Span = styled.span`
    padding-left: 24px;
`;

const CTA = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    margin-top: auto;

    a {
        text-decoration: none;
    }
    & button {
        margin-left: 10px;
    }
`;

export default ReportConfigurationModal;