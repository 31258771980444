import { Box } from '@gmg/gmg-react-components';
import React, { FC } from 'react';
import useFormatter from 'src/shared/useFormatter';
import styled from 'styled-components';
import PatchSquare from '../PatchSquare';

export interface TooltipProps {
    field: string;
    hex1: string;
    hex2: string | undefined;
    measurements: Map<string, [number, number, number]>;
    inks: Array<{
        inkName: string;
        hex: string;
        percentage: number;
    }>;
    calculateDeltaE: (lab1: [number, number, number], lab2: [number, number, number]) => number;
}

const Ink: FC<{ name: string; hex: string; percentage: number }> = ({ name, hex, percentage }) => {
    const { formatNumber } = useFormatter();
    return (
        <>
            <MiniSquareContainer>
                <MiniSquare bg={hex} />
            </MiniSquareContainer>
            <InkNameCell>{name}</InkNameCell>
            <TextRightAlignedCell>{`${formatNumber(percentage)}`}</TextRightAlignedCell>
        </>);
};

const Tooltip: FC<TooltipProps> = props => {
    const { formatNumber } = useFormatter();

    const labValues = Array.from(props.measurements.values());

    return (
        <>
            <Grid numberOfMeasurements={props.measurements.size} className="px10_400_normal">
                <Header numberOfMeasurements={props.measurements.size} className="px12_400_normal">
                    <PatchSquare
                        hex1={props.hex1}
                        hex2={props.hex2}
                        size="30px"
                    />
                    <Box ml="12px">Patch {props.field}</Box>
                </Header>
                {
                    [0, 1, 2, 3].map(i => {

                        const left = props.inks.length > i
                            ? <Ink
                                name={props.inks[i].inkName}
                                hex={props.inks[i].hex}
                                percentage={props.inks[i].percentage}
                            />
                            : <><div /><span /><span /></>;

                        let right: JSX.Element | null;
                        switch (i) {
                            case 0:
                                right = (
                                    <>
                                        <span></span>
                                        {Array.from(props.measurements.keys()).map(measurementName => (
                                            <MeasurementNameCell key={measurementName}>{measurementName}</MeasurementNameCell>
                                        ))}
                                    </>);
                                break;
                            case 1:
                                right = (
                                    <>
                                        <TextRightAlignedCell>L</TextRightAlignedCell>
                                        {labValues.map((labValue, index) => (
                                            <LabCell key={index}>{formatNumber(labValue[0])}</LabCell>
                                        ))}
                                    </>);
                                break;
                            case 2:
                                right = (
                                    <>
                                        <TextRightAlignedCell>a</TextRightAlignedCell>
                                        {labValues.map((labValue, index) => (
                                            <LabCell key={index}>{formatNumber(labValue[1])}</LabCell>
                                        ))}
                                    </>);
                                break;
                            case 3:
                                right = (
                                    <>
                                        <TextRightAlignedCell>b</TextRightAlignedCell>
                                        {labValues.map((labValue, index) => (
                                            <LabCell key={index}>{formatNumber(labValue[2])}</LabCell>
                                        ))}
                                    </>);
                                break;
                            default:
                                right = null;
                                break;
                        }

                        return (
                            <React.Fragment key={i}>
                                {left}
                                {right}
                            </React.Fragment>);
                    })
                }
                {
                    props.inks.length > 4 && props.inks.filter((_, i) => i > 3).map((_, i) => (
                        <React.Fragment key={props.inks[i + 4].inkName}>
                            <Ink
                                name={props.inks[i + 4].inkName}
                                hex={props.inks[i + 4].hex}
                                percentage={props.inks[i + 4].percentage}
                            />
                            <span />
                            {Array.from(props.measurements.keys()).map(m => <span key={m} />)}
                        </React.Fragment>
                    ))
                }
                {props.measurements.size === 2 && (
                    <DeltaE className="px10_400_weak">
                        ΔE: {formatNumber(props.calculateDeltaE(labValues[0], labValues[1]))}
                    </DeltaE>)}
            </Grid>
        </>);
};

const TextRightAlignedCell = styled.span`
    text-align: right;
`;

const Grid = styled.div<{ numberOfMeasurements: number }>`
    position: absolute;
    bottom: 12px;
    display: inline-grid;
    grid-template-columns: auto auto 44px 32px repeat(${props => props.numberOfMeasurements}, 44px);
    grid-row-gap: 3px;
    background-color: ${props => props.theme.colors.background};
    z-index: 900;
    padding: 8px;
    box-shadow: 0px 1px 8px rgba(0,0,0,0.6);
    line-height: 1.4rem;
`;

const MiniSquareContainer = styled.div`
    position: relative;
    width: 18px;
`;

// use attrs to avoid creation of too many classes
const MiniSquare = styled.div.attrs<{ bg: string | undefined }>(({ bg }) => ({
    style: {
        backgroundColor: bg || 'inherit',
    },
})) <{ bg: string | undefined }>`
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-width: 0.5px;
    border-style: solid;
    border-color: #EFEFEF;
`;

const LabCell = styled.span`
    text-align: right;
    margin-left: 8px;
`;

const MeasurementNameCell = styled.span`
    margin-left: 2px;
    overflow: hidden;
    text-align:right;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const Header = styled.div<{ numberOfMeasurements: number }>`
    grid-column: span ${props => props.numberOfMeasurements + 4};
    margin-bottom: 12px;
    display: flex;
    align-items: center;
`;

const DeltaE = styled.div`
    grid-column: span 6;
    margin-top: 8px;
    display: flex;
    justify-content: flex-end;
`;

const InkNameCell = styled.span`
    margin-right: 4px;
    white-space: nowrap;
`;

export default Tooltip;