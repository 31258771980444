import { useContext } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import AppContext from 'src/AppContext';
import { MoveItemsInput, MoveItemsPayload } from '../generated/types';

const mutation = `mutation MoveItems($input: MoveItemsInput!) {
    moveItems(input: $input) {
        succeeded
    }
}`;

export const useMoveItems = (sourceFolderId: string) => {
    const context = useContext(AppContext);
    const queryClient = useQueryClient();

    return useMutation(
        async (args: { targetFolderId: string; measurementIds: Array<string>; folderIds: Array<string> }) => {

            const input: MoveItemsInput = {
                measurementIds: args.measurementIds,
                folderIds: args.folderIds,
                targetFolderId: args.targetFolderId,
            };

            await context.populateCacheForAppSynch?.();

            const response = await (context.api.graphql({ query: mutation, variables: { input } }) as Promise<{
                data: {
                    moveItems: MoveItemsPayload;
                };
            }>);

            return response.data.moveItems.succeeded;
        },
        {
            onSettled: async (_, __, variables) => {
                await queryClient.invalidateQueries({
                    predicate: q =>
                        q.queryKey[0] === 'folderContent' && (
                            q.queryKey[1] === variables.targetFolderId ||
                            q.queryKey[1] === sourceFolderId ||
                            variables.folderIds.includes(q.queryKey[1] as string)
                        ),
                });
            },
        });
};
