import { IconErrorCircle } from '@gmg/gmg-react-components';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import DoNotShowAgainCheckBox, { isNotificationSkipped } from 'src/shared/DoNotShowAgainCheckBox';
import styled from 'styled-components';

interface EnabledColorCorrectionNotificationProps {};

const id = 'isEnabledColorCorrectionNotificationSkipped';
export const isEnabledColorCorrectionNotificationSkipped = () => isNotificationSkipped(id);

const EnabledColorCorrectionNotification: FunctionComponent<EnabledColorCorrectionNotificationProps> = (props) => {
    const { t } = useTranslation();

    return (
        <Main>
            <IconErrorCircle width={22} height={22} />
            <Content>
                <span className="px12_600_normal">
                    {t('Teaser.EnabledColorCorrectionNotification.title_desc', 'You enabled color correction tools')}
                </span>
                <span className="px10_400_normal">
                    {t('Teaser.EnabledColorCorrectionNotification.content_desc',
                        'Because you have provided all the necessary parameters for your measurement data, you can use the Color corrections tools available in the toolbar to manually edit the data.')}
                </span>
                <DoNotShowAgainCheckBox
                    localStorageId={id}
                />
            </Content>
        </Main>
    );
};

const Main = styled.div`
    display: flex;
    margin: auto 0;
    gap: 16px;
    padding: 0px 24px 16px 8px;
`;

const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;

    button {
        margin-top: 16px;
    }
`;

export default EnabledColorCorrectionNotification;