import { DeltaETypes, FilterCMYK, Illuminant, MeasurementSettings, MethodCMYKANDSpot, Observer } from 'src/AppContext';
import { CmykFilters, CmykMethods, Illuminants, Observers, Setting, ValueParams } from '../generated/types';

export const mapFromMeasurementSettingsViewModel = (source: MeasurementSettings): ValueParams => {
    return {
        labLChParams: {
            illuminant: source.illuminant as Illuminants,
            observer: source.observer as Observers,
        },
        densityToneParams: {
            filterCMYK: source.filterCMYK as CmykFilters,
            methodCMYK: source.methodCMYK as CmykMethods,
            methodSpot: source.methodSpot as CmykMethods,
        },
    };
};

export interface ApplicationSettings {
    measurementSettings: MeasurementSettings;
    theme: string | undefined;
}

export const mapToApplicationSettingsViewModel = (source: Array<Setting>, currentSettings: MeasurementSettings): ApplicationSettings => {

    //TODO: find a way for typeschecking of paramkey

    function getValue<T>(paramKey: string): T | undefined {
        const item = source.find(setting => setting.key === paramKey);
        return item ? item.value as unknown as T : undefined;
    };

    return {
        measurementSettings: {
            illuminant: getValue<Illuminant>('illuminant') || currentSettings.illuminant,
            observer: getValue<Observer>('observer') || currentSettings.observer,
            filterCMYK: getValue<FilterCMYK>('filterCMYK') || currentSettings.filterCMYK,
            methodCMYK: getValue<MethodCMYKANDSpot>('methodCMYK') || currentSettings.methodCMYK,
            methodSpot: getValue<MethodCMYKANDSpot>('methodSpot') || currentSettings.methodSpot,
            deltaE: getValue<DeltaETypes>('deltaE') || currentSettings.deltaE,
        },
        theme: getValue('theme'),
    };
};
