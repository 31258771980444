import { useContext } from 'react';
import AppContext from 'src/AppContext';

const useFormatter = () => {
    const context = useContext(AppContext);

    // note that new Intl.NumberFormat() call is expensive, so we extract it from formatNumber function
    // why do we use a homegrown hook here instead of i18next.t('intlNumber', { val: num, formatParams: { val: { minimumFractionDigits: 2, maximumFractionDigits: 2 }}}) ?
    // because the latter shows very poor performance when called many times!
    const numberFormatter = new Intl.NumberFormat(context.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 });

    return {
        formatNumber: (num: number | undefined | null) => {
            return num !== undefined && num != null
                ? numberFormatter.format(num)
                : undefined;
        },
    };
};

export default useFormatter;