import { useQuery } from '@tanstack/react-query'; // useQueryClient
import { useContext } from 'react';
import AppContext from 'src/AppContext';
import { PredictProcessParamsInput, ProcessParamsPayload } from '../generated/types';
import { mapToProcessParamsViewModel } from '../mapper/mapToProcessParamsViewModel';

const query = `query PredictProcessParams($input: PredictProcessParamsInput!) {
    predictProcessParams(input:  $input) {
        finishing
        inks {
          name
          screen {
            configuration
            ruling
            type
          }
          sequenceIndex
        }
        isReversePrinting
        printProcess
        substrate {
          category
          filmMaterial
          type
        }
      }
}`;

export const useParamPrediction = (measurementId: string, orgId: string, folderId: string, isProcessParamsDefined: boolean) => {
    const context = useContext(AppContext);

    return useQuery({
        queryKey: ['paramPrediction', measurementId],

        queryFn: async () => {
            await context.populateCacheForAppSynch?.();

            const input: PredictProcessParamsInput = {
                measurementId,
                orgId,
                folderId,
            };

            const response = await (context.api.graphql({ query, variables: { input } }) as Promise<{
                data: {
                    predictProcessParams: ProcessParamsPayload;
                };
            }>);

            return mapToProcessParamsViewModel(response.data.predictProcessParams);
        },
        enabled: !isProcessParamsDefined,
    });
};