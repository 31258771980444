import { DeltaE } from '@gmg/gmg-colorsmath';
import { GmgTheme } from '@gmg/gmg-react-components';
import { FunctionComponent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from 'src/AppContext';
import { MeasurementViewModel, ProcessParamViewModel } from 'src/graphql/ViewModels';
import useFormatter from 'src/shared/useFormatter';
import styled, { useTheme } from 'styled-components';
import { LegendInkRow } from '../visualization/spider/Legend';
import { getSortedRowsForSpiderTable } from '../visualization/spider/calculationsForSpider';
import InfoInkRow, { InkRowGrid, Labd, NameContainer } from './InfoInkRow';
import Footer from './shared/Footer';
import InfoBlock from './shared/InfoBlock';
import Section from './shared/Section';
import { Divider, Page } from './shared/SharedComponents';

export interface InfoPageProps {
    header: JSX.Element;
    measurement: MeasurementViewModel;
    condition: string;
    processParams: ProcessParamViewModel | undefined;
    measurementInfo: {
        chartName: string | undefined;
        device: Array<string> | undefined;
    } | undefined;
};

const InfoPage: FunctionComponent<InfoPageProps> = (props) => {
    const { t } = useTranslation();
    const { formatNumber } = useFormatter();
    const theme: GmgTheme = useTheme();
    const { measurementSettings, user } = useContext(AppContext);

    // Should we put in the parent component in order to calculate ones ?
    // because the fn is used in Spiderpages.tsx as well
    const spiderInksData = getSortedRowsForSpiderTable({
        measurements: [props.measurement],
        editedInksState: [],
        deltaCalculator: measurementSettings.deltaE === 'DE76' ? DeltaE.deltaE76 : DeltaE.deltaE00,
        formatNumber,
        theme,
    });

    const getLabdValueForInk = (searchedInkName: string, inkData: LegendInkRow[]) => {
        const { l, a, b, d } = inkData.find(ink => ink.inkName === searchedInkName)!.values[0];

        return {
            l: l.value,
            a: a.value,
            b: b.value,
            d: d.value,
        };
    };

    const inkRows = props.processParams !== undefined
        ? props.processParams.inks.map(ink => ({
            colorHex: props.measurement.inks.get(ink.inkName)!,
            colorName: ink.inkName,
            screeningType: ink.screeningType?.label,
            rulingValue: ink.screeningRulingValue,
            configuration: ink.screeningConfiguration?.label,
            labdValues: getLabdValueForInk(ink.inkName, spiderInksData),
        }))
        : Array.from(props.measurement.inks, ([colorName, colorHex]) => ({
            colorName,
            colorHex,
            screeningType: undefined,
            rulingValue: undefined,
            configuration: undefined,
            labdValues: getLabdValueForInk(colorName, spiderInksData),
        }));

    return (
        <>
            <Page flexDirection="column">
                {props.header}
                <Section title="Contact">
                    <InfoGrid>
                        <InfoBlock
                            title={t('Report.infoPage_email_title', 'Email')}
                            content={user?.email}
                        />
                        <InfoBlock
                            title={t('Report.infoPage_name_title', 'Name')}
                            content={user?.name}
                        />
                        <InfoBlock
                            title={t('Report.infoPage_company_title', 'Company')}
                            content={user?.orgName}
                        />
                    </InfoGrid>
                </Section>
                <Section title="Measurement" mt={32}>
                    <InfoGrid>
                        <InfoBlock
                            title={t('Report.infoPage_testChart_title', 'Test chart name')}
                            content={props.measurementInfo?.chartName}
                        />
                        <InfoBlock
                            title={t('Report.infoPage_measurement_title', 'Measurement')}
                            content={props.measurement.name}
                        />
                        <InfoBlock
                            title={t('Report.infoPage_measurement_device_title', 'Measurement device')}
                            content={props.measurementInfo?.device?.toString()} // in order to turn an Array of string to strings
                        />
                        <InfoBlock
                            title={t('Report.infoPage_measurement_condition_title', 'Measurement conditions')}
                            content={props.condition}
                        />
                        <InfoBlock
                            title={t('Settings.observer_lil', 'Observer')}
                            content={t(
                                `Settings.observer_menu.${measurementSettings.observer}`,
                                `${measurementSettings.observer}°`,
                            )}
                        />
                        <InfoBlock
                            title={t('Settings.illuminant_lil', 'Illuminant')}
                            content={t(
                                `Settings.illuminant_menu.${measurementSettings.illuminant}`,
                                measurementSettings.illuminant,
                            )}
                        />
                    </InfoGrid>
                </Section>
                <Section title="Process" mt={32}>
                    <InfoGrid>
                        <InfoBlock
                            title={t('PrintProcessSection.printing_process_title', 'Printing process')}
                            content={props.processParams?.printingProcess?.label}
                        />
                        <InfoBlock
                            title={t('PrintProcessSection.print_finishing_title', 'Print finishing')}
                            content={props.processParams?.printFinishing?.label}
                        />
                    </InfoGrid>
                </Section>
                <Section title="Media" mt={32}>
                    <InfoGrid>
                        <InfoBlock
                            title={t('MediaSection.media_category_title', 'Substrate category')}
                            content={props.processParams?.substrateCategory?.label}
                        />
                        <InfoBlock
                            title={t('MediaSection.substrate_type_title', 'Substrate type')}
                            content={props.processParams?.substrateType?.label}
                        />
                        {
                            props.processParams?.filmMaterial?.label &&
                            <InfoBlock
                                title={t('MediaSection.film_material_title', 'Film material')}
                                content={props.processParams?.filmMaterial?.label}
                            />
                        }
                        <InfoBlock
                            title={t('MediaSection.print_direction_title', 'Print direction')}
                            content={props.processParams?.printDirection?.label}
                        />
                    </InfoGrid>
                </Section>
                <Section title="Tone value calculation method" mt={32}>
                    <InfoGrid>
                        <InfoBlock
                            title={t('Settings.filterCMYK_desc', 'For CYMK')}
                            content={t(
                                `Settings.methodSpotAndCMYK_menu.${measurementSettings.methodCMYK}`,
                                measurementSettings.methodCMYK,
                            )}
                        />
                        <InfoBlock
                            title={t('Settings.filterSpot_desc', 'For sport colors')}
                            content={t(
                                `Settings.methodSpotAndCMYK_menu.${measurementSettings.methodSpot}`,
                                measurementSettings.methodSpot,
                            )}
                        />
                    </InfoGrid>
                </Section>
                <Section title="Inks" mt={32}>
                    <HeaderRow isOdd={false}>
                        <NameContainer>
                            <span>{t('InksSection.order_title', 'Order')}</span>
                            <span>{t('InksSection.name_title', 'Name')}</span>
                        </NameContainer>
                        <Labd>
                            <span>{'L'}</span>
                            <span>{'a'}</span>
                            <span>{'b'}</span>
                            <span>{'D'}</span>
                        </Labd>
                        <span>{t('InksSection.screening_type_title', 'Screening type')}</span>
                        <span>{t('InksSection.ruling_title', 'Ruling')}</span>
                        <span>{t('InksSection.configuration_title', 'Configuration')}</span>
                    </HeaderRow>
                    {inkRows.map((ink, index) => (
                        <InfoInkRow
                            key={ink.colorHex}
                            isOdd={index % 2 === 0}
                            colorHex={ink.colorHex}
                            colorName={ink.colorName}
                            screeningType={ink.screeningType}
                            rulingValue={ink.rulingValue}
                            configuration={ink.configuration}
                            labdValues={ink.labdValues}
                        />
                    ))}
                </Section>
                <Footer />
            </Page>
            <Divider />
        </>
    );
};

const InfoGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 33%);
    grid-column-gap: 16px;
    grid-template-rows: auto;
`;
const HeaderRow = styled(InkRowGrid)` 
    margin-bottom: 4px;
    color: ${props => props.theme.colors.greyContrastMediumHigher};
    font-size: 12px;
`;

export default InfoPage;