import { FC } from 'react';
import styled from 'styled-components';

export interface StatusIndicatorProps {
    statusIsGreen?: boolean;
}

interface CircleProps {
    statusIsGreen?: boolean;
}

const Circle = styled.span<CircleProps>`
    margin-right: 15px;
    height: 8px;
    width: 8px;
    background-color: ${props => props.statusIsGreen ? props.theme.colors.success : props.theme.colors.warning };
    border-radius: 50%;
    display: inline-block;
`;

const StatusIndicator: FC<StatusIndicatorProps> = (props: StatusIndicatorProps) => {
    return <Circle statusIsGreen={props.statusIsGreen} />;
};

export default StatusIndicator;