import { MutableRefObject, useEffect, useRef } from 'react';

export const useOutsideClick = (callback: (e?: any) => void, disabled: boolean, existingRef?: MutableRefObject<any>) => {
    const ref = useRef(null);

    const currentTarget = existingRef
        ? 'current' in existingRef ? existingRef.current : existingRef
        : 'current' in ref ? ref.current : ref;

    useEffect(() => {

        const handleClick = (event: any) => {
            // check if target is outside of ref element
            if (currentTarget && !(currentTarget as any).contains(event.target)) {
                // might be a check for non functional areas will get necessary, e.g.
                // const tag = event.target.tagName.toLowerCase();
                // if (tag !== 'div' && tag !== 'span' && tag !== 'nav') {
                callback(event);
            };
        };

        if (!disabled) {
            document.addEventListener('click', handleClick, true);
        };

        return () => {
            document.removeEventListener('click', handleClick, true);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled]);

    return existingRef || ref;
};