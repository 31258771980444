import { Flex, GmgTheme, Toggle, Tooltip, toast } from '@gmg/gmg-react-components';
import { FunctionComponent, MutableRefObject, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from 'src/AppContext';
import UnsavedChangesModal from 'src/components/optimize/UnsavedChangesModal';
import { getErrorMsgForToast } from 'src/components/toastErrorHandler';
import { useTonalValueCorrection } from 'src/graphql/customHooks/colorCollection/useToneValueCorrection';
import { diagramFactorCalculator } from 'src/shared/diagramFactorCalculator';
import useFormatter from 'src/shared/useFormatter';
import { useUnSavedChangesmodal } from 'src/shared/useUnSavedChangesModal';
import { getEditTonalValueEvent } from 'src/tracking';
import styled, { useTheme } from 'styled-components';
import { useImmer } from 'use-immer';
import { ReactComponent as IconAverage } from '../../../assets/icon_average.svg';
import SimpleColorSelector from '../SimpleColorSelector';
import { ColorCorrection, Measurements } from '../VisualizationContainer';
import ActionBar from '../spider/ActionBar';
import Gradient from './Gradient';
import TonalValueGrid from './TonalValueGrid';
import TonalValueTable from './TonalValueTable';
import { getDataForTonalValue, getFormattedAndSortedTonaValueTableRows } from './calculationsForTonalValue';

interface TonalValueProps {
    measurementContext: Measurements;
    onHighlightMeasurement: (versionedId?: string) => void;
    activeColorCorrection: ColorCorrection;
    diagramContainerRef: MutableRefObject<any>;
    onCloseColorCorrection: (correctedMeasurementId?: string) => void;
    onStartColorCorrection: () => void;
    isHistoryMode: boolean;
}

export interface EditedTonalValue {
    index: string;
    value: string;
}

const TonalValue: FunctionComponent<TonalValueProps> = ({
    measurementContext,
    onHighlightMeasurement,
    activeColorCorrection,
    diagramContainerRef,
    onCloseColorCorrection,
    onStartColorCorrection,
    isHistoryMode,
}) => {
    const theme: GmgTheme = useTheme();
    const { t } = useTranslation();
    const { trackEvent } = useContext(AppContext);
    const { formatNumber } = useFormatter();
    const [selectedColor, setSelectedColor] = useState<string | undefined>();
    const [isDuplicatesVisible, setIsDuplicatesVisible] = useState<boolean>(true);
    const [editedTonalValues, setEditedTonalValues] = useImmer<Map<string, Array<EditedTonalValue>>>(new Map());
    const updateTonalValueMutation = useTonalValueCorrection(measurementContext.condition);
    const scalingFactor = useMemo(() => diagramFactorCalculator(360), []);
    const diagramSize = 360 * scalingFactor;
    useUnSavedChangesmodal(<UnsavedChangesModal />, handleClickApply, diagramContainerRef, editedTonalValues.size === 0);

    const availableColors = Array.from(new Set(measurementContext.viewmodels
        .flatMap(measurement => Array.from(measurement.inks.keys())),
    ));

    useEffect(() => {
        // useCase: when the selected color is unique, aka exists only in the currently selected Measurement
        // and it is being removed form the FilePicker then we have to switch to another color
        if (!selectedColor || !availableColors.includes(selectedColor)) {
            setSelectedColor(measurementContext.viewmodels[0]
                ? Array.from(measurementContext.viewmodels[0].inks.keys())[0]
                : undefined,
            );
        }
    }, [measurementContext.viewmodels, selectedColor, availableColors]);

    if (!measurementContext.viewmodels.length || !selectedColor || !availableColors.includes(selectedColor)) return null;

    const editedTonalValuesOfSelectedColor = editedTonalValues.get(selectedColor) ?? [];
    const tonalValueData = getDataForTonalValue(measurementContext.viewmodels, selectedColor, theme, diagramSize, editedTonalValuesOfSelectedColor);
    const sortedRows = getFormattedAndSortedTonaValueTableRows(
        tonalValueData.map(data => data.tableData),
        formatNumber,
        editedTonalValuesOfSelectedColor,
    );

    const handleChangeTonalValue = (index: string, value: string) => {
        setEditedTonalValues(prev => {
            const toneValuesOfColor = prev.get(selectedColor!) ?? [];
            const updatedToneValuesOfColor = [
                ...toneValuesOfColor.filter(ink => ink.index !== index),
                { index, value },
            ];
            prev.set(selectedColor!, updatedToneValuesOfColor);
        });
    };

    const handleClickReset = () => {
        setEditedTonalValues(new Map());
    };

    const handleClickClose = () => {
        handleClickReset();
        onCloseColorCorrection();
    };

    function handleClickApply() {
        trackEvent(getEditTonalValueEvent());

        const graphs = Array.from(editedTonalValues.entries()).map(([ink, editedValues]) => ({
            ink,
            values: getDataForTonalValue(measurementContext.viewmodels, ink, theme, diagramSize, editedValues)[0].indexValueSeriesWithEdits,
        }));

        const pendingPromise = updateTonalValueMutation.mutateAsync({
            measurementId: activeColorCorrection!.measurementId,
            graphs,
        });

        onStartColorCorrection();

        void toast.promise(pendingPromise, {
            loading: t('Common.Toast.applying_correction', 'Your corrections are being applied ...'),
            success: () => {
                handleClickReset();
                onCloseColorCorrection(activeColorCorrection!.measurementId);
                return t('Common.Toast.success_saved_changes', 'Your changes have been saved successfully.');
            },
            error: (response: any) => {
                handleClickReset();;
                onCloseColorCorrection();
                return getErrorMsgForToast(response, t);
            },
        });
    };
    const isActionBarVisible = activeColorCorrection?.isLoading === false;

    const toggleBtnLabel = isDuplicatesVisible
        ? t('Common.ToggleBtnLabels.icon_hidefulcrums_tip', 'Hide fulcrums')
        : t('Common.ToggleBtnLabels.icon_showfulcrums_tip', 'Show fulcrums');

    return (
        <Flex flexDirection="column" alignItems="flex-start" mt="6px">
            <SimpleColorSelector
                isRadioSelection={true}
                colors={new Map(Array.from(availableColors).map(ink => [ink, ink === selectedColor]))}
                onChangeSelection={colors => { setSelectedColor(colors[0]); }}
            />
            <Flex>
                <DiagramContainer>
                    <Flex pl="28px" alignItems="end">
                        <Toggle
                            onClick={() => { setIsDuplicatesVisible(!isDuplicatesVisible); }}
                            isOn={isDuplicatesVisible}
                        >
                            <Tooltip message={toggleBtnLabel}>
                                <IconAverage aria-label={toggleBtnLabel} />
                            </Tooltip>
                        </Toggle>
                    </Flex>
                    <TonalValueGrid
                        diagramSize={diagramSize}
                        scalingFactor={scalingFactor}
                        data={tonalValueData.map(data => data.gridData)}
                        highlightedMeasurement={measurementContext.highlightedId}
                        isDuplicatesVisible={isDuplicatesVisible}
                        onChangeTonalValue={handleChangeTonalValue}
                        isColorCorrectionActive={activeColorCorrection?.mode === 'tonalValue'}
                    />
                    {measurementContext.viewmodels.length === 1 && (
                        <Gradient
                            calculatedAverages={tonalValueData[0].indexValueSeries}
                            diagramSize={diagramSize}
                        />)}
                </DiagramContainer>
                <TableContainer>
                    {isActionBarVisible &&
                        <Flex alignItems="center" justifyContent="center">
                            <ActionBar
                                onResetClick={handleClickReset}
                                onCloseClick={handleClickClose}
                                onApplyClick={handleClickApply}
                                haveInksBeenEdited={editedTonalValues.size > 0}
                            />
                        </Flex>
                    }
                    <TonalValueTable
                        measurements={tonalValueData.map(item => ({
                            versionedId: item.tableData.versionedMeasurementId,
                            name: isHistoryMode ? item.tableData.versionLabel : item.tableData.name,
                        }))}
                        data={sortedRows}
                        selectedToneColor={{ name: selectedColor, hex: tonalValueData.filter(i => i.hex.length)[0].hex }}
                        tableDimensions={{ size: diagramSize, reservedSpace: 611 }} // cacl of 611 see in }
                        activeColorCorrection={activeColorCorrection}
                        onHoverMeasurement={onHighlightMeasurement}
                        onChangeTonalValue={handleChangeTonalValue}
                        hasReference={isHistoryMode ? false : measurementContext.hasReference}
                    />
                </TableContainer>
            </Flex>
        </Flex >);
};

const TableContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 48px 1fr;
`;

const DiagramContainer = styled(TableContainer)`
    height: fit-content;
`;

export default TonalValue;