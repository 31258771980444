import { useQuery } from '@tanstack/react-query';
import fileDownload from 'js-file-download';
import { useContext } from 'react';
import AppContext from 'src/AppContext';
import { GqlError } from '../GqlError';
import { GetMeasurementPayloadUrl, GetMeasurementsDownloadUrlInput } from '../generated/types';

const query = `query getMeasurementsDownloadUrl($input: GetMeasurementsDownloadUrlInput!) {
    getMeasurementsDownloadUrl(input: $input) {
        url
    }
}`;

export const useMultipleFileDownload = (ids: Array<string> | undefined, fileName: string) => {
    const context = useContext(AppContext);

    return useQuery<boolean, Error>({
        queryKey: ['multipleFileDownload', ids],

        queryFn: async () => {
            await context.populateCacheForAppSynch?.();

            const input: GetMeasurementsDownloadUrlInput = {
                ids: ids!,
                isCGATS: true,
            };

            const res = await (context.api.graphql({ query, variables: { input } }) as Promise<{
                data: {
                    getMeasurementsDownloadUrl: GetMeasurementPayloadUrl;
                };
                errors?: Array<GqlError>;
            }>);

            const url = res.data.getMeasurementsDownloadUrl.url!;
            const ext = url.includes('.zip?') ? 'zip' : 'txt';
            const response = await fetch(url);
            const content = ext === 'zip'
                ? await response.blob()
                : await response.text();

            // TODO: when BE has been fixed exchanged expression in fileDownload fn
            // const fileName = props.selectedFiles.size > 1
            //     ? `Export_from_${folderName}.zip`
            //     : `ids[0].txt`;

            fileDownload(content, `Export_from_${fileName}.${ext}`);

            return true;
        },
        enabled: ids !== undefined,
        staleTime: 0, // no caching
    });
};