import { Select } from '@gmg/gmg-react-components';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { SingleVal } from 'src/graphql/ViewModels';
import { SubstrateCategory } from 'src/graphql/mapper/mapToProcessParamsViewModel';
import styled from 'styled-components';

export interface MediaSectionProps {
    availableSubstrateCategories: Array<SubstrateCategory>;
    availablePrintDirections: Array<SingleVal>;
    selectedSubstrateCategory: SingleVal | undefined;
    selectedSubstrateType: SingleVal | undefined;
    selectedFilmMaterial: SingleVal | undefined;
    selectedPrintDirection: SingleVal | undefined;
    onChangeSubstrateCategory: (value: SingleVal) => void;
    onChangeSubstrateType: (value: SingleVal) => void;
    onChangeMediaParams: (param: 'filmMaterial' | 'printDirection', value: SingleVal) => void;
};

const MediaSection: FunctionComponent<MediaSectionProps> = props => {
    const { t } = useTranslation();

    const categoryData =
        props.availableSubstrateCategories.find(cat => cat.value === props.selectedSubstrateCategory?.value);

    return (
        <Grid>
            <SelectWrapper>
                <p className="px10_400_weak">{t('MediaSection.media_category_title', 'Substrate category')}</p>
                <Select
                    name="SubstrateCategorySelection"
                    options={props.availableSubstrateCategories.map(({ label, value }) => ({ label, value }))}
                    value={props.selectedSubstrateCategory ?? null}
                    isSearchable={true}
                    width="100%"
                    onChange={newValue => {
                        props.onChangeSubstrateCategory(newValue as SingleVal);
                    }}
                    error={props.selectedSubstrateCategory === undefined}
                />
            </SelectWrapper>
            <SelectWrapper>
                <p className="px10_400_weak">{t('MediaSection.substrate_type_title', 'Substrate type')}</p>
                <Select
                    name="SubstrateTypeSelection"
                    options={categoryData?.substrateTypes!}
                    value={props.selectedSubstrateType ?? null}
                    isSearchable={true}
                    width="100%"
                    onChange={newValue => {
                        props.onChangeSubstrateType(newValue as SingleVal);
                    }}
                    error={props.selectedSubstrateType === undefined}
                />
            </SelectWrapper>
            {props.selectedSubstrateCategory?.value.includes('film') &&
                <>
                    <SelectWrapper>
                        <p className="px10_400_weak">{t('MediaSection.film_material_title', 'Film material')}</p>
                        <Select
                            name="FilmMaterialSelection"
                            options={categoryData!.filmMaterial!}
                            value={props.selectedFilmMaterial ?? null}
                            isSearchable={true}
                            width="100%"
                            onChange={newValue => {
                                props.onChangeMediaParams('filmMaterial', (newValue as SingleVal));
                            }}
                            error={props.selectedFilmMaterial === undefined}
                        />
                    </SelectWrapper>
                    {props.selectedSubstrateType !== undefined && props.selectedSubstrateType.value.includes('transparent') && (
                        <SelectWrapper>
                            <p className="px10_400_weak">{t('MediaSection.print_direction_title', 'Print direction')}</p>
                            <Select
                                name="PrintDirectionSelection"
                                options={props.availablePrintDirections}
                                value={props.selectedPrintDirection ?? null}
                                isSearchable={false}
                                width="100%"
                                onChange={newValue => {
                                    props.onChangeMediaParams('printDirection', (newValue as SingleVal));
                                }}
                                error={props.selectedPrintDirection === undefined}
                            />
                        </SelectWrapper>
                    )}
                </>
            }
        </Grid>
    );
};

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    row-gap: 24px;
`;

const SelectWrapper = styled.div`
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr;
    
    p {
        margin: 0 0 8px;
    }
`;


export default MediaSection;