
import styled, { useTheme } from 'styled-components';
import { GmgTheme } from '@gmg/gmg-react-components';
import { ReactComponent as NoMatchDark } from '../assets/graphic_magnifier-dark-255.svg';
import { ReactComponent as NoMatchLight } from '../assets/graphic_magnifier-light-255.svg';
import { useTranslation } from 'react-i18next';

export interface NoFilterMatchPageProps { };

const FilteredTableEmptyPlaceholder: React.FunctionComponent<NoFilterMatchPageProps> = (props) => {
    const { t } = useTranslation();
    const theme: GmgTheme = useTheme();

    return (
        <TR>
            <td colSpan={8}>
                <DIV>
                    {theme.type === 'dark'
                        ? <NoMatchDark width={255} height={255} aria-label="dark magnifier" />
                        : <NoMatchLight width={255} height={255} aria-label="light magnifier" />
                    }
                    <p className='px14_600_contrast'>
                        {t('FilteredTableEmptyPlaceholder.page_title', 'Oh no, no items match your search.')}
                    </p>
                    <p className='px12_400_normal'>
                        {t('FilteredTableEmptyPlaceholder.page_desc', 'Try a different search.')}
                    </p>
                </DIV>
            </td>
        </TR>
    );
};

const TR = styled.tr`
    height: calc(100vh - 260px);
`;

const DIV = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
      
    p {
        margin: 0;
        letter-spacing: 0.5px;
    }
`;

export default FilteredTableEmptyPlaceholder;