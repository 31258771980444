import { Flex, Tooltip } from '@gmg/gmg-react-components';
import { colord } from 'colord';
import { FunctionComponent, useContext } from 'react';
import styled from 'styled-components';
import { ReactComponent as PercentIcon } from '../../../assets/icon_percent.svg';
import NumberCell from '../NumberCell';
import { ColorCorrection } from '../VisualizationContainer';
import useFormatter from 'src/shared/useFormatter';
import { EditedPoint } from './Dotgain';
import { DotGainTableRow } from './calculationsForDotgain';
import { getDotGainAutoCorrectionEvent } from 'src/tracking';
import AppContext from 'src/AppContext';
import { ReactComponent as ReferenceIcon } from '../../../assets/icon_referenceChecked.svg';


export interface TableProps {
    data: Array<DotGainTableRow>;
    measurements: Array<{
        name: string;
        versionedId: string;
        inkSet: Map<string, string>;
    }>;
    onHoverMeasurement: (id?: string) => void;
    tableDimensions: {
        offset: number;
        height: number;
    };
    activeColorCorrection: ColorCorrection;
    onChangeInkValue: (editedPoint: EditedPoint) => void;
    hasReference: boolean;
}

const DotgainTable: FunctionComponent<TableProps> = props => {
    const { formatNumber } = useFormatter();
    const { trackEvent } = useContext(AppContext);

    const isDotgainCorrectionActive = props.activeColorCorrection?.mode === 'dotgain';

    return (
        <Container
            className="horizontal-scrollbar"
            offset={props.tableDimensions.offset}>
            <StyledTable maxHeight={props.tableDimensions.height}>
                <thead>
                    <tr>
                        <TH>
                            &nbsp;
                        </TH>
                        {props.measurements.map((mes, i) => (
                            <TH
                                className='px10_400_weak'
                                length={mes.inkSet.size}
                                key={mes.versionedId}
                                onMouseEnter={() => props.onHoverMeasurement(mes.versionedId)}
                                onMouseLeave={() => props.onHoverMeasurement()}
                            >
                                <Tooltip message={mes.name}>
                                    <Flex alignItems='center' justifyContent='center' pl="4px" pr="4px" gap="4px">
                                        {i === 0 && props.hasReference &&
                                            <ReferenceIcon aria-label="Reference indicator" />
                                        }
                                        <span>{mes.name}</span>
                                    </Flex>
                                </Tooltip>
                            </TH>

                        ))}
                    </tr>
                    <tr>
                        <TH className="px10_400_normal">
                            Index
                        </TH>
                        {props.measurements.map(mes => (
                            Array.from(mes.inkSet.keys()).map((ink, inkIndex) => (
                                <TH
                                    className="px10_400_normal color"
                                    key={inkIndex}
                                    avoidRightBorder={inkIndex > 0}
                                >
                                    {ink.replace(/pantone/i, 'P.')}
                                </TH>))
                        ))}
                    </tr>
                </thead>
                <tbody className="px10_400_normal vertical-scrollbar">
                    {props.data.map((row, i) => {
                        const indexPercentage = formatNumber(row.indexPercentage);
                        return (
                            <tr key={indexPercentage}>
                                <td>{indexPercentage} %</td>
                                {row.measurements.map(mes => (
                                    mes.inkValues.map(({ inkName, value, hasChanged, range, isReadOnly }) => (
                                        <TdColored
                                            key={`${inkName}-${indexPercentage}`}
                                            isOdd={i % 2 !== 0}
                                            bg={props.measurements.find(m => m.versionedId === mes.versionedId)!.inkSet.get(inkName)!}
                                            className="color"
                                        >
                                            <NumberCell
                                                indicatorIcon={value !== '-' ? <PercentIcon /> : undefined}
                                                value={value}
                                                isHighligted={hasChanged}
                                                bgColor="transparent"
                                                mode={{
                                                    isTextBox: isDotgainCorrectionActive && !isReadOnly,
                                                    readonly:
                                                        !isDotgainCorrectionActive ||
                                                        isReadOnly ||
                                                        Boolean(props.activeColorCorrection?.isLoading),
                                                }}
                                                label={`InkValue of ${inkName} at ${indexPercentage}%`}
                                                onChange={inkValue => {
                                                    props.onChangeInkValue(new EditedPoint({
                                                        ink: inkName,
                                                        index: row.indexPercentage,
                                                        percentageText: inkValue,
                                                    }));
                                                }}
                                                range={range}
                                                onAutoCorrection={() => {
                                                    trackEvent(getDotGainAutoCorrectionEvent('table'));
                                                }}
                                            />
                                        </TdColored>
                                    ))
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </StyledTable>
        </Container>
    );
};

const StyledTable = styled.table<{ maxHeight: number }>`
    width: max-content;
    border-spacing: 0;
    cursor: default;


    th, td {
        padding: 6px 0;
        height: 32px;
        box-sizing: border-box;
        text-align: center;
    }

    thead tr {
        display: block;
        background-color: ${props => props.theme.colors.backgroundPaper};

        .color {
            padding: 0 4px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    tbody {
        display: block;
        overflow-y: auto;
        overflow-x: hidden;
        height: ${props => props.maxHeight - 25}px; /*see calculation in TonalValueTable.tsx */

        tr:nth-child(odd) {
            background-color: ${props => colord(props.theme.colors.background).darken(0.03).toHex()};
        }

        td {
            max-width: 64px;
            min-width: 64px;
        }
    }
`;

const TdColored = styled.td<{ bg: string; isOdd: boolean }>`
    box-sizing: border-box;
    background: ${props =>
        colord(props.isOdd
            ? props.theme.colors.background
            : colord(props.theme.colors.background).darken(0.03).toHex())
            .mix(props.bg, 0.1)
            .toHex()};
            border-left: 1px solid ${props => props.bg};
`;


const TH = styled.th<{ length?: number; avoidRightBorder?: boolean }>`
    min-width: ${props => props.length ? props.length * 64 : 64}px;
    max-width: ${props => props.length ? props.length * 64 : 64}px;

    &:not(:first-child ){
        border-left: ${props => (props.avoidRightBorder ? 'none' : `1px solid ${props.theme.colors.background}`)}; ;
    }


    svg {
        min-height: 16px;
        min-width: 16px;
        max-height: 16px;
        max-width: 16px;
    }
    
    span {
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 1.6;
    }
`;

const Container = styled.div<{ offset: number }>`
    margin-top: 14px;
    max-width: calc(100vw - ${props => props.offset}px);  /* see calc description in Spider.tsx */
    grid-row: 2 / 3;
`;

export default DotgainTable;