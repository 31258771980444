export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `AWSJSON` scalar type provided by AWS AppSync, represents a JSON string that complies with [RFC 8259](https://tools.ietf.org/html/rfc8259).  Maps like "**{\\"upvotes\\": 10}**", lists like "**[1,2,3]**", and scalar values like "**\\"AWSJSON example string\\"**", "**1**", and "**true**" are accepted as valid JSON and will automatically be parsed and loaded in the resolver mapping templates as Maps, Lists, or Scalar values rather than as the literal input strings.  Invalid JSON strings like "**{a: 1}**", "**{'a': 1}**" and "**Unquoted string**" will throw GraphQL validation errors. */
  AWSJSON: any;
};











export type ApplicationSettingsPayload = {
  __typename?: 'ApplicationSettingsPayload';
  settings: Array<Setting>;
};

export type AutoOptimizeInput = {
  transactionId: Scalars['String'];
  measurementIds: Array<Scalars['ID']>;
  measurementName?: Maybe<Scalars['String']>;
};

export type AutoOptimizePayload = {
  __typename?: 'AutoOptimizePayload';
  transactionId: Scalars['String'];
  measurementIds: Array<Scalars['ID']>;
  measurementName?: Maybe<Scalars['String']>;
};

export enum CmykFilters {
  StatusT = 'StatusT',
  StatusE = 'StatusE',
  OpenColor = 'OpenColor'
}

export enum CmykMethods {
  MurrayDavies = 'MurrayDavies',
  Sctv = 'SCTV'
}

export enum ClientType {
  Desktop = 'desktop',
  Webapp = 'webapp'
}

export type ColorCorrectionPayload = {
  __typename?: 'ColorCorrectionPayload';
  measurementId: Scalars['ID'];
  succeeded: Scalars['Boolean'];
};

export type CompleteAutoOptimizeInput = {
  transactionId: Scalars['String'];
  measurementId?: Maybe<Scalars['ID']>;
  measurementName?: Maybe<Scalars['String']>;
  succeeded: Scalars['Boolean'];
};

export type CompleteAutoOptimizePayload = {
  __typename?: 'CompleteAutoOptimizePayload';
  transactionId: Scalars['String'];
  measurementId?: Maybe<Scalars['ID']>;
  measurementName?: Maybe<Scalars['String']>;
  succeeded: Scalars['Boolean'];
};

export type CopyMeasurementInput = {
  id: Scalars['ID'];
  targetFolderId: Scalars['ID'];
  measurementName: Scalars['String'];
};

export type CreateAveragedMeasurementInput = {
  measurementIds: Array<Scalars['ID']>;
  useSmartAveraging: Scalars['Boolean'];
  targetName: Scalars['String'];
};

export type CreateFolderInput = {
  parentFolder: Scalars['ID'];
  orgId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type CreateOrEditFolderPayload = {
  __typename?: 'CreateOrEditFolderPayload';
  id?: Maybe<Scalars['ID']>;
};

export type DeleteFolderInput = {
  id: Scalars['ID'];
};

export type DeleteFolderPayload = {
  __typename?: 'DeleteFolderPayload';
  succeeded: Scalars['Boolean'];
};

export type DeleteItemsInput = {
  measurementIds?: Maybe<Array<Scalars['ID']>>;
  folderIds?: Maybe<Array<Scalars['ID']>>;
};

export type DeleteItemsPayload = {
  __typename?: 'DeleteItemsPayload';
  folderIds: Array<Scalars['ID']>;
  measurementIds: Array<Scalars['ID']>;
  succeeded: Scalars['Boolean'];
};

/**
 *   TableView <--
 * inputs
 */
export type DeleteMeasurementInput = {
  id: Scalars['ID'];
  version: Scalars['Int'];
};

export type DeleteMeasurementPayload = {
  __typename?: 'DeleteMeasurementPayload';
  id: Scalars['ID'];
  succeeded: Scalars['Boolean'];
};

export type DeleteMeasurementVersionInput = {
  id: Scalars['ID'];
  version: Scalars['Int'];
};

export type DeleteMeasurementVersionPayload = {
  __typename?: 'DeleteMeasurementVersionPayload';
  measurementId: Scalars['ID'];
  versionsDeleted: Scalars['Int'];
};

export type DensityToneParams = {
  filterCMYK?: Maybe<CmykFilters>;
  methodCMYK?: Maybe<CmykMethods>;
  methodSpot?: Maybe<CmykMethods>;
};

export type EditFolderInput = {
  id: Scalars['ID'];
  orgId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type Folder = {
  __typename?: 'Folder';
  id: Scalars['ID'];
  name: Scalars['String'];
  fullPath: Array<PathElement>;
  parentFolderId?: Maybe<Scalars['ID']>;
  orgId: Scalars['ID'];
  isTraversable?: Maybe<Scalars['Boolean']>;
  isEmpty?: Maybe<Scalars['Boolean']>;
  isEditable?: Maybe<Scalars['Boolean']>;
};

export enum Formulas {
  De76 = 'DE76',
  De00 = 'DE00'
}

export type GetDownloadUrlInput = {
  id: Scalars['ID'];
  isCGATS: Scalars['Boolean'];
};

export type GetMeasurementBasicsPayload = {
  __typename?: 'GetMeasurementBasicsPayload';
  id: Scalars['ID'];
  name: Scalars['String'];
  version: Scalars['Int'];
  metadata: Scalars['AWSJSON'];
  folderId: Scalars['ID'];
  folder?: Maybe<Folder>;
};

export type GetMeasurementHistoryInput = {
  id: Scalars['ID'];
};

export type GetMeasurementHistoryPayload = {
  __typename?: 'GetMeasurementHistoryPayload';
  versions: Array<MeasurementMeta>;
};

export type GetMeasurementPayloadUrl = {
  __typename?: 'GetMeasurementPayloadUrl';
  url?: Maybe<Scalars['String']>;
};

export type GetMeasurementUploadUrlsPayload = {
  __typename?: 'GetMeasurementUploadUrlsPayload';
  uploadUrls: Array<UploadUrl>;
};

export type GetMeasurementsDownloadUrlInput = {
  ids: Array<Scalars['ID']>;
  isCGATS: Scalars['Boolean'];
};

export type GetUploadUrlsInput = {
  folderId: Scalars['ID'];
  fileNames: Array<Scalars['String']>;
};

export type Group = {
  __typename?: 'Group';
  name: Scalars['String'];
  id: Scalars['ID'];
};

export enum Illuminants {
  A = 'A',
  C = 'C',
  D50 = 'D50',
  D65 = 'D65',
  F11 = 'F11'
}

export type Ink = {
  __typename?: 'Ink';
  Name?: Maybe<Scalars['String']>;
  Color?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export type InkDensityItemInput = {
  inkName: Scalars['String'];
  relativeDensity: Scalars['Float'];
};

export type LabInput = {
  labKey: Scalars['String'];
  labValue: LabItemInput;
};

export type LabItemInput = {
  l: Scalars['Float'];
  a: Scalars['Float'];
  b: Scalars['Float'];
};

export type LabLChParams = {
  illuminant?: Maybe<Illuminants>;
  observer?: Maybe<Observers>;
};

export type ListFoldersInput = {
  parentFolderId?: Maybe<Scalars['ID']>;
};

export type ListGroupsPayload = {
  __typename?: 'ListGroupsPayload';
  group?: Maybe<Array<Maybe<Group>>>;
};

export type ListMeasurementsInput = {
  folderId: Scalars['ID'];
};

/**   Main types */
export type MeasurementMeta = {
  __typename?: 'MeasurementMeta';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['AWSJSON']>;
  folderId: Scalars['ID'];
  versionInformation: VersionInformation;
};

export type MeasurementOptimizationPayload = {
  __typename?: 'MeasurementOptimizationPayload';
  measurementId: Scalars['ID'];
  measurementName: Scalars['String'];
};

export type MoveItemsInput = {
  targetFolderId: Scalars['ID'];
  measurementIds?: Maybe<Array<Scalars['ID']>>;
  folderIds?: Maybe<Array<Scalars['ID']>>;
};

export type MoveItemsPayload = {
  __typename?: 'MoveItemsPayload';
  succeeded: Scalars['Boolean'];
};

export type MutateMeasurementInput = {
  uploadUrl: Scalars['String'];
  folderId: Scalars['ID'];
  clientType: ClientType;
};

export type MutateMeasurementPayload = {
  __typename?: 'MutateMeasurementPayload';
  data?: Maybe<MeasurementMeta>;
};

export type Mutation = {
  __typename?: 'Mutation';
  deleteMeasurement: DeleteMeasurementPayload;
  addMeasurement?: Maybe<MutateMeasurementPayload>;
  setApplicationSettings?: Maybe<ApplicationSettingsPayload>;
  createFolder?: Maybe<CreateOrEditFolderPayload>;
  editFolder?: Maybe<CreateOrEditFolderPayload>;
  renameFolder?: Maybe<CreateOrEditFolderPayload>;
  deleteFolder: DeleteFolderPayload;
  createAveragedMeasurement?: Maybe<MeasurementOptimizationPayload>;
  removeOutliers: OptimizationPayload;
  smooth: OptimizationPayload;
  rescale: OptimizationPayload;
  renameMeasurement: MeasurementMeta;
  replaceMeasurement: MeasurementMeta;
  deleteMeasurementVersion: DeleteMeasurementVersionPayload;
  autoOptimize: AutoOptimizePayload;
  completeAutoOptimize: CompleteAutoOptimizePayload;
  setProcessParameters: SetProcessParametersPayload;
  updateLab: ColorCorrectionPayload;
  updateDensity: ColorCorrectionPayload;
  updateTonalValue: ColorCorrectionPayload;
  updateDotGain: ColorCorrectionPayload;
  copyMeasurement: MeasurementMeta;
  moveItems: MoveItemsPayload;
  deleteItems: DeleteItemsPayload;
};


export type MutationDeleteMeasurementArgs = {
  id: Scalars['ID'];
  version: Scalars['Int'];
};


export type MutationAddMeasurementArgs = {
  input: MutateMeasurementInput;
};


export type MutationSetApplicationSettingsArgs = {
  input: SetApplicationSettingsInput;
};


export type MutationCreateFolderArgs = {
  input: CreateFolderInput;
};


export type MutationEditFolderArgs = {
  input: EditFolderInput;
};


export type MutationRenameFolderArgs = {
  input: RenameFolderInput;
};


export type MutationDeleteFolderArgs = {
  input: DeleteFolderInput;
};


export type MutationCreateAveragedMeasurementArgs = {
  input: CreateAveragedMeasurementInput;
};


export type MutationRemoveOutliersArgs = {
  input: RemoveOutliersInput;
};


export type MutationSmoothArgs = {
  input: SmoothInput;
};


export type MutationRescaleArgs = {
  input: RescaleInput;
};


export type MutationRenameMeasurementArgs = {
  input: RenameMeasurementInput;
};


export type MutationReplaceMeasurementArgs = {
  input: ReplaceMeasurementInput;
};


export type MutationDeleteMeasurementVersionArgs = {
  input: DeleteMeasurementVersionInput;
};


export type MutationAutoOptimizeArgs = {
  input: AutoOptimizeInput;
};


export type MutationCompleteAutoOptimizeArgs = {
  input: CompleteAutoOptimizeInput;
};


export type MutationSetProcessParametersArgs = {
  input: SetProcessParametersInput;
};


export type MutationUpdateLabArgs = {
  input: UpdateLabInput;
};


export type MutationUpdateDensityArgs = {
  input: UpdateDensityInput;
};


export type MutationUpdateTonalValueArgs = {
  input: UpdateTonalValueOrDotGainInput;
};


export type MutationUpdateDotGainArgs = {
  input: UpdateTonalValueOrDotGainInput;
};


export type MutationCopyMeasurementArgs = {
  input: CopyMeasurementInput;
};


export type MutationMoveItemsArgs = {
  input: MoveItemsInput;
};


export type MutationDeleteItemsArgs = {
  input: DeleteItemsInput;
};

export enum Observers {
  O2 = 'O2',
  O10 = 'O10'
}

export type OptimizationPayload = {
  __typename?: 'OptimizationPayload';
  measurementId: Scalars['ID'];
  succeeded: Scalars['Boolean'];
};

export type Patch = {
  __typename?: 'Patch';
  Index?: Maybe<Array<Maybe<Scalars['Int']>>>;
  InkValues?: Maybe<Array<Scalars['Int']>>;
  Values?: Maybe<Array<Value>>;
};

export type PatchListParams = {
  singleColorAndOverprintsOnly?: Maybe<Scalars['Boolean']>;
  includeCalculatedAverages?: Maybe<Scalars['Boolean']>;
  type?: Maybe<PatchType>;
};

export type PatchParams = {
  condition: Scalars['String'];
  patchListParams?: Maybe<PatchListParams>;
  sessionFilter?: Maybe<Scalars['String']>;
  patchValueParams: ValueParams;
};

export enum PatchType {
  CalculatedAverage = 'calculatedAverage',
  Measured = 'measured'
}

/**
 *   TableView inputs <--
 *  Payload types
 * type TableViewPayload{
 *   tableView: TableView
 *  path: MeasurementPath  <- not needed currently, but later when path has be be queried for direct measurement access
 * }
 * type MeasurementPath {
 *   elements: [PathElement!]!
 * }
 */
export type PathElement = {
  __typename?: 'PathElement';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PredictProcessParamsInput = {
  measurementId: Scalars['ID'];
  orgId: Scalars['ID'];
  folderId: Scalars['ID'];
};

export type ProcessParamsInk = {
  __typename?: 'ProcessParamsInk';
  name: Scalars['String'];
  sequenceIndex: Scalars['Int'];
  screen: Screen;
};

/**   remove me */
export type ProcessParamsPayload = {
  __typename?: 'ProcessParamsPayload';
  substrate: Substrate;
  printProcess: Scalars['String'];
  isReversePrinting: Scalars['Boolean'];
  finishing: Scalars['String'];
  inks: Array<ProcessParamsInk>;
};

export type ProcessParamsScreenType = {
  screenTypeKey: Scalars['String'];
  inkName: Scalars['String'];
  newName?: Maybe<Scalars['String']>;
  rulingValue: Scalars['Int'];
  configurationKey?: Maybe<Scalars['Int']>;
  sequenceIndex: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  getMeasurementBasics: GetMeasurementBasicsPayload;
  getMeasurementTableView?: Maybe<TableView>;
  getMeasurementUploadUrls?: Maybe<GetMeasurementUploadUrlsPayload>;
  getMeasurementDownloadUrl?: Maybe<GetMeasurementPayloadUrl>;
  getMeasurementsDownloadUrl?: Maybe<GetMeasurementPayloadUrl>;
  getFolderContent?: Maybe<GetFolderContentPayload>;
  getApplicationSettings?: Maybe<ApplicationSettingsPayload>;
  getMeasurementHistory?: Maybe<GetMeasurementHistoryPayload>;
  predictProcessParams?: Maybe<ProcessParamsPayload>;
  getProcessParams?: Maybe<ProcessParamsPayload>;
};


export type QueryGetMeasurementBasicsArgs = {
  measurementId: Scalars['ID'];
};


export type QueryGetMeasurementTableViewArgs = {
  input: TableViewInput;
};


export type QueryGetMeasurementUploadUrlsArgs = {
  input: GetUploadUrlsInput;
};


export type QueryGetMeasurementDownloadUrlArgs = {
  input: GetDownloadUrlInput;
};


export type QueryGetMeasurementsDownloadUrlArgs = {
  input: GetMeasurementsDownloadUrlInput;
};


export type QueryGetFolderContentArgs = {
  folderId?: Maybe<Scalars['ID']>;
};


export type QueryGetMeasurementHistoryArgs = {
  input: GetMeasurementHistoryInput;
};


export type QueryPredictProcessParamsArgs = {
  input: PredictProcessParamsInput;
};


export type QueryGetProcessParamsArgs = {
  id: Scalars['ID'];
};

export type RemoveOutliersInput = {
  measurementId: Scalars['ID'];
};

export type RenameFolderInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type RenameMeasurementInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  version: Scalars['Int'];
};

export type ReplaceMeasurementInput = {
  id: Scalars['ID'];
  uploadUrl: Scalars['String'];
  version?: Maybe<Scalars['Int']>;
};

export type RescaleInput = {
  measurementId: Scalars['ID'];
  templateMeasurementId: Scalars['ID'];
};

export type Screen = {
  __typename?: 'Screen';
  type: Scalars['String'];
  ruling: Scalars['Float'];
  configuration: Scalars['Int'];
};

export type SetApplicationSettingsInput = {
  settings: SettingsInput;
};

export type SetProcessParametersInput = {
  measurementId: Scalars['ID'];
  reverse: Scalars['Boolean'];
  process: Scalars['String'];
  printFinishing: Scalars['String'];
  substrateCategory: SubstrateCategoryinput;
  screenTypes: Array<ProcessParamsScreenType>;
  isBasedOnPrediction: Scalars['Boolean'];
};

export type SetProcessParametersPayload = {
  __typename?: 'SetProcessParametersPayload';
  saved: Scalars['Boolean'];
};

export type Setting = {
  __typename?: 'Setting';
  key: Scalars['String'];
  value: Scalars['String'];
  appKeys?: Maybe<Array<Scalars['String']>>;
};

export type SettingsInput = {
  key: Scalars['String'];
  value: Scalars['String'];
  appKeys: Array<Scalars['String']>;
};

export type SmoothInput = {
  measurementId: Scalars['ID'];
  smoothingIntensity: Scalars['Int'];
};

export type Subscription = {
  __typename?: 'Subscription';
  onAddMeasurement?: Maybe<MutateMeasurementPayload>;
  onCreateFolder?: Maybe<CreateOrEditFolderPayload>;
  onEditFolder?: Maybe<CreateOrEditFolderPayload>;
  onDeleteFolder?: Maybe<DeleteFolderPayload>;
  onRenameMeasurement?: Maybe<MeasurementMeta>;
  onReplaceMeasurement?: Maybe<MeasurementMeta>;
  onDeleteMeasurement?: Maybe<DeleteMeasurementPayload>;
  onCompleteAutoOptimize?: Maybe<CompleteAutoOptimizePayload>;
  onDeleteItems?: Maybe<DeleteItemsPayload>;
};


export type SubscriptionOnCompleteAutoOptimizeArgs = {
  transactionId: Scalars['String'];
};

export type Substrate = {
  __typename?: 'Substrate';
  category: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  filmMaterial?: Maybe<Scalars['String']>;
};

export type SubstrateCategoryinput = {
  substrateCategoryKey: Scalars['String'];
  substrateType: SubstrateTypeInput;
};

export type SubstrateTypeInput = {
  substrateTypeKey: Scalars['String'];
  filmMaterial?: Maybe<Scalars['String']>;
};

/**   TableView --> */
export type TableView = {
  __typename?: 'TableView';
  Id: Scalars['String'];
  Version: Scalars['Int'];
  Name: Scalars['String'];
  CreationDate?: Maybe<Scalars['String']>;
  HasProcessParamsDefined: Scalars['Boolean'];
  Conditions: Array<Scalars['String']>;
  InkSet?: Maybe<Array<Maybe<Ink>>>;
  ColumnLabels?: Maybe<Array<Maybe<Scalars['String']>>>;
  RowLabels?: Maybe<Array<Maybe<Scalars['String']>>>;
  Patches?: Maybe<Array<Patch>>;
};

/**   TableView inputs --> */
export type TableViewInput = {
  id: Scalars['ID'];
  version?: Maybe<Scalars['Int']>;
  /**  query returns the latest version if not specified */
  patchParams: PatchParams;
};

export type TonalValueOrDotGainGraph = {
  ink: Scalars['String'];
  values: Array<TonalValueOrDotGainValue>;
};

export type TonalValueOrDotGainValue = {
  index: Scalars['Float'];
  percentage: Scalars['Float'];
};

export type UpdateDensityInput = {
  measurementId: Scalars['ID'];
  condition: Scalars['String'];
  inkDensity: Array<InkDensityItemInput>;
  filterCMYK: CmykFilters;
  methodSpot: CmykMethods;
  methodCMYK: CmykMethods;
};

export type UpdateLabInput = {
  measurementId: Scalars['ID'];
  lab: Array<LabInput>;
  condition: Scalars['String'];
  observer: Observers;
  illuminant: Illuminants;
};

export type UpdateTonalValueOrDotGainInput = {
  measurementId: Scalars['ID'];
  condition: Scalars['String'];
  filterCMYK: CmykFilters;
  methodSpot: CmykMethods;
  methodCMYK: CmykMethods;
  graphs: Array<TonalValueOrDotGainGraph>;
};

export type UploadMeasurementInput = {
  uploadUrl: Scalars['String'];
  folderId: Scalars['ID'];
};

export type UploadUrl = {
  __typename?: 'UploadUrl';
  fileName: Scalars['String'];
  url: Scalars['String'];
};

export type UserName = {
  __typename?: 'UserName';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type Value = {
  __typename?: 'Value';
  ConditionIndex: Scalars['Int'];
  SessionIndex: Scalars['Int'];
  Hex: Scalars['String'];
  Lab: Array<Scalars['Float']>;
  LCh: Array<Scalars['Float']>;
  Density?: Maybe<Scalars['Float']>;
  ToneValue?: Maybe<Scalars['Float']>;
};

export type ValueParams = {
  labLChParams?: Maybe<LabLChParams>;
  densityToneParams?: Maybe<DensityToneParams>;
};

export type VersionInformation = {
  __typename?: 'VersionInformation';
  version: Scalars['Int'];
  timestamp: Scalars['String'];
  reason: VersionInformationReason;
  author: VersionInformationAuthor;
  params?: Maybe<Array<VersionInformationParam>>;
};

export type VersionInformationAuthor = {
  __typename?: 'VersionInformationAuthor';
  userId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type VersionInformationParam = {
  __typename?: 'VersionInformationParam';
  key: Scalars['String'];
  value: Scalars['String'];
};

export enum VersionInformationReason {
  Created = 'created',
  Renamed = 'renamed',
  Replaced = 'replaced',
  OutliersRemoved = 'outliers_removed',
  Smoothed = 'smoothed',
  Rescaled = 'rescaled',
  Moved = 'moved',
  Deleted = 'deleted',
  CorrectedMediaLab = 'corrected_MediaLab',
  CorrectedSolidsLab = 'corrected_SolidsLab',
  CorrectedSolidsDensity = 'corrected_SolidsDensity',
  CorrectedTonalValue = 'corrected_TonalValue',
  CorrectedDotGain = 'corrected_DotGain'
}

export type GetFolderContentPayload = {
  __typename?: 'getFolderContentPayload';
  folder?: Maybe<Folder>;
  measurements: Array<MeasurementMeta>;
  subfolders?: Maybe<Array<Folder>>;
};
