import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import AppContext from 'src/AppContext';
import { CreateFolderInput, CreateOrEditFolderPayload } from '../generated/types';

const mutation = `mutation CreateFolder($input: CreateFolderInput!) {
    createFolder(input: $input) {
        id
    }
}`;

export const useCreateFolder = (parentFolderId: string) => {
    const context = useContext(AppContext);
    const queryClient = useQueryClient();

    return useMutation(
        async (name: string) => {

            await context.populateCacheForAppSynch?.();

            const input: CreateFolderInput = {
                parentFolder: parentFolderId,
                name,
            };

            const response = await (context.api.graphql({
                query: mutation,
                variables: { input },
            }) as Promise<{
                data: {
                    createFolder: CreateOrEditFolderPayload;
                };
            }>);

            return !!response.data.createFolder.id;
        },
        {
            onSettled: async () => { // also in case of errors, reload the list to avoid confusion
                await queryClient.invalidateQueries(['folderContent', parentFolderId]);
            },
        },
    );
};