import { useQuery } from '@tanstack/react-query'; // useQueryClient
import { useContext } from 'react';
import AppContext from 'src/AppContext';
import { mapToProcessParamsViewModel } from '../mapper/mapToProcessParamsViewModel';
import { ProcessParamsPayload } from '../generated/types';

const query = `query GetProcessParams($id: ID!) {
  getProcessParams(id:  $id) {
        finishing
        inks {
          name
          sequenceIndex
          screen {
            configuration
            ruling
            type
          }
        }
        isReversePrinting
        printProcess
        substrate {
          category
          filmMaterial
          type
        }
      }
}`;

export const useGetProcessParams = (measurementId: string, isProcessParamsDefined: boolean) => {
    const context = useContext(AppContext);

    return useQuery({
        queryKey: ['getProcessParams', measurementId],

        queryFn: async () => {
            await context.populateCacheForAppSynch?.();

            const response = await (context.api.graphql({ query, variables: { id: measurementId } }) as Promise<{
                data: {
                    getProcessParams: ProcessParamsPayload;
                };
            }>);

            return mapToProcessParamsViewModel(response.data.getProcessParams);
        },
        enabled: isProcessParamsDefined,
        refetchOnWindowFocus: false,
    });
};