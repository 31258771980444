import { Flex } from '@gmg/gmg-react-components';
import { FC, ReactNode } from 'react';

export interface MainProps {
    secondaryNav: JSX.Element;
    children: ReactNode;
};

const Main: FC<MainProps> = props => {
    return (
        <>
            <Flex
                flexDirection="column"
                minHeight="100%"
                minWidth="100%" // normally full window width
                width="max-content" // but even wider if content is so wide that a scrollbar is visible
                maxWidth="calc(100vw - 72px)" // max width is the Full width - appframe nav 72px
            >
                <Flex
                    height="44px"
                    mb="2px"
                    bg="backgroundPaper"
                    alignItems="center"
                    px="12px"
                >
                    {props.secondaryNav}
                </Flex>
                {props.children}
            </Flex>
        </>
    );
};

export default Main;