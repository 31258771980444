import { IconKebap } from '@gmg/gmg-react-components';
import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { PathItem } from './BreadCrumb';


export interface DotMenuProps {
    items: Array<PathItem>;
    onNavigateToFolder: (id: string) => void;
};

const DotMenu: FunctionComponent<DotMenuProps> = (props) => {

    const [isVisible, setIsVisible] = useState(false);

    return (
        <DIV>
            <IconKebap
                width='14px'
                height='14px'
                onClick={() => setIsVisible(!isVisible)}
                aria-label='Dot Menu Icon'
            />
            {isVisible && (
                <MenuWrapper onMouseLeave={() => setIsVisible(false)}>
                    {props.items.map(item => (
                        <Item
                            key={item.name}
                            className='px10_600_normal'
                            aria-labelledby='dot menu item'
                            onClick={() => props.onNavigateToFolder(item.id!)}
                        >
                            {item.name}
                        </Item>
                    ))}
                </MenuWrapper>
            )}
        </DIV>
    );
};

const DIV = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    cursor: pointer;
    z-index: 10;
`;
const MenuWrapper = styled.div`
    position:  absolute;
    left: 0px;
    top: 20px;
    display: flex;
    flex-direction: column;
    min-width: 130px;
    border-radius: ${props => props.theme.radii.default}px;
`;

const Item = styled.span`
    display: flex;
    align-items: center;
    padding: 7px 10px;
    background-color: ${props => props.theme.colors.background};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    
    &:not(:last-child) {
        border-bottom: 0.5px solid ${props => props.theme.colors.greyContrastMediumLower};
    }
    &:hover {
        background-color: ${props => props.theme.colors.greyContrastLowest};
    }
`;

export default DotMenu;