import { FunctionComponent } from 'react';
import styled from 'styled-components';

export interface HistoryRowProps {
    date: string;
    entry: string;
    attributes: Array<string>;
    isOdd: boolean;
};

const HistoryRow: FunctionComponent<HistoryRowProps> = (props) => {

    return (
        <HistoryRowGrid isOdd={props.isOdd}>
            <span className="px12_400_normal">{props.date}</span>
            <span className="px12_400_normal">{props.entry}</span>
            <span className="px12_400_normal">{props.attributes.toString()}</span>
        </HistoryRowGrid>
    );
};

export const HistoryRowGrid = styled.div<{ isOdd?: boolean }>`
    width: 100%;
    display: grid;
    grid-template-columns: 120px 140px 1fr;
    align-items: stretch;
    column-gap: 16px;
    max-height: 100%;
    cursor: default;
    background-color: ${props => props.isOdd ? props.theme.colors.greyContrastLowest : 'unset'};
    margin-bottom: 4px;
    padding: 6px;
    margin-left: -6px;
`;

export default HistoryRow;