import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { InputTypeTextReduced, Select } from '@gmg/gmg-react-components';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SingleVal } from 'src/graphql/ViewModels';
import { ScreeningType } from 'src/graphql/mapper/mapToProcessParamsViewModel';
import styled from 'styled-components';
import { ReactComponent as LpiIcon } from '../../../assets/icon_lpi.svg';
import { ReactComponent as MuIcon } from '../../../assets/icon_mu.svg';
import { ReactComponent as Draggable } from '../../../assets/icon_reorder.svg';

export interface InkSectionRowProps {
    inkName: string;
    hash: string;
    hex: string | undefined;
    availableScreeningTypes: Array<ScreeningType>;
    selectedScreeningType: SingleVal | undefined;
    selectedScreeningRulingValue: string | undefined;
    selectedScreeningConfiguration: SingleVal | undefined;
    existingInkNames: Array<string>;
    onChangeInkName: (hash: string, newName: string) => void;
    onChangeScreeningType: (hash: string, value: SingleVal) => void;
    onChangeRulingValue: (hash: string, value: string) => void;
    onChangeConfiguration: (hash: string, value: SingleVal) => void;
};

const InkSectionRow: FunctionComponent<InkSectionRowProps> = props => {
    const { t } = useTranslation();

    const [renamedInk, setRenamedInk] = useState<string | undefined>(undefined);
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: props.hash });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const icon = !props.selectedScreeningType
        ? undefined
        : props.selectedScreeningType.value.includes('FM')
            ? <MuIcon aria-label='Micrometer Icon' />
            : <LpiIcon aria-label='Lpi Icon' />;

    const handleChangeInkName = (newName: string) => {
        setRenamedInk(newName);
        props.onChangeInkName(props.hash, newName);
    };

    const hasNameCollision = renamedInk
        ? props.existingInkNames.filter(existingInkName => existingInkName.toLowerCase() === renamedInk.toLowerCase()).length > 1
        : false;

    return (
        <div ref={setNodeRef} style={style} >
            <Row role='row'>
                <InkName>
                    <span
                        aria-label={`draggable for row containing ${props.inkName} ink`}
                        {...attributes}
                        {...listeners}
                    >
                        <StyledDraggable />
                    </span>

                    <Square size="14px" color={props.hex ?? 'transparent'} />

                    <InputTypeTextReduced
                        onChange={(e) => {
                            handleChangeInkName(e.target.value);
                        }}
                        value={props.inkName}
                        aria-label={`${props.inkName} ${t('InksSection.ink_name_inputfield_label', 'input field')}`}
                        error={props.inkName === undefined || hasNameCollision}
                    />
                </InkName>

                <Select
                    name={`${props.inkName}_ScreeningTypeSelection`}
                    options={props.availableScreeningTypes.map(({ value, label }) => ({ value, label }))}
                    value={props.selectedScreeningType ?? null}
                    isSearchable={false}
                    width="100%"
                    onChange={newValue => {
                        props.onChangeScreeningType(props.hash, newValue as SingleVal);
                    }}
                    error={props.selectedScreeningType === undefined}
                />

                <InputTypeTextReduced
                    // only allow numeric keyboard press
                    // https://stackoverflow.com/questions/43687964/only-numbers-input-number-in-react/65138192#65138192:~:text=22-,Answers,-Sorted%20by%3A
                    // TODO: it is marked as deprecated ... search for better solution!
                    onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }}
                    onChange={(e) => {
                        if (Number(e.target.value) > 600) return;
                        props.onChangeRulingValue(props.hash, e.target.value);
                    }}
                    value={props.selectedScreeningRulingValue}
                    icon={icon}
                    iconPosition="right"
                    width="100%"
                    aria-label={`${props.inkName}_RulingValue`}
                    error={props.selectedScreeningRulingValue === ''}
                />

                <Select
                    name={`${props.inkName}_ScreeningConfiguration`}
                    placeholder={!props.selectedScreeningType || props.selectedScreeningType?.value.includes('FM') ? '' : 'Select ...'}
                    options={props.availableScreeningTypes
                        .find(type => type.value === props.selectedScreeningType?.value)?.configurations ?? []
                    }
                    value={props.selectedScreeningConfiguration ?? null}
                    isSearchable={false}
                    width="100%"
                    isDisabled={props.selectedScreeningType === undefined || props.selectedScreeningType?.value.includes('FM')}
                    onChange={newValue => {
                        props.onChangeConfiguration(props.hash, newValue as SingleVal);
                    }}
                    error={props.selectedScreeningConfiguration === undefined}
                />
            </Row>
        </div>
    );
};

export const Row = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 140px 1fr 1fr 1fr;
    align-items: stretch;
    column-gap: 16px;
    max-height: 100%;
    cursor: default;
`;

const InkName = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const Square = styled.div<{ size: string; color: string }>`
    width: ${props => props.size};
    height: ${props => props.size};
    min-width: ${props => props.size};
    min-height: ${props => props.size};
    background-color: ${props => props.color};
    border: 0.5px solid ${props => props.theme.colors.greyContrastHighest};
    box-sizing: border-box;
`;


const StyledDraggable = styled(Draggable)`
    width: 16px;
    height: 16px;
    cursor: pointer;

    &:hover {
        color: ${props => props.theme.colors.textContrast};
    }
`;

export default InkSectionRow;