import { IconInfo } from '@gmg/gmg-react-components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const FunctionUnavailable: React.FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <UnavailableWrapper>
            <div>
                <IconInfo />
            </div>
            <div>
                {t('ToolBar.FunctionUnavailable', 'This function is currently unavailable. We are already investigating. Please try again later.')}
            </div>
        </UnavailableWrapper>);
};

const UnavailableWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 8px 16px 16px 16px;
    line-height: 1.4;
    gap: 8px;
    svg {
        width: 16px;
        height: 16px;
        color: ${props => props.theme.colors.textContrast};
    }
`;

export default FunctionUnavailable;