import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import AppContext from 'src/AppContext';

export interface useSetApplicationSettingsInput {
    paramType: string;
    paramValue: string;
}

// note that the return value is strange... would have expected notification object...
const mutation = `mutation SetApplicationSettings($input: SetApplicationSettingsInput!) {
    setApplicationSettings(input: $input) {
      settings {
        appKeys
        key
        value
      }
    }
  }`;

export const useSetApplicationSettings = () => {
    const context = useContext(AppContext);

    return useMutation(async (variables: useSetApplicationSettingsInput) => {
        await context.populateCacheForAppSynch?.();

        void context.api.graphql({ // note that this must not be awaited, it's a fire-and-forget operation
            query: mutation,
            variables: {
                input: {
                    settings: {
                        appKeys: ['MIO', 'Admin'],
                        key: variables.paramType,
                        value: variables.paramValue,
                    },
                },
            },
        });
    });
};
