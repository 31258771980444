import { Flex, GmgTheme } from '@gmg/gmg-react-components';
import { FunctionComponent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from 'src/AppContext';
import { MeasurementViewModel } from 'src/graphql/ViewModels';
import styled, { useTheme } from 'styled-components';
import DotGainGrid from '../visualization/dotgain/DotGainGrid';
import { getDotGainData } from '../visualization/dotgain/calculationsForDotgain';
import Footer from './shared/Footer';
import InfoBlock from './shared/InfoBlock';
import Section from './shared/Section';
import { Divider, Page } from './shared/SharedComponents';

export interface DotGainGraphicProps {
    referenceMeasurement: MeasurementViewModel;
    compareMeasurement: MeasurementViewModel | undefined;
    header: JSX.Element;
    allAvailableColorNames: string[];
};

const DotGainGraphicPage: FunctionComponent<DotGainGraphicProps> = (props) => {
    const theme: GmgTheme = useTheme();
    const { measurementSettings } = useContext(AppContext);
    const { t } = useTranslation();
    const diagramWidth = 440;
    const diagramHeight = diagramWidth * 2 / 3;
    const isMethodSctv = measurementSettings?.methodCMYK === 'SCTV' || measurementSettings?.methodSpot === 'SCTV';
    const yAxisOffset = isMethodSctv ? 6 : 9;
    const processInks = ['Cyan', 'Magenta', 'Yellow', 'Black'];

    const measurements = [props.referenceMeasurement];
    if (props.compareMeasurement) {
        measurements.push(props.compareMeasurement);
    }

    const dataForProcessColors = getDotGainData({
        measurements: measurements,
        editedPoints: [],
        selectedColors: props.allAvailableColorNames.filter(colorName => !!processInks.find(inkName => inkName === colorName)),
        diagramWidth,
        diagramHeight,
        yAxisOffset,
        theme,
    });
    const dataForSpotColors = getDotGainData({
        measurements: measurements,
        editedPoints: [],
        selectedColors: props.allAvailableColorNames.filter(colorName => !processInks.find(inkName => inkName === colorName)),
        diagramWidth,
        diagramHeight,
        yAxisOffset,
        theme,
    });

    return (
        <>
            <Page flexDirection="column">
                {props.header}
                <Section title="Dot Gain">
                    <Flex>
                        <DotGainGrid
                            diagramWidth={diagramWidth}
                            diagramHeight={diagramHeight}
                            scalingFactor={1.5}
                            data={dataForProcessColors.map(data => data.gridData)}
                            isPointsVisible={true}
                            isMethodSctv={isMethodSctv}
                            yAxisOffset={yAxisOffset}
                            isColorCorrectionActive={false}
                            onChangeInkValue={() => {}}
                        />
                        <Flex flexDirection="column" mt="12px">
                            <H2 className="px14_600_contrast">{t('Report.dotgain_cmyk_title', 'Dot Gain for CYMK')}</H2>
                            <InfoBlock
                                title={`${t('Settings.calculationMethod_title', 'Tone Value calculation method')}`}
                                content={t(
                                    `Settings.methodSpotAndCMYK_menu.${measurementSettings.methodCMYK}`,
                                    measurementSettings.methodCMYK,
                                )}
                            />
                            <InfoBlock title={t('Report.spider_densityFilter_cymk_title', 'Density filter for CMYK')}
                                content={t(
                                    `Settings.filterCMYK_menu.${measurementSettings.filterCMYK}`,
                                    measurementSettings.filterCMYK,
                                )}
                            />
                            <InfoBlock
                                title={t('Settings.observer_lil', 'Observer')}
                                content={t(
                                    `Settings.observer_menu.${measurementSettings.observer}`,
                                    `${measurementSettings.observer}°`,
                                )}
                            />
                            <InfoBlock
                                title={t('Settings.illuminant_lil', 'Illuminant')}
                                content={t(
                                    `Settings.illuminant_menu.${measurementSettings.illuminant}`,
                                    measurementSettings.illuminant,
                                )}
                            />
                        </Flex>
                    </Flex>
                    <Flex mt="40px">
                        <DotGainGrid
                            diagramWidth={diagramWidth}
                            diagramHeight={diagramHeight}
                            scalingFactor={1.5}
                            data={dataForSpotColors.map(data => data.gridData)}
                            isPointsVisible={true}
                            isMethodSctv={isMethodSctv}
                            yAxisOffset={yAxisOffset}
                            isColorCorrectionActive={false}
                            onChangeInkValue={() => {}}
                        />
                        <Flex flexDirection="column" mt="12px">
                            <H2 className="px14_600_contrast">{t('Report.dotgain_spot_title', 'Dot Gain for spot colors')}</H2>
                            <InfoBlock
                                title={`${t('Settings.calculationMethod_title', 'Tone Value calculation method')}`}
                                content={t(
                                    `Settings.methodSpotAndCMYK_menu.${measurementSettings.methodSpot}`,
                                    measurementSettings.methodSpot,
                                )}
                            />
                            <InfoBlock title={t('Report.spider_densityFilter_spot_title', 'Density filter for spot colors')}
                                content={'OpenColor Filter'}
                            />
                            <InfoBlock
                                title={t('Settings.observer_lil', 'Observer')}
                                content={t(
                                    `Settings.observer_menu.${measurementSettings.observer}`,
                                    `${measurementSettings.observer}°`,
                                )}
                            />
                            <InfoBlock
                                title={t('Settings.illuminant_lil', 'Illuminant')}
                                content={t(
                                    `Settings.illuminant_menu.${measurementSettings.illuminant}`,
                                    measurementSettings.illuminant,
                                )}
                            />
                        </Flex>
                    </Flex>
                </Section>
                <Footer />
            </Page>
            <Divider />
        </>
    );
};

const H2 = styled.h2`
    margin-bottom: 24px;
`;

export default DotGainGraphicPage;