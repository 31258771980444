import { Flex, Tooltip } from '@gmg/gmg-react-components';
import { colord } from 'colord';
import { Fragment, FunctionComponent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from 'src/AppContext';
import { getTonalValueAutoCorrectionEvent } from 'src/tracking';
import styled from 'styled-components';
import { ReactComponent as PercentIcon } from '../../../assets/icon_percent.svg';
import { ReactComponent as ReferenceIcon } from '../../../assets/icon_referenceChecked.svg';
import NumberCell from '../NumberCell';
import { ColorCorrection } from '../VisualizationContainer';
import { TonalValueRow } from './calculationsForTonalValue';

interface TableProps {
    measurements: Array<{ name: string; versionedId: string }>;
    data: Array<TonalValueRow>;
    selectedToneColor: { name: string; hex: string };
    activeColorCorrection: ColorCorrection;
    onHoverMeasurement: (versionedId?: string) => void;
    onChangeTonalValue: (index: string, value: string) => void;
    tableDimensions: {
        size: number;
        reservedSpace: number;
    };
    hasReference: boolean;
}

const TonalValueTable: FunctionComponent<TableProps> = (props) => {
    const { t } = useTranslation();
    const { trackEvent } = useContext(AppContext);

    const hasMultipleMeasurements = props.measurements.length !== 1;
    const heightOfAllRows = props.data.length * 32;
    const tableHeight = heightOfAllRows > props.tableDimensions.size ? props.tableDimensions.size : heightOfAllRows;
    const isTVCorrectionActive = props.activeColorCorrection?.mode === 'tonalValue';

    return (
        <Container
            className="horizontal-scrollbar"
            offset={props.tableDimensions.size + props.tableDimensions.reservedSpace}>
            <StyledTable
                isMultiSelected={hasMultipleMeasurements}
                tableHeight={tableHeight}>
                <thead>
                    <tr>
                        <th></th>
                        {props.measurements.map((measurement, index) => (
                            <Fragment key={index}>
                                <OuterNameCell
                                    className="px10_400_weak"
                                    onMouseEnter={() => props.onHoverMeasurement(measurement.versionedId)}
                                    onMouseLeave={() => props.onHoverMeasurement()}
                                    isMultiSelected={hasMultipleMeasurements}
                                    scrollBarWidth={heightOfAllRows > props.tableDimensions.size ? 8 : 0}
                                    isReference={index === 0 && props.hasReference}
                                >
                                    <Tooltip message={measurement.name}>
                                        <Flex alignItems="center" justifyContent="center" pl="4px" pr="4px" gap="4px">
                                            {index === 0 && props.hasReference &&
                                                <ReferenceIcon aria-label="Reference indicator" />}
                                            <span>
                                                {measurement.name}
                                            </span>
                                        </Flex>
                                    </Tooltip>
                                </OuterNameCell>
                            </Fragment>
                        ))}
                    </tr>
                    <tr>
                        <th>{t('TonalValueTable.index_title', 'Index')}</th>
                        {props.measurements.map(measurement => (
                            <Fragment key={measurement.versionedId}>
                                <BorderedTh>{t('TonalValueTable.tonalValue_title', 'Tonal Value')}</BorderedTh>
                                {!hasMultipleMeasurements && (
                                    <Fragment>
                                        <BorderedTh>L</BorderedTh>
                                        <th>a</th>
                                        <th>b</th>
                                        <BorderedTh>C</BorderedTh>
                                        <th>h</th>
                                    </Fragment>
                                )}
                            </Fragment>))}
                    </tr>
                </thead>
                <tbody className="vertical-scrollbar">
                    {props.data.map((row, i) => (
                        <tr key={row.indexPercentage}>
                            <td> {`${row.indexPercentage} %`}</td>

                            {row.values.map(values => {
                                const { l, a, b, c, h, tv, id } = values;

                                return (
                                    <Fragment key={id}>
                                        <ToneValueTD
                                            hex={props.selectedToneColor.hex}
                                            isOdd={i % 2 !== 0}
                                        >
                                            <NumberCell
                                                indicatorIcon={<PercentIcon />}
                                                bgColor="transparent"
                                                label={`TonalValue at ${row.indexPercentage}%`}
                                                isHighligted={tv.hasChanged}
                                                value={tv.value}
                                                range={tv.range}
                                                mode={{
                                                    isTextBox: isTVCorrectionActive && !tv.isReadOnly,
                                                    readonly: !isTVCorrectionActive ||
                                                        tv.isReadOnly ||
                                                        Boolean(props.activeColorCorrection?.isLoading),
                                                }}
                                                onChange={value => {
                                                    props.onChangeTonalValue(row.indexPercentage, value);
                                                }}
                                                onAutoCorrection={() => {
                                                    trackEvent(getTonalValueAutoCorrectionEvent('table'));
                                                }}
                                            />
                                        </ToneValueTD>
                                        {!hasMultipleMeasurements &&
                                            <Fragment key={id}>
                                                <td>{l}</td>
                                                <td>{a}</td>
                                                <td>{b}</td>
                                                <td>{c}</td>
                                                <td>{h}</td>
                                            </Fragment>
                                        }
                                    </Fragment>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </StyledTable>
        </Container>
    );
};

const StyledTable = styled.table<{ isMultiSelected?: boolean; tableHeight: number }>`
    cursor: default;
    margin-top: 14px;
    width: max-content;
    border-spacing: 0;
    th,td {
       box-sizing: border-box;
       padding: 6px 0;
       text-align: center;
       height: 32px;
       width: 65px;
    }

    thead tr {
        display: block;
        background-color: ${props => props.theme.colors.backgroundPaper};
    }

    tbody {
        display: block;
        overflow-y: auto;
        overflow-x: hidden;
        /*Calculated digrammSize === props.tableHeight*/
        /* tableHeight + padding50 - 64thead - 11 distance from container to text =  tableHeight -25*/
        /* tableHeight + padding50 + gradient30 - 64thead  =  tableHeight + 16*/
        height: ${props => props.isMultiSelected ? props.tableHeight - 25 : props.tableHeight + 16}px;

        tr:nth-child(odd) {
            background-color: ${props => colord(props.theme.colors.background).darken(0.03).toHex()};
        }
    }
`;

const ToneValueTD = styled.td<{ hex: string; isOdd: boolean }>`
    border-left:1px solid ${props => props.hex};
    background: ${props =>
        colord(props.isOdd
            ? props.theme.colors.background
            : colord(props.theme.colors.background).darken(0.03).toHex())
            .mix(props.hex, 0.1)
            .toHex()};
`;

const BorderedTh = styled.th`
    border-left: 1px solid ${props => props.theme.colors.background};
`;
const OuterNameCell = styled(BorderedTh) <{ isMultiSelected?: boolean; scrollBarWidth: number; isReference: boolean }>`
    min-width: ${props => props.isMultiSelected
        ? 65
        : 389}px;// 6 X column - 1px border
    max-width: ${props => props.isMultiSelected
        ? 65
        : 389}px;// 6 X column - 1px border

    svg {
        min-height: 16px;
        min-width: 16px;
        max-height: 16px;
        max-width: 16px;
    }

    span {
        max-width: 100%;    
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 1.6;
     }
`;

const Container = styled.div<{ offset: number }>`
     max-width: calc(100vw - ${props => props.offset}px);  /* see calc description in Spider.tsx */
     grid-row: 2 / 3;
`;

export default TonalValueTable;