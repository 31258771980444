import { colord } from 'colord';
import { Fragment, FunctionComponent, useCallback, useRef } from 'react';

import { useVirtualizer } from '@tanstack/react-virtual';
import styled from 'styled-components';
import PatchSquare from '../PatchSquare';
import { definitionsOfwidth } from './Header';
import { TableRowData } from './Table';
import { getPropertyNameFromMeasurement } from './createPropertyName';

export interface RowVirtualizerProps {
    rows: Array<TableRowData>;
    condition: string;
    inks: Map<string, string>;
    measurementIds: Array<string>;
    formatNumber: (num: number | undefined | null) => string | undefined; // we pass this in due to performance
}

const RowVirtualizer: FunctionComponent<RowVirtualizerProps> = ({ rows, formatNumber, ...props }) => {

    const patchSize = 30;
    const showOnlySingleMeasurement = props.measurementIds.length === 1;
    const showDeltaValues = props.measurementIds.length === 2;

    const parentRef = useRef(null);

    const rowVirtualizer = useVirtualizer({
        count: 500,
        getScrollElement: () => parentRef.current,
        estimateSize: useCallback(() => patchSize, []), // height of each row determined by the patch size
    });

    const hexPropertyNameOf1stMeasurement = getPropertyNameFromMeasurement('hex', props.measurementIds[0]);
    const hexPropertyNameOf2ndMeasurement = props.measurementIds.length === 2
        ? getPropertyNameFromMeasurement('hex', props.measurementIds[1])
        : undefined;

    const calculateTableWith = (): number => {
        const scrollbarWidth = 8;
        const { border, margin, inkCell, sortBtnCell } = definitionsOfwidth;

        const indexColumn = props.inks.size * inkCell + border;
        const measurementColumn = (3 * sortBtnCell) + (2 * margin) + border;
        const deltasColumnWidth = props.measurementIds.length === 2
            ? (4 * sortBtnCell) + (2 * margin) + border
            : 0
            ;
        const chartColumn = props.measurementIds.length === 1
            ? 4 * sortBtnCell
            : props.measurementIds.length === 2
                ? sortBtnCell
                : 0
            ;
        return chartColumn + indexColumn + (props.measurementIds.length * measurementColumn) + deltasColumnWidth + scrollbarWidth;
    };

    console.log(rowVirtualizer.getVirtualItems());

    return (
        <>
            <div
                // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
                role="scrollbar"
                className="vertical-scrollbar"
                ref={parentRef}
                style={{
                    height: 'calc(100vh - 240px)',
                    overflowX: 'hidden',
                    minWidth: `${calculateTableWith()}px`,
                }}
            >
                <div
                    style={{
                        height: `${rowVirtualizer.getTotalSize}px`,
                        minWidth: '100%',
                        position: 'relative',
                    }}
                >
                    {rowVirtualizer.getVirtualItems().map(virtualRow => {
                        console.log(virtualRow);
                        let hex1, hex2;
                        const h1 = rows[virtualRow.index][hexPropertyNameOf1stMeasurement];
                        switch (props.measurementIds.length) {
                            case 1:
                                hex1 = h1;
                                break;
                            case 2:
                                const h2 = rows[virtualRow.index][hexPropertyNameOf2ndMeasurement!];
                                hex1 = h1 || h2;
                                hex2 = h1 !== undefined
                                    ? h2
                                    : undefined;
                                break;
                            default: // more than 2 measurements: leave empty
                                break;
                        }

                        return (
                            <Row
                                key={rows[virtualRow.index].key}
                                // ref={virtualRow.measureRef}
                                size={virtualRow.size}
                                start={virtualRow.start}
                                isEven={virtualRow.index % 2 === 0}
                                role="row"
                            >
                                {showOnlySingleMeasurement && <Cell role="cell">{rows[virtualRow.index].page}</Cell>}
                                {showOnlySingleMeasurement && <Cell role="cell">{rows[virtualRow.index].row}</Cell>}
                                {showOnlySingleMeasurement && <Cell role="cell">{rows[virtualRow.index].column}</Cell>}
                                {props.measurementIds.length < 3 && (
                                    <Cell role="cell">
                                        <PatchSquare
                                            size={`${patchSize}px`}
                                            hex1={hex1}
                                            hex2={hex2}
                                        />
                                    </Cell>)}
                                {Array.from(props.inks.keys()).map(ink => (
                                    <CellColord
                                        key={ink}
                                        bg={props.inks.get(ink)!}
                                        isEven={virtualRow.index % 2 === 0}
                                        role="cell"
                                    >
                                        {formatNumber(rows[virtualRow.index][ink])}
                                    </CellColord>
                                ))}
                                {props.measurementIds.map(measurementId => (
                                    <Fragment key={measurementId}>
                                        <Cell margin="left" role="cell">
                                            {formatNumber(rows[virtualRow.index][getPropertyNameFromMeasurement('l', measurementId)]) ?? '-'}
                                        </Cell>
                                        <Cell role="cell">
                                            {formatNumber(rows[virtualRow.index][getPropertyNameFromMeasurement('a', measurementId)]) ?? '-'}
                                        </Cell>
                                        <Cell margin="right" role="cell">
                                            {formatNumber(rows[virtualRow.index][getPropertyNameFromMeasurement('b', measurementId)]) ?? '-'}
                                        </Cell>
                                    </Fragment>
                                ))}
                                {showDeltaValues &&
                                    <>
                                        <Cell margin="left" role="cell">
                                            {formatNumber(rows[virtualRow.index].deltaE) ?? '-'}
                                        </Cell>
                                        <Cell role="cell">
                                            {formatNumber(rows[virtualRow.index].dL) ?? '-'}
                                        </Cell>
                                        <Cell role="cell">
                                            {formatNumber(rows[virtualRow.index].da) ?? '-'}
                                        </Cell>
                                        <Cell margin="right" role="cell">
                                            {formatNumber(rows[virtualRow.index].db) ?? '-'}
                                        </Cell>
                                    </>
                                }
                            </Row>);
                    })}
                </div>
            </div>
        </>
    );
};

// positioning comes from react-virtualized and should not be changed
const Row = styled.div.attrs<{ size: number; start: number }>(props => ({
    style: {
        height: `${props.size}px`,
        transform: `translateY(${props.start}px)`,
    },
})) <{ size: number; start: number; isEven: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    /* the following ones do not have to do anything with react-virtualized */
    display: flex;
    background-color: ${props => props.isEven
        ? colord(props.theme.colors.background).darken(0.03).toHex()
        : props.theme.colors.background};
`;

const Cell = styled.div<{ margin?: 'left' | 'right' }>`
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 64px;
    margin-left: ${props => props.margin === 'left'
        ? '5px'
        : '0'};
    margin-right: ${props => props.margin === 'right'
        ? '5px'
        : '0'};
`;

const CellColord = styled(Cell).attrs<{ bg: string; isEven: boolean }>(props => ({
    style: {
        // possible performance improvement: calculate .darken() and .mix() upfront once
        borderLeft: `1px solid ${props.bg}`,
        backgroundColor: colord(props.isEven
            ? colord(props.theme.colors.background).darken(0.03).toHex()
            : props.theme.colors.background,
        )
            .mix(props.bg, 0.1)
            .toHex(),
    },
})) <{ bg: string; isEven: boolean }>`justify-content: end; padding-right: 8px;  width: 80px;`;

export default RowVirtualizer;