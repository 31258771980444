import { Flex, GmgTheme } from '@gmg/gmg-react-components';
import { FunctionComponent } from 'react';
import { MeasurementViewModel } from 'src/graphql/ViewModels';
import useFormatter from 'src/shared/useFormatter';
import styled, { useTheme } from 'styled-components';
import Gradient from '../visualization/tonalvalue/Gradient';
import TonalValueGrid from '../visualization/tonalvalue/TonalValueGrid';
import TonalValueTable from '../visualization/tonalvalue/TonalValueTable';
import { getDataForTonalValue, getFormattedAndSortedTonaValueTableRows } from '../visualization/tonalvalue/calculationsForTonalValue';
import Footer from './shared/Footer';
import Section from './shared/Section';
import { Divider, Page } from './shared/SharedComponents';

export interface TonalValuePageProps {
    header: JSX.Element;
    referenceMeasurement: MeasurementViewModel;
    compareMeasurement: MeasurementViewModel | undefined;
    color: string;
};

const TonalValuePage: FunctionComponent<TonalValuePageProps> = (props) => {
    const theme: GmgTheme = useTheme();
    const { formatNumber } = useFormatter();

    const measurements = [props.referenceMeasurement];
    if (props.compareMeasurement) {
        measurements.push(props.compareMeasurement);
    }

    const scalingFactor = 1.5;
    const diagramSize = props.compareMeasurement !== undefined ? 520 : 240;
    const tonalValueData = getDataForTonalValue(measurements, props.color, theme, diagramSize, []);
    const sortedTvRows = getFormattedAndSortedTonaValueTableRows(tonalValueData.map(data => data.tableData), formatNumber, []);

    return (
        <>
            <Page flexDirection="column">
                {props.header}
                <Section title={`Tonal Value ( ${props.color} )`}>
                    <Flex justifyContent="space-between">
                        <Flex flexDirection="column">
                            <TonalValueGrid
                                diagramSize={diagramSize}
                                scalingFactor={scalingFactor}
                                data={tonalValueData.map(data => data.gridData)}
                                isDuplicatesVisible={true}
                                onChangeTonalValue={() => { }}
                                isColorCorrectionActive={false}
                            />
                            <Gradient
                                calculatedAverages={tonalValueData[0].indexValueSeries}
                                diagramSize={diagramSize}
                            />
                        </Flex>
                        <Container>
                            <TonalValueTable
                                measurements={tonalValueData.map(item => ({
                                    versionedId: item.tableData.versionedMeasurementId,
                                    name: item.tableData.name,
                                }))}
                                data={sortedTvRows}
                                selectedToneColor={{ name: props.color, hex: tonalValueData.filter(i => i.hex.length)[0].hex }}
                                tableDimensions={{ size: 1100, reservedSpace: -1100 }}
                                activeColorCorrection={undefined}
                                onHoverMeasurement={() => { }}
                                onChangeTonalValue={() => { }}
                                hasReference={props.compareMeasurement !== undefined}
                            />
                        </Container>
                    </Flex>
                </Section>
                <Footer />
            </Page>
            <Divider />
        </>
    );
};

const Container = styled.span`
    tbody {
        height: min-content; // we have to wrap TonalValueTable in order to overWrite the initial behaviour in report 
    }
`;

export default TonalValuePage;