import { Flex, IconCopy, IconDelete, IconExport, IconMoveFile, IconOpen, IconRename, ImageButton, KebabMenu, Tooltip, toast } from '@gmg/gmg-react-components';
import { MenuItem } from '@gmg/gmg-react-components/dist/src/Components/gmg/KebabMenu';
import { FunctionComponent, MouseEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from 'src/AppContext';
import { useMultipleFileDownload } from 'src/graphql/customHooks/useMultipleFileDownload';
import { getErrorMsgForToast } from '../toastErrorHandler';
import FileExplorerModalContainer from '../visualization/FileExplorerModal/FileExplorerModalContainer';
import { PathItem } from './BreadCrumb';
import RenameFileModalContainer from './RenameFileModalContainer';

export interface FileActionsProps {
    measurementId: string;
    measurementName: string;
    measurementVersion: number;
    allOtherMeasurementNamesInFolder: Array<string> | undefined;
    isFileEditable: boolean;
    onDelete: () => void;
    onOpen: () => void;
    isWideScreen: boolean;
    path: Array<PathItem>;
    onChangePath: (itemOrItems: Array<PathItem> | PathItem) => void;
};

const FileActions: FunctionComponent<FileActionsProps> = props => {
    const { t } = useTranslation();
    const context = useContext(AppContext);
    const exportInProgressToastId = 'FileAction_Export_In_Progress';
    const [isExportBtnClicked, setIsExportBtnClicked] = useState(false);

    const { error, isSuccess, fetchStatus } = useMultipleFileDownload(
        isExportBtnClicked
            ? [props.measurementId]
            : undefined,
        props.measurementName,
    );

    useEffect(() => {

        if (fetchStatus !== 'fetching' && isSuccess) {
            toast.dismiss(exportInProgressToastId);
            setIsExportBtnClicked(false);
        }

        if (error) {
            toast.dismiss(exportInProgressToastId);
            toast.error(getErrorMsgForToast(error, t));
            setIsExportBtnClicked(false);
        }

    }, [error, isSuccess, fetchStatus, t]);

    const handleDeleteClick = (e: MouseEvent) => {
        e.stopPropagation();
        context.onShowModal('infoModal', {
            title: t('FileOrFolderAction.modals.delete.title', 'Delete'),
            content:
                <span>
                    {t('FileOrFolderAction.modals.delete.content_desc', 'Are you sure you want to delete "{{name}}" ?',
                        { name: props.measurementName },
                    )}
                </span>,
            onOkClick: props.onDelete,
            btnOkCaption: t('Common.ButtonLabels.delete', 'Delete'),
        });
    };

    const handleRenameClick = (ev: any) => {
        ev.stopPropagation();
        context.onShowModal('inputModal', {
            title: t('RenameFileModal.modal_title', 'Rename File'),
            titleIcon: <IconRename />,
            content: <RenameFileModalContainer
                allOtherMeasurementNamesInFolder={props.allOtherMeasurementNamesInFolder!}
                measurementId={props.measurementId}
                measurementName={props.measurementName}
                measurementVersion={props.measurementVersion}
                folderId={props.path[props.path.length - 1].id!}
            />,
        });
    };

    const handleCopyClick = () => {
        context.onShowModal('inputModal', {
            title: t('FileOrFolderAction.modals.copy.title', 'Copy file'),
            titleIcon: <IconMoveFile />,
            content: <FileExplorerModalContainer
                operationType='copyFile'
                baseSelection={[{ id: props.measurementId, name: props.measurementName, isFolder: false }]}
                path={props.path}
                onChangePath={props.onChangePath}
                sectionTitle={t('FileOrFolderAction.modals.move.section_title', 'Choose Destination')}
            />,
        });
    };

    const handleClickMoveFile = () => {
        context.onShowModal('inputModal', {
            title: `${t('FileOrFolderAction.modals.move.title', 'Move')} ${props.measurementName}`,
            titleIcon: <IconMoveFile />,
            content: <FileExplorerModalContainer
                operationType='move'
                baseSelection={[{ id: props.measurementId, name: props.measurementName, isFolder: false }]}
                path={props.path}
                onChangePath={props.onChangePath}
                sectionTitle={t('FileOrFolderAction.modals.move.section_title', 'Choose Destination')} // Think about to specify in the Container based on the type
            />,
        });
    };

    const handleExport = (ev: any) => {
        ev.stopPropagation();
        setIsExportBtnClicked(true);
        toast.loading(t('FileOrFolderAction.spinner_export_prog', 'Exporting...'), { id: exportInProgressToastId });
    };

    // requirement: max. number of ActionIcons is 3
    const ActionIcons = <>
        <Tooltip message={t('FileOrFolderAction.button_text_openFile', 'Open')}>
            <ImageButton
                icon={<IconOpen />}
                onClick={() => { props.onOpen(); }}
                aria-label="open file"
            />
        </Tooltip>
        <Tooltip message={t('FileOrFolderAction.button_text_export', 'Export')}>
            <ImageButton
                icon={<IconExport />}
                onClick={handleExport}
                aria-label="export file"
                disabled={isExportBtnClicked}
            />
        </Tooltip>
        <Tooltip message={t('FileOrFolderAction.button_text_copy', 'Copy')}>
            <ImageButton
                icon={<IconCopy />}
                onClick={handleCopyClick}
                aria-label="copy file"
            />
        </Tooltip>
    </>;

    let items: Array<MenuItem> = [{
        label: t('FileOrFolderAction.button_text_openFile', 'Open'),
        icon: <IconOpen />,
        onItemClick: () => { props.onOpen(); },
    }, {
        label: t('FileOrFolderAction.button_text_export', 'Export'),
        icon: <IconExport />,
        onItemClick: handleExport,
    }, {
        label: t('FileOrFolderAction.button_text_copy', 'Copy'),
        icon: <IconCopy />,
        onItemClick: handleCopyClick,
    }];
    if (props.isFileEditable) {
        items = [
            ...items, {
                label: t('FileOrFolderAction.button_text_rename', 'Rename'),
                icon: <IconRename />,
                onItemClick: handleRenameClick,
            }, {
                label: t('Common.ButtonLabels.move_file_icon_title', 'Move'),
                icon: <IconMoveFile />,
                onItemClick: handleClickMoveFile,
            }, {
                label: t('Common.ButtonLabels.delete', 'Delete'),
                icon: <IconDelete />,
                onItemClick: handleDeleteClick,
            },
        ];
    }
    const Kebab = <KebabMenu
        items={items}
        identifier={props.measurementName}
    />;

    return (
        <Flex className="invisibleSVG" gap="16px" justifyContent="flex-end" maxWidth="max-content" ml="auto">
            {props.isWideScreen
                ? <>
                    {ActionIcons}
                    {props.isFileEditable && Kebab}
                </>
                : Kebab
            }
        </Flex>);
};

export default FileActions;