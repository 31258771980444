
import { CSS } from '@dnd-kit/utilities';
import { FunctionComponent, useState } from 'react';
import { draggablePoint } from 'src/shared/DraggablePointModule';
import styled from 'styled-components';

export interface IDraggablePoint {
    scalingFactor: number;
    hex: string;
    isColorCorrectionActive: boolean;
    point: {
        inkName?: string;
        x: number;
        y: number;
        id: string;
        index: number;
        value: number;
        isDraggable: boolean;
        range: { min: number; max: number };
    };
};

const DraggablePoint: FunctionComponent<IDraggablePoint> = ({ point, scalingFactor, hex, isColorCorrectionActive }) => {

    const [circleRadius, setCircleRadius] = useState(2);
    const { useDraggable } = draggablePoint();

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
    } = useDraggable({
        id: point.id,
        data: {
            index: point.index,
            currentValue: point.value,
            range: point.range,
            inkName: point?.inkName,
        },
        disabled: isColorCorrectionActive ? !point.isDraggable : true,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
    };

    return (
        <Circle
            disabelCursor={!point.isDraggable || !isColorCorrectionActive}
            style={style}
            {...listeners}
            {...attributes}
            ref={setNodeRef as any}
            cx={point.x}
            cy={point.y}
            r={circleRadius * scalingFactor}
            fill={hex}
            onMouseEnter={() => {
                if (point.isDraggable && isColorCorrectionActive) {
                    setCircleRadius(3.5);
                }
            }}
            onMouseLeave={() => setCircleRadius(2)}
            aria-label={`draggable at index ${point.index}`}
        />
    );
};

const Circle = styled.circle<{ disabelCursor: boolean }>`
    cursor: ${props => props.disabelCursor ? 'default' : 'pointer'};
    outline: none;
`;

export default DraggablePoint;