import { DeltaETypes } from 'src/AppContext';
import { MeasurementViewModel } from './ViewModels';
import { CmykFilters, CmykMethods, Illuminants, Observers, TableView } from './generated/types';

export const MockedApiProcessParamResponse = {
    printingProcess: {
        label: 'Flexo Printing',
        value: 'flexo_conventional',
    },
    printFinishing: {
        label: 'Gloss Laminate',
        value: 'surface_gloss_laminate',
    },
    substrateCategory: {
        label: 'Film',
        value: 'category_film',
    },
    printDirection: {
        label: 'Front',
        value: '',
    },
    substrateType: {
        value: 'film_metallic_white_undercoat',
        label: 'Metallic + White undercoat',
    },
    filmMaterial: {
        value: 'OPP/CPP',
        label: 'OPP/CPP',
    },
    inks: [
        {
            inkName: 'Yellow',
            hash: '1b5f5f3d8bb52c1bd18f6ee94dd762b433727655',
            screeningType: {
                label: 'AM',
                value: 'AM',
            },
            screeningRulingValue: '150',
            screeningConfiguration: {
                label: '0°',
                value: '0',
            },
        },
        {
            inkName: 'Magenta',
            hash: '76b7deda8a30be196583daa060b7d45353ee2d00',
            screeningType: {
                label: 'AM',
                value: 'AM',
            },
            screeningRulingValue: '150',
            screeningConfiguration: {
                label: '15°',
                value: '1',
            },
        },
        {
            inkName: 'Cyan',
            hash: '149d43e541a635138d7dfba3a395a2ca73c7c3c2',
            screeningType: {
                label: 'AM',
                value: 'AM',
            },
            screeningRulingValue: '150',
            screeningConfiguration: {
                label: '45°',
                value: '2',
            },
        },
        {
            inkName: 'Black',
            hash: '008add6afe06cae1e995102baa6845c4bea8b324',
            screeningType: {
                label: 'AM',
                value: 'AM',
            },
            screeningRulingValue: '150',
            screeningConfiguration: {
                label: '75°',
                value: '3',
            },
        },
    ],
};

export const aiMockData = {
    printingProcess: {
        label: 'Gravure',
        value: 'gravure',
    },
    printFinishing: {
        label: 'Gloss Laminate',
        value: 'surface_gloss_laminate',
    },
    substrateCategory: {
        label: 'Cardboard',
        value: 'category_cardboard',
    },
    printDirection: {
        label: 'Front',
        value: '',
    },
    substrateType: {
        value: 'cardboard_coated_matte',
        label: 'Coated - matte',
    },
    filmMaterial: undefined,
    inks: [
        {
            inkName: 'Yellow',
            hash: '1b5f5f3d8bb52c1bd18f6ee94dd762b433727655',
            screeningType: {
                label: 'AM',
                value: 'AM',
            },
            screeningRulingValue: '150',
            screeningConfiguration: {
                label: '0°',
                value: '0',
            },
        },
        {
            inkName: 'Magenta',
            hash: '76b7deda8a30be196583daa060b7d45353ee2d00',
            screeningType: {
                label: 'AM',
                value: 'AM',
            },
            screeningRulingValue: '150',
            screeningConfiguration: {
                label: '15°',
                value: '1',
            },
        },
        {
            inkName: 'Cyan',
            hash: '149d43e541a635138d7dfba3a395a2ca73c7c3c2',
            screeningType: {
                label: 'AM',
                value: 'AM',
            },
            screeningRulingValue: '150',
            screeningConfiguration: {
                label: '45°',
                value: '2',
            },
        },
        {
            inkName: 'Black',
            hash: '008add6afe06cae1e995102baa6845c4bea8b324',
            screeningType: {
                label: 'AM',
                value: 'AM',
            },
            screeningRulingValue: '150',
            screeningConfiguration: {
                label: '75°',
                value: '3',
            },
        },
    ],
};

export const processParameters = { // note that this is currently used productively !!
    surfaceFinishings: [
        {
            key: 'surface_dispersion_gloss_varnish',
            label: 'Dispersion Gloss Varnish',
        },
        {
            key: 'surface_dispersion_matt_varnish',
            label: 'Dispersion Matt Varnish',
        },
        {
            key: 'surface_gloss_laminate',
            label: 'Gloss Laminate',
        },
        {
            key: 'surface_matt_laminate',
            label: 'Matt Laminate',
        },
        {
            key: 'surface_none',
            label: 'No Surface Finishing',
        },
        {
            key: 'surface_semi_matt_laminate',
            label: 'Semi-Matt Laminate',
        },
        {
            key: 'surface_uv_matt_varnish',
            label: 'UV Matt Varnish',
        },
        {
            key: 'surface_uv_gloss_varnish',
            label: 'UV Gloss Varnish',
        },
    ],
    screenTypes: [
        {
            label: 'AM',
            rulingUnit: 'lpi',
            key: 'AM',
            configurations: [
                {
                    label: '0°',
                    value: 0,
                },
                {
                    label: '15°',
                    value: 1,
                },
                {
                    label: '45°',
                    value: 2,
                },
                {
                    label: '75°',
                    value: 3,
                },
            ],
        },
        {
            label: 'EM-Gravure',
            rulingUnit: 'lpi',
            key: 'EM_gravure',
            configurations: [
                {
                    label: '70/0',
                    value: 0,
                },
                {
                    label: '70/2',
                    value: 1,
                },
                {
                    label: '70/3',
                    value: 2,
                },
                {
                    label: '70/4',
                    value: 3,
                },
            ],
        },
        {
            label: 'FM',
            rulingUnit: 'μ',
            key: 'FM',
            configurations: null,
        },
    ],
    substrateCategories: [
        {
            key: 'category_cardboard',
            label: 'Cardboard',
            substrateTypes: [
                {
                    key: 'cardboard_coated_glossy',
                    label: 'Coated - glossy',
                    printDirections: null,
                },
                {
                    key: 'cardboard_coated_matte',
                    label: 'Coated - matte',
                    printDirections: null,
                },
                {
                    key: 'cardboard_corrugated',
                    label: 'Corrugated',
                    printDirections: null,
                },
            ],
            filmMaterial: null,
        },
        {
            key: 'category_film',
            label: 'Film',
            substrateTypes: [
                {
                    key: 'film_transparent_with_additional_white',
                    label: 'Transparent + Additional white layer',
                    printDirections: [
                        {
                            label: 'Front',
                            value: false,
                        },
                        {
                            label: 'Reverse',
                            value: true,
                        },
                    ],
                },
                {
                    key: 'film_white',
                    label: 'White',
                    printDirections: null,
                },
                /* => #79201 {
                    key: 'film_metallic',
                    label: 'Metallic',
                    printDirections: null,
                },*/
                {
                    key: 'film_metallic_white_undercoat',
                    label: 'Metallic + White undercoat',
                    printDirections: null,
                },
            ],
            filmMaterial: [
                { key: 'OPP/CPP', label: 'OPP/CPP' },
                { key: 'PET', label: 'PET' },
                { key: 'PVC', label: 'PVC' },
                { key: 'LDPE', label: 'LDPE' },
            ],
        },
        {
            key: 'category_paper',
            label: 'Paper',
            substrateTypes: [
                {
                    key: 'paper_coated_matte',
                    label: 'Coated - matte',
                    printDirections: null,
                },
                {
                    key: 'paper_coated_glossy',
                    label: 'Coated - glossy',
                    printDirections: null,
                },
                {
                    key: 'paper_super_calendered',
                    label: 'Super Calendered',
                    printDirections: null,
                },
                {
                    key: 'paper_uncoated',
                    label: 'Uncoated',
                    printDirections: null,
                },
            ],
            filmMaterial: null,
        },

    ],
    printingProcesses: [
        {
            key: 'digital_toner',
            label: 'Digital Toner',
        },
        {
            key: 'digital_inkjet',
            label: 'Digital Inkjet',
        },
        {
            key: 'flexo_conventional',
            label: 'Flexo',
        },
        {
            key: 'gravure',
            label: 'Gravure',
        },
        {
            key: 'offset',
            label: 'Offset / Litho',
        },
    ],
};

export const DefaultMeasurementSettings = {
    illuminant: 'D50' as Illuminants,
    observer: 'O2' as Observers,
    filterCMYK: 'StatusE' as CmykFilters,
    methodCMYK: 'MurrayDavies' as CmykMethods,
    methodSpot: 'MurrayDavies' as CmykMethods,
    deltaE: 'DE00' as DeltaETypes,
};

export const testFiles = (folderId: string) => {
    return [
        {
            id: '2222-KQM1RYOW-28DPIIQA2LB',
            metadata: '{"InkNames":[{"Preview":{"D50_2":[42.45,-26.68,-62.74],"Color":"#0074CDFF"},"Type":"None","Name":"Cyan"},{"Preview":{"D50_2":[46.16,78.19,6.9],"Color":"#DA0066FF"},"Type":"None","Name":"Magenta"},{"Preview":{"D50_2":[87.26,-1.38,118.39],"Color":"#FBD900FF"},"Type":"None","Name":"Yellow"},{"Preview":{"D50_2":[2.17,-0.31,1.63],"Color":"#090804FF"},"Type":"None","Name":"Black"}],"TestchartName":"","MeasurementConditions":["M3"],"MeasuringDevices":["X-Rite i1 Pro3"]}',
            name: 'Measurement_PANTONE_249_C_Tints',
            folderId,
            versionInformation: {
                version: 1,
                timestamp: '2022-06-28T15:23:04Z',
            },
        },
        {
            id: '3333-KQM1RYOW-28DPIIQA2LB',
            metadata: '{"InkNames":[{"Preview":{"D50_2":[42.45,-26.68,-62.74],"Color":"#0074CDFF"},"Type":"None","Name":"Cyan"},{"Preview":{"D50_2":[46.16,78.19,6.9],"Color":"#DA0066FF"},"Type":"None","Name":"Magenta"},{"Preview":{"D50_2":[87.26,-1.38,118.39],"Color":"#FBD900FF"},"Type":"None","Name":"Yellow"},{"Preview":{"D50_2":[2.17,-0.31,1.63],"Color":"#090804FF"},"Type":"None","Name":"Black"}],"TestchartName":"","MeasurementConditions":["M3"],"MeasuringDevices":["X-Rite i1 Pro3"]}',
            name: 'Measurement_White_333_E_Tints',
            folderId,
            versionInformation: {
                version: 1,
                timestamp: '2021-06-28T15:23:04Z',
            },
        },
        {
            id: '4444-KQM1RYOW-28DPIIQA2LB',
            metadata: '{"InkNames":[{"Preview":{"D50_2":[42.45,-26.68,-62.74],"Color":"#0074CDFF"},"Type":"None","Name":"Cyan"},{"Preview":{"D50_2":[46.16,78.19,6.9],"Color":"#DA0066FF"},"Type":"None","Name":"Magenta"},{"Preview":{"D50_2":[87.26,-1.38,118.39],"Color":"#FBD900FF"},"Type":"None","Name":"Yellow"},{"Preview":{"D50_2":[2.17,-0.31,1.63],"Color":"#090804FF"},"Type":"None","Name":"Black"}],"TestchartName":"","MeasurementConditions":["M3"],"MeasuringDevices":["X-Rite i1 Pro3"]}',
            name: 'Measurement_Black_260_D_Tints',
            folderId,
            versionInformation: {
                version: 1,
                timestamp: '2020-06-28T15:23:04Z',
            },
        },
    ];
};

export const chartTableView = (id: string, name: string, condition: 'M0' | 'M1' | 'M2' | 'M3' = 'M0'): TableView => ({
    HasProcessParamsDefined: false,
    Conditions: [condition],
    ColumnLabels: [
        'A',
        'B',
        'C',
    ],
    RowLabels: [
        '1',
        '2',
    ],
    CreationDate: '2021-06-30T13:06:43Z',
    Id: id,
    Version: 4,
    InkSet: [
        {
            Color: '#00ADEE',
            Name: 'Cyan',
            Type: 'None',
        },
        {
            Color: '#ED008C',
            Name: 'Magenta',
            Type: 'None',
        },
    ],
    Name: name,
    Patches: [
        { // Patch A1
            Index: [0, 0, 0],
            InkValues: [100, 0],
            Values: [
                {
                    Hex: '#3e77de',
                    ConditionIndex: 0,
                    Density: 0.0,
                    LCh: [
                        51.1,
                        59.27,
                        278.17,
                    ],
                    Lab: [
                        12.34,
                        8.42,
                        -58.67,
                    ],
                    SessionIndex: 0,
                    ToneValue: 0.0,
                },
                {
                    Hex: '#3e77de',
                    ConditionIndex: 1,
                    Density: 0.0,
                    LCh: [
                        28.37,
                        59.27,
                        278.17,
                    ],
                    Lab: [
                        12.35,
                        8.42,
                        -58.67,
                    ],
                    SessionIndex: 0,
                    ToneValue: 0.0,
                },
            ],
        },
        { // Patch B1
            Index: [0, 0, 1],
            InkValues: [0, 100],
            Values: [
                {
                    Hex: '#321333',
                    ConditionIndex: 0,
                    Density: 0.0,
                    LCh: [
                        11.95,
                        23.66,
                        326.53,
                    ],
                    Lab: [
                        30.01,
                        19.74,
                        -13.05,
                    ],
                    SessionIndex: 0,
                    ToneValue: 0.0,
                },
                {
                    Hex: '#321333',
                    ConditionIndex: 1,
                    Density: 0.0,
                    LCh: [
                        11.95,
                        23.66,
                        326.53,
                    ],
                    Lab: [
                        30.02,
                        19.74,
                        -13.05,
                    ],
                    SessionIndex: 0,
                    ToneValue: 0.0,
                },
            ],
        },
        { // Patch C1
            Index: [0, 0, 2],
            InkValues: [50, 50],
            Values: [
                {
                    Hex: '#321333',
                    ConditionIndex: 0,
                    Density: 0.0,
                    LCh: [
                        11.95,
                        23.66,
                        326.53,
                    ],
                    Lab: [
                        11.95,
                        19.74,
                        -13.05,
                    ],
                    SessionIndex: 0,
                    ToneValue: 0.0,
                },
                {
                    Hex: '#321333',
                    ConditionIndex: 1,
                    Density: 0.0,
                    LCh: [
                        11.95,
                        23.66,
                        326.53,
                    ],
                    Lab: [
                        11.95,
                        19.74,
                        -13.05,
                    ],
                    SessionIndex: 0,
                    ToneValue: 0.0,
                },
            ],
        },
        { // Patch A2
            Index: [0, 1, 0],
            InkValues: [50, 0],
            Values: [
                {
                    Hex: '#f2f29e',
                    ConditionIndex: 0,
                    Density: 0.0,
                    LCh: [
                        94.28,
                        42.45,
                        101.33,
                    ],
                    Lab: [
                        94.28,
                        -8.34,
                        41.62,
                    ],
                    SessionIndex: 0,
                    ToneValue: 0.0,
                },
                {
                    Hex: '#f2f29e',
                    ConditionIndex: 1,
                    Density: 0.0,
                    LCh: [
                        94.28,
                        42.45,
                        101.33,
                    ],
                    Lab: [
                        94.28,
                        -8.34,
                        41.62,
                    ],
                    SessionIndex: 0,
                    ToneValue: 0.0,
                },
            ],
        },
        { // Patch B2
            Index: [0, 1, 1],
            InkValues: [0, 50],
            Values: [
                {
                    Hex: '#0d0c13',
                    ConditionIndex: 0,
                    Density: 0.0,
                    LCh: [
                        3.77,
                        2.55,
                        290.42,
                    ],
                    Lab: [
                        3.77,
                        0.89,
                        -2.39,
                    ],
                    SessionIndex: 0,
                    ToneValue: 0.0,
                },
                {
                    Hex: '#0d0c13',
                    ConditionIndex: 1,
                    Density: 0.0,
                    LCh: [
                        3.77,
                        2.55,
                        290.42,
                    ],
                    Lab: [
                        3.77,
                        0.89,
                        -2.39,
                    ],
                    SessionIndex: 0,
                    ToneValue: 0.0,
                },
            ],
        },
        // Patch C2 => gap
    ],
});


export const diagramTableView = (id: string, name: string, condition: 'M0' | 'M1' | 'M2' | 'M3' = 'M0'): TableView => ({
    HasProcessParamsDefined: true,
    Conditions: [condition],
    ColumnLabels: [
        'A',
        'B',
    ],
    RowLabels: [
        '1',
        '2',
        '3',
        '4',
        '5',
    ],
    CreationDate: '2021-06-30T13:06:43Z',
    Id: id,
    Version: 0,
    InkSet: [
        {
            Color: '#FFF200',
            Name: 'Yellow',
            Type: 'None',
        },
        {
            Color: '#ED008C',
            Name: 'Magenta',
            Type: 'None',
        },
        {
            Color: '#00ADEE',
            Name: 'Cyan',
            Type: 'None',
        },
        {
            Color: '#000000',
            Name: 'Black',
            Type: 'None',
        },
    ],
    Name: name,
    Patches: [
        { // Calculated Average Yellow 100 / Magenta 0 / Cyan 0 / Black 0
            Index: null,
            InkValues: [100, 0, 0, 0],
            Values: [
                {
                    Hex: '#3e77de',
                    ConditionIndex: 0,
                    Density: 0.62,
                    LCh: [
                        51.1,
                        59.27,
                        278.17,
                    ],
                    Lab: [
                        12.34,
                        8.42,
                        -58.67,
                    ],
                    SessionIndex: 0,
                    ToneValue: 100.00,
                },
            ],
        },
        { // Patch 0A
            Index: [0, 0, 0],
            InkValues: [100, 0, 0, 0],
            Values: [
                {
                    Hex: '#3e77de',
                    ConditionIndex: 0,
                    Density: 0.0,
                    LCh: [
                        51.1,
                        59.27,
                        278.17,
                    ],
                    Lab: [
                        12.34,
                        8.42,
                        -58.67,
                    ],
                    SessionIndex: 0,
                    ToneValue: 0.0,
                },
            ],
        },
        { // Patch 0B
            Index: [0, 0, 1],
            InkValues: [100, 0, 0, 0],
            Values: [
                {
                    Hex: '#321333',
                    ConditionIndex: 0,
                    Density: 0.0,
                    LCh: [
                        11.95,
                        23.66,
                        326.53,
                    ],
                    Lab: [
                        30.01,
                        19.74,
                        -13.05,
                    ],
                    SessionIndex: 0,
                    ToneValue: 0.0,
                },
            ],
        },
        { // Calculated Average Yellow 80 / Magenta 0 / Cyan 0  / Black 0
            Index: null,
            InkValues: [80, 0, 0, 0],
            Values: [
                {
                    Hex: '#3e76de',
                    ConditionIndex: 0,
                    Density: 0.63,
                    LCh: [
                        51.1,
                        59.27,
                        278.17,
                    ],
                    Lab: [
                        12.34,
                        8.42,
                        -58.67,
                    ],
                    SessionIndex: 0,
                    ToneValue: 82.22,
                },
            ],
        },
        { // Patch 1A
            Index: [0, 1, 0],
            InkValues: [80, 0, 0, 0],
            Values: [
                {
                    Hex: '#f2f29e',
                    ConditionIndex: 0,
                    Density: 0.0,
                    LCh: [
                        94.28,
                        42.45,
                        101.33,
                    ],
                    Lab: [
                        94.28,
                        -8.34,
                        41.62,
                    ],
                    SessionIndex: 0,
                    ToneValue: 82.111,
                },
            ],
        },
        { // Calculated Average Yellow 0 / Magenta 80 / Cyan 0 / Black 0
            Index: null,
            InkValues: [0, 80, 0, 0],
            Values: [
                {
                    Hex: '#3e66de',
                    ConditionIndex: 0,
                    Density: 0.65,
                    LCh: [
                        51.1,
                        59.27,
                        278.17,
                    ],
                    Lab: [
                        12.34,
                        8.42,
                        -58.67,
                    ],
                    SessionIndex: 0,
                    ToneValue: 85.22,
                },
            ],
        },
        { // Patch 1B
            Index: [0, 1, 1],
            InkValues: [0, 80, 0, 0],
            Values: [
                {
                    Hex: '#f2f29e',
                    ConditionIndex: 0,
                    Density: 0.0,
                    LCh: [
                        94.28,
                        42.45,
                        101.33,
                    ],
                    Lab: [
                        94.28,
                        -8.34,
                        41.62,
                    ],
                    SessionIndex: 0,
                    ToneValue: 81.01,
                },
            ],
        },
        { // Calculated Average Yellow 0 / Magenta 100 / Cyan 0 / Black 0
            Index: null,
            InkValues: [0, 100, 0, 0],
            Values: [
                {
                    Hex: '#3e77de',
                    ConditionIndex: 0,
                    Density: 0.69,
                    LCh: [
                        51.1,
                        59.27,
                        278.17,
                    ],
                    Lab: [
                        12.34,
                        8.42,
                        -58.67,
                    ],
                    SessionIndex: 0,
                    ToneValue: 100,
                },
            ],
        },
        { // Patch 2A
            Index: [0, 2, 0],
            InkValues: [0, 100, 0, 0],
            Values: [
                {
                    Hex: '#f2f29e',
                    ConditionIndex: 0,
                    Density: 0.0,
                    LCh: [
                        94.28,
                        42.45,
                        101.33,
                    ],
                    Lab: [
                        94.28,
                        -8.34,
                        41.62,
                    ],
                    SessionIndex: 0,
                    ToneValue: 0.0,
                },
            ],
        },
        { // Patch 2B
            Index: [0, 2, 1],
            InkValues: [0, 100, 0, 0],
            Values: [
                {
                    Hex: '#0d0c13',
                    ConditionIndex: 0,
                    Density: 0.0,
                    LCh: [
                        3.77,
                        2.55,
                        290.42,
                    ],
                    Lab: [
                        3.77,
                        0.89,
                        -2.39,
                    ],
                    SessionIndex: 0,
                    ToneValue: 0.0,
                },
            ],
        },
        { // Calculated Average Yellow 30 / Magenta 70 / Cyan 0  / Black 0 (=Overprint Red)=>  asymetric
            Index: null,
            InkValues: [30, 70, 0, 0],
            Values: [
                {
                    Hex: '#3e77de',
                    ConditionIndex: 0,
                    Density: 0.71,
                    LCh: [
                        51.1,
                        59.27,
                        278.17,
                    ],
                    Lab: [
                        12.34,
                        8.42,
                        -58.67,
                    ],
                    SessionIndex: 0,
                    ToneValue: 101.11,
                },
            ],
        },
        { // Patch 3A
            Index: [0, 3, 0],
            InkValues: [30, 70, 0, 0],
            Values: [
                {
                    Hex: '#f2f29e',
                    ConditionIndex: 0,
                    Density: 0.0,
                    LCh: [
                        94.28,
                        42.45,
                        101.33,
                    ],
                    Lab: [
                        94.28,
                        -8.34,
                        41.62,
                    ],
                    SessionIndex: 0,
                    ToneValue: 0.0,
                },
            ],
        },
        { // Patch 3B
            Index: [0, 3, 1],
            InkValues: [30, 70, 0, 0],
            Values: [
                {
                    Hex: '#0d0c13',
                    ConditionIndex: 0,
                    Density: 0.0,
                    LCh: [
                        3.77,
                        2.55,
                        290.42,
                    ],
                    Lab: [
                        3.77,
                        0.89,
                        -2.39,
                    ],
                    SessionIndex: 0,
                    ToneValue: 0.0,
                },
            ],
        },
        { // Calculated Average Yellow 30 / Magenta 30  / Cyan 0 / Black 0 (= Overprint Red) =>  symetric
            Index: null,
            InkValues: [30, 30, 0, 0],
            Values: [
                {
                    Hex: '#3e77de',
                    ConditionIndex: 0,
                    Density: 0.71,
                    LCh: [
                        51.1,
                        59.27,
                        278.17,
                    ],
                    Lab: [
                        12.34,
                        8.42,
                        -58.67,
                    ],
                    SessionIndex: 0,
                    ToneValue: 101.11,
                },
            ],
        },
        { // Patch 4A
            Index: [0, 4, 0, 0],
            InkValues: [30, 30, 0],
            Values: [
                {
                    Hex: '#f2f29e',
                    ConditionIndex: 0,
                    Density: 0.0,
                    LCh: [
                        94.28,
                        42.45,
                        101.33,
                    ],
                    Lab: [
                        94.28,
                        -8.34,
                        41.62,
                    ],
                    SessionIndex: 0,
                    ToneValue: 0.0,
                },
            ],
        },
        { // Patch 4B
            Index: [0, 4, 1],
            InkValues: [30, 30, 0, 0],
            Values: [
                {
                    Hex: '#0d0c13',
                    ConditionIndex: 0,
                    Density: 0.0,
                    LCh: [
                        3.77,
                        2.55,
                        290.42,
                    ],
                    Lab: [
                        3.77,
                        0.89,
                        -2.39,
                    ],
                    SessionIndex: 0,
                    ToneValue: 0.0,
                },
            ],
        },
        { // Calculated Average Yellow 0 / Magenta 0 / Cyan 100 / Black 0
            Index: null,
            InkValues: [0, 0, 100, 0],
            Values: [
                {
                    Hex: '#3e77de',
                    ConditionIndex: 0,
                    Density: 0.62,
                    LCh: [
                        51.1,
                        59.27,
                        278.17,
                    ],
                    Lab: [
                        12.34,
                        8.42,
                        -58.67,
                    ],
                    SessionIndex: 0,
                    ToneValue: 100,
                },
            ],
        },
        { // Calculated Average Yellow 0 / Magenta 0 / Cyan 60 / Black 0
            Index: null,
            InkValues: [0, 0, 60, 0],
            Values: [
                {
                    Hex: '#3e77de',
                    ConditionIndex: 0,
                    Density: 0.62,
                    LCh: [
                        51.1,
                        59.27,
                        278.17,
                    ],
                    Lab: [
                        12.34,
                        8.42,
                        -58.67,
                    ],
                    SessionIndex: 0,
                    ToneValue: 80,
                },
            ],
        },
        { // Patch 5A
            Index: [0, 5, 0],
            InkValues: [0, 0, 60, 0],
            Values: [
                {
                    Hex: '#3e77de',
                    ConditionIndex: 0,
                    Density: 0.0,
                    LCh: [
                        51.1,
                        59.27,
                        278.17,
                    ],
                    Lab: [
                        12.34,
                        8.42,
                        -58.67,
                    ],
                    SessionIndex: 0,
                    ToneValue: null,
                },
            ],
        },
        { // Patch 5B
            Index: [0, 5, 1],
            InkValues: [100, 0, 0, 0],
            Values: [
                {
                    Hex: '#321333',
                    ConditionIndex: 0,
                    Density: 0.0,
                    LCh: [
                        11.95,
                        23.66,
                        326.53,
                    ],
                    Lab: [
                        30.01,
                        19.74,
                        -13.05,
                    ],
                    SessionIndex: 0,
                    ToneValue: 0.0,
                },
            ],
        },
        { // Calculated Average Media
            Index: null,
            InkValues: [0, 0, 0, 0],
            Values: [
                {
                    Hex: '#ffffff',
                    ConditionIndex: 0,
                    Density: 0,
                    LCh: [
                        51.1,
                        59.27,
                        278.17,
                    ],
                    Lab: [
                        90.81,
                        8.42,
                        -58.67,
                    ],
                    SessionIndex: 0,
                    ToneValue: 0.0,
                },
            ],
        },
        { // Patch 6A
            Index: [0, 6, 0],
            InkValues: [0, 0, 0, 0],
            Values: [
                {
                    Hex: '#ffffff',
                    ConditionIndex: 0,
                    Density: 0.0,
                    LCh: [
                        94.28,
                        42.45,
                        101.33,
                    ],
                    Lab: [
                        94.28,
                        -8.34,
                        41.62,
                    ],
                    SessionIndex: 0,
                    ToneValue: 0.0,
                },
            ],
        },
        { // Patch 6B
            Index: [0, 6, 1],
            InkValues: [0, 0, 0, 0],
            Values: [
                {
                    Hex: '#ffffff',
                    ConditionIndex: 0,
                    Density: 0.0,
                    LCh: [
                        3.77,
                        2.55,
                        290.42,
                    ],
                    Lab: [
                        3.77,
                        0.89,
                        -2.39,
                    ],
                    SessionIndex: 0,
                    ToneValue: 0.0,
                },
            ],
        },
        { // Calculated Average Yellow 0 / Magenta 0 / Cyan 0 / Black 100
            Index: null,
            InkValues: [0, 0, 0, 100],
            Values: [
                {
                    Hex: '#000',
                    ConditionIndex: 0,
                    Density: 0.62,
                    LCh: [
                        51.1,
                        59.27,
                        278.17,
                    ],
                    Lab: [
                        12.34,
                        8.42,
                        -58.67,
                    ],
                    SessionIndex: 0,
                    ToneValue: 100,
                },
            ],
        },
        { // Calculated Average Yellow 0 / Magenta 0 / Cyan 0 / Black 60
            Index: null,
            InkValues: [0, 0, 0, 40],
            Values: [
                {
                    Hex: '#000',
                    ConditionIndex: 0,
                    Density: 0.62,
                    LCh: [
                        51.1,
                        59.27,
                        278.17,
                    ],
                    Lab: [
                        12.34,
                        8.42,
                        -58.67,
                    ],
                    SessionIndex: 0,
                    ToneValue: 45,
                },
            ],
        },
    ],
});

export const getApplicationSettings =
{
    data: {
        getApplicationSettings: {
            settings: [
                {
                    appKeys: [
                        'MIO',
                    ],
                    key: 'filterCMYK',
                    value: 'StatusE',
                },
                {
                    appKeys: [
                        'MIO',
                    ],
                    key: 'methodCMYK',
                    value: 'MurrayDavies',
                },
                {
                    appKeys: [
                        'MIO',
                    ],
                    key: 'methodSpot',
                    value: 'SCTV',
                },
                {
                    appKeys: [
                        'MIO',
                    ],
                    key: 'observer',
                    value: 'O2',
                },
                {
                    appKeys: [
                        'MIO',
                    ],
                    key: 'illuminant',
                    value: 'D65',
                },
            ],
        },
    },
};

export const getApplicationSettingsResponseNoSettings =
{
    data: {
        getApplicationSettings: {
            notification: {
                message: '',
                succeeded: true,
                version: 'ccce9f8',
            },
            settings: [

            ],
        },
    },
};

export const measurementParams = {
    illuminant: 'D50' as Illuminants,
    observer: 'O2' as Observers,
    methodSpot: 'MurrayDavies' as CmykMethods,
    filterCMYK: 'StatusE' as CmykFilters,
    methodCMYK: 'MurrayDavies' as CmykMethods,
};

export const getMeasurementTableView = (variant: 'chart' | 'spider', id: string, name: string, condition: 'M0' | 'M1' | 'M2' | 'M3' = 'M0') => {
    return ({
        data: {
            getMeasurementTableView: variant === 'chart'
                ? chartTableView(id, name, condition)
                : diagramTableView(id, name, condition),
        },
    });
};

export const getMeasurementDownloadUrlResponse = (extension: 'zip' | 'txt') => ({
    data: {
        getMeasurementDownloadUrl: {
            url: `https://gmg-dev-mio-storage.s3.eu-central-1.amazonaws.com/df83b80c-88e2-4854-80ad-a24b2f0b6ba9/e2eTestRun-1636389527.473/testFile.${extension}?/blabla`,
        },
    },
});
export const getMeasurementsDownloadUrlResponse = (extension: 'zip' | 'txt') => ({
    data: {
        getMeasurementsDownloadUrl: {
            url: `https://gmg-dev-mio-storage.s3.eu-central-1.amazonaws.com/df83b80c-88e2-4854-80ad-a24b2f0b6ba9/e2eTestRun-1636389527.473/testFile.${extension}?/blabla`,
        },
    },
});

export const getDeleteItemsResponse = (result: 'success' | 'error', measurementIds: Array<string>, folderIds: Array<string>) => ({
    data: {
        deleteItems: {
            measurementIds: result === 'success' ? measurementIds : [],
            folderIds: result === 'success' ? folderIds : [],
            succeeded: result === 'success',
        },
        errors: result === 'error'
            ? [{ message: '78463953_DeleteMeasurementHasFailed' }]
            : null,
    },
});
export const getMoveItemsResponse = (result: 'success' | 'error') => ({
    data: {
        moveItems: {
            succeeded: result === 'success' ? true : null,
        },
    },
    errors: result === 'error'
        ? [{ message: '78463953_MoveFile' }]
        : '',
});

export const getCopyMeasurementResponse = () => ({
    data: {
        copyMeasurement: {
            id: 'someID',
        },
    },
    errors: '',
});
export const getMeasurementUploadUrlResponse = {
    data: {
        getMeasurementUploadUrl: {
            url: '"https://gmg-dev-mio-storage.s3.eu-central-1.amazonaws.com/df83b80c-88e2-4854-80ad-a24b2f0b6ba9/e2eTestRun-1636389527.473',
            notification: {
                message: null,
                succeeded: true,
            },
        },
    },
};
export const getMeasurementUploadResponse = {
    data: {
        addMeasurement: {
            data: {
                id: '4711',
                timestamp: '2006-01-02T15:04:05.000Z',
            },
            notification: {
                message: null,
                succeeded: true,
            },
        },
    },
};
export const getFolderContent = (params: 'root' | 'USA' | 'California' | 'Silicon Valley' | 'LA' | 'San Jose' | 'Los Coches' | 'STANDARDCHARACTERIZATIONS'| 'MesWithChangedIds'): any => {
    if (params === 'root') {
        return {
            data: {
                getFolderContent: {
                    subfolders: [
                        {
                            id: '01G24EEYT7BYP2GHGX11EJAPVZ',
                            name: 'United States of America',
                            isTraversable: true,
                        },
                        {
                            id: '01G1RVP0X6D3TPBC107W7W288H',
                            name: 'United Kingdom',
                            isTraversable: true,
                        },
                        {
                            id: 'STANDARDCHARACTERIZATIONS',
                            name: 'STANDARD CHARACTERIZATIONS Folder',
                            isTraversable: true,
                        },
                    ],
                    measurements: [],
                },
            },
        };
    }
    if (params === 'MesWithChangedIds') {
        return {
            data: {
                getFolderContent: {
                    folder: {
                        id: 'MesWithChangedIds',
                        isEditable: true,
                    },
                    subfolders: [
                        {
                            id: 'TESTFOLDERID',
                            name: 'TEST SUBFOLDER NAME',
                            isTraversable: true,
                        },
                    ],
                    measurements: testFiles('01G24EEYT7BYP2GHGX11EJAPVZ').map((file, index) => ({
                        ...file,
                        name: `Test File with new ID ${index + 1}`,
                        id: `${file.id}_${index + 1}`,
                    })),
                },
            },
        };
    }
    if (params === 'STANDARDCHARACTERIZATIONS') {
        return {
            data: {
                getFolderContent: {
                    folder: {
                        id: 'STANDARDCHARACTERIZATIONS',
                        isEditable: false,
                    },
                    subfolders: [],
                    measurements: testFiles('01G24EEYT7BYP2GHGX11EJAPVZ').map((file, index) => ({
                        ...file,
                        name: `STANDARD CHARACTERIZATIONS File ${index + 1}`,
                    })),
                },
            },
        };
    }

    if (params === 'USA') {
        return {
            data: {
                getFolderContent: {
                    folder: {
                        id: '01G24EEYT7BYP2GHGX11EJAPVZ',
                        isEditable: true,
                    },
                    subfolders: [
                        {
                            id: '01G24EGQJTWYYPKH9ZJA72RQVN',
                            name: 'California as a State',
                            isTraversable: true,
                        },
                        {
                            id: '01G24EHNQP3BTBDQ11T54JX7W2',
                            name: 'Florida',
                            isTraversable: true,
                        },
                        {
                            id: 'EmptyFolder',
                            name: 'EmptyFolder',
                            isTraversable: true,
                        },
                        {
                            id: 'MesWithChangedIds',
                            name: 'MesWithChangedIds Folder',
                            isTraversable: true,
                        },
                    ],
                    measurements: testFiles('01G24EEYT7BYP2GHGX11EJAPVZ'),
                },
            },
        };
    }

    if (params === 'California') {
        return {
            data: {
                getFolderContent: {
                    folder: {
                        id: 'California',
                        isEditable: true,
                    },
                    subfolders: [
                        {
                            id: '4711',
                            name: 'Silicon Valley region',
                            isTraversable: true,
                        },
                        {
                            id: 'LA',
                            name: 'Los Angeles',
                            isTraversable: true,
                        },
                    ],
                    measurements: testFiles('01G24EGQJTWYYPKH9ZJA72RQVN'),
                },
            },
        };
    }
    if (params === 'Silicon Valley') {
        return {
            data: {
                getFolderContent: {
                    folder: {
                        id: 'Silicon Valley',
                        isEditable: true,
                    },
                    subfolders: [
                        {
                            id: '01G24EGQJTWYYPKH9ZJA72RQVN22',
                            name: 'San Jose city as a placeholder',
                            isTraversable: true,
                        },
                    ],
                    measurements: testFiles('4711'),
                },
            },
        };
    }
    if (params === 'LA') {
        return {
            data: {
                getFolderContent: {
                    folder: {
                        id: 'LA',
                        isEditable: true,
                    },
                    subfolders: [{
                        id: 'HO',
                        name: 'Hollywood',
                        isTraversable: true,
                    }],
                    measurements: [],
                },
            },
        };
    }
    if (params === 'San Jose') {
        return {
            data: {
                getFolderContent: {
                    folder: {
                        id: 'San Jose',
                        isEditable: true,
                    },
                    subfolders: [
                        {
                            id: '01G24EHNQP3BTBDQ11T54JX7W2333',
                            name: 'Los Coches Ave placeholder',
                            isTraversable: true,
                        },
                    ],
                    measurements: testFiles('01G24EGQJTWYYPKH9ZJA72RQVN22'),
                },
            },
        };
    }
    if (params === 'Los Coches') {
        return {
            data: {
                getFolderContent: {
                    folder: {
                        id: 'Los Coches',
                        isEditable: true,
                    },
                    subfolders: [
                        {
                            id: '01G24EHNQP3BTBDQ11T54JX7W2333',
                            name: 'Antonio Coimbra de la Coronilla y Azevedo',
                            isTraversable: true,
                        },
                    ],
                    measurements: [],
                },
            },
        };
    }

    return {
        data: {
            getFolderContent: {
                folder: {
                    id: params,
                    isEditable: true,
                },
                subfolders: [],
                measurements: [],

            },
        },
    };
};
export const useOutliersMock = () => {
    return {
        data: {
            removeOutliers: {
                measurementId: 'someId',
                succeeded: true,
            },
        },
    };
};
export const useSmoothingMock = () => {
    return {
        data: {
            smooth: {
                succeded: true,
            },
        },
    };
};
export const createAveragedMeasurementMock = () => {
    return {
        data: {
            createAveragedMeasurement: {
                measurementId: '48ba4b87-28ba-48f8-bcf6-e044df278093',
                measurementName: 'New averaged file name',
            },
        },
    };
};
export const useAutoOptimizationMock = (name: string = 'Original file name') => {
    return {
        data: {
            autoOptimize: {
                measurementId: 'automatically created on BE',
                measurementName: name,
                succeded: true,
            },
        },
    };
};

export const useAutoOptimizationMockError = () => ({
    data: null,
    errors: [{ message: '78463953_AutoOpt' }],
});

export const useRescaleMock = () => {
    return {
        data: {
            rescale: {
                measurementId: 'automatically created on BE',
                succeded: true,
            },
        },
    };
};
export const renameFileMock = (result: 'success' | 'error') => {
    return {
        data: {
            renameMeasurement: {
                id: result === 'success' ? 'someID' : null,
            },
        },
    };
};

export const createFolderMock = (result: 'success' | 'error') => {
    return {
        data: {
            createFolder: {
                id: result === 'success' ? 'someID' : null,
            },
        },
    };
};

const versions = [
    {
        versionInformation: {
            version: 3,
            timestamp: '01.03.2023 11:27 am',
            reason: 'smoothed',
            author: {
                firstName: 'Max',
                lastName: 'Goldman',
            },
            params: [
                {
                    key: 'smoothingStrength',
                    value: '8',
                },
            ],

        },
    },
    {
        versionInformation: {
            version: 2,
            timestamp: '01.03.2023 11:28 am',
            reason: 'created', // note that this does not make sense, should be the oldest history item
            author: {
                firstName: 'Jacob',
                lastName: 'Goldman',
            },
            params: [
                {
                    key: 'creationType', // umwandeln
                    value: 'Arithmetic average',
                },
                {
                    key: 'sourceFile',
                    value: 'Gmg template file 1',
                },
                {
                    key: 'sourceFile',
                    value: 'Gmg template file 2',
                },
            ],

        },
    },
    {
        versionInformation: {
            version: 1,
            timestamp: '01.03.2023 11:30 am',
            reason: 'outliers_removed',
            author: {
                firstName: 'John',
                lastName: 'Gustafson',
            },
            params: undefined,
        },
    },
    {
        versionInformation: {
            version: 0,
            timestamp: '01.03.2023 11:29 am',
            reason: 'rescaled',
            author: {
                firstName: '',
                lastName: '',
                email: 'steven.spielberg@lucasfilm.com',
            },
            params: [
                {
                    key: 'templateFile',
                    value: 'GMG_ECI2002_random_i1_i1iO_1122334_Bla_bla_average',
                },
            ],

        },
    },
];


export const getHistoryMockSuccess = () => ({
    data: {
        getMeasurementHistory: {
            versions,
        },
    },
});


export const getHistoryMockkError = () => ({
    data: null,
    errors: [{ message: '78463953' }],
});

export const renameFolderMockSuccess = () => ({
    data: {
        editFolder: {
            id: 'someID',
        },
    },
});

export const renameFolderMockError = () => ({
    data: null,
    errors: [{ message: '78463953' }],
});

// mocks for Table component
const inksArray = [
    {
        key: 'Cyan',
        value: '#0093C8',
    },
    {
        key: 'Magenta',
        value: '#B43E70',
    },
    {
        key: 'Orange',
        value: '#C8854A',
    },
];

const inksMap = new Map(
    inksArray.map(ink => [ink.key, ink.value]),
);

export const getMeasurementTestDataForTable = (
    measurementId: string,
    measurementName: string,
    measurementCondition: string,
    labValues: [number, number, number],
    cyanValue: number = 10,
): MeasurementViewModel => {
    return {
        id: measurementId,
        versionedId: `${measurementId}-#0`,
        name: measurementName,
        processParamsDefined: false,
        version: 0,
        versionLabel: '#0',
        isLatestVersion: true,
        inks: inksMap,
        overPrints: new Map(),
        pageCount: 3,
        rowLabels: ['1'],
        columnLabels: ['A', 'B'],
        patches: [
            {
                id: '2-0-0',
                pageIndex: 2,
                rowIndex: 0,
                rowLabel: '1',
                columnIndex: 0,
                columnLabel: 'A',
                characteristic: undefined,
                isCalculatedAverage: false,
                inkValues: [
                    {
                        name: 'Cyan',
                        value: 0,
                    },
                    {
                        name: 'Magenta',
                        value: 0,
                    },
                    {
                        name: 'Orange',
                        value: 0,
                    },
                ],
                value: {
                    hex: '#fedca9',
                    lab: [
                        100,
                        0,
                        0,
                    ],
                    lch: [
                        93.38,
                        13.02,
                        274.54,
                    ],
                    density: 1,
                    toneValue: 1,
                },
            },
            {
                id: '2-1-1',
                pageIndex: 2,
                rowIndex: 1,
                rowLabel: '2',
                columnIndex: 1,
                columnLabel: 'B',
                characteristic: undefined,
                isCalculatedAverage: false,
                inkValues: [
                    {
                        name: 'Cyan',
                        value: cyanValue,
                    },
                    {
                        name: 'Magenta',
                        value: 20,
                    },
                    {
                        name: 'Orange',
                        value: 50,
                    },
                ],
                value: {
                    hex: '#3f6461',
                    lab: labValues,
                    lch: [
                        93.38,
                        13.02,
                        274.54,
                    ],
                    density: 1,
                    toneValue: 1,
                },
            },
        ],
        variant: 'chart',
        condition: measurementCondition,
        availableConditions: [measurementCondition],
        folderId: '01H6V7JGZ7HZYWRFPVK9ME0N2G',
        isEditable: true,
    };
};

export const getConditionMismatchTestDataForTable = (
    measurementId: string,
    measurementName: string,
    measurementCondition: string,
): MeasurementViewModel => {
    return {
        id: measurementId,
        versionedId: `${measurementId}-#0`,
        name: measurementName,
        processParamsDefined: false,
        version: 0,
        versionLabel: '#0',
        isLatestVersion: true,
        inks: inksMap,
        overPrints: new Map(),
        pageCount: 0,
        rowLabels: ['1'],
        columnLabels: ['A', 'B'],
        patches: [
        ],
        variant: 'chart',
        condition: measurementCondition,
        availableConditions: [measurementCondition],
        folderId: '01H6V7JGZ7HZYWRFPVK9ME0N2G',
        isEditable: true,
    };
};
