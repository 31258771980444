import { Box, Checkbox, IconCancel, IconHistory, KebabMenu, Tooltip } from '@gmg/gmg-react-components';
import { FunctionComponent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from 'src/AppContext';
import { getFileHistoryClickEvent } from 'src/tracking';
import styled from 'styled-components';
import { ReactComponent as AddFileIcon } from '../../assets/icon_addFile.svg';
import { ReactComponent as ReferenceIcon } from '../../assets/icon_referenceChecked.svg';
import { NecessaryFileData } from '../Inspect';
import { PathItem } from '../measurements/BreadCrumb';
import FileExplorerModalContainer from './FileExplorerModal/FileExplorerModalContainer';
import HistoryContainer from './HistoryContainer';
import { ColorCorrection } from './VisualizationContainer';

interface MarkedFiles {
    id: string;
    versionObj?: {
        num: number;
        isLatestVersion: boolean;
    };
}

export interface FilePickerProps {
    files: Map<string, NecessaryFileData>;
    markedFiles: Array<MarkedFiles>;
    path: Array<PathItem>;
    selectedHistoricVersions: Array<number>;
    activeColorCorrection?: ColorCorrection;
    isHistoryDisplayed: boolean;
    onChangeMarkedFiles: (selection: Array<MarkedFiles>) => void;
    onToggleHistory: (measurementId?: string) => void;
    onRemoveFile: (id: string) => void;
    onHighlightMeasurement: (versionedId?: string) => void;
    onSelectAdditionalFiles: (idsAndNecessaryFileData: Map<string, NecessaryFileData>) => void;
    onSetReference: (id: string | undefined) => void;
    referenceMeasurementId: string | undefined;
}

/*
 NOTE: it might not be ideal that <FilePicker> renders the measurement list itself and <HistoryContainer>
is a child which renders the file versions. They actually should be on the same level, and a container component
above them should "control" their communication and own functionality both components have in common.
*/
const FilePicker: FunctionComponent<FilePickerProps> = props => {
    const { t } = useTranslation();
    const { isUnlimitedLicenseAvailable, onShowModal, trackEvent } = useContext(AppContext);

    const handleCheckboxClick = (
        selection: { id: string; versionObj?: { num: number; isLatestVersion: boolean } },
        action: 'select' | 'deselect') => {

        // FIXME: With the currecnt solution we can not avvoid the selection click
        // although we have introduced in the useOutsideClick preventing the event propagation.
        // The possible problem is, that we use a custom circled checkbox which have several elements piled on eacht other!

        if (props.activeColorCorrection !== undefined) return;

        if (action === 'deselect') {
            if (props.markedFiles.length > 1) { // last marked file cannot be deselected
                const newList = props.markedFiles.filter(item => item.id !== selection.id || item.versionObj?.num !== selection.versionObj?.num);
                props.onChangeMarkedFiles(newList);

                if (props.referenceMeasurementId?.includes(selection.id)) {
                    props.onSetReference(undefined);
                }
            }
        } else {
            props.onChangeMarkedFiles([...props.markedFiles, selection]);
        }
    };

    const handleFileClick = (selection: { id: string; versionObj?: { num: number; isLatestVersion: boolean } }) => {
        if (!props.referenceMeasurementId?.includes(selection.id)) {
            props.onSetReference(undefined);
        }
        props.onChangeMarkedFiles([selection]);
    };

    const handleRemoveFile = (e: React.MouseEvent, idToBeRemoved: string) => {
        e.stopPropagation();
        props.onRemoveFile(idToBeRemoved);
        if (props.referenceMeasurementId === idToBeRemoved) {
            props.onSetReference(undefined);
        }

        // remove file from FilePicker & remove marker
        props.onChangeMarkedFiles(props.markedFiles.filter(item => item.id !== idToBeRemoved));
    };

    const handleMouseMove = (id?: string) => {
        if (!id) {
            props.onHighlightMeasurement();
            return;
        }

        if (!props.markedFiles.find(item => item.id === id)) {
            return;
        }

        const versionedId = `${id}-#${props.files.get(id)!.version}`;
        props.onHighlightMeasurement(versionedId);
    };

    const handleShowHistory = (id: string) => {
        props.onHighlightMeasurement();
        props.onSetReference(undefined);
        props.onToggleHistory(id);
    };

    const handleAddFileClick = () => {
        onShowModal('inputModal', {
            title: t('FilePicker.open_additional_file_modal_title', 'Open additional files'),
            titleIcon: <AddFileIcon />,
            content: <FileExplorerModalContainer
                operationType='addToFilePicker'
                onSelectAdditionalFiles={props.onSelectAdditionalFiles}
                baseSelection={Array.from(
                    props.files.entries(), ([id, { name, version }]) => ({ id, name, version, isFolder: false }))
                }
                path={props.path}
                isSelectedFilesInfoRequired
                sectionTitle={t('FileExplorerModalContainer.select_files_section_title', 'Select files')}
            />,
        });
    };
    const handleReferenceClick = (tobeChangedId: string) => {
        const clickedItemFoundAsSelected = props.markedFiles.find(file => file.id === tobeChangedId);

        if (clickedItemFoundAsSelected) {
            props.onSetReference(
                props.referenceMeasurementId === tobeChangedId
                    ? undefined
                    : tobeChangedId,
            );
        } else {
            props.onChangeMarkedFiles([...props.markedFiles, { id: tobeChangedId }]);
            props.onSetReference(tobeChangedId);
        }
    };

    const toolTipPath = props.path.slice(1).map(path => path.name).join('/');

    return (
        <Box
            bg="backgroundPaper"
            flexGrow={1} // grow to the bottom
        >
            <StyledNav aria-label="Opened Files">
                {
                    props.isHistoryDisplayed
                        ? (
                            <HistoryContainer
                                measurementName={props.files.get(props.markedFiles[0].id)!.name}
                                measurementId={props.markedFiles[0].id}
                                selectedVersions={props.selectedHistoricVersions}
                                onCheckboxClick={handleCheckboxClick}
                                onFileVersionClick={handleFileClick}
                                onHighlightMeasurement={props.onHighlightMeasurement}
                                onClose={() => { props.onToggleHistory(); }}
                            />)
                        : <>
                            <Header>
                                <h4 className="px12_600_weak">
                                    {t('FilePicker.box_title', 'Opened Files')}
                                </h4>
                                <Tooltip message={t('Common.ButtonLabels.add_files_btn_title', 'Open additional files')}>
                                    <AddFileIcon
                                        width="16px"
                                        height="16px"
                                        onClick={() => { handleAddFileClick(); }}
                                        aria-label={t('Common.ButtonLabels.add_files_btn_title', 'Open additional files')}
                                    />
                                </Tooltip>

                            </Header>
                            <FilePickerScrollContainer className="vertical-scrollbar">
                                <ul className="px12_400_normal">
                                    {Array.from(props.files.keys())
                                        .map(id => {
                                            const isSelected = !!props.markedFiles.find(item => item.id === id);
                                            const isLastFile = isSelected && props.markedFiles.length === 1;
                                            const name = props.files.get(id)!.name;
                                            const isReference = props.referenceMeasurementId === id;

                                            return <StyledLi
                                                key={id}
                                                isActive={isSelected}
                                                aria-label={name}
                                                aria-selected={isSelected}
                                                onMouseEnter={() => { handleMouseMove(id); }}
                                                onMouseLeave={() => { handleMouseMove(); }}
                                            >
                                                {
                                                    isUnlimitedLicenseAvailable && <Checkbox
                                                        variant='circled'
                                                        id={`${name}'s checkbox`}
                                                        checked={isSelected}
                                                        onChange={() => { handleCheckboxClick({ id }, isSelected ? 'deselect' : 'select'); }}
                                                    />
                                                }
                                                <Tooltip
                                                    key={id}
                                                    message={`${toolTipPath}/${name}`}
                                                >
                                                    <DIV
                                                        isActive={isSelected}
                                                        onClick={() => { handleFileClick({ id }); }}
                                                    >
                                                        <span>{name}</span>
                                                    </DIV>
                                                </Tooltip>
                                                <ReferenceIconContainer>
                                                    {isReference && <ReferenceIcon aria-label={`Reference indicator for ${name}`} />}
                                                </ReferenceIconContainer>
                                                <KebabMenu
                                                    identifier={name}
                                                    items={[
                                                        {
                                                            label: isReference
                                                                ? t('KebabMenu.unset_reference_btn', 'Unset as reference')
                                                                : t('KebabMenu.set_reference_btn', 'Set as reference'),
                                                            icon: <ReferenceIcon />,
                                                            onItemClick: (e: any) => {
                                                                e.stopPropagation();
                                                                handleReferenceClick(id);
                                                            },
                                                        },
                                                        {
                                                            label: t('KebabMenu.show_history_btn', 'Show history'),
                                                            icon: <IconHistory />,
                                                            onItemClick: (e: any) => {
                                                                e.stopPropagation();
                                                                trackEvent(getFileHistoryClickEvent());
                                                                handleShowHistory(id);
                                                            },
                                                            disabled: props.files.get(id)?.folderId === 'STANDARDCHARACTERIZATIONS',
                                                        },
                                                        {
                                                            label: t('KebabMenu.remove_btn', 'Remove'),
                                                            icon: <IconCancel />,
                                                            onItemClick: (e: any) => { !isLastFile && handleRemoveFile(e, id); },
                                                            disabled: !!isLastFile,
                                                        },
                                                    ]}
                                                />
                                            </StyledLi>;
                                        })}
                                </ul>
                            </FilePickerScrollContainer>
                        </>
                }
            </StyledNav>
        </Box >
    );
};

const ReferenceIconContainer = styled.div`
    color: ${props => props.theme.colors.greyContrastSemiHigh};
    display: flex;
    align-items: center;

     svg {
        height: 16px; 
        width: 16px;
    }
`;

const FilePickerScrollContainer = styled.div`
    height: calc(100vh - 144px);
    max-height: calc(100vh - 144px);
    background-color: ${props => props.theme.colors.backgroundPaper};
`;

const StyledNav = styled.nav`
    display: block;
    box-sizing: border-box;
    margin: 0;
    width: 100%;
   

    ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1px;
        margin: 0;
        padding: 0;
        background-color: ${props => props.theme.colors.background};
        border-bottom: 2px solid ${props => props.theme.colors.background};
    }
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 24px 8px 16px 40px;
    background-color: ${props => props.theme.colors.backgroundPaper};

    svg {
        cursor: pointer;
    }
`;

const DIV = styled.div<{ isActive?: boolean }>`
    grid-column: 2/3;

    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 100%;
    width: 100%;
    line-height: 1.4;
    overflow: hidden;
    cursor: ${props => props.isActive ? 'default' : 'pointer'};

    span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
`;

const StyledLi = styled.li<{ isActive?: boolean }>`
    display: grid;
    grid-template-columns: 16px 1fr 16px 16px;
    grid-template-rows: 100%;
    grid-column-gap: 8px;
    align-items: center;
    height: 40px;
    min-height:  40px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 8px 0 16px;
    list-style-type: none;
    cursor: ${props => props.isActive ? 'default' : 'pointer'};
    background-color: ${props => props.isActive
        ? props.theme.colors.greyContrastLowest
        : props.theme.colors.backgroundPaper};
    border: 1px solid ${props => props.isActive ? props.theme.colors.highlight : 'transparent'};

    &:hover {
        background-color: ${props =>
        props.isActive
            ? props.theme.colors.background
            : props.theme.colors.greyContrastLowest};
    }

    &:hover label  { /*set custom circled checkbox's border-color */
        border-color:  ${props => props.theme.colors.greyContrastHigh};
    }
`;

export default FilePicker;