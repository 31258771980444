import { useContext } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import AppContext from 'src/AppContext';
import { GqlError } from '../GqlError';
import { MeasurementMeta, RenameMeasurementInput } from '../generated/types';

const mutation = `mutation RenameMeasurement($input: RenameMeasurementInput!) {
    renameMeasurement(input: $input) {
        id
    }
}`;

export const useRenameMeasurement = (folderId: string) => {
    const context = useContext(AppContext);

    const queryClient = useQueryClient();

    return useMutation(

        async (params: RenameMeasurementInput) => {

            await context.populateCacheForAppSynch?.();

            const response = await (context.api.graphql({
                query: mutation,
                variables: {
                    input: {
                        id: params.id,
                        version: params.version,
                        name: params.name,
                    },
                },
            }) as Promise<{
                data: {
                    renameMeasurement: MeasurementMeta;
                };
                errors?: Array<GqlError>;
            }>);

            return Boolean(response.data.renameMeasurement.id);
        },
        {
            onSettled: async (_, __, variables) => { // also in case of errors, reload the list to avoid confusion
                await queryClient.invalidateQueries(['folderContent', folderId]);
            },
        },
    );
};


