import { IconSearch, InputTypeTextReduced } from '@gmg/gmg-react-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface ExplorerSearchBarProps {
    onInputChange: (input: string) => void;
    filterInput: string;
};

const ExplorerSearchBar: FC<ExplorerSearchBarProps> = (props) => {
    const { t } = useTranslation();

    return (
        <InputTypeTextReduced
            icon={<IconSearch />}
            width="228px"
            placeholder={t('Explorer.inputPlaceholder', 'Filter here ...')}
            error={false}
            onChange={(e) => { props.onInputChange(e.target.value); }}
            value={props.filterInput}
        />
    );
};

export default ExplorerSearchBar;