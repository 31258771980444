import { GmgTheme } from '@gmg/gmg-react-components';
import { MeasurementViewModel, Value } from 'src/graphql/ViewModels';
import { Point } from '../Point';
import { EditedTonalValue } from './TonalValue';
import { IDraggablePoint } from './DraggablePoint';


interface ValueWithRange extends Value {
    range: { min: number; max: number };
}
export interface TonalValueData {
    hex: string;
    indexValueSeries: Array<Point>;
    indexValueSeriesWithEdits: Array<{ index: number; percentage: number }>;
    gridData: {
        versionedMeasurementId: string;
        hex: string;
        lineDefinition: {
            original: string;
            edited: string | undefined;
        };
        points: Array<IDraggablePoint['point']>;
    };
    tableData: {
        versionedMeasurementId: string;
        name: string;
        versionLabel: string;
        content: Map<number, ValueWithRange>;
    };
}

export const getDataForTonalValue = (
    measurements: MeasurementViewModel[],
    selectedColor: string,
    theme: GmgTheme,
    diagramSize: number,
    editedTonalValues: EditedTonalValue[],
): Array<TonalValueData> => {

    function calculateSvgPos(axis: 'x' | 'y', value: number) {
        const dimensionPercentage = diagramSize / 100;
        return axis === 'x'
            ? Math.round(value * dimensionPercentage)
            : Math.round(diagramSize - value * dimensionPercentage);
    };

    const createLineDefinition = (values: Array<{ x: number; y: number }>) => {
        return values.reduce((prev, { x, y }, i) =>
            `${prev} ${i === 0 ? 'M' : ''}${i === 1 ? 'L' : ''} ${calculateSvgPos('x', x)} ${calculateSvgPos('y', y)}`, '');
    };

    const result = measurements.map(viewModel => {

        const hex = selectedColor !== 'Black' ? viewModel.inks.get(selectedColor!) || '' : theme.colors.greyContrastMedium;

        const singleColorPatches = viewModel.patches
            .filter(patch => (patch.characteristic?.type === 'singleColor' || patch.characteristic?.type === 'media') && patch.isCalculatedAverage);

        const mediaAvgPatch = singleColorPatches.find(patch => patch.isCalculatedAverage && patch.characteristic?.type === 'media');

        const createOrigin = (): Point => ({
            x: 0,
            y: 0,
            hex: mediaAvgPatch ? mediaAvgPatch.value!.hex : theme.colors.white,
            id: 'avg-origin',
        });

        const calculatedAverages: Array<Point> = [createOrigin()];

        const tableContent = new Map<number, Value>();
        if (mediaAvgPatch) {
            tableContent.set(0, mediaAvgPatch.value!);
        }

        singleColorPatches
            .filter(patch => patch.inkValues.find(inkValue => inkValue.value > 0 && inkValue.name === selectedColor))
            .forEach(patch => {
                const inkValue = patch.inkValues.find(item => item.value > 0)!;

                calculatedAverages.push({
                    x: inkValue.value,
                    y: patch.value!.toneValue,
                    hex: patch.value!.hex,
                    id: patch.id,
                });

                tableContent.set(inkValue.value, patch.value!);
            });

        const originalTonalValuePoints = calculatedAverages.map(({ id, x, y }) => {
            const isFixedPoint = x === 0 || x === 100;
            return {
                id,
                x,
                y,
                isDraggable: isFixedPoint
                    ? false
                    : !editedTonalValues.find(entry => Number(entry.index) === x),
            };
        });

        const editedTonalValuePoints = editedTonalValues.map(tv => {
            return {
                id: `${tv.index}-${selectedColor}-editedPoint`,
                x: Number(tv.index),
                y: Number(tv.value),
                isDraggable: true,
            };
        });

        const sortedTvIndexValuesWithEdits = calculatedAverages.map(average => {
            const editedAverage = editedTonalValues.find(editedTone => Number(editedTone.index) === average.x);
            return {
                index: average.x,
                percentage: !!editedAverage
                    ? Number(editedAverage.value)
                    : average.y,
            };
        }).sort((a, b) => a.index - b.index);


        const tvIndexBoundaries = new Map<number, { min: number; max: number }>(
            sortedTvIndexValuesWithEdits.map((tonalValue, currentIndex) => {
                const previousValue = sortedTvIndexValuesWithEdits[currentIndex - 1]?.percentage ?? 0;
                const nextValue = sortedTvIndexValuesWithEdits[currentIndex + 1]?.percentage ?? 100;

                return [tonalValue.index, { min: previousValue, max: nextValue }];
            }));

        const tableContentWithMinMaxRange = new Map(
            Array.from(tableContent.entries(),
                ([index, values]) => ([
                    index,
                    { ...values, range: tvIndexBoundaries.get(index)! },
                ])),
        );

        return {
            indexValueSeries: calculatedAverages,
            indexValueSeriesWithEdits: sortedTvIndexValuesWithEdits,
            hex,
            gridData: {
                versionedMeasurementId: viewModel.versionedId,
                hex,
                lineDefinition: {
                    original: createLineDefinition(calculatedAverages),
                    edited: editedTonalValues.length === 0
                        ? undefined
                        : createLineDefinition(sortedTvIndexValuesWithEdits.map(tv => ({ x: tv.index, y: tv.percentage }))),
                },
                points: [...originalTonalValuePoints, ...editedTonalValuePoints].map(({ id, x, y, isDraggable }) => ({
                    id,
                    x: calculateSvgPos('x', x),
                    y: calculateSvgPos('y', y),
                    index: x,
                    value: y,
                    isDraggable,
                    range: tvIndexBoundaries.get(x)!,
                })),
            },
            tableData: {
                name: viewModel.name,
                versionLabel: viewModel.versionLabel,
                versionedMeasurementId: viewModel.versionedId,
                content: tableContentWithMinMaxRange,
            },
        };
    });

    return result;
};

export interface TonalValueRow {
    indexPercentage: string;
    values: Array<{
        id: string;
        l: string;
        a: string;
        b: string;
        c: string;
        h: string;
        tv: {
            value: string;
            hasChanged: boolean;
            range: { min: number; max: number };
            isReadOnly: boolean;
        };
    }>;
}

export function getFormattedAndSortedTonaValueTableRows(
    contents: Array<{ name: string; versionedMeasurementId: string; content: Map<number, ValueWithRange> }>,
    formatNumber: (num: number | null | undefined) => string | undefined,
    editedTonalValues: Array<{ index: string; value: string }>,
): TonalValueRow[] {

    const indexes = contents.map(item => Array.from(item.content.keys())).flat();
    const indexColumn = new Set(indexes);

    const sortedIndexColumn = Array.from(indexColumn).sort((a: number, b: number) => a - b);

    const result: Array<TonalValueRow> = sortedIndexColumn.map(index => {
        const indexPercentage = formatNumber(index)!;
        const editedTonalValue = editedTonalValues.find(entry => entry.index === indexPercentage);
        const isReadOnly = index === 0 || index === 100;

        return {
            indexPercentage,
            values: contents.map(measurement => {

                const values = measurement.content.get(index);
                const l = formatNumber(values?.lab[0]) ?? '-';
                const a = formatNumber(values?.lab[1]) ?? '-';
                const b = formatNumber(values?.lab[2]) ?? '-';
                const c = formatNumber(values?.lch[1]) ?? '-';
                const h = formatNumber(values?.lch[2]) ?? '-';
                const tv = {
                    value: editedTonalValue
                        ? editedTonalValue.value
                        : formatNumber(values?.toneValue) ?? '-',
                    hasChanged: !!editedTonalValue,
                    range: values?.range!,
                    isReadOnly,
                };

                return {
                    id: measurement.versionedMeasurementId, tv, l, a, b, c, h,
                };
            }),
        };
    });

    return result;
}