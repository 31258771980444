import { FunctionComponent, useContext, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AppContext, { MeasurementSettings } from 'src/AppContext';
import { PathItem } from './measurements/BreadCrumb';
import ExplorerContainer from './measurements/ExplorerContainer';
import { default as VisualizationContainer } from './visualization/VisualizationContainer';

interface InspectProps {
    onChangeMeasurementParams: (type: keyof MeasurementSettings, value: string) => void;
}

export interface NecessaryFileData {
    name: string;
    conditions: Array<string>;
    // note that the property is currently not updated for file version changes.
    // as of today, this is not a big problem as it's only used for highlighting diagrams
    version: number;
    folderId: string;
    isEditable: boolean;
}

type SelectAsOnlyAction = {
    type: 'selectAsOnly';
    id: string;
    necessaryFileData: NecessaryFileData;
}
type SelectAsFirstAction = {
    type: 'selectAsFirst';
    id: string;
    necessaryFileData: NecessaryFileData;
}
type SelectAction = {
    type: 'select';
    idsAndNecessaryFileData: Map<string, NecessaryFileData>;
}
type DeselectAction = {
    type: 'deselect';
    id: string;
}
type ResetAction = {
    type: 'reset';
}

export type FileAction = SelectAsOnlyAction | SelectAsFirstAction | SelectAction | DeselectAction | ResetAction

const Inspect: FunctionComponent<InspectProps> = props => {
    const { user } = useContext(AppContext);
    const [selectedFiles, setSelectedFiles] = useState<Map<string, NecessaryFileData>>(new Map());
    const [path, setPath] = useState<Array<PathItem>>([{ id: undefined, name: user!.orgName }]);

    const handleFileAction = (action: FileAction) => {
        switch (action.type) {
            case 'selectAsOnly':
                setSelectedFiles(new Map([[action.id, action.necessaryFileData]]));
                break;
            case 'selectAsFirst':
                setSelectedFiles(prev => new Map([
                    [action.id, action.necessaryFileData],
                    ...Array.from(prev.entries()).filter(iterable => iterable[0] !== action.id),
                ]));
                break;
            case 'select':
                setSelectedFiles(prev => new Map([
                    ...Array.from(prev.entries()).filter(iterable => !Array.from(action.idsAndNecessaryFileData.keys()).includes(iterable[0])),
                    ...Array.from(action.idsAndNecessaryFileData.entries()),
                ]));
                break;
            case 'deselect':
                setSelectedFiles(prev => new Map([
                    ...Array.from(prev.entries()).filter(iterable => iterable[0] !== action.id),
                ]));
                break;
            case 'reset':
                setSelectedFiles(new Map());
                break;
        }
    };

    return (
        <Routes>
            <Route path="/*"
                element={selectedFiles.size > 0
                    ? <VisualizationContainer
                        files={selectedFiles}
                        onFileAction={handleFileAction}
                        onChangeMeasurementParams={props.onChangeMeasurementParams!}
                        path={path}
                    />
                    : <Navigate to="/inspect" replace />}
            />
            <Route
                path="/"
                element={
                    <ExplorerContainer
                        path={path}
                        setPath={itemOrItems => {
                            if (Array.isArray(itemOrItems)) {
                                setPath(itemOrItems);
                            } else {
                                setPath(oldItems => [...oldItems, itemOrItems]);
                            }
                        }}
                        selectedFilesIds={Array.from(selectedFiles.keys())}
                        onFileAction={handleFileAction}
                    />}
            />
        </Routes>
    );
};

export default Inspect;
