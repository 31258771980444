import { Flex, IconArrowRightSmall, IconOrganisationOutlined } from '@gmg/gmg-react-components';
import { FC } from 'react';
import styled from 'styled-components';
import { BreadCrumbItem } from './BreadCrumbItem';
import DotMenu from './DotMenu';

export type PathItem = { id: string | undefined; name: string };
export interface BreadCrumbProps {
    path: Array<PathItem>;
    setPath: (path: Array<PathItem>) => void;
    isCompactView?: boolean;
};

const BreadCrumb: FC<BreadCrumbProps> = (props) => {

    const navigateToFolder = (id: string) => {
        const index = props.path.findIndex(folder => folder.id === id);

        if (index === props.path.length - 1) return;

        const newPath: Array<PathItem> = props.path.slice(0, index + 1);

        props.setPath(newPath);
    };

    const numberOfDisplayedItemsAfterCollapse = props.isCompactView ? 1 : 2;
    const isCollapsedMode = (!props.isCompactView && props.path.length > 3) || (props.isCompactView && props.path.length > 2);

    return (
        <Container aria-label="breadcrumb">
            <InteractiveDiv
                isActive={props.path.length === 1}
                aria-selected={props.path.length === 1}
                aria-label={props.path[0].name}
                onClick={() => {
                    props.setPath([props.path[0]]);
                }}
            >
                {
                    props.isCompactView
                        ? <HomeIcon isActive={props.path.length === 1}/>
                        : props.path[0].name
                }
            </InteractiveDiv>
            {isCollapsedMode
                ? (
                    <>
                        <ArrowBox className="px12_400_normal">
                            <IconArrowRightSmall />
                        </ArrowBox>
                        <DotMenu
                            items={props.path.slice(1, -numberOfDisplayedItemsAfterCollapse)}
                            onNavigateToFolder={navigateToFolder}
                        />
                        {props.path
                            .slice(-numberOfDisplayedItemsAfterCollapse)
                            .map((folder, index) =>
                                <BreadCrumbItem
                                    key={index}
                                    folder={folder}
                                    onNavigateToFolder={navigateToFolder}
                                    isLastElement={props.path.slice(-numberOfDisplayedItemsAfterCollapse).length === index + 1}
                                />,
                            )
                        }
                    </>
                ) : (
                    <>
                        {props.path.map((folder, index) =>
                            index > 0 && (
                                <BreadCrumbItem
                                    key={folder.id}
                                    folder={folder}
                                    onNavigateToFolder={navigateToFolder}
                                    isLastElement={props.path.length === index + 1}
                                />
                            ),
                        )}
                    </>
                )
            }
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    height: 18px;
    min-height: 18px;
    width: 100%;
    background-color: ${props => props.theme.colors.backgroundPaper};
    font-size: 12px;

    svg {
        width: 16px;
        height: 16px;
    }
`;

export const ArrowBox = styled(Flex)`
    align-items: center;
    color: ${props => props.theme.colors.contrastSemiHigh};
`;

export const InteractiveDiv = styled(Flex) <{ isActive: boolean }>`
    align-items: center;
    cursor: ${props => props.isActive ? 'default' : 'pointer'};
    transition: 0.3s ease-out;
    height: 16px;
    line-height: 16px;
    white-space: nowrap;
    
    color: ${props => props.isActive
        ? props.theme.colors.textContrast
        : props.theme.colors.textWeak}
    ;
    
    &:hover {
        color: ${props => props.theme.colors.textContrast};
    }
`;

const HomeIcon = styled(IconOrganisationOutlined) <{ isActive: boolean }>`
    color: ${props => props.isActive
        ? props.theme.colors.textContrast
        : props.theme.colors.textWeak};
`;

export default BreadCrumb;