import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import AppContext from 'src/AppContext';
import { Folder, MeasurementListItemViewModel } from '../ViewModels';
import { DeleteItemsInput, DeleteItemsPayload } from '../generated/types';

const mutation = `mutation DeleteItems($input: DeleteItemsInput!) {
    deleteItems(input: $input) {
      folderIds
      measurementIds
      succeeded
    }
  }`;

export const useDeleteItems = (workingFolderId: string | undefined) => {
    const context = useContext(AppContext);

    const queryClient = useQueryClient();

    return useMutation(
        async (input: DeleteItemsInput) => {
            await context.populateCacheForAppSynch?.();

            const response = await (context.api.graphql({ query: mutation, variables: { input } }) as Promise<{
                data: {
                    deleteItems: DeleteItemsPayload;
                };
            }>);

            if (response.data.deleteItems.succeeded === false) {
                throw new Error('Delete items failed');
            }

            return response.data.deleteItems;
        },
        {
            onError: async (_, variables) => {
                // in case of errors, reload the list to avoid confusion by having a clean state
                await queryClient.invalidateQueries(['folderContent', workingFolderId]);
            },
            onSuccess: (data, variables) => {
                // we are updating the data manually instead of reloading the list for performance reasons
                queryClient.setQueriesData(
                    ['folderContent', workingFolderId],
                    (prev: any) => ({
                        ...prev!,
                        measurements: prev.measurements.filter((m: MeasurementListItemViewModel) => !data.measurementIds.includes(m.id)),
                        subfolders: prev.subfolders.filter((f: Folder) => !data.folderIds.includes(f.id)),
                    }),
                );
            },
        });
};
