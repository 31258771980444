import { GmgStyleProvider } from '@gmg/gmg-react-components';
import { FunctionComponent } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetHistory } from 'src/graphql/customHooks/useGetHistory';
import { useGetMeasurementBasics } from 'src/graphql/customHooks/useGetMeasurementBasics';
import { useGetProcessParams } from 'src/graphql/customHooks/useGetProcessParams';
import { useMeasurement } from 'src/graphql/customHooks/useMeasurement';
import styled from 'styled-components';
import { Variant } from '../visualization/VisualizationContainer';
import CoverPage from './CoverPage';
import DotGainGraphicPage from './DotGainGraphic';
import DotGainTablePage from './DotGainTablePage';
import HistoryPage from './HistoryPage';
import InfoPage from './InfoPage';
import SpiderPage from './SpiderPage';
import TonalValuePage from './TonalValuePage';
import Header from './shared/Header';

const Report: FunctionComponent<{}> = () => {
    const searchParams = useSearchParams()[0];
    const referenceId = searchParams.get('referenceId')!;
    const compareId = searchParams.get('compareId');
    const measurementCondition = searchParams.get('condition')!;
    const reportName = searchParams.get('name')!;
    const listOfOmittedPages = searchParams.get('omit')?.split(',') ?? [];

    const requests = [referenceId, compareId]
        .filter(id => !!id)
        .map(id => ({
            id: id!,
            condition: measurementCondition,
            variant: 'diagram' as Variant,
            outdatedVersion: undefined,
            isEditable: false,
            folderId: 'n.a.',
        }));

    const [referenceResult, compareResult] = useMeasurement(requests, () => { });

    const referenceMeasurement = referenceResult.data;
    const compareMeasurement = compareResult?.data;

    const { data: referenceProcessParams } = useGetProcessParams(referenceId, !!referenceMeasurement?.processParamsDefined);
    const { data: referenceMeasurementInfo } = useGetMeasurementBasics(referenceId);
    const { data: referenceHistory } = useGetHistory(referenceId);
    const { data: compareProcessParams } = useGetProcessParams(compareId ?? '', !!compareMeasurement?.processParamsDefined);
    const { data: compareMeasurementInfo } = useGetMeasurementBasics(compareId ?? '');
    const { data: compareHistory } = useGetHistory(compareId ?? '');

    if (!referenceMeasurement || (compareId ? !compareMeasurement : false)) return null;

    const allAvailableColorNames = Array.from(
        new Set([
            ...Array.from(referenceMeasurement.inks.keys()),
            ...Array.from(compareMeasurement?.inks.keys() ?? []),
        ]),
    );
    const header = <Header reportName={reportName} />;
    const comparisonHeader = <Header reportName={reportName} type='comparison' />;
    const referenceHeader = <Header reportName={reportName} type={compareId ? 'reference' : undefined} />;

    return (
        <GmgStyleProvider theme='light'>
            <Container>
                <CoverPage reportName={reportName} />
                <InfoPage
                    header={referenceHeader}
                    measurement={referenceMeasurement}
                    condition={measurementCondition}
                    processParams={referenceProcessParams}
                    measurementInfo={referenceMeasurementInfo}
                />
                {!!compareMeasurement &&
                    <InfoPage
                        header={comparisonHeader}
                        measurement={compareMeasurement}
                        condition={measurementCondition}
                        processParams={compareProcessParams}
                        measurementInfo={compareMeasurementInfo}
                    />
                }
                {!listOfOmittedPages?.includes('spider') &&
                    <SpiderPage
                        header={header}
                        referenceMeasurement={referenceMeasurement}
                        compareMeasurement={compareMeasurement}
                    />
                }
                {!listOfOmittedPages?.includes('tonalValue') &&
                    allAvailableColorNames.map(color => (
                        <TonalValuePage
                            key={color}
                            header={header}
                            referenceMeasurement={referenceMeasurement}
                            compareMeasurement={compareMeasurement}
                            color={color}
                        />
                    ))
                }
                {!listOfOmittedPages?.includes('dotGain') &&
                    <>
                        <DotGainGraphicPage
                            header={header}
                            referenceMeasurement={referenceMeasurement}
                            compareMeasurement={compareMeasurement}
                            allAvailableColorNames={allAvailableColorNames}
                        />
                        <DotGainTablePage header={referenceHeader} measurement={referenceMeasurement} />
                        {!!compareMeasurement &&
                            <>
                                <DotGainTablePage header={comparisonHeader} measurement={compareMeasurement} />
                            </>
                        }
                    </>
                }
                {!listOfOmittedPages?.includes('fileHistory') &&
                    <>
                        <HistoryPage header={referenceHeader} measurementHistory={referenceHistory} />
                        {!!compareMeasurement &&
                            <HistoryPage header={comparisonHeader} measurementHistory={compareHistory} />
                        }
                    </>
                }
            </Container>
        </GmgStyleProvider>
    );
};


const Container = styled.div`
   background-color: white;
   display: flex;
   flex-direction: column;
   align-items: center;
   width: max-content;
   margin: 0 auto;
   box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.3);
   
   @media print{
        border: none;
        box-shadow: none;
    }
`;


export default Report;