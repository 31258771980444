import { MutableRefObject, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from 'src/AppContext';
import { useOutsideClick } from 'src/shared/useOutsideClick';

export const useUnSavedChangesmodal = (
    content: JSX.Element,
    applyClickHandler: () => void,
    diagramContainerRef: MutableRefObject<any>,
    disabled: boolean,
) => {
    const context = useContext(AppContext);
    const { t } = useTranslation();
    const [isUnsavedChangesModalDisplayed, setIsUnsavedChangesModalDisplayed] = useState(false);

    const handleClickOutsideDiagram = (event: any) => {
        event.stopPropagation();
        setIsUnsavedChangesModalDisplayed(true);
        context.onShowModal('infoModal', {
            title: t('UnsavedChangesModal.title', 'Unsaved changes'),
            content: content,
            onOkClick: () => {
                setIsUnsavedChangesModalDisplayed(false);
                void applyClickHandler();
            },
            btnOkCaption: t('UnsavedChangesModal.apply', 'Apply corrections'),
            onCancelClick: () => {
                setIsUnsavedChangesModalDisplayed(false);
            },
        });
    };
    useOutsideClick(handleClickOutsideDiagram, disabled || isUnsavedChangesModalDisplayed, diagramContainerRef);
};