import { ProcessParamViewModel } from '../ViewModels';
import { SetProcessParametersInput, ProcessParamsScreenType } from '../generated/types';

export const mapToSetProcessParamsInput = (
    source: ProcessParamViewModel, isBasedOnPrediction: boolean): Omit<SetProcessParametersInput, 'measurementId'> => {

    const screenTypes = source.inks.map<ProcessParamsScreenType>((ink, currentIndex) => ({
        inkName: ink.inkName,
        newName: ink.newName,
        screenTypeKey: ink.screeningType!.value,
        rulingValue: parseInt(ink.screeningRulingValue),
        configurationKey: ink.screeningConfiguration?.value !== undefined
            ? parseInt(ink.screeningConfiguration.value)
            : undefined,
        sequenceIndex: currentIndex,
    }));

    return {
        process: source.printingProcess!.value,
        printFinishing: source.printFinishing!.value,
        substrateCategory: {
            substrateCategoryKey: source.substrateCategory!.value,
            substrateType: {
                substrateTypeKey: source.substrateType!.value,
                filmMaterial: source.filmMaterial?.value,
            },
        },
        reverse: !!source.printDirection?.value,
        screenTypes,
        isBasedOnPrediction,
    };
};