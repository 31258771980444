import styled from 'styled-components';

export const FlyOutContent = styled.div`
  padding: 8px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  line-height: 1.4;
  `;
export const ActionBtnContainer = styled.div`
  display: flex;
  justify-content: end;
  padding: 10px 12px;
  margin: 0 4px;
  border-top: 0.5px solid ${(props) => props.theme.colors.greyContrastSemiLower};
  `;
export const InteractiveContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;