import styled, { useTheme } from 'styled-components';
import { GmgTheme } from '@gmg/gmg-react-components';
import { ReactComponent as EmptyFolderDark } from '../assets/graphic_folder-dark-255.svg';
import { ReactComponent as EmptyFolderLight } from '../assets/graphic_folder-light-255.svg';
import { useTranslation } from 'react-i18next';

export interface EmptyFolderPageProps {
    subtext?: string;
};

const EmptyFolderPage: React.FunctionComponent<EmptyFolderPageProps> = (props) => {
    const { t } = useTranslation();
    const theme: GmgTheme = useTheme();

    return (
        <Wrapper>
            <DIV>
                {theme.type === 'dark'
                    ? <EmptyFolderDark width={255} height={255} />
                    : <EmptyFolderLight width={255} height={255} />
                }
                <p className='px14_600_contrast'>
                    {t('EmptyFolderPage.page_title', 'This folder is empty. ')}
                </p>
                {props.subtext &&
                    <p className='px12_400_normal'>
                        {props.subtext}
                    </p>
                }
            </DIV>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: ${props => props.theme.colors.backgroundPaper};
    position: relative;
`;

const DIV = styled.div`
    position: absolute;
    top: calc(50% - 32px); /* Appframe top = 32px */
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    text-align: center;
      
    p {
        margin: 0;
        letter-spacing: 0.5px;
    }
`;

export default EmptyFolderPage;