import {
    Active,
    Collision,
    DndContextProps,
    DndContext as OriginalDndContext,
    Over,
    Translate,
    UseDraggableArguments,
    useDraggable as useOriginalDraggable,
} from '@dnd-kit/core';

// The idea of having typeSafe draggable data in Event and in ReturnValue of DnDContext came from this article:
// https://github.com/clauderic/dnd-kit/issues/935

export interface DraggableData {
    index: number;
    currentValue: number;
    range: { min: number; max: number };
    inkName?: string;
}
interface TypesafeActive extends Omit<Active, 'data'> {
    data: React.MutableRefObject<DraggableData>;
}

interface DragEvent {
    activatorEvent: Event;
    active: TypesafeActive;
    collisions: Collision[] | null;
    delta: Translate;
    over: Over | null;
}

interface DragMoveEvent extends DragEvent { }
interface DragEndEvent extends DragEvent { }

interface DndContextTypesafeProps
    extends Omit<DndContextProps, 'onDragMove' | 'onDragEnd'> {
    onDragMove?(event: DragMoveEvent): void;
    onDragEnd?(event: DragEndEvent): void;

}
interface UseDraggableTypesafeArguments extends Omit<UseDraggableArguments, 'data'> {
    data: DraggableData;
}
type UseDraggableTypesafeReturnValue = Omit<ReturnType<typeof useOriginalDraggable>, 'active'> & {
    active: TypesafeActive;
};

export function draggablePoint() {

    function useDraggable(props: UseDraggableTypesafeArguments) {
        return useOriginalDraggable(props) as UseDraggableTypesafeReturnValue;
    }

    function DndContext(props: DndContextTypesafeProps) {
        return <OriginalDndContext {...props} />;
    }

    return { DndContext, useDraggable };
};