import { Spinner, Flex } from '@gmg/gmg-react-components';
import { FC } from 'react';
export interface WrappedSpinnerProps {
    label: string;
    labelPosition: 'bottom' | 'right' | 'left' | undefined;
    svgSize?: string;
};

const WrappedSpinner: FC<WrappedSpinnerProps> = (props) => {

    return (
        <Flex
            justifyContent={'center'}
            alignItems={'center'}
            height={'100%'}
            className="px12_400_weak"
        >
            <Spinner
                label={props.label}
                labelPosition={props.labelPosition}
                svgSize={props.svgSize}
            />
        </Flex>
    );
};

export default WrappedSpinner;