import { Flex } from '@gmg/gmg-react-components';
import { FunctionComponent } from 'react';
import { formatDate } from 'src/shared/format';
import styled from 'styled-components';
import { ReactComponent as GMGLogo } from '../../assets/GMG-Logo-sRGB.svg';
import { ReactComponent as ReportGraphic } from '../../assets/graphic_report.svg';
import { Divider, Page } from './shared/SharedComponents';
import { useTranslation } from 'react-i18next';

export interface CoverPageProps {
    reportName: string;
};

const CoverPage: FunctionComponent<CoverPageProps> = (props) => {
    const { t } = useTranslation();

    return (
        <>
            <CoverPageContainer>
                <StyledReportGraphic />
                <Flex flexDirection="column" gap="12px" alignItems="center">
                    <Headline>{t('Report.report_headline_title', 'Print Quality Report')}</Headline>
                    <h1>{props.reportName}</h1>
                    <DateWrapper>{formatDate(new Date())}</DateWrapper>
                </Flex>
                <Footer>
                    <GMGLogo />
                </Footer>
            </CoverPageContainer>
            <Divider />
        </>
    );
};

const CoverPageContainer = styled(Page)`
    margin: 0;
    flex-direction: column;
    padding-top: 112px;
    align-items: center;

    h1 {
        font-size: 28px;
        color: ${props => props.theme.colors.greyContrastHighest};
        text-align: center;
    }
`;
const Headline = styled.span`
    font-size: 20px;
    font-weight: 600;
    color: ${props => props.theme.colors.greyContrastMediumHigher};
`;
const DateWrapper = styled.span`
    font-size: 20px;
    font-weight: 400;
    color: ${props => props.theme.colors.greyContrastMediumHigher};
`;
const Footer = styled(Flex)`
    width: max-content;
    gap: 8px;
    margin-top: auto;
    align-items: center;

    svg {
        width: 72px;
        padding-left: 16px;
    }
`;
const StyledReportGraphic = styled(ReportGraphic)`
    width: 120px;
    height: 140px;
    margin-bottom: 132px;
`;

export default CoverPage;