import { Flex } from '@gmg/gmg-react-components';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

export interface InfoProps {
    title: string;
    content: string | undefined;
};

const InfoBlock: FunctionComponent<InfoProps> = ({ title, content }) => (
    <Info>
        <span>{title}</span>
        <p>{content ?? '-'}</p>
    </Info>
);

const Info = styled(Flex)`
    flex-direction: column;
    gap: 8px;
    margin-bottom: 20px;
   
    span {
        font-size: 10px;
        color: ${props => props.theme.colors.greyContrastMediumHigher};
    }
    p {
        margin: 0;
        font-size: 12px;
        color: ${props => props.theme.colors.greyContrastHighest};
    }
`;

export default InfoBlock;