import { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

export interface PageSectionProps {
    title: string;
    mt?: number;
    children: ReactNode;
};

const Section: FunctionComponent<PageSectionProps> = props => {

    return (
        <Container marginTop={props.mt ?? 0}>
            <Wrapper>{props.title}</Wrapper>
            <Divider />
            <Wrapper>
                {props.children}
            </Wrapper>
        </Container>
    );
};
const Wrapper = styled.div`
    padding: 0 16px;
`;

const Container = styled.div<{marginTop: number}>`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: ${props => props.marginTop}px;
`;
const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.greyContrastLow};
`;


export default Section;