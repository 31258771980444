import { Fragment, FunctionComponent } from 'react';
import { Patch } from 'src/graphql/ViewModels';
import { getHighlightedColor } from 'src/shared/getHighlightedColor';
import styled, { useTheme } from 'styled-components';
import circleGradient from '../../../assets/circle-gradient.png';
import { Point } from '../Point';

export interface Connector {
    calculatedAveragePatches: Array<Patch>;
    measuredPatches: Array<Patch>;
}

export interface GridProps {
    isDuplicatesVisible: boolean;
    isGradientCircleVisible: boolean;
    highlightedMeasurement?: string | undefined;
    data: Array<{
        versionedMeasurementId: string;
        mediaPoint: Point | undefined;
        legs: Array<{
            ink: string;
            hex: string;
            calculatedAveragePatches: Array<Patch>;
            measuredPatches: Array<Patch>;
        }>;
        connectors: Array<Connector>;
    }>;
    scalingFactor: number;
}

const SpiderGrid: FunctionComponent<GridProps> = props => {
    const theme = useTheme();
    const axisItems = [120, 100, 80, 60, 40, 20, 0, -20, -40, -60, -80, -100, -120];

    const diagramsize = 400 * props.scalingFactor;
    const numberOfCells = 26;
    const cellSize = diagramsize / numberOfCells;
    const cellRange = 10; // each cell represents 10 units


    const calculateSvgPos = (axis: 'x' | 'y', value: number) => {
        const factor = axis === 'x' ? 1 : -1;
        return Math.round(value * diagramsize / (factor * (numberOfCells * cellRange)) + diagramsize / 2);
    };

    const gridData: Array<{
        versionedMeasurementId: string;
        mediaPoint: Point | undefined;
        legs: Array<{
            ink: string;
            hex: string;
            lineDefinition: string;
            points: Array<Point>;
        }>;
        connectors: Array<{
            calculatedAveragePoints: Array<Point>;
            measuredPoints: Array<Point>;
        }>;
    }> = props.data.map(m => ({
        versionedMeasurementId: m.versionedMeasurementId,
        mediaPoint: m.mediaPoint
            ? {
                ...m.mediaPoint,
                x: calculateSvgPos('x', m.mediaPoint.x),
                y: calculateSvgPos('y', m.mediaPoint.y),
            }
            : undefined,
        legs: m.legs.map(l => {
            const points: Array<{ x: number; y: number }> = [];
            if (m.mediaPoint) {
                points.push({
                    x: calculateSvgPos('x', m.mediaPoint.x),
                    y: calculateSvgPos('y', m.mediaPoint.y),
                });
            }
            l.calculatedAveragePatches.forEach(p => {
                points.push({
                    x: calculateSvgPos('x', p.value.lab[1]),
                    y: calculateSvgPos('y', p.value.lab[2]),
                });
            });

            return {
                ink: l.ink,
                hex: props.highlightedMeasurement === m.versionedMeasurementId
                    ? getHighlightedColor(l.hex, theme)
                    : l.hex,
                lineDefinition: points.reduce((prev, { x, y }, i) => `${prev} ${i === 0 ? 'M' : ''}${i === 1 ? 'L' : ''} ${x} ${y}`, ''),
                points: l.measuredPatches.map(p => ({
                    id: p.id,
                    hex: l.hex,
                    x: calculateSvgPos('x', p.value.lab[1]),
                    y: calculateSvgPos('y', p.value.lab[2]),
                })),
            };
        }),
        connectors: m.connectors.map(connector => {
            // avoid unimportant lightening of every segment of the connector line, instead do it just once
            const lightenedHex = getHighlightedColor(connector.calculatedAveragePatches[0].value.hex, theme);
            return {
                calculatedAveragePoints: connector.calculatedAveragePatches.map(p => ({
                    id: p.id,
                    x: calculateSvgPos('x', p.value.lab[1]),
                    y: calculateSvgPos('y', p.value.lab[2]),
                    hex: props.highlightedMeasurement === m.versionedMeasurementId ? lightenedHex : p.value.hex,
                })),
                measuredPoints: connector.measuredPatches.map(p => ({
                    id: p.id,
                    x: calculateSvgPos('x', p.value.lab[1]),
                    y: calculateSvgPos('y', p.value.lab[2]),
                    hex: p.value.hex,
                })),
            };
        }),
    }));

    return (
        <StyledGrid numberOfCells={numberOfCells} cellSize={cellSize}>
            {[...Array(numberOfCells * numberOfCells)].map((_, index) => <div key={`c${index}`} />)}
            {
                axisItems.map((item, index) => (
                    <YAxisItem key={`y${index}`} className="px10_400_weak" index={index} size={diagramsize} cellSize={cellSize}>
                        {item}
                    </YAxisItem>
                ))
            }
            {
                axisItems.reverse().map((item, index) => (
                    <XAxisItem key={`x${index}`} className="px10_400_weak" index={index} length={item.toString().length} cellSize={cellSize}>
                        {item}
                    </XAxisItem>
                ))
            }

            <XAxisTop className="px10_400_weak">b*</XAxisTop>
            <YAxisRight className="px10_400_weak">a*</YAxisRight>

            <Svg version="1.1" xmlns="http://www.w3.org/2000/svg">
                <line className="axis" x1="50%" y1="0" x2="50%" y2="100%" />
                <line className="axis" x1="0" y1="50%" x2="100%" y2="50%" />
                {gridData.map((m, i) => (
                    <Fragment key={m.versionedMeasurementId + i}>
                        {m.connectors.map(connector => connector.calculatedAveragePoints
                            .slice(0, -1) // do not draw line for the last point
                            .map((startPoint, index) => {
                                const endPoint = connector.calculatedAveragePoints[index + 1];
                                return (
                                    <line
                                        key={startPoint.id}
                                        x1={startPoint.x}
                                        y1={startPoint.y}
                                        x2={endPoint.x}
                                        y2={endPoint.y}
                                        stroke={startPoint.hex}
                                        strokeWidth={(props.highlightedMeasurement === m.versionedMeasurementId ? 1 : 0.6) * props.scalingFactor}
                                    />);
                            }),
                        )}
                        {m.connectors.map(connector => connector.measuredPoints
                            .map(point => (
                                <circle
                                    key={point.id}
                                    cx={point.x}
                                    cy={point.y}
                                    fill={point.hex}
                                    r={1.5 * props.scalingFactor}
                                />)),
                        )}

                        {m.legs.map(leg => (
                            <Fragment key={leg.ink}>
                                <path
                                    stroke={leg.hex}
                                    strokeWidth={(props.highlightedMeasurement === m.versionedMeasurementId ? 1.4 : 1) * props.scalingFactor}
                                    fill="none"
                                    d={leg.lineDefinition}
                                />
                                {leg.points.map(p => (
                                    <circle
                                        key={p.id}
                                        cx={p.x}
                                        cy={p.y}
                                        r={2 * props.scalingFactor}
                                        fill={p.hex}
                                    />
                                ))}

                            </Fragment>
                        ))}
                        {m.mediaPoint && (
                            <circle
                                cx={m.mediaPoint.x}
                                cy={m.mediaPoint.y}
                                r={5 * props.scalingFactor}
                                fill={m.mediaPoint.hex}
                            />
                        )}
                    </Fragment>))}
            </Svg>
            {props.isGradientCircleVisible && <img src={circleGradient} alt="circleGradient" />}
        </StyledGrid>
    );
};

const Svg = styled.svg`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;

    line.axis {
        stroke: ${props => props.theme.colors.greyContrastMedium};
        stroke-width: 1;
    }
    
    overflow: visible;
`;

const YAxisItem = styled.span.attrs<{ index: number; size: number; cellSize: number }>(props => ({
    style: {
        top: `${(props.index + 0.5) * (props.cellSize * 2) - 6}px`,
    },
})) <{ index: number; size: number; cellSize: number }>`
    right: ${props => props.size + 6}px;
`;

const XAxisItem = styled.span.attrs<{ index: number; length: number; cellSize: number }>(props => ({
    style: {
        left: `${((props.index + 0.5) * (props.cellSize * 2)) - props.length * 3}px`,
    },
})) <{ index: number; length: number; cellSize: number }>`
    bottom: -22px;
`;

const XAxisTop = styled.span`
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
`;

const YAxisRight = styled.span`
    top: 50%;
    transform: translateY(-50%);
    right: -20px;
`;

const StyledGrid = styled.div<{ cellSize: number; numberOfCells: number }>`
    display: grid;
    position: relative;
    height: fit-content;
    grid-template-columns: repeat(${props => props.numberOfCells}, ${props => props.cellSize}px);
    border-right: solid 1px ${props => props.theme.colors.greyContrastSemiLower};
    border-bottom: solid 1px ${props => props.theme.colors.greyContrastSemiLower};
    margin: 14px 48px 28px 28px;
    cursor: default;

    div {
        height: ${props => props.cellSize - 1}px;
        border-top: solid 1px ${props => props.theme.colors.greyContrastSemiLower};
        border-left: solid 1px ${props => props.theme.colors.greyContrastSemiLower};
    }

    img {
        position: absolute;
        width: 96%;
        height: 96%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    span {
        position: absolute;
    }`;

export default SpiderGrid;