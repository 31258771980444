import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import AppContext from 'src/AppContext';
import { ProcessParamViewModel } from '../ViewModels';
import { SetProcessParametersInput, SetProcessParametersPayload, TableViewInput } from '../generated/types';
import { mapToSetProcessParamsInput } from '../mapper/mapToSetProcessParamsInput';

const mutation = `mutation SetProcessParameters($input: SetProcessParametersInput!) {
    setProcessParameters(input: $input) {
      saved
  }
}`;

export const useSetProcessParameters = (measurementId: string, folderId: string) => {
    const context = useContext(AppContext);
    const queryClient = useQueryClient();

    return useMutation(async (params: ProcessParamViewModel & { isBasedOnPrediction: boolean }) => {

        await context.populateCacheForAppSynch?.();
        const input: SetProcessParametersInput = {
            measurementId,
            ...mapToSetProcessParamsInput(params, params.isBasedOnPrediction),
        };

        const response = await (context.api.graphql({ query: mutation, variables: { input } }) as Promise<{
            data: {
                setProcessParameters: SetProcessParametersPayload;
            };
        }>);

        return response.data.setProcessParameters.saved;
    }, {
        onSettled: async () => {
            await queryClient.invalidateQueries({
                predicate: q =>
                    (q.queryKey[0] === 'folderContent' && q.queryKey[1] === folderId) || // Switch "Missing" to "Defined" in Explorer
                    (q.queryKey[0] === 'getProcessParams' && q.queryKey[1] === measurementId) ||
                    (q.queryKey[0] === 'getMeasurementHistory' && q.queryKey[1] === measurementId) ||
                    (q.queryKey[0] === 'getMeasurementTableView' && (q.queryKey[1] as TableViewInput).id === measurementId),
                type: 'all', // also inactive
            });
        },
    });
};