import { DeltaE } from '@gmg/gmg-colorsmath';
import { Flex, GmgTheme } from '@gmg/gmg-react-components';
import { FunctionComponent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from 'src/AppContext';
import { MeasurementViewModel } from 'src/graphql/ViewModels';
import useFormatter from 'src/shared/useFormatter';
import { useTheme } from 'styled-components';
import Legend from '../visualization/spider/Legend';
import SpiderGrid from '../visualization/spider/SpiderGrid';
import { calculateDataForSpiderGrid, getColorsByCharacteristics, getSortedRowsForSpiderTable } from '../visualization/spider/calculationsForSpider';
import Footer from './shared/Footer';
import InfoBlock from './shared/InfoBlock';
import Section from './shared/Section';
import { Divider, Page } from './shared/SharedComponents';

export interface SpiderPageProps {
    referenceMeasurement: MeasurementViewModel;
    compareMeasurement: MeasurementViewModel | undefined;
    header: JSX.Element;
};

const SpiderPage: FunctionComponent<SpiderPageProps> = (props) => {
    const theme: GmgTheme = useTheme();
    const { formatNumber } = useFormatter();
    const { t } = useTranslation();
    const { measurementSettings } = useContext(AppContext);

    const measurements = [props.referenceMeasurement];
    if (props.compareMeasurement) {
        measurements.push(props.compareMeasurement);
    }

    const colorItems = getColorsByCharacteristics(measurements)
        .map(characteristic => ({
            isOverprint: characteristic.type === 'symmetricOverprint',
            name: characteristic.colorName,
            sortIndex: characteristic.sortIndex,
            isSelected: true,
        }));

    const spiderGridData = calculateDataForSpiderGrid(measurements, colorItems, true, true, theme);
    const spiderTableData = getSortedRowsForSpiderTable({
        measurements: measurements,
        editedInksState: [],
        deltaCalculator: measurementSettings.deltaE === 'DE76' ? DeltaE.deltaE76 : DeltaE.deltaE00,
        formatNumber,
        theme,
    });

    return (
        <>
            <Page flexDirection="column">
                {props.header}
                <Section title="Spider">
                    <Flex mt="8px">
                        <SpiderGrid
                            data={spiderGridData}
                            isDuplicatesVisible={true}
                            isGradientCircleVisible={true}
                            scalingFactor={1.1} // spiderGrid 400 * 1.1 = 440px
                        />
                        <Flex flexDirection="column" mt="12px">
                            <InfoBlock title={t('Report.spider_densityFilter_cymk_title', 'Density filter for CMYK')}
                                content={t(
                                    `Settings.filterCMYK_menu.${measurementSettings.filterCMYK}`,
                                    measurementSettings.filterCMYK,
                                )}
                            />
                            <InfoBlock title={t('Report.spider_densityFilter_spot_title', 'Density filter for spot colors')}
                                content={'OpenColor Filter'}
                            />
                            <InfoBlock
                                title={t('Settings.observer_lil', 'Observer')}
                                content={t(
                                    `Settings.observer_menu.${measurementSettings.observer}`,
                                    `${measurementSettings.observer}°`,
                                )}
                            />
                            <InfoBlock
                                title={t('Settings.illuminant_lil', 'Illuminant')}
                                content={t(
                                    `Settings.illuminant_menu.${measurementSettings.illuminant}`,
                                    measurementSettings.illuminant,
                                )}
                            />
                        </Flex>
                    </Flex>
                    <Flex mt="16px" width="430px">
                        <Legend
                            measurements={measurements.map(({ versionedId, name }) => ({ versionedId, name }))}
                            data={spiderTableData}
                            offset={0}
                            showDeltas={measurements.length === 2}
                            onHoverMeasurement={() => { }}
                            onChangeInkValues={() => { }}
                            activeColorCorrection={undefined}
                            hasReference={measurements.length === 2}
                        />
                    </Flex>
                </Section>
                <Footer />
            </Page>
            <Divider />
        </>
    );
};


export default SpiderPage;