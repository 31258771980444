import { Flex, IconArrowRightSmall as RightArrow } from '@gmg/gmg-react-components';
import { FC } from 'react';
import styled from 'styled-components';
import { ArrowBox, InteractiveDiv, PathItem } from './BreadCrumb';

interface BreadCrumbItemProps {
    folder: PathItem;
    onNavigateToFolder: (id: string) => void;
    isLastElement: boolean;
}

export const BreadCrumbItem: FC<BreadCrumbItemProps> = props => (
    <Flex
        key={props.folder.id}
        alignItems="center"
        minWidth="min-content"
        gap="10px"
    >
        <ArrowBox>
            <RightArrow aria-label="Arrow Icon" />
        </ArrowBox>
        <InteractiveDiv
            isActive={props.isLastElement}
            onClick={() => props.onNavigateToFolder(props.folder.id!)}
        >
            <Div aria-selected={props.isLastElement}>
                {props.folder.name}
            </Div>
        </InteractiveDiv>
    </Flex>
);

const Div = styled.div`
    max-width: 200px;
    min-width: min-content;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1.4;
`;