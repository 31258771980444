import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query'; // useQueryClient
import AppContext from 'src/AppContext';
import { GqlError } from '../GqlError';
import { GetMeasurementHistoryInput, GetMeasurementHistoryPayload } from '../generated/types';
import { MeasurementVersionViewModel } from '../ViewModels';
import { mapToVersionViewModel } from '../mapper/mapToVersionViewModel';

const query = `query GetMeasurementHistory($input: GetMeasurementHistoryInput!) {
    getMeasurementHistory(input: $input) {
      versions {
        id
        versionInformation {
          version
          timestamp
          reason
          author {
            firstName
            lastName
            email
          }
          params {
            key
            value
          }
        }
      }
    }
}`;

export const useGetHistory = (id: string) => {
    const context = useContext(AppContext);

    return useQuery<Array<MeasurementVersionViewModel>, Error>({
        queryKey: ['getMeasurementHistory', id],

        queryFn: async () => {
            await context.populateCacheForAppSynch?.();

            const input: GetMeasurementHistoryInput = {
                id,
            };

            const response = await (context.api.graphql({ query, variables: { input } }) as Promise<{
                data: {
                    getMeasurementHistory: GetMeasurementHistoryPayload;
                };
                errors?: Array<GqlError>;
            }>);

            return mapToVersionViewModel(response.data.getMeasurementHistory.versions);
        },
        enabled: !!id,
    });
};