import { Box, Button, IconFolderFilled, IconPlus } from '@gmg/gmg-react-components';
import { FunctionComponent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from 'src/AppContext';
import styled from 'styled-components';
import UploadMeasurementFile from '../visualization/UploadMeasurementFile';
import CreateOrRenameFolderModalContainer from './CreateOrRenameFolderModalContainer';

export interface ButtonsContainerProps {
    folderId: string | undefined;
    isFolderEditable: boolean;
    isNoFileSelected: boolean;
    folderNames: Array<string>;
    measurements?: Array<{
        id: string;
        version: number;
    }>;
}

const ButtonsContainer: FunctionComponent<ButtonsContainerProps> = props => {
    const { t } = useTranslation();
    const context = useContext(AppContext);

    const handleAddFolder = (ev: any) => {
        ev.stopPropagation();
        context.onShowModal('inputModal', {
            title: t('ButtonsContainer.add_folder_modal_title', 'Create folder'),
            titleIcon: <IconFolderFilled />,
            content: (
                <CreateOrRenameFolderModalContainer
                    parentFolderId={props.folderId!} /* root folders cannot be created, i.e. we always have a parent folder id */
                    siblingFolderNames={props.folderNames}
                />),
        });
    };

    return (
        <Wrapper>
            <Button
                variant="secondary"
                icon={<IconPlus />}
                disabled={!props.isFolderEditable}
                onClick={handleAddFolder}
            >
                {t('ButtonsContainer.create_folder_btn', 'Create folder')}
            </Button>
            <UploadMeasurementFile
                isUploadAllowed={props.isFolderEditable}
                folderId={props.folderId!}
            />
            <Box flexGrow={1} />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 44px;
    width: 100%;
    gap: 10px;
    padding: 10px 20px;
    background-color: ${(props) => props.theme.colors.backgroundPaper};
`;

export default ButtonsContainer;
