import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import AppContext from 'src/AppContext';
import { CopyMeasurementInput, MeasurementMeta } from '../generated/types';

const mutation = `mutation CopyMeasurement($input: CopyMeasurementInput!) {
    copyMeasurement(input: $input) {
      folderId
    }
  }`;

export const useCopyFile = (measurementId: string | undefined) => {
    const context = useContext(AppContext);
    const queryClient = useQueryClient();

    return useMutation(
        async (args: { fileName: string; targetFolderId: string }) => {

            const input: CopyMeasurementInput = {
                id: measurementId!,
                targetFolderId: args.targetFolderId,
                measurementName: args.fileName,
            };

            await context.populateCacheForAppSynch?.();

            const response = await (context.api.graphql({ query: mutation, variables: { input } }) as Promise<{
                data: {
                    copyMeasurement: MeasurementMeta;
                };
            }>);

            return Boolean(response.data.copyMeasurement.folderId);
        },
        {
            onSettled: async (_, __, args) => {
                await queryClient.invalidateQueries(['folderContent', args.targetFolderId]);
            },
        });

};
