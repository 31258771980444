import { toast } from '@gmg/gmg-react-components';
import { FunctionComponent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from 'src/AppContext';
import { getRenameFileEvent } from 'src/tracking';
import RenameFileModal from './RenameFileModal';
import { useRenameMeasurement } from 'src/graphql/customHooks/useRenameMeasurement';

export interface RenameFileModalContainerProps {
    onClose?: () => void;
    allOtherMeasurementNamesInFolder: Array<string>;
    measurementId: string;
    measurementName: string;
    measurementVersion: number;
    folderId: string;
};

const RenameFileModalContainer: FunctionComponent<RenameFileModalContainerProps> = props => {
    const { trackEvent } = useContext(AppContext);
    const { t } = useTranslation();

    const renameFile = useRenameMeasurement(props.folderId);

    const handleSettled = (response: boolean | undefined) => {
        props.onClose?.();

        if (response) {
            toast.success(t('RenameFileModalContainer.toast_success_desc', 'You changed the file successfully!'));
        } else {
            toast.error(t('RenameFileModalContainer.toast_error_desc', 'Oh no, an unexpected error occurred. Please try again.'));
        }
    };

    const onSubmit = async (name: string) => {
        trackEvent(getRenameFileEvent());

        renameFile.mutate({
            id: props.measurementId,
            name: name,
            version: props.measurementVersion,
        },
        {
            onSettled: handleSettled,
        },
        );
    };

    return (
        <RenameFileModal
            onSubmit={onSubmit}
            onClose={props.onClose!}
            isLoading={renameFile.isLoading}
            allOtherFileNamesInFolder={props.allOtherMeasurementNamesInFolder}
            currentMeasurementName={props.measurementName}
        />
    );
};

export default RenameFileModalContainer;