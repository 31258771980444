import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import AppContext from 'src/AppContext';
import { GetMeasurementUploadUrlsPayload, UploadUrl } from '../generated/types';

const query = `query GetMeasurementUploadUrl($input: GetUploadUrlsInput!) {
    getMeasurementUploadUrls(input: $input) {
        uploadUrls {
            fileName
            url
        }
    }
}`;

export const useFileUploadUrl = (fileNames: Array<string> | undefined, folderId: string) => {
    const context = useContext(AppContext);

    return useQuery<Array<UploadUrl>, Error>({
        queryKey: ['urls', folderId],
        queryFn: async () => {
            await context.populateCacheForAppSynch?.();

            const input = {
                fileNames,
                folderId,
            };

            const response = await (context.api.graphql({ query, variables: { input } }) as Promise<{
                data: {
                    getMeasurementUploadUrls: GetMeasurementUploadUrlsPayload;
                };
            }>);

            return response.data.getMeasurementUploadUrls.uploadUrls;
        },
        enabled: !!fileNames,
        staleTime: 0,
        refetchOnWindowFocus: false,
    });
};