import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import AppContext from 'src/AppContext';
import { GetMeasurementBasicsPayload } from '../generated/types';
import { MeasurementMetadata } from '../listMeasurementsMetaData';


const query = `query GetMeasurementBasics($measurementId: ID!) {
    getMeasurementBasics(measurementId: $measurementId) {
      metadata
      name
      id
    }
  }`;

export const useGetMeasurementBasics = (measurementId: string) => {
    const context = useContext(AppContext);

    return useQuery({
        queryKey: ['getMeasurementBasics', measurementId],
        queryFn: async () => {
            await context.populateCacheForAppSynch?.();

            const response = await (context.api.graphql({ query, variables: { measurementId } }) as Promise<{
                data: {
                    getMeasurementBasics: GetMeasurementBasicsPayload;
                };
            }>);


            // TODO: check BE why it is like that and change it!
            const { TestchartName, MeasuringDevices }: MeasurementMetadata = JSON.parse(JSON.parse(response.data.getMeasurementBasics.metadata));

            return {
                chartName: TestchartName.length > 0 ? TestchartName : undefined,
                device: MeasuringDevices.length > 0 ? MeasuringDevices : undefined,
            };
        },
        enabled: !!measurementId,
    });
};