import { Box, Checkbox, Flex } from '@gmg/gmg-react-components';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

const localStorageItemKey = 'skipExternalAppRequiredHint';

export const isExternalAppRequiredHintSkipped = () => sessionStorage.getItem(localStorageItemKey) === 'true';

const ExternalAppRequiredModal: FC<{}> = () => {
    const { t } = useTranslation();
    return (
        <Box maxWidth='360px'>
            <Box pb="16px">
                <span>
                    {t('ExternalAppRequiredModal.messageBox_msg', 'To continue, you need to have the GMG Measure desktop app installed on your computer.')}
                </span>
                <StyledFlex className="px10_400_weak">
                    <span>{t('ExternalAppRequiredModal.warning_claim_desc', 'Please make sure the app is installed.')}</span>
                    <Box className="px10_400_weak">
                        <span>{t('ExternalAppRequiredModal.warning_question_desc', 'Don\'t have the app installed yet?')}</span>
                        <Trans
                            t={t}
                            i18nKey={'ExternalAppRequiredModal.modalWindow.infoAppNotInstalled_msg'}>
                            Download it now for
                            <a
                                href="https://api.dev.licence.aws.gmgcolor.com/latestproduct?type=MeasureAppSetup&os=Windows"
                                rel="noopener noreferrer"
                                className="px10_400_normal"
                            >
                                Windows
                            </a>
                            <a
                                href="https://api.dev.licence.aws.gmgcolor.com/latestproduct?type=MeasureAppSetup&os=MAC"
                                rel="noopener noreferrer"
                                className="px10_400_normal"
                            >
                                Mac.
                            </a>
                        </Trans>
                    </Box>
                </StyledFlex>
            </Box>
            <Flex alignItems='center' gap='8px'>
                <Checkbox
                    id="dontShowAgainCheckbox"
                    onClick={(e: any) => {
                        sessionStorage.setItem(localStorageItemKey, e.target.checked === true ? 'true' : 'false');
                    }}
                />
                <label
                    htmlFor="dontShowAgainCheckbox"
                    className="px12_600_normal"
                >
                    &nbsp;{t('Common.CheckBoxLabels.dontShowAgain_cb', "Don't show again")}
                </label>
            </Flex>
        </Box>
    );
};

const StyledFlex = styled(Flex)`
    flex-direction: column;
    line-height: 1.6;
    padding-top: 8px;

    a {
        color: ${props => props.theme.colors.highlight};
    }
`;

export default ExternalAppRequiredModal;