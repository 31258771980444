import i18next from 'i18next';

// think about moving this to useFormatter.ts as well as this might be also very slow when being used in loops
export const formatDate = (date: Date) => {
    if (!date) return '';
    return i18next.t('intlDateTime', {
        val: new Date(date),
        formatParams: {
            val: {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
                hour: 'numeric',
                minute: 'numeric',
                hour12: false,
            },
        },
    });
};
