import { Flex, IconArrowRight, IconDelete, IconMoveFile, IconRename, ImageButton, Tooltip } from '@gmg/gmg-react-components';
import { FunctionComponent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from 'src/AppContext';
import { Folder } from 'src/graphql/ViewModels';
import styled from 'styled-components';
import CreateOrRenameFolderModalContainer from './CreateOrRenameFolderModalContainer';
import FileExplorerModalContainer from '../visualization/FileExplorerModal/FileExplorerModalContainer';
import { PathItem } from './BreadCrumb';

export interface FolderActionsProps {
    folder: Folder;
    siblingFolderNames: Array<string>;
    isFolderEditable: boolean;
    onOpen: (clickedFolder: Folder) => void;
    onDelete: () => void;
    path: Array<PathItem>;
    onChangePath: (itemOrItems: Array<PathItem> | PathItem) => void;
};

const FolderActions: FunctionComponent<FolderActionsProps> = props => {
    const { t } = useTranslation();
    const { onShowModal } = useContext(AppContext);

    const handleRenameFolder = (ev: any) => {
        ev.stopPropagation();
        onShowModal('inputModal', {
            title: t('FileOrFolderAction.button_text_rename', 'Rename'),
            titleIcon: <IconRename />,
            content: <CreateOrRenameFolderModalContainer
                folderId={props.folder.id}
                currentName={props.folder.name}
                parentFolderId={props.folder.parentFolderId!}
                siblingFolderNames={props.siblingFolderNames}
            />,
        });
    };

    const handleDeleteFolder = (ev: any) => {
        ev.stopPropagation();
        onShowModal('infoModal', {
            title: t('FileOrFolderAction.modals.delete.title', 'Delete'),
            content:
                <span>
                    {t('FileOrFolderAction.modals.delete.content_desc', 'Are you sure you want to delete "{{name}}" ?',
                        { name: props.folder.name },
                    )}
                </span>,
            onOkClick: props.onDelete,
            btnOkCaption: t('Common.ButtonLabels.delete', 'Delete'),
        });
    };

    const handleClickMoveFolder = () => {
        onShowModal('inputModal', {
            title: `${t('FileOrFolderAction.modals.move.title', 'Move')} ${props.folder.name}`,
            titleIcon: <IconMoveFile />,
            content: <FileExplorerModalContainer
                operationType='move'
                baseSelection={[{ id: props.folder.id, name: props.folder.name, isFolder: true }]}
                path={props.path}
                onChangePath={props.onChangePath}
                sectionTitle={t('FileOrFolderAction.modals.move.section_title', 'Choose Destination')} // Think about to specify in the Container based on the type
            />,
        });
    };

    return (
        <Flex gap='16px' justifyContent='flex-end' className='invisibleSVG'>
            {props.isFolderEditable && <>
                <ImageButton
                    icon={<IconRename />}
                    onClick={(ev: any) => { handleRenameFolder(ev); }}
                    aria-label={`Rename ${props.folder.name} folder`}
                />
                <ImageButton
                    icon={<IconDelete />}
                    onClick={handleDeleteFolder}
                    aria-label={`Delete ${props.folder.name} folder`}
                />
                {
                    <Tooltip message={t('Common.ButtonLabels.move_folder_icon_title', 'Move folder')}>
                        <ImageButton
                            icon={<IconMoveFile />}
                            onClick={handleClickMoveFolder}
                            aria-label={`Move ${props.folder.name} folder`}
                        />
                    </Tooltip>
                }
            </>}
            <Div>
                <ImageButton
                    onClick={() => { props.onOpen(props.folder); }}
                    icon={<IconArrowRight />}
                    aria-label={`Open ${props.folder.name} folder`}
                />
            </Div>
        </Flex>
    );
};

const Div = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 24px;
`;

export default FolderActions;