import { Checkbox, directionStatus, IconFolderFilled, IconMeasurementFilled, IconMeasurementStarFilled, SortButton, Tooltip } from '@gmg/gmg-react-components';
import { FunctionComponent, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import AppContext from 'src/AppContext';
import { Table, TableContainer, Tr } from 'src/components/measurements/ExplorerStyles';
import { Folder, FolderContent, MeasurementListItemViewModel } from 'src/graphql/ViewModels';
import { formatDate } from 'src/shared/format';
import { getSortByDate, getSortByPropertyName } from 'src/shared/sortingFunctions';
import styled from 'styled-components';
import WrappedSpinner from '../../shared/WrappedSpinner';
import EmptyFolderPage from '../EmptyFolderPage';
import FilteredTableEmptyPlaceholder from '../FilteredTableEmptyPlaceholder';
import { PathItem } from './BreadCrumb';
import FileActions from './FileActions';
import FolderActions from './FolderActions';
import Resizer from './Resizer';
import StatusIndicator from './StatusIndicator';
import { FileAction, NecessaryFileData } from '../Inspect';

interface SortingOrder {
    propertyName: 'name' | 'creationDate' | 'testchart' | 'device' | 'conditions' | 'inks' | 'processParamsStatusLabel';
    direction: directionStatus;
}

interface ExplorerProps {
    isLoading: boolean;
    folderContent: FolderContent | undefined;
    path: Array<PathItem>;
    setPath: (itemOrItems: Array<PathItem> | PathItem) => void;
    selectedFileIds: Array<string>;
    onFileAction: (action: FileAction) => void;
    onDeleteItem: (type: 'folder' | 'measurement', id: string, name: string) => void;
    filterInput: string;
    selectedFolderIds: Array<string>;
    onSelectedFoldersChange: (clickedFolder: Folder) => void;
}

const Explorer: FunctionComponent<ExplorerProps> = props => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { language, isUnlimitedLicenseAvailable, featureFlags } = useContext(AppContext);
    const secondColumnRef = useRef<HTMLTableCellElement | null>(null);
    const minWidthOfSecondColumn = 360;
    const [secondColumnWidth, setSecondColumnWidth] = useState<number | undefined>(minWidthOfSecondColumn);
    const [sortingStatus, setSortingStatus] = useState<SortingOrder>({ direction: 'ASC', propertyName: 'name' });
    const isRootFolder = props.path.length === 1;

    const handleOpenFolder = (clickedFolder: Folder) => {
        props.setPath({ id: clickedFolder.id, name: clickedFolder.name });
    };

    const handleSelectFile = (clickedFile: MeasurementListItemViewModel) => {
        const { id, name, version, conditionsArray: conditions, folderId, isEditable } = clickedFile;

        if (props.selectedFileIds.indexOf(id) > -1) {
            props.onFileAction({ type: 'deselect', id });
        } else {
            props.onFileAction({
                type: 'select',
                idsAndNecessaryFileData: new Map<string, NecessaryFileData>([[id, { name, conditions, version, isEditable, folderId }]]),
            });
        }
    };

    const handleQuickSelectClick = () => {
        const isQuickSelectChecked = filteredFolders?.length === props.selectedFolderIds.length &&
            filteredFiles?.length === props.selectedFileIds.length;

        if (filteredFolders) {
            filteredFolders
                .filter(f => props.selectedFolderIds.includes(f.id) === isQuickSelectChecked)
                .forEach(f => { props.onSelectedFoldersChange(f); });
        }
        if (filteredFiles) {
            filteredFiles
                .filter(f => props.selectedFileIds.includes(f.id) === isQuickSelectChecked)
                .forEach(handleSelectFile);
        }
    };

    const handleOpenFile = (clickedFile: MeasurementListItemViewModel) => {
        const { id, name, version, conditionsArray: conditions, folderId, isEditable } = clickedFile;

        props.onFileAction({ type: 'selectAsOnly', id, necessaryFileData: { name, conditions, version, folderId, isEditable } });

        navigate(isUnlimitedLicenseAvailable ? 'spider' : 'tonalvalue');
    };

    const handleSortButtonClick = (label: SortingOrder['propertyName']) => {
        setSortingStatus({
            direction: sortingStatus.direction === 'ASC' ? 'DESC' : 'ASC',
            propertyName: label,
        });
    };
    const isColumnSorted = (propName: SortingOrder['propertyName']) => sortingStatus.propertyName === propName
        ? sortingStatus.direction
        : undefined;

    const sortfn = () => sortingStatus.propertyName === 'creationDate'
        ? getSortByDate(sortingStatus.propertyName, sortingStatus.direction)
        : getSortByPropertyName(sortingStatus.propertyName, sortingStatus.direction, language);

    const sortedFolders = props.folderContent?.subfolders
        .sort(getSortByPropertyName('name', 'ASC', language))
        .sort(sortfn());

    const sortedFiles = props.folderContent?.measurements
        .sort(getSortByPropertyName('name', 'ASC', language))
        .sort(sortfn());

    const filteredFolders = sortedFolders?.filter(folder => folder.name.toLowerCase().includes(props.filterInput.trim().toLowerCase()));

    const definedLabel = t('Explorer.tableLabels.process_parameters_defined_gl', 'Defined');
    const missingLabel = t('Explorer.tableLabels.process_parameters_missing_gl', 'Missing');
    const filteredFiles = sortedFiles?.filter(({ name, testchart, device, conditions, inks, processParamsDefined }) =>
        [name, testchart, device, conditions, inks, processParamsDefined ? definedLabel : missingLabel]
            .some(item => item.toLowerCase().includes(props.filterInput.trim().toLowerCase())),
    );

    const isFolderEmpty = props.folderContent?.subfolders.length === 0 && props.folderContent?.measurements.length === 0;
    const isFilteredTableEmpty = filteredFolders?.length === 0 && filteredFiles?.length === 0;

    const isWideScreen = useMediaQuery({ query: '(min-width: 1470px)' });

    return (
        <ExplorerWrapper className="vertical-scrollbar">
            {props.isLoading
                ? <WrappedSpinner label="Loading..." labelPosition="bottom" />
                : isFolderEmpty
                    ? <EmptyFolderPage
                        subtext={t('EmptyFolderPage.page_desc', 'Click on "Import data" if you want to upload a file.')}
                    />
                    : <Table aria-label="File Explorer">
                        <TableHead
                            calculatedWidth={secondColumnWidth}
                            minWidthOfSecondColumn={minWidthOfSecondColumn}
                            omitCheckboxColumn={isRootFolder}
                        >
                            <TableRow className="px12_400_weak">
                                <th>
                                    {!isRootFolder && <Checkbox
                                        id="quickSelect"
                                        variant="circled"
                                        checked={filteredFolders?.length === props.selectedFolderIds.length &&
                                            filteredFiles?.length === props.selectedFileIds.length}
                                        onChange={handleQuickSelectClick}
                                    />}
                                </th>
                                <th ref={secondColumnRef}>
                                    <ResizerContainer calculatedWidth={secondColumnWidth} minWidthOfSecondColumn={minWidthOfSecondColumn}>
                                        <Resizer
                                            onResizeColumn={setSecondColumnWidth}
                                            minWidthOfColumn={minWidthOfSecondColumn}
                                            prevDefinedWidth={secondColumnWidth}
                                            targetElementRef={secondColumnRef}
                                        />
                                        <SortButton
                                            buttonLabel={t('Explorer.tableLabels.name_btn', 'Name')}
                                            onClick={() => handleSortButtonClick('name')}
                                            direction={isColumnSorted('name')}
                                        />
                                    </ResizerContainer>
                                </th>
                                <th>
                                    <SortButton
                                        buttonLabel={t('Explorer.tableLabels.creation_btn', 'Upload')}
                                        onClick={() => handleSortButtonClick('creationDate')}
                                        direction={isColumnSorted('creationDate')}
                                    />
                                </th>
                                <th>
                                    <SortButton
                                        buttonLabel={t('Explorer.tableLabels.testchart_btn', 'Test Chart')}
                                        onClick={() => handleSortButtonClick('testchart')}
                                        direction={isColumnSorted('testchart')}
                                    />
                                </th>
                                <th>
                                    <SortButton
                                        buttonLabel={t('Explorer.tableLabels.device_btn', 'Device')}
                                        onClick={() => handleSortButtonClick('device')}
                                        direction={isColumnSorted('device')}
                                    />
                                </th>
                                <th>
                                    <SortButton
                                        buttonLabel={t('Explorer.tableLabels.conditions_btn', 'Conditions')}
                                        onClick={() => handleSortButtonClick('conditions')}
                                        direction={isColumnSorted('conditions')}
                                    />
                                </th>
                                <th>
                                    <SortButton
                                        buttonLabel={t('Explorer.tableLabels.inks_btn', 'Inks')}
                                        onClick={() => handleSortButtonClick('inks')}
                                        direction={isColumnSorted('inks')}
                                    />
                                </th>
                                <th>
                                    {!featureFlags.disableColorCorrections &&
                                        <SortButton
                                            buttonLabel={t('Explorer.tableLabels.process_parameters_btn', 'Process parameters')}
                                            onClick={() => handleSortButtonClick('processParamsStatusLabel')}
                                            direction={isColumnSorted('processParamsStatusLabel')}
                                        />
                                    }
                                </th>
                                <th></th>{/* placeholder in thead for action buttons */}
                            </TableRow>
                        </TableHead>
                        <tbody>
                            {isFilteredTableEmpty
                                ? <FilteredTableEmptyPlaceholder />
                                : <>
                                    {filteredFolders!.map(folder => (
                                        <TableRow
                                            key={folder.id}
                                            data-testid="row"
                                            isSelected={props.selectedFolderIds.indexOf(folder.id) > -1}
                                        >
                                            <td>
                                                {!isRootFolder && <Checkbox
                                                    variant="circled"
                                                    id={`${folder.name}'s checkbox`}
                                                    checked={props.selectedFolderIds.indexOf(folder.id) > -1}
                                                    onChange={() => { props.onSelectedFoldersChange(folder); }}
                                                />}
                                            </td>
                                            <td
                                                className="px12_400_normal"
                                                onClick={() => { handleOpenFolder(folder); }}
                                            >
                                                <BaseBox>
                                                    <IconFolderFilled width={16} height={16} />
                                                    <span className="px12_400_normal">{folder.name}</span>
                                                </BaseBox>
                                            </td>
                                            <td className="px12_400_normal"></td>
                                            <td className="px12_400_normal"></td>
                                            <td className="px12_400_normal"></td>
                                            <td className="px12_400_normal"></td>
                                            <td className="px12_400_normal"></td>
                                            <td className="px12_400_normal"></td>{/* placeholder for characterization parameters*/}
                                            <td>
                                                <FolderActions
                                                    folder={folder}
                                                    isFolderEditable={!!props.folderContent && props.folderContent.isEditable}
                                                    siblingFolderNames={props.folderContent?.subfolders
                                                        .filter(f => f.id !== folder.id)
                                                        .map(f => f.name) || []}
                                                    onOpen={handleOpenFolder}
                                                    onDelete={() => { props.onDeleteItem('folder', folder.id, folder.name); }}
                                                    path={props.path}
                                                    onChangePath={props.setPath}
                                                />
                                            </td>
                                        </TableRow>
                                    ))}
                                    {filteredFiles!.map(file => (
                                        <TableRow
                                            key={file.id}
                                            isSelected={props.selectedFileIds.indexOf(file.id) > -1}
                                            data-testid="row"
                                        >
                                            <td>
                                                {!isRootFolder && <Checkbox
                                                    variant="circled"
                                                    id={`${file.name}'s checkbox`}
                                                    checked={props.selectedFileIds.indexOf(file.id) > -1}
                                                    onChange={() => { handleSelectFile(file); }}
                                                />}
                                            </td>
                                            <td className="px12_400_normal">
                                                <BaseBox>
                                                    {file.isStandardCharacterization
                                                        ? <IconMeasurementStarFilled width={16} height={16} />
                                                        : <IconMeasurementFilled width={16} height={16} />}
                                                    <span onClick={() => { handleOpenFile(file); }}>
                                                        <Tooltip message={file.name}>
                                                            <span>{file.name}
                                                            </span>
                                                        </Tooltip>
                                                    </span>
                                                </BaseBox>
                                            </td>
                                            <td className="px12_400_normal"> {formatDate(file.creationDate)}</td>
                                            <td className="px12_400_normal">
                                                <Tooltip message={file.testchart}>
                                                    <span>{file.testchart}</span>
                                                </Tooltip>
                                            </td>
                                            <td className="px12_400_normal">{file.device}</td>
                                            <td className="px12_400_normal">{file.conditions}</td>
                                            <td className="px12_400_normal">
                                                {file.inks && file.inks.length > 100
                                                    ? `${file.inks.substring(0, 100)}...`
                                                    : `${file.inks}`}
                                            </td>
                                            <td className="px12_400_normal">
                                                {!featureFlags.disableColorCorrections &&
                                                    <ProcessParamsStatusWrapper>
                                                        <StatusIndicator statusIsGreen={file.processParamsDefined} />
                                                        {file.processParamsDefined ? definedLabel : missingLabel}
                                                    </ProcessParamsStatusWrapper>
                                                }
                                            </td>
                                            <td>
                                                <FileActions
                                                    measurementId={file.id}
                                                    measurementName={file.name}
                                                    measurementVersion={file.version}
                                                    allOtherMeasurementNamesInFolder={props.folderContent?.measurements
                                                        .filter(mes => mes.id !== file.id)
                                                        .map(mes => mes.name)}
                                                    isFileEditable={!!props.folderContent && props.folderContent.isEditable}
                                                    onDelete={() => { props.onDeleteItem('measurement', file.id, file.name); }}
                                                    onOpen={() => { handleOpenFile(file); }}
                                                    isWideScreen={isWideScreen}
                                                    path={props.path}
                                                    onChangePath={props.setPath}
                                                />
                                            </td>
                                        </TableRow>
                                    ))}
                                </>
                            }
                        </tbody>
                    </Table>
            }
        </ExplorerWrapper >
    );
};

const ResizerContainer = styled.div<{ calculatedWidth: number | undefined; minWidthOfSecondColumn: number }>`
    display: flex;
    height: 100%;
    min-width: ${props => props.minWidthOfSecondColumn}px;
    width: 100%;
    max-width: ${props => props.calculatedWidth ? props.calculatedWidth + 'px' : '100%'};
`;

const ExplorerWrapper = styled(TableContainer)`
    max-height: calc(100vh - 215px );
    min-height: 100%;
    &::-webkit-scrollbar-track {
        margin-top: 46px; // height of TableHead
    }
`;

const TableHead = styled.thead<{ calculatedWidth: number | undefined; minWidthOfSecondColumn: number; omitCheckboxColumn: boolean }>`
    top: 0px;

    & th:first-child {
        box-sizing: border-box;
        max-width: 53px;
        width: ${props => !props.omitCheckboxColumn ? '53' : '0'}px;     /* if padding has changed, the value in the Resizer.tsx, and in Cypress test has to be adjusted*/
        padding-right: ${props => !props.omitCheckboxColumn ? '18' : '0'}px;
    }

    & th:nth-child(2) { /* name (resizable) */
        padding-left: 0px;
        min-width: ${props => props.minWidthOfSecondColumn}px;
        width: ${props => props.calculatedWidth ? props.calculatedWidth : props.minWidthOfSecondColumn}px;
        max-width: calc(45vw - 72px - 58px);
        &:hover [aria-label='Resizer'] {
            opacity: 1;
        }
    }

    & th:nth-child(3) { /* Creation  */
        min-width: 7%;
        width: 7%;
        max-width: 7%;
    }

    & th:nth-child(4) { /* TestChart */
        min-width: 10%;
    }

    & th:nth-child(5) { /* Device */
        width: 7%;
        max-width: 8%;
    }

    & th:nth-child(6) { /* Condtions */
        min-width: 6%;
        width: 7%;
        max-width: 7%;
    }
`;

const TableRow = styled(Tr)`
    cursor: default;

    label { /* make checkbox visible also when not hovering */
        border-color: ${props => props.theme.colors.greyContrastHigh};
    }

    td {
        border-top-color: ${props => props.isSelected && props.theme.colors.highlight};
        border-bottom-color: ${props => props.isSelected && props.theme.colors.highlight};
        background-color: ${props => props.isSelected && props.theme.colors.greyContrastLowest};
        padding-top: 8px;
        padding-bottom: 8px;
    }

    td:first-child {
        border-left-color: ${props => props.isSelected && props.theme.colors.highlight};
    }

    & td:nth-child(2) { /* name (resizable) */
        cursor: default;
        padding-left: 8px;

        span {
            max-width: 95%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    & td:last-child {
        border-right-color: ${props => props.isSelected && props.theme.colors.highlight};
    }

    & td:nth-child(4) {
        max-width: 90%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

     /* we have to overwrite the style again because of the selection */
     &:last-child td{
        border-bottom: 1px solid ${props => props.isSelected ? props.theme.colors.highlight : props.theme.colors.greyContrastMediumLower} ;
    }
`;

const BaseBox = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    svg {
        color: ${props => props.theme.colors.greyContrastSemiHigh};
        min-width: 16px;
    }
`;

const ProcessParamsStatusWrapper = styled.span`
     white-space: nowrap;
     overflow: hidden;
`;

export default Explorer;
