import { FC, useContext, useRef } from 'react';
import { Button, IconImport } from '@gmg/gmg-react-components';
import { useTranslation } from 'react-i18next';
import AppContext from 'src/AppContext';
import { getUploadEvent } from 'src/tracking';
import { loadFileContent } from 'src/shared/fileReader';

export interface UploadMeasurementButtonProps {
    onSelectFile: (contents: Map<string, string>) => void;
    disabled: boolean;
};

const UploadMeasurementButton: FC<UploadMeasurementButtonProps> = props => {
    const { t } = useTranslation();
    const { trackEvent } = useContext(AppContext);
    const inputFile = useRef<HTMLInputElement>(null);

    const onOpenFileDialogClick = () => {
        if (!inputFile.current) return;
        inputFile.current.click();
    };

    const handleFileInput = async (e: any) => {
        const filenameToContent = new Map();

        const contents = await Promise.all(Object.values(e.target.files).map(file => loadFileContent(file)));
        Object.values(e.target.files).forEach((file: any, i) => filenameToContent.set(file.name, contents[i]));

        trackEvent(getUploadEvent(filenameToContent.size));

        props.onSelectFile(filenameToContent);
        e.target.value = null; // we're calling the function onchange/oninput. If you upload the same file the value of the file input HAS NOT changed from the previous upload and therefore isn't triggered. This also explains why it works if we upload a different file. =>  we can work around this by resetting the input field's value after you read the file.
    };

    return (
        <>
            <Button
                variant="secondary"
                icon={<IconImport />}
                onClick={onOpenFileDialogClick}
                disabled={props.disabled}
            >
                {t('ButtonsContainer.import_data_btn', 'Import data')}
            </Button>
            <input
                type="file"
                id="cloudFile"
                accept=".json, .txt"
                onChange={handleFileInput}
                ref={inputFile}
                multiple
                style={{ display: 'none' }}
                placeholder='hidden input'
            />
        </>
    );
};

export default UploadMeasurementButton;