import { Folder as ApiFolder, Maybe, MeasurementMeta } from '../generated/types';
import { mapToMeasurementListViewModel } from './mapToMeasurementListItemViewModel';
import { Folder, FolderContent } from '../ViewModels';

const mapToSubfolderList = (sourceList: Array<ApiFolder>, parentFolderId: string | undefined): Array<Folder> => {
    return sourceList
        .filter(source => source.isTraversable)
        .map((source: ApiFolder): Folder => ({
            id: source.id,
            name: source.name,
            parentFolderId: parentFolderId,
        }));
};

export const mapToFolderContent = (
    sourceFolder: Maybe<ApiFolder> | undefined,
    sourceSubFolders: Array<ApiFolder>,
    sourceFiles: Array<Maybe<MeasurementMeta>>): FolderContent => {

    const isEditable = sourceFolder
        ? !!sourceFolder.isEditable
        : false; // root is not editable in MIO

    return {
        isEditable,
        subfolders: mapToSubfolderList(sourceSubFolders, sourceFolder ? sourceFolder.id : undefined),
        measurements: sourceFolder
            ? mapToMeasurementListViewModel(sourceFiles, sourceFolder.id, isEditable)
            : [],
    };
};
