import { Button } from '@gmg/gmg-react-components';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FormElement from './FormElement';
import { useEffect } from 'react';

export interface CreateOrRenameFolderModalProps {
    parentFolderId: string;
    siblingFolderNames: Array<string>;
    folderId?: string;
    currentName?: string;
    onClose: () => void;
    onSubmit: (data: any) => void;
    useCase: 'rename' | 'add';
}

const CreateOrRenameFolderModal: React.FunctionComponent<CreateOrRenameFolderModalProps> = props => {
    const { t } = useTranslation();
    const { register, handleSubmit, formState: { errors }, setFocus } = useForm<{ name: string }>({ mode: 'onBlur' });

    useEffect(() => {
        setFocus('name');
    }, [setFocus]);

    const registerOptionsForName = {
        required: t('Validation.forms.field_required_val', 'Uh oh, please fill out this field.'),
        maxLength: {
            value: 40,
            message: t('Validation.forms.maxLength_exceeded_val', {
                defaultValue: 'Oops, you have exceeded the limit of 40 characters.',
                maxCharacters: 40,
            }),
        },
        validate: {
            isValid: (newName: string) => !props.siblingFolderNames.find(name => name === newName.trim())
                ? true
                : t('CreateOrRenameFolderModal.nameNotUnique', 'Oh no, a folder with this name already exists. Please use a different name.'),
        },
    };

    return (
        <Container>
            <form onSubmit={handleSubmit(data => { props.onSubmit(data.name.trim()); })}>
                <FormElement
                    label={t('CreateOrRenameFolderModal.input_label_title', 'Folder name')}
                    dataProperty='name'
                    defaultValue={props.currentName}
                    placeholder={t('CreateOrRenameFolderModal.input_placeholder_title', 'Enter a folder name')}
                    registerElement={{
                        ...register('name', registerOptionsForName),
                    }}
                    validationError={errors?.name?.message}
                />
                <ButtonContainer>
                    <Button
                        variant="primary"
                        type="submit"
                    >
                        {props.useCase === 'add'
                            ? t('Common.ButtonLabels.add_title', 'Add')
                            : t('Common.ButtonLabels.applyBtn_title', 'Apply')
                        }
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={props.onClose}
                    >
                        {t('Common.ButtonLabels.cancelBtn_title', 'Cancel')}
                    </Button>
                </ButtonContainer>
            </form>
        </Container>
    );
};

const Container = styled.div`
   width: 100%;
    
    form {
        width: 100%;
    }
`;

const ButtonContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding-right: 23px;
    & button {
        margin-left: 10px;
    }
`;

export default CreateOrRenameFolderModal;