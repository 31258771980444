import { GmgStyleProvider, InfoModalProps, InputModalProps, ModalType, ThemeType } from '@gmg/gmg-react-components';
import { fetchAuthSession, signInWithRedirect, getCurrentUser, signOut } from 'aws-amplify/auth';
import i18next from 'i18next';
import { FunctionComponent, cloneElement, useEffect, useRef, useState } from 'react';
import { IConfig } from 'src/Config';
import AppContext, { IAppContext, Language, MeasurementSettings, defaultContext } from '../AppContext';
import { useApplicationSettings } from '../graphql/customHooks/useApplicationSettings';
import { useSetApplicationSettings } from '../graphql/customHooks/useSetApplicationSettings';
import { TrackingEvent, checkDisableTracking, getChangeSettingsEvent } from '../tracking';
import App from './App';
import ErrorBoundary from './ErrorBoundary';
import { ApplicationError } from './ErrorMsg';

export interface AppContainerProps {
    config: IConfig;
    trackEvent: (event: TrackingEvent) => void;
};

const AppContainer: FunctionComponent<AppContainerProps> = props => {
    const [modal, setModal] = useState<ModalType>(undefined);
    const [errorType, setErrorType] = useState<undefined | ApplicationError>(undefined);
    const [theme, setTheme] = useState<ThemeType>('light');
    const updateLocaleRef = useRef<(value: string) => Promise<void>>();

    const [appContext, setAppContext] = useState<IAppContext>({ // why state? reason for lifting context to state see https://reactjs.org/docs/context.html#caveats
        ...defaultContext,
        onError: (e: any) => {
            setErrorType(e.errors && e.errors.length && e.errors.some((err: any) => err.errorType === 'Unauthorized')
                ? 'unauthorized'
                : 'unhandled',
            );
        },
        onShowModal: (variant: 'infoModal' | 'inputModal', options: InfoModalProps | InputModalProps) => {
            const onClose = () => setModal(undefined);
            setModal({
                variant,
                options: {
                    ...options,
                    content: cloneElement(options.content, { ...options.content.props, onClose }),
                    onClose,
                },
            });
        },
        featureFlags: props.config.featureFlags,
    });

    const { data: applicationSettings, isFetching: isFetchingApplicationSettings } = useApplicationSettings(appContext.populateCacheForAppSynch);
    const applicationSettingsMutation = useSetApplicationSettings();

    useEffect(() => {
        if (!applicationSettings) return;

        setAppContext((ctx): IAppContext => ({
            ...ctx,
            measurementSettings: applicationSettings.measurementSettings,
        }));

        if (applicationSettings.theme) {
            setTheme(applicationSettings.theme);
        }

    }, [applicationSettings]);

    useEffect(() => {
        void (async () => {
            try {
                const { userId } = await getCurrentUser();
                await handleSignIn(userId);
            } catch (err) {
                await signInWithRedirect();
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUnlimitedLicenseAvailable = (groups: Array<string>) => {
        if (!groups) {
            return false;
        }
        return (groups.indexOf('Optimize') > -1);
    };

    const handleSignIn = async (userId: string) => {

        const tokens = (await fetchAuthSession()).tokens;
        const idTokenPayload = tokens?.idToken?.payload!;

        const moduleLicenses = idTokenPayload['cognito:groups'] as Array<string>;
        // User needs either module "Measure" or "Optimize" assigned in CMP.
        const hasAccessToModuleMIO = moduleLicenses && (moduleLicenses.indexOf('Measure') > -1 || moduleLicenses.indexOf('Optimize') > -1);
        const isUnlimitedLicenseAvailable = getUnlimitedLicenseAvailable(moduleLicenses);

        let authorizationError: ApplicationError | undefined = undefined;
        if (!userId || !idTokenPayload) {
            authorizationError = 'unhandled';
        } else if (!idTokenPayload.organization_id) {
            authorizationError = 'userWithoutOrg';
        } else if (!hasAccessToModuleMIO) {
            authorizationError = 'unauthorized';
        }
        if (authorizationError) {
            setErrorType(authorizationError);
        } else {
            setAppContext((ctx): IAppContext => ({
                ...ctx,
                user: {
                    // name and orgId will be undefined if user was not added to an organization yet.
                    name: `${idTokenPayload.given_name || ''} ${idTokenPayload.family_name || ''}`.trim(),
                    email: idTokenPayload.email as string,
                    guid: userId, // user.getUsername(),
                    orgId: idTokenPayload.organization_id as string,
                    orgName: idTokenPayload.organization_name as string,
                    refreshToken: '', // user.getSignInUserSession()!.getRefreshToken().getToken(),
                },
                populateCacheForAppSynch: async () => {
                    // call to fetchAuthSession takes care about refreshing tokens
                    // see https://docs.amplify.aws/react/build-a-backend/auth/under-the-hood/#pageMain)
                    // TODO needed?
                    // const { accessToken } = (await fetchAuthSession()).tokens!;
                },
                trackEvent: props.trackEvent,
                isUnlimitedLicenseAvailable,
            }));

            checkDisableTracking(idTokenPayload.email as string, props.config.tracking.ignore);

            // const localeUserSettings = user.getSignInUserSession()?.getIdToken().decodePayload().locale || 'en';
            handleChangeLanguage('en');

            // TODO: When we will be using the localization and language switch in the app again, we need to save the locale settings in the User item in CMP (not in Cognito anymore).
            updateLocaleRef.current = (value: string) => Promise.resolve();
        }

    };

    const handleChangeTheme = (selectedTheme: ThemeType) => {
        setTheme(selectedTheme);

        // persist theme in Settings Service
        applicationSettingsMutation.mutate(
            {
                paramType: 'theme',
                paramValue: selectedTheme,
            },
        );
    };

    const handleChangeLanguage = (language: Language) => {
        void i18next.changeLanguage(language);
        void updateLocaleRef.current?.(language);
        setAppContext((ctx): IAppContext => ({ ...ctx, language }));
    };

    const handleChangeMeasurementParams = (key: keyof MeasurementSettings, value: string) => {
        if (!key) return;
        setAppContext((ctx): IAppContext => (
            {
                ...ctx,
                measurementSettings: {
                    ...ctx.measurementSettings!,
                    [key]: value,
                },
            }
        ));

        props.trackEvent(getChangeSettingsEvent(key));

        applicationSettingsMutation.mutate(
            {
                paramType: key,
                paramValue: value,
            },
        );
    };

    return (
        <AppContext.Provider value={appContext}>
            <ErrorBoundary>
                <GmgStyleProvider theme={theme}>
                    {((appContext.user && !isFetchingApplicationSettings) || errorType) && (
                        <App
                            errorType={errorType}
                            modal={modal}
                            signOut={signOut}
                            theme={theme}
                            onChangeTheme={handleChangeTheme}
                            onChangeLanguage={handleChangeLanguage}
                            onChangeMeasurementParams={handleChangeMeasurementParams}
                            environmentName={props.config.environmentName}
                        />
                    )}
                </GmgStyleProvider>
            </ErrorBoundary>
        </AppContext.Provider>
    );
};

export default AppContainer;