import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query'; // useQueryClient
import AppContext from 'src/AppContext';
import { mapToApplicationSettingsViewModel } from '../mapper/mapToApplicationSettingsViewModel';
import { ApplicationSettingsPayload } from '../generated/types';

export const useApplicationSettings = (populateCacheForAppSynch: (() => Promise<void>) | undefined) => {

    const context = useContext(AppContext);

    const query = `query GetApplicationSettings {
    getApplicationSettings {
      settings {
        appKeys
        key
        value
      }
    }
  }`;

    return useQuery({
        queryKey: ['getApplicationSettings'],

        queryFn: async () => {

            await populateCacheForAppSynch?.();

            try {
                const response = await (context.api.graphql({ query }) as Promise<{
                    data: {
                        getApplicationSettings: ApplicationSettingsPayload;
                    };
                }>);

                return mapToApplicationSettingsViewModel(response.data.getApplicationSettings.settings, context.measurementSettings);

            } catch (e: any) {
                // in case of errors, let's fall back to the default values
                return { measurementSettings: context.measurementSettings, theme: undefined };
            }
        },
        enabled: !!populateCacheForAppSynch,
    });
};
