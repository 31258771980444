import { FunctionComponent } from 'react';
import useFormatter from 'src/shared/useFormatter';
import styled from 'styled-components';

export interface NumberCellProps {
    value: string;
    label: string;
    bgColor: string;
    indicatorIcon?: JSX.Element;
    isHighligted: boolean;
    mode: { isTextBox: boolean; readonly: boolean };
    onChange: (value: string) => void;
    // Range property takes care of correcting typed in value
    range?: { min: number; max: number };
    onAutoCorrection?: () => void;
};

const NumberCell: FunctionComponent<NumberCellProps> = props => {
    const { formatNumber } = useFormatter();

    return (
        <DIV
            bgColor={props.bgColor}
            isTextBox={props.mode.isTextBox}
            hasIcon={props.indicatorIcon !== undefined}
            hasValueBeenChanged={props.isHighligted}
        >
            <ColoredBox
                type="text"
                value={props.value}
                readOnly={props.mode.readonly}
                aria-label={props.label}
                hasIcon={!!props.indicatorIcon}
                // To allow only number and dot and minus keypress
                onKeyPress={(event) => {
                    if (!/[0-9,.-]/.test(event.key)) {
                        event.preventDefault();
                    }
                }}
                onChange={(e) => {
                    // ^ => Start of the string
                    // (-?) => Matches an optional negative sign (-)
                    // (\d{0,3}) => Matches 0 to 3 digits
                    // (\.\d{0,2})? => Matches an optional decimal point followed by 0 to 2 digits
                    // $ => End of the string
                    if (!/^(-?\d{0,3})(\.\d{0,2})?$/.exec(e.target.value)) return;

                    props.onChange(e.target.value);
                }}
                onBlur={() => {
                    if (props.range === undefined) return;

                    if (+props.value > props.range.max) {
                        props.onAutoCorrection?.();
                        props.onChange(formatNumber(props.range.max)!);
                    }
                    if (+props.value < props.range.min) {
                        props.onAutoCorrection?.();
                        props.onChange(formatNumber(props.range.min)!);
                    }
                }}
            />
            {props.indicatorIcon !== undefined && props.indicatorIcon}
        </DIV>
    );
};

const DIV = styled.div<{ bgColor: string; isTextBox: boolean; hasIcon: boolean; hasValueBeenChanged: boolean }>`
    display: grid;
    grid-template-columns: ${props => props.hasIcon ? '36px 16px' : '1fr'};
    align-items: center;
    justify-content: center;
    height: 100%;
    max-width: 54px;
    margin: auto;
    border-radius: ${props => props.theme.radii.default}px;
    background-color: ${props => props.isTextBox && !props.hasValueBeenChanged
        ? props.theme.colors.greyContrastSemiLower
        : props.isTextBox && props.hasValueBeenChanged
            ? props.theme.colors.backgroundPaper
            : props.bgColor};
    border: 1px solid ${props => props.isTextBox
        ? props.theme.colors.greyContrastHigh
        : 'transparent'};
    svg {
        grid-column: 2 / 3;
        width: 16px;
        height: 16px;
        fill: ${props => props.theme.colors.textNormal};;
    }

    &:active {
        border-color: ${props => props.isTextBox
        ? props.theme.colors.highlight
        : 'transparent'};
    }
`;

const ColoredBox = styled.input <{ hasIcon: boolean }>`
    box-sizing: border-box;
    grid-column: 1 / 2 ;
    height: 100%;
    max-width: ${props => props.hasIcon ? 36 : 50}px;
    text-align: right;
    padding-right: ${props => props.hasIcon ? 0 : 8}px;
    background-color: transparent;
    color:  ${props => props.theme.colors.textNormal};
    border-radius: ${props => props.theme.radii.default}px;
    outline: none;
    border: none;
`;

export default NumberCell;