import i18n from 'i18next';
import 'react-i18next';
// eslint-disable-next-line no-duplicate-imports
import { initReactI18next } from 'react-i18next';
import { i18nextPlugin } from 'translation-check';
import deTranslation from './I18Next/locales/localizedLabels_GMG-Env_MIO_de-DE.json';
import enTranslation from './I18Next/locales/localizedLabels_GMG-Env_MIO_en-US.json';

export const resources = {
    en: {
        translation: enTranslation,
    },
    de: {
        translation: deTranslation,
    },
};

// Run the app and add ?showtranslations in the url,
// i.e. http://localhost:3000?showtranslations

//if env == dev then use this setup with

if (process.env.NODE_ENV === 'development') {
    void i18n.use(i18nextPlugin).init({
        debug: true,
        lng: 'en',
        fallbackLng: 'en',
        ns: 'translation',
        resources,
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });
}

// any other cases
void i18n.use(initReactI18next).init({
    debug: false,
    lng: 'en',
    fallbackLng: 'en',
    ns: 'translation',
    resources,
    interpolation: {
        escapeValue: false, // react already safes from xss
    },
});

export default i18n;