import { InfoModalProps, InputModalProps } from '@gmg/gmg-react-components';
import React from 'react';
import { FeatureFlags } from './Config';
import { Illuminants, CmykFilters, CmykMethods, Observers } from './graphql/generated/types';
import { TrackingEvent } from './tracking';
import { generateClient } from 'aws-amplify/api';
import { V6Client } from '@aws-amplify/api-graphql';

export interface MioUser {
    name?: string;
    email: string;
    guid: string;
    orgName: string;
    orgId: string;
    refreshToken: string; // does this really belong to the user?
};

// NOTE: is the same as type Language = 'en' | 'de' ,  =>  see https://vieirinha.dev/blog/2020/12/27/typescript-iterate-over-string-literal.html

export const LanguageArray = ['en', 'de'] as const;
export type Language = typeof LanguageArray[number];

// see https://stackoverflow.com/questions/52393730/typescript-string-literal-union-type-from-enum/64966647#64966647

export type DeltaETypes = 'DE00' | 'DE76';

export type FilterCMYK = `${CmykFilters}`;
export type MethodCMYKANDSpot = `${CmykMethods}`;
export type Observer = `${Observers}`;
export type Illuminant = `${Illuminants}`;

export type MeasurementSettings = {
    filterCMYK: FilterCMYK;
    methodCMYK: MethodCMYKANDSpot;
    methodSpot: MethodCMYKANDSpot;
    observer: Observer;
    illuminant: Illuminant;
    deltaE: DeltaETypes;
};

export interface IAppContext {
    api: V6Client<never>;
    onError: (e: Error) => void;
    user: MioUser | undefined; // note that is currently not really needed in context, but there might be upcoming features which make it necessary. if not, remove it
    onShowModal: (variant: 'infoModal' | 'inputModal', options: InfoModalProps | InputModalProps) => void;
    measurementSettings: MeasurementSettings;
    populateCacheForAppSynch: (() => Promise<void>) | undefined;
    language: Language;
    preferencesSubObject: any;
    featureFlags: FeatureFlags;
    trackEvent: (event: TrackingEvent) => void;
    isUnlimitedLicenseAvailable: boolean;
}

const api = generateClient();

export const defaultContext: IAppContext = {
    api,
    onError: () => { },
    user: undefined,
    onShowModal: () => { },
    measurementSettings: {
        illuminant: 'D50',
        observer: 'O2',
        filterCMYK: 'StatusE',
        methodCMYK: 'MurrayDavies',
        methodSpot: 'MurrayDavies',
        deltaE: 'DE00',
    },
    populateCacheForAppSynch: undefined,
    language: 'en',
    preferencesSubObject: {},
    featureFlags: {
        disableRemoveOutlier: false,
        disableSmoothing: false,
        disableAveraging: false,
        disableRescale: false,
        disableAutoOptimize: false,
        disableColorCorrections: false,
    },
    trackEvent: () => { },
    isUnlimitedLicenseAvailable: true,
};

const AppContext = React.createContext<IAppContext>(defaultContext);

export default AppContext;