import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import AppContext from 'src/AppContext';
import { DeleteMeasurementVersionInput, DeleteMeasurementVersionPayload } from '../generated/types';

const mutation = `mutation DeleteMeasurementVersion($input: DeleteMeasurementVersionInput!) {
    deleteMeasurementVersion(input: $input) {
        measurementId
        versionsDeleted
    }
}`;

export const useDeleteVersion = (measurementId: string) => {
    const context = useContext(AppContext);

    const queryClient = useQueryClient();

    return useMutation(
        async (version: number) => {

            await context.populateCacheForAppSynch?.();

            const input: DeleteMeasurementVersionInput = {
                id: measurementId,
                version,
            };

            const response = await (context.api.graphql({ query: mutation, variables: { input } }) as Promise<{
                data: {
                    deleteMeasurementVersion: DeleteMeasurementVersionPayload;
                };
            }>);

            return response.data.deleteMeasurementVersion.versionsDeleted;
        },
        {
            onSettled: () => {
                // this would affect many queries:
                // - folderContent (because hasProcessParamsDefined flag might have changed)
                // - getMeasurementTableView for latest version
                // - getMeasurementTableView queries for deleted versions (would have to be removed anyway)
                // - getMeasurementHistory
                // might be more...
                // as this is a lot and it is not a very common use case, we just remove all of them
                queryClient.removeQueries();
            },
        });
};
