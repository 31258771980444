import { Box, Button, Checkbox, Flex, IconClose, toast } from '@gmg/gmg-react-components';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from 'src/AppContext';
import BreadCrumb, { PathItem } from 'src/components/measurements/BreadCrumb';
import { getErrorMsgForToast } from 'src/components/toastErrorHandler';
import { Folder, MeasurementListItemViewModel } from 'src/graphql/ViewModels';
import { useFolderContent } from 'src/graphql/customHooks/useFolderContent';
import { getSortByPropertyName } from 'src/shared/sortingFunctions';
import styled, { css } from 'styled-components';
import { NecessaryFileData } from '../../Inspect';
import FileExplorer from './FileExplorer';
import FileNameInput from 'src/components/measurements/FileNameInput';
import { useCopyFile } from 'src/graphql/customHooks/useCopyFile';
import { getAddFilesToFilePickerEvent, getCopyFileEvent, getMoveFileEvent, getMoveFolderEvent } from 'src/tracking';
import { useMoveItems } from 'src/graphql/customHooks/useMoveItems';

export interface FileExplorerModalContainerProps {
    operationType: 'addToFilePicker' | 'move' | 'copyFile';
    sectionTitle: string;
    path: Array<PathItem>;
    baseSelection: Array<{ id: string; name: string; isFolder: boolean }>;
    isSelectedFilesInfoRequired?: boolean;
    onSelectAdditionalFiles?: (idsAndNecessaryFileData: Map<string, NecessaryFileData>) => void;
    onChangePath?: (path: Array<PathItem>) => void;
    onClose?: () => void;
};

const FileExplorerModalContainer: FunctionComponent<FileExplorerModalContainerProps> = (
    { isSelectedFilesInfoRequired = false, ...props }) => {

    const { t } = useTranslation();
    const { language, trackEvent } = useContext(AppContext);
    const [path, setPath] = useState<Array<PathItem>>(props.path);
    const [selectedFiles, setSelectedFiles] = useState<Array<MeasurementListItemViewModel>>([]);
    const [navigateToFolderAfterAction, setNavigateToFolderAfterAction] = useState(true);
    const defaultCopiedFileName = props.operationType === 'copyFile' ? `${props.baseSelection[0].name}_Copy` : '';
    const [copiedFileName, setCopiedFileName] = useState<{ name: string; isValid: boolean }>({ name: defaultCopiedFileName, isValid: true });
    const sourceFolderId = props.path[props.path.length - 1].id!;
    const targetFolderId = path[path.length - 1].id;

    const alreadyExistsErrorMessage = t(
        'FileExplorerModalContainer.file_already_exist_desc',
        'Oh no, an item with the same name already exists in the destination location. Please rename or choose a different destination to proceed.');

    const { data, isLoading, isFetching, error: folderContentError } = useFolderContent(targetFolderId || '');

    const { mutateAsync: moveItems } = useMoveItems(sourceFolderId);

    const { mutateAsync: mutateCopyFile } = useCopyFile(props.operationType === 'copyFile' ? props.baseSelection[0].id : undefined);

    useEffect(() => {
        if (folderContentError) {
            toast.error(getErrorMsgForToast(folderContentError, t));
            setPath(path.slice(0, -1));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [folderContentError]);

    const handleSelectFiles = (selectedItem: MeasurementListItemViewModel) => {
        setSelectedFiles(prev => {
            const isItemInState = prev.find(item => item.id === selectedItem.id)!;
            return isItemInState
                ? prev.filter(item => item.id !== selectedItem.id)
                : [...prev, selectedItem];
        });
    };

    const handleSelectFolder = ({ id, name }: Folder) => {
        const selectedPath = { id, name };
        setSelectedFiles([]);
        setPath(prev => {
            const pathIndex = prev.findIndex(item => item.id === selectedPath.id);

            return pathIndex > 0
                ? prev.slice(0, pathIndex + 1)
                : [...prev, selectedPath];
        });
    };

    const handlePromiseWithToast = (promise: Promise<boolean>) => {
        void toast.promise(promise, {
            loading: props.operationType === 'copyFile'
                ? t('FileExplorerModalContainer.toast.copy_loading', 'Copy is being created ...')
                : t('FileExplorerModalContainer.toast.move_selection_loading_title', 'Your selection is being moved ...'),
            success: () => {
                if (navigateToFolderAfterAction) {
                    props.onChangePath?.(path);
                }
                if (props.operationType === 'copyFile') {
                    return t('FileExplorerModalContainer.toast.copy_success', 'Copy has been created successfully.');
                }
                return t('FileExplorerModalContainer.toast.move_selection_success_title', 'Your selection has been moved successfully.');
            },
            error: resp => getErrorMsgForToast(resp, t),
        });
    };
    const handleMove = () => {
        if (sourceFolderId === targetFolderId) return;

        if (props.baseSelection.some(item => item.isFolder === false)) {
            trackEvent(getMoveFileEvent());
        }
        if (props.baseSelection.some(item => item.isFolder === true)) {
            trackEvent(getMoveFolderEvent());
        }

        const pendingPromise = moveItems({
            targetFolderId: targetFolderId!,
            measurementIds: props.baseSelection
                .filter(item => item.isFolder === false)
                .map(item => item.id),
            folderIds: props.baseSelection
                .filter(item => item.isFolder === true)
                .map(item => item.id),
        });

        handlePromiseWithToast(pendingPromise);
    };

    const handleSubmitFileSelection = () => {
        trackEvent(getAddFilesToFilePickerEvent());

        props.onSelectAdditionalFiles!(new Map<string, NecessaryFileData>(
            selectedFiles.map((f: MeasurementListItemViewModel) =>
                [f.id, {
                    name: f.name,
                    conditions: f.conditionsArray,
                    version: f.version,
                    folderId: f.folderId,
                    isEditable: f.isEditable,
                }],
            )));
    };

    const handleCopyFile = () => {

        trackEvent(getCopyFileEvent());

        const pendingPromise = mutateCopyFile({ fileName: copiedFileName.name, targetFolderId: targetFolderId! });
        handlePromiseWithToast(pendingPromise);
    };

    const handleSubmit = () => {
        props.onClose?.();
        switch (props.operationType) {
            case 'addToFilePicker':
                handleSubmitFileSelection();
                break;
            case 'move':
                handleMove();
                break;
            case 'copyFile':
                handleCopyFile();
                break;
            default:
                break;
        }
    };

    const sortedFolders = data?.subfolders
        .sort(getSortByPropertyName('name', 'ASC', language));

    const sortedFiles = data?.measurements
        .sort(getSortByPropertyName('name', 'ASC', language));

    const hasValidationErrorWhenMovingItems = Boolean(
        props.operationType === 'move' &&
        sourceFolderId !== targetFolderId && (
            sortedFiles?.some(item => !!props.baseSelection.filter(item2 => item2.isFolder === false).find(item2 => item2.name === item.name)) ||
            sortedFolders?.some(item => !!props.baseSelection.filter(item2 => item2.isFolder === true).find(item2 => item2.name === item.name))
        ));

    const hasValidationErrorWhenCopyingFile =
        props.operationType === 'copyFile' &&
        copiedFileName.isValid === false;

    const isActionDisabled =
        isFetching || isLoading
        || targetFolderId === undefined // root folder
        || (props.operationType !== 'addToFilePicker' && Boolean(data?.isEditable) === false)
        || (props.operationType === 'addToFilePicker' && selectedFiles.length === 0)
        || (props.operationType === 'move' && targetFolderId === sourceFolderId)
        || hasValidationErrorWhenMovingItems
        || hasValidationErrorWhenCopyingFile;

    const getSubmitButtonLabel = () => {
        switch (props.operationType) {
            case 'addToFilePicker': return t('Common.ButtonLabels.open_btn_title', 'Open');
            case 'move':
                return t('Common.ButtonLabels.move_file_btn_title', 'Move');
            case 'copyFile': return t('FileOrFolderAction.button_text_copy', 'Copy');
        }
    };

    return (
        <ModalContainer>
            {props.operationType === 'copyFile' && (
                <Section>
                    <FileNameInput
                        allOtherFileNamesInFolder={sortedFiles
                            ? sortedFiles.map(file => file.name)
                            : []
                        }
                        onSubmit={handleSubmit}
                        initialFileName={defaultCopiedFileName}
                        alreadyExistsErrorMessage={alreadyExistsErrorMessage}
                        onNameChange={name => { setCopiedFileName(prev => ({ ...prev, name })); }}
                        onValidationResultChange={isValid => { setCopiedFileName(prev => ({ ...prev, isValid })); }}
                    />
                </Section>)}
            <Section>
                <Box className="px12_400_normal" pl="24px" pb="8px">
                    {props.sectionTitle}
                </Box>
                <Main>
                    <Flex flexDirection="column" gap="16px" pl="8px">
                        <BreadCrumb
                            path={path}
                            setPath={setPath}
                            isCompactView
                        />

                        {isSelectedFilesInfoRequired &&
                            <Info isSelectionActive={selectedFiles.length > 0} role="complementary">
                                <IconClose
                                    onClick={() => setSelectedFiles([])}
                                    aria-label={t('FileExplorerModalContainer.clear_selection_btn', 'clear selection')}
                                />
                                <span className="px12_400_normal">
                                    {selectedFiles.length} {t('Common.Suffixes.selected_title', 'selected')}
                                </span>
                            </Info>
                        }
                    </Flex>

                    {!folderContentError &&
                        <FileExplorer
                            operationType={props.operationType}
                            availableFiles={sortedFiles!}
                            availableFolders={sortedFolders!}
                            selectedIdsInModal={selectedFiles.map((item: MeasurementListItemViewModel | Folder) => item.id)}
                            unselectableFileIds={props.baseSelection.filter(m => !m.isFolder).map(m => m.id)}
                            unselectableFolderIds={props.baseSelection.filter(m => m.isFolder).map(m => m.id)}
                            onSelectFiles={handleSelectFiles}
                            onSelectFolder={handleSelectFolder}
                            isLoading={isLoading || isFetching}
                        />
                    }
                </Main>
            </Section>
            <Div isVisible={props.operationType !== 'addToFilePicker'}>
                <span className="px9_400_error" aria-label="file exist error">
                    { hasValidationErrorWhenMovingItems && alreadyExistsErrorMessage }
                </span>
                <Checkbox
                    label={t('FileExplorerModalContainer.navigate_to_folder_cb', 'Navigate to target folder afterwards')}
                    id="Navigate to folder"
                    checked={navigateToFolderAfterAction}
                    aria-selected={navigateToFolderAfterAction}
                    onChange={() => setNavigateToFolderAfterAction(!navigateToFolderAfterAction)}
                />
            </Div>
            <CTA>
                <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={isActionDisabled}
                >
                    {getSubmitButtonLabel()}
                </Button>
                <Button
                    variant="secondary"
                    onClick={() => { props.onClose?.(); }}
                >
                    {t('Common.ButtonLabels.cancelBtn_title', 'Cancel')}
                </Button>
            </CTA>
        </ModalContainer >
    );
};

const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    gap: 24px;
    width: 100%;
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
`;

const Main = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-top: 1px solid ${props => props.theme.colors.greyContrastSemiLower};
    max-height: 500px;
    height: 500px;
    padding: 16px 16px 0 16px;
`;

const Info = styled.div<{ isSelectionActive: boolean }>`
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
        width: 16px;
        height: 16px;
        color: ${props => props.isSelectionActive && props.theme.colors.greyContrastHighest};
        
        ${props => props.isSelectionActive && css` 
            &:hover {
                color:  ${prop => prop.theme.colors.textContrast};
                cursor: pointer;
            }
        `}
   }
`;

const Div = styled.div<{ isVisible: boolean }>`
    display: ${props => props.isVisible ? 'flex' : 'none'};
    flex-direction: column;
    justify-content: space-between;
    height: 62px;
    padding: 0 16px;
    align-items: start;
`;

const CTA = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;

    a {
        text-decoration: none;
    }
    & button {
        margin-left: 10px;
    }
`;

export default FileExplorerModalContainer;