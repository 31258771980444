import { Button, Select, toast } from '@gmg/gmg-react-components';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from 'src/AppContext';
import { useRescale } from 'src/graphql/customHooks/optimize/useRescale';
import { getRescaleEvent } from 'src/tracking';
import { getErrorMsgForToast } from '../toastErrorHandler';
import { ActionBtnContainer, FlyOutContent, InteractiveContent } from './style';

export interface RescaleProps {
    measurementId: string;
    measurementName: string;
    onStart: () => void;
    onFinish: (id: string | undefined) => void;
    unselectedFiles: Array<{id: string; name: string}>;
    folderId: string;
};

type SingleVal = { value: string; label: string };

const Rescale: FunctionComponent<RescaleProps> = props => {
    const { trackEvent } = useContext(AppContext);

    const { t } = useTranslation();
    const [dropDownSelection, setDropDownSelection] = useState<SingleVal | undefined>(undefined);

    const rescale = useRescale(props.measurementId, props.folderId);

    useEffect(() => {
        setDropDownSelection(undefined);
    }, [props.unselectedFiles, props.measurementId]);

    const dropDownOptions: Array<SingleVal> = props.unselectedFiles
        .map(item => ({
            value: item.id!,
            label: item.name,
        }));

    const handleRescaleButtonClick = () => {
        trackEvent(getRescaleEvent());

        props.onStart();

        const pendingPromise = rescale.mutateAsync(dropDownSelection!.value);

        void toast.promise(pendingPromise, {
            loading: t('ToolBar.Rescale.toast_loading_desc', 'We are rescaling the data...'),
            success: _ => {
                // invalidating the corresponding query (in useRemoveOutliers) refetches the measurement,
                // but that alone isn't enough. Repaint has to be triggered by removing the viewmodel
                props.onFinish(props.measurementId);

                return t('ToolBar.Rescale.toast_success_desc', {
                    defaultValue: 'We have successfully rescaled {{fileName}}',
                    fileName: props.measurementName,
                });
            },
            error: response => {
                props.onFinish(undefined);
                return getErrorMsgForToast(response, t);
            },
        });
    };

    return (
        <>
            <FlyOutContent>
                <label className="px10_400_weak">
                    {t('ToolBar.Rescale.content_desc', 'This tool recalculates the measurement data to the destination chart layout you selected. Missing patches will be interpolated.')}
                </label>
                <InteractiveContent>
                    <label className="px10_400_normal">
                        {t('ToolBar.Rescale.SelectTitle_title', 'Destination chart layout')}
                    </label>
                    <Select
                        name="Measurement selector"
                        placeholder={t('ToolBar.Rescale.SelectPlaceHolder_desc', 'Select a chart from opened files')}
                        value={dropDownSelection}
                        options={dropDownOptions}
                        onChange={(newValue) => setDropDownSelection(newValue as SingleVal)}
                        isSearchable={false}
                        width="100%"
                    />
                </InteractiveContent>
            </FlyOutContent>
            <ActionBtnContainer>
                <Button
                    variant="secondary"
                    size="small"
                    aria-label='Rescale button'
                    disabled={!dropDownSelection}
                    onClick={handleRescaleButtonClick}
                >
                    {t('Common.ButtonLabels.applyBtn_title', 'Apply')}
                </Button>
            </ActionBtnContainer>
        </>
    );
};

export default Rescale;