import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import AppContext from 'src/AppContext';
import { ColorCorrectionPayload, Illuminants, Observers, TableViewInput, UpdateLabInput } from '../../generated/types';

const mutation = `mutation UpdateLab($input: UpdateLabInput!) {
    updateLab(input: $input) {
        measurementId
        succeeded
    }
}`;

export const useLabCorrection = (measurementId: string, condition: string) => {
    const context = useContext(AppContext);
    const queryClient = useQueryClient();

    return useMutation(
        async (labList: Array<{ ink: string; a: number; b: number; l: number}>) => {
            await context.populateCacheForAppSynch?.();

            const input: UpdateLabInput = {
                lab: labList.map(item => ({
                    labKey: item.ink,
                    labValue: { l: item.l, a: item.a, b: item.b },
                })),
                measurementId,
                observer: context.measurementSettings.observer as Observers,
                illuminant: context.measurementSettings.illuminant as Illuminants,
                condition,
            };

            const response = await (context.api.graphql({
                query: mutation,
                variables: { input },
            }) as Promise<{
                data: {
                    updateMediaLab: ColorCorrectionPayload;
                };
            }>);

            return response.data;
        },
        {
            onSettled: async () => {
                await queryClient.invalidateQueries({
                    predicate: q =>
                        (q.queryKey[0] === 'getMeasurementTableView' && (q.queryKey[1] as TableViewInput).id === measurementId) ||
                        (q.queryKey[0] === 'getMeasurementHistory' && q.queryKey[1] === measurementId),
                    type: 'all', // also inactive
                });
            },
        },
    );
};