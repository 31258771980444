import { FunctionComponent, ReactNode, useEffect } from 'react';
import { useForm, useFormState, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FormElement from './FormElement';

export interface FileNameInputProps {
    allOtherFileNamesInFolder: Array<string>;
    onSubmit: (data: string) => void;
    initialFileName: string;
    alreadyExistsErrorMessage: string;
    // next two callbacks are necessary because FileExplorerModal (use case: copy file) needs information about name and if it's valid
    onNameChange?: (name: string) => void;
    onValidationResultChange?: (isValid: boolean) => void;
    children?: ReactNode;
};

const FileNameInput: FunctionComponent<FileNameInputProps> = props => {
    const { t } = useTranslation();
    const { register, handleSubmit, formState: { errors }, setFocus, trigger, control } = useForm<{ name: string }>({ mode: 'onBlur' });

    const watchedFileName = useWatch({ name: 'name', control });
    const { isValid } = useFormState({ control });

    useEffect(() => {
        if (watchedFileName === undefined) return;
        props.onNameChange?.(watchedFileName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchedFileName]);

    useEffect(() => {
        props.onValidationResultChange?.(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValid]);

    useEffect(() => {
        setFocus('name');
    }, [setFocus]);

    useEffect(() => {
        async function revalidate() {
            await trigger('name');
        }
        void revalidate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.allOtherFileNamesInFolder]);

    const registrationOptions = {
        required: t('Validation.forms.field_required_val', 'Uh oh, please fill out this field.'),
        maxLength: {
            value: 200,
            message: t('Validation.forms.maxLength_exceeded_val', {
                defaultValue: 'Oops, you have exceeded the limit of 200 characters.',
                maxCharacters: 200,
            }),
        },
        validate: {
            isValid: (newName: string) => !props.allOtherFileNamesInFolder.find(name => name === newName.trim())
                ? true
                : props.alreadyExistsErrorMessage,
        },
    };
    return (
        <Container>
            <form onSubmit={handleSubmit(data => { props.onSubmit(data.name.trim()); })}>
                <FormElement
                    label={t('RenameFileModal.input_label_title', 'File name')}
                    dataProperty='name'
                    defaultValue={props.initialFileName}
                    placeholder={t('RenameFileModal.input_placeholder_title', 'Enter a file name')}
                    registerElement={{
                        ...register('name', registrationOptions),
                    }}
                    validationError={errors?.name?.message}
                />
                {props.children}
            </form>
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    form {
        width: 100%;
    }
`;

export default FileNameInput;