import { Checkbox } from '@gmg/gmg-react-components';
import { useTranslation } from 'react-i18next';

export interface DoNotShowAgainCheckBoxProps {
    onClick?: (e?: any) => void;
    localStorageId: string;
};

export const isNotificationSkipped = (storageKey: string) => localStorage.getItem(storageKey) === 'true';

const DoNotShowAgainCheckBox = (props: DoNotShowAgainCheckBoxProps) => {
    const { t } = useTranslation();

    return (
        <Checkbox
            id={`dontShowAgainCheckbox for ${props.localStorageId}`}
            onClick={(e: any) => {
                localStorage.setItem(props.localStorageId, e.target.checked === true ? 'true' : 'false');
                props.onClick?.(e);
            }}
            label={t('Common.CheckBoxLabels.dontShowAgain_cb', "Don't show again")}
        />
    );
};

export default DoNotShowAgainCheckBox;