import { Button, Checkbox, InputTypeTextReduced, toast } from '@gmg/gmg-react-components';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from 'src/AppContext';
import { useCreateMeasurementWithAveraging } from 'src/graphql/customHooks/optimize/useCreateMeasurementWithAveraging';
import { MeasurementViewModel } from 'src/graphql/ViewModels';
import { getCalculateAverageEvent } from 'src/tracking';
import styled from 'styled-components';
import { getErrorMsgForToast } from '../toastErrorHandler';
import { ActionBtnContainer, FlyOutContent } from './style';

interface AveragingProps {
    measurements: Array<Pick<MeasurementViewModel, 'id' | 'name'>>;
    folderId: string;
    onStart: () => void;
    onFinish: (id: string | undefined, newMeasurementName?: string) => void;
}

const Averaging: FunctionComponent<AveragingProps> = props => {
    const { trackEvent } = useContext(AppContext);
    const { t } = useTranslation();
    const createMeasurementWithAveraging = useCreateMeasurementWithAveraging();

    const [isSmartAveragingEnabled, setIsSmartAveragingEnabled] = useState<boolean>(false);
    const [newFilename, setNewFilename] = useState('');

    useEffect(() => {
        setNewFilename(`${props.measurements[0].name}_averaged`); // if the selection in FilePicker has been changed the newFileName has to be updated
        setIsSmartAveragingEnabled(props.measurements.length >= 3);
    }, [props.measurements]);

    const handleClickAveragingButton = () => {
        props.onStart();

        const pendingPromise = createMeasurementWithAveraging.mutateAsync({
            sourceMeasurementIds: props.measurements.map(item => item.id),
            folderId: props.folderId,
            useSmartAveraging: isSmartAveragingEnabled,
            targetName: newFilename,
        });

        void toast.promise(pendingPromise, {
            loading: t('ToolBar.Averaging.toast_loading_desc', 'We are averaging the data...'),
            success: (data) => {
                props.onFinish(data.id!, data.name!);

                trackEvent(getCalculateAverageEvent(props.measurements.length, isSmartAveragingEnabled));

                return t('ToolBar.Averaging.toast_success_desc', {
                    defaultValue: 'We have successfully created and selected averaged {{fileName}}!',
                    fileName: data.name,
                });
            },
            error: response => {
                props.onFinish(undefined);
                return getErrorMsgForToast(response, t);
            },
        });
    };

    return (
        <>
            <FlyOutContent>
                <span className="px10_400_weak">
                    {t('ToolBar.Averaging.content_desc', 'This tool provides an effortless and easy option to average two or more files without any manual calculations required.')}
                </span>
                <InputContainer>
                    <span className="px10_400_weak">
                        {t('ToolBar.Averaging.new_filename_title', 'Name of the average file')}
                    </span>
                    <InputTypeTextReduced
                        width="100%"
                        error={!newFilename && t('ToolBar.Averaging.missing_filename_error_desc', 'Please enter a file name.')}
                        value={newFilename}
                        onChange={(e) => setNewFilename(e.target.value)}
                        aria-label={t('ToolBar.Averaging.input_label_title', 'Average filename input')}
                        placeholder={t('ToolBar.Averaging.input_placeholder_title', 'Please enter a filename')}
                    />
                </InputContainer>
                {props.measurements.length > 2 && (
                    <Checkbox
                        id='GmgSmartAverage'
                        defaultChecked={true}
                        label={t('ToolBar.Averaging.checkbox_title', 'Use GMG smart average option')}
                        onChange={(e) => setIsSmartAveragingEnabled(e.target.checked)}
                    />
                )}
            </FlyOutContent>
            <ActionBtnContainer>
                <Button
                    variant="secondary"
                    size="small"
                    onClick={handleClickAveragingButton}
                    aria-label="Averaging button"
                >
                    {t('Common.ButtonLabels.applyBtn_title', 'Apply')}
                </Button>
            </ActionBtnContainer>
        </>
    );
};

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    > span {
        margin-bottom: 4px;
    }
`;

export default Averaging;
