import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as FileSettingsIcon } from '../../assets/icon_file-settings-24.svg';

interface TeaserContextProps {
    onClickhandler: (event?: any) => void;
}

const MissingProcessParamsTeaser: FunctionComponent<TeaserContextProps> = (props) => {
    const { t } = useTranslation();

    return (
        <Main>
            <FileSettingsIcon width={22} height={22} />
            <Content>
                <span className="px12_600_normal">
                    {t('Teaser.MissingParams.teaser_title_title', 'There are missing parameters in your measurement data.')}
                </span>
                <span className="px10_400_normal">
                    {t('Teaser.MissingParams.content_main_desc',
                        'Some important process parameters of your selected measurement data are missing. Please provide these parameters to enable the full range of optimization tools.')}
                </span>
                <ActionLinkWrapper
                    className="px10_400_normal"
                    onClick={props.onClickhandler}
                    aria-label="Set parameters button"
                >
                    {t('Teaser.MissingParams.link_desc', 'Set parameters now')}
                </ActionLinkWrapper>
            </Content>
        </Main>
    );
};

const Main = styled.div`
    display: flex;
    margin: auto 0;
    gap: 16px;
    padding: 0px 24px 16px 8px;
`;

const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
`;

const ActionLinkWrapper = styled.span`
    margin-top: 16px;
    text-decoration: underline;
    cursor: pointer;
`;

export default MissingProcessParamsTeaser;