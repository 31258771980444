import { GmgTheme } from '@gmg/gmg-react-components';
import { MeasurementViewModel } from 'src/graphql/ViewModels';
import { Point } from '../Point';
import { IDraggablePoint } from '../tonalvalue/DraggablePoint';
import { EditedPoint } from './Dotgain';

interface Range {
    min: number;
    max: number;
}
export interface DotGainTableData {
    inks: Array<string>;
    content: Map<number, Map<string, {
        value: number;
        range: Range;
        isReadOnly: boolean;
    }>>;
    versionedId: string;
};

export interface DotGainGridData {
    versionedMeasurementId: string;
    inks: Array<{
        nameOfInk: string;
        hex: string;
        lineDefinition: string;
        editedLineDefinition: string | undefined;
        points: Array<IDraggablePoint['point']>;
    }>;
};

interface DotGainData {
    gridData: DotGainGridData;
    tableData: DotGainTableData;
}

export const getDotGainData = (source: {
    measurements: Array<MeasurementViewModel>;
    editedPoints: Array<EditedPoint>;
    diagramWidth: number;
    diagramHeight: number;
    yAxisOffset: number;
    selectedColors: Array<string>;
    theme: GmgTheme;
}): Array<DotGainData> => {

    const calculateSvgPos = (axis: 'x' | 'y', value: number) => {
        return axis === 'x'
            ? Math.round(value * source.diagramWidth / 100)
            : Math.round((source.diagramHeight / 10 * source.yAxisOffset) - value * source.diagramHeight / 50);
    };

    const createLineDefinition = (values: Array<{ x: number; y: number }>) => {
        return values.reduce((prev, { x, y }, i) =>
            `${prev} ${i === 0 ? 'M' : ''}${i === 1 ? 'L' : ''} ${calculateSvgPos('x', x)} ${calculateSvgPos('y', y)}`, '');
    };

    const result = source.measurements.map(viewModel => {

        const availableInks = Array.from(viewModel.inks.keys());

        const selectedColors = availableInks
            .filter(ink => (source.selectedColors).indexOf(ink) > -1);

        const createInitalMap = (): Iterable<[string, Array<Point>]> => availableInks
            .map(c => [c, [{
                x: 0,
                y: 0,
                hex: c !== 'Black'
                    ? viewModel.inks.get(c) || ''
                    : source.theme.colors.greyContrastMedium,
                id: `origin-${c}`,
            }]]);

        const calculatedAveragesPerInk = new Map<string, Array<Point>>(createInitalMap());
        const tableContent = new Map<number, Map<string, number>>();
        const avgPatchesPerMeasurement = viewModel.patches
            .filter(patch => patch.characteristic?.type === 'singleColor' && patch.isCalculatedAverage);

        avgPatchesPerMeasurement
            .forEach(patch => {
                const inkValue = patch.inkValues.find(item => item.value > 0)!;
                const points = calculatedAveragesPerInk.get(inkValue.name);
                if (points) {
                    points.push({
                        x: inkValue.value,
                        y: patch.value!.toneValue - inkValue.value,
                        hex: inkValue.name !== 'Black' ? viewModel.inks.get(inkValue.name) || '' : source.theme.colors.greyContrastMedium,
                        id: patch.id,
                    });
                }

                if (tableContent.get(inkValue.value) === undefined) {
                    tableContent.set(inkValue.value, new Map<string, number>());
                }
                const valuesPerInk = tableContent.get(inkValue.value)!;
                valuesPerInk.set(inkValue.name, patch.value!.toneValue - inkValue.value);

            });

        // set the 0% index for the table
        if (tableContent.get(0) === undefined) {
            tableContent.set(0, new Map<string, number>());

            availableInks.forEach(inkName => {
                tableContent.get(0)!.set(inkName, 0);
            });
        }

        const originalPoints = new Map(availableInks.map(inkName => {
            const avaragesPerInk = calculatedAveragesPerInk.get(inkName)!;
            const points = avaragesPerInk.map(({ id, x, y }) => {
                const isFixedPoint = x === 0 || x === 100;
                return {
                    id,
                    x,
                    y,
                    isDraggable: isFixedPoint
                        ? false
                        : !source.editedPoints.find(editedPoint => editedPoint.index === x && editedPoint.ink === inkName),
                };
            });

            return [inkName, points];
        }));

        const editedPoints = new Map(availableInks.map(inkName => {
            if (source.editedPoints.length === 0) {
                return [inkName, []];
            }
            const pointsPerInk = source.editedPoints.filter(point => point.ink === inkName);
            const points = pointsPerInk.map(point => ({
                id: `${point.index}-${inkName}-editedPoint`,
                x: point.index,
                y: point.percentage,
                isDraggable: true,
            }));

            return [inkName, points];
        }));

        const sortedDotGainIndexValuesWithEdits = new Map<string, Array<{ index: number; percentage: number }>>(
            availableInks.map(inkName => {
                const sortedAveragesPerInk = calculatedAveragesPerInk.get(inkName)!.sort((a, b) => a.x - b.x);
                const averagesWithEditsPerInk = sortedAveragesPerInk.map(({ x, y }) => {
                    const editedAverage = source.editedPoints
                        .find(editedPoint => editedPoint.index === x && editedPoint.ink === inkName);

                    return {
                        index: x,
                        percentage: !!editedAverage
                            ? editedAverage.percentage
                            : y,
                    };
                });

                return [inkName, averagesWithEditsPerInk];
            }));

        const dotGainIndexBoundaries = new Map<string, Map<number, Range>>(
            availableInks.map(inkName => {
                const sortedIndexValuesPerInk = sortedDotGainIndexValuesWithEdits.get(inkName)!;

                const indexValuesWithRange = new Map<number, Range>(sortedIndexValuesPerInk.map((currentDotGain, posInArray) => {
                    const prev = sortedIndexValuesPerInk[posInArray - 1];
                    const next = sortedIndexValuesPerInk[posInArray + 1];

                    const minValue = currentDotGain.index === 0
                        ? 0
                        : prev.index + prev.percentage - currentDotGain.index;

                    const maxValue = currentDotGain.index === 100
                        ? 0
                        : next.index + next.percentage - currentDotGain.index;

                    return [currentDotGain.index, { min: minValue, max: maxValue }];
                }));

                return [inkName, indexValuesWithRange];
            }));

        const tableContentWithRange = new Map(Array.from(tableContent.entries(), ([index, colorsToValueMap]) => {
            const valuesWithRange = new Map(Array.from(colorsToValueMap.entries(), ([inkName, value]) => {
                const range = dotGainIndexBoundaries.get(inkName)?.get(index)!;
                const isReadOnly = index === 0 || index === 100;

                return [inkName, { value, range, isReadOnly }];
            }));

            return [index, valuesWithRange];
        }));

        return {
            gridData: {
                versionedMeasurementId: viewModel.versionedId,
                inks: Array.from(viewModel.inks.entries())
                    .filter(([inkName, _]) => selectedColors.includes(inkName))
                    .map(([inkName, inkHex]) => {
                        const hex = inkName === 'Black' ? source.theme.colors.greyContrastMedium : inkHex;
                        return {
                            nameOfInk: inkName,
                            hex,
                            lineDefinition: createLineDefinition(calculatedAveragesPerInk.get(inkName)!),
                            editedLineDefinition: source.editedPoints.some(p => p.ink === inkName)
                                ? createLineDefinition(sortedDotGainIndexValuesWithEdits.get(inkName)!.map(dg => ({ x: dg.index, y: dg.percentage })))
                                : undefined,
                            points: [...originalPoints.get(inkName)!, ...editedPoints.get(inkName)!].map(p => ({
                                id: p.id,
                                inkName,
                                index: p.x,
                                value: p.y,
                                x: calculateSvgPos('x', p.x),
                                y: calculateSvgPos('y', p.y),
                                isDraggable: p.isDraggable,
                                range: dotGainIndexBoundaries.get(inkName)!.get(p.x)!,
                            })),
                        };
                    }),
            },
            tableData: {
                inks: availableInks,
                content: tableContentWithRange,
                versionedId: viewModel.versionedId,
            },
        };
    });

    return result;
};

export interface InkValue {
    inkName: string;
    value: string;
    hasChanged: boolean;
    range: {
        min: number;
        max: number;
    } | undefined;
    isReadOnly: boolean;
}

export interface DotGainTableRow {
    indexPercentage: number;
    measurements: Array<{
        versionedId: string;
        inkValues: Array<InkValue>;
    }>;
}

export const getFormattedAndSortedDotGainTableRows = (
    contents: Array<DotGainTableData>,
    editedPoints: Array<EditedPoint>,
    formatNumber: (num: number | null | undefined) => string | undefined,
): Array<DotGainTableRow> => {
    const sortedIndexes = Array.from(
        new Set(contents.map(m => Array.from(m.content.keys()))
            .flat()
            .sort((a: number, b: number) => a - b),
        ));

    const result = sortedIndexes.map(indexPercentage => {
        return {
            indexPercentage,
            measurements: contents.map(measurement => {
                const inksInRow = measurement.content.get(indexPercentage);
                let inkValues = [];

                if (inksInRow !== undefined) {
                    inkValues = measurement.inks.map(inkName => {


                        const editedIndex = editedPoints
                            .find(ink => ink.ink === inkName && ink.index === indexPercentage);
                        const ink = inksInRow.get(inkName);

                        return {
                            inkName,
                            value: editedIndex
                                ? editedIndex.percentageText
                                : formatNumber(ink?.value) ?? '-',
                            hasChanged: !!editedIndex,
                            range: ink?.range,
                            isReadOnly: ink?.isReadOnly ?? true,
                        };
                    });

                } else {
                    inkValues = measurement.inks
                        .map(inkName => ({
                            inkName,
                            value: '-',
                            hasChanged: false,
                            isReadOnly: true,
                            range: undefined,
                        }));
                }

                return {
                    versionedId: measurement.versionedId,
                    inkValues,
                };
            }),
        };
    });

    return result;
};