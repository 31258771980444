import { IconErrorCircle } from '@gmg/gmg-react-components';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import DoNotShowAgainCheckBox, { isNotificationSkipped } from 'src/shared/DoNotShowAgainCheckBox';
import styled from 'styled-components';

interface StandardCharacterizationNotificationProps {};

const id = 'isStandardCharacterizationNotificationSkipped';
export const isStandardCharacterizationNotificationSkipped = () => isNotificationSkipped(id);

const StandardCharacterizationNotification: FunctionComponent<StandardCharacterizationNotificationProps> = (props) => {
    const { t } = useTranslation();

    return (
        <Main>
            <IconErrorCircle width={22} height={22} />
            <Content>
                <span className="px12_600_normal">
                    {t('Teaser.StandardCharacterization.teaser_title_title', 'Customize standard characterizations with editable copies')}
                </span>
                <span className="px10_400_normal">
                    {t('Teaser.StandardCharacterization.content_main_desc',
                        'Please note that standard characterizations cannot be edited. However, you can create an editable copy of them to tailor the characterization to your specific needs.')}
                </span>
                <DoNotShowAgainCheckBox
                    localStorageId={id}
                />
            </Content>
        </Main>
    );
};

const Main = styled.div`
    display: flex;
    margin: auto 0;
    gap: 16px;
    padding: 0px 24px 16px 8px;
`;

const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;

    button {
        margin-top: 16px;
    }
`;

export default StandardCharacterizationNotification;