import { formatDate } from 'src/shared/format';
import { MeasurementMeta } from '../generated/types';
import { MeasurementVersionViewModel, VersionReason } from '../ViewModels';

export const mapToVersionViewModel = (source: Array<MeasurementMeta>): Array<MeasurementVersionViewModel> => {

    return source.map<MeasurementVersionViewModel>(item => {
        return {
            versionedId: `${item.id}-#${item.versionInformation.version}`,
            version: item.versionInformation.version,
            timestamp: formatDate(new Date(item.versionInformation.timestamp!)),
            reason: item.versionInformation.reason as VersionReason,
            authorName: (!!item.versionInformation.author.firstName && !!item.versionInformation.author.lastName)
                ? `${item.versionInformation.author.firstName} ${item.versionInformation.author.lastName}`
                : item.versionInformation.author.email
                    ? item.versionInformation.author.email
                    : 'Unknown',
            params: item.versionInformation.params?.some(p => p.key === 'creationType')
                ? [
                    ...item.versionInformation.params?.filter(p => p.key === 'creationType'),
                    ...item.versionInformation.params?.filter(p => p.key !== 'creationType'),
                ]
                : item.versionInformation.params ?? [],
            isLatestVersion: item.versionInformation.version === Math.max(...source.map(e => e.versionInformation.version!)),
            isInitialVersion: item.versionInformation.version === 0,
        };
    })
        .sort((item1, item2) => item2.version - item1.version);
};