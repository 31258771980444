import { Button, toast } from '@gmg/gmg-react-components';
import { FunctionComponent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from 'src/AppContext';
import { useRemoveOutliers } from 'src/graphql/customHooks/optimize/useRemoveOutliers';
import { getRemoveOutliersEvent } from 'src/tracking';
import { getErrorMsgForToast } from '../toastErrorHandler';
import { ActionBtnContainer, FlyOutContent } from './style';
interface RemoveOutlierProps {
    measurementId: string;
    measurementName: string;
    folderId: string;
    onStart: () => void;
    onFinish: (id: string | undefined) => void;
}

const RemoveOutliers: FunctionComponent<RemoveOutlierProps> = props => {
    const { trackEvent } = useContext(AppContext);

    const { t } = useTranslation();
    const removeOutliers = useRemoveOutliers(props.measurementId, props.folderId);

    const handleClickRemoveOutlierButton = () => {
        trackEvent(getRemoveOutliersEvent());

        props.onStart();

        const pendingPromise = removeOutliers.mutateAsync();

        void toast.promise(pendingPromise, {
            loading: t('ToolBar.RemoveOutlier.toast_loading_desc', 'We are removing the outliers...'),
            success: _ => {
                // invalidating the corresponding query (in useRemoveOutliers) refetches the measurement,
                // but that alone isn't enough. Repaint has to be triggered by removing the viewmodel
                props.onFinish(props.measurementId);

                return t('ToolBar.RemoveOutlier.toast_success_desc', {
                    defaultValue: 'We have successfully removed outliers from {{fileName}}',
                    fileName: props.measurementName,
                });
            },
            error: response => {
                props.onFinish(undefined);
                return getErrorMsgForToast(response, t);
            },
        });
    };

    return (
        <>
            <FlyOutContent>
                <span className="px10_400_weak">
                    {t('ToolBar.RemoveOutlier.description_desc', 'This tool detects and corrects outliers of your file automatically.')}
                </span>
            </FlyOutContent>
            <ActionBtnContainer>
                <Button
                    variant="secondary"
                    size="small"
                    onClick={handleClickRemoveOutlierButton}
                    aria-label='Remove Outlier'
                >
                    {t('Common.ButtonLabels.applyBtn_title', 'Apply')}
                </Button>
            </ActionBtnContainer>
        </>
    );
};

export default RemoveOutliers;
