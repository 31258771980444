import { directionStatus } from '@gmg/gmg-react-components';

export const getSortedList = (arr: Array<any>, criteria: string, direction: directionStatus) => {

    // throw out potentional doubled criteria
    const props = Array.from(new Set([criteria, 'page', 'rowIndex', 'columnIndex']));

    // Split the data
    const listOfNonEmptyValues = arr.filter(obj => obj[criteria] !== undefined);
    const listOfEmptyValues = arr.filter(obj => obj[criteria] === undefined);

    const comparatorGenerator = (directionParam: directionStatus, ...properties: Array<any>) => {

        return (objectA: any, objectB: any): number => {
            const a: number = objectA[properties[0]];
            const b: number = objectB[properties[0]];
            if (a < b) return directionParam === 'ASC' ? -1 : 1;
            if (a > b) return directionParam === 'ASC' ? 1 : -1;
            if (properties.length > 1) return comparatorGenerator('ASC', ...properties.slice(1))(objectA, objectB);
            return 0;
        };
    };

    const sortedList = listOfNonEmptyValues.sort(comparatorGenerator(direction, ...props));

    return [...sortedList, ...listOfEmptyValues];
};