import styled from 'styled-components';

// generic table styles currently used only for the Explorer component
export const Tr = styled.tr<{ isSelected?: boolean }>`
    &:last-child td{
        border-bottom: 1px solid ${props => props.theme.colors.greyContrastMediumLower};
    }
    
    a {
      text-decoration: none;
    }
    .invisibleSVG svg {
        opacity: 0;
    }

    &:nth-child(odd) {
        background-color: ${props => props.theme.colors.greyContrastLow};
    }

    &:hover {
        background-color: ${props => props.theme.colors.greyContrastLowest};
        
        .invisibleSVG svg {
            opacity: 1;
        }
    }
`;

export const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 131px);
    min-width: 100% ;
    max-width: calc(100vw - 72px);
    background-color: ${props => props.theme.colors.backgroundPaper};
    &::-webkit-scrollbar-track {
        /* has to be set either here or direclty in the component*/
    }
`;

export const Table = styled.table`
    border-spacing: 0;
    width: 100%;
    table-layout: fixed;

    thead {
        position: sticky;
        /* definition of the top prop has to be done individually in the consumer file */
        /* top: 65px; */

        th {
            height: 44px;
            background-color: ${props => props.theme.colors.backgroundPaper};
            text-align: left;
            padding-left: 20px;   /* 'cos sortColumnButtons have an addtional 6px padding */
            padding-right: 15px;  /*  needed for the sort button icon */
        }
    }
    
    td {
        padding-left: 20px;
        line-height: 1.8rem;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
        height: 44px;  
    }

    & td:first-child {
        border-left: 1px solid transparent;
    }

    & td:last-child {
        padding-right: 20px;
        border-right: 1px solid transparent;
    }
`;
