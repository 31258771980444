import { IconHistory } from '@gmg/gmg-react-components';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ToolBarItem from '../optimize/ToolBarItem';
import styled from 'styled-components';

export interface HistoryToolBarItemProps {
    onHistoryBtnClick: () => void;
    isDisabled: boolean;
    isHistoryDisplayed: boolean;
};

const HistoryToolBarItem: FunctionComponent<HistoryToolBarItemProps> = props => {
    const { t } = useTranslation();
    const buttonLabel = props.isHistoryDisplayed
        ? t('History.close_history_btn', 'Close history')
        : t('KebabMenu.show_history_btn', 'Show history');

    return (
        <Container>
            <ToolBarItem
                icon={<IconHistory />}
                label={buttonLabel}
                isLabelRequired={false}
                isActive={props.isHistoryDisplayed}
                onClick={() => {
                    if (!props.isDisabled) {
                        props.onHistoryBtnClick();
                    }
                }}
                isDisabled={props.isDisabled}
            />
        </Container>
    );
};
const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.3);
    padding: 4px;
    background-color: ${props => props.theme.colors.backgroundPaper};
    border-radius:  ${props => props.theme.radii.default}px;
    margin:  0 0 6px 2px;
`;

export default HistoryToolBarItem;