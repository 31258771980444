import { AppFrame, Button, IconInspectFill, IconInspectOutline, IconMeasureFill, IconMeasureOutline } from '@gmg/gmg-react-components';
import { FunctionComponent, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AppContext, { Language, LanguageArray } from '../AppContext';
import { AppProps } from './App';
import ErrorMsg from './ErrorMsg';
import ExternalAppRequiredModal, { isExternalAppRequiredHintSkipped } from './ExternalAppRequiredModal';
import Inspect from './Inspect';

const Frame: FunctionComponent<AppProps> = props => {
    const context = useContext(AppContext);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleNavigateToDesktopApp = async () => {

        const callDesktop = () => {
            window.location.href = `com.gmgcolor.mio://?u=${context.user!.guid}&t=${context.user!.refreshToken}&e=${props.environmentName}`;
        };

        if (isExternalAppRequiredHintSkipped()) {
            callDesktop();
        } else {
            context.onShowModal('infoModal', {
                title: t('ExternalAppRequiredModal.modal_title', 'GMG Measure desktop app required'),
                content: <ExternalAppRequiredModal />,
                onOkClick: callDesktop,
                btnOkCaption: t('Frame.btnOkCaption_title', 'Continue'),
            });
        }
    };

    const availableLanguages = useMemo(
        () => new Map(LanguageArray.map(
            (key: string) => [key, t(`PanelSettings.lang_${key}` as any) as string]),
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [context.language],
    );

    const appName = context.isUnlimitedLicenseAvailable
        ? t('AppFrame.appName_premium_title', 'GMG Optimize')
        : t('AppFrame.appName_basic_title', 'Optimize Basic');

    const ctaButton = !context.isUnlimitedLicenseAvailable
        ? <Button variant='primary' size='small'>{t('AppFrame.premium_upgrade_btn_title', 'Get premium upgrade')}</Button>
        : undefined;

    return (
        <>
            <AppFrame
                navItems={[
                    {
                        title: t('Frame.navItem1_title', 'Measure'),
                        iconOutline: <IconMeasureOutline />,
                        iconFill: c => <IconMeasureFill color={c} />,
                        onSelect: handleNavigateToDesktopApp,
                    },
                    {
                        title: t('Frame.navItem2_title', 'Optimize'),
                        iconOutline: <IconInspectOutline />,
                        iconFill: c => <IconInspectFill color={c} />,
                        onSelect: () => { navigate('/inspect'); },
                        isSelected: true,
                    },

                ]}
                userName={context.user?.name}
                userEmail={context.user?.email}
                signOut={props.signOut}
                labels={
                    {
                        menu: {
                            preferences: t('PanelSettings.preferences_btn', 'Preferences'),
                            logout: t('PanelSettings.logout_btn', 'Logout'),
                            manageOrganization: t('PanelSettings.manageOrg_btn', 'Manage organization'),
                        },
                        preferences: {
                            version: t('Preferences.version', 'Version'),
                            language: t('Preferences.language', 'Language'),
                            mode: t('Preferences.mode', 'Mode'),
                            dark: t('Preferences.dark', 'Dark'),
                            light: t('Preferences.light', 'Light'),
                        },
                    }
                }
                modal={props.modal}
                activeTheme={props.theme}
                onChangeTheme={props.onChangeTheme}
                availableLanguages={availableLanguages}
                activeLanguage={context.language.toString()}
                onChangeLanguage={(language: string) => props.onChangeLanguage(language as Language)}
                commitId={(window as any)._mioCommitId}
                appName={appName}
                ctaButton={ctaButton}
            >
                {props.errorType
                    ? <ErrorMsg errorType={props.errorType} />

                    : <Inspect onChangeMeasurementParams={props.onChangeMeasurementParams} />
                }
            </AppFrame>

        </>

    );
};

export default Frame;
