import { DeltaE } from '@gmg/gmg-colorsmath';
import { GmgTheme, toast } from '@gmg/gmg-react-components';
import { FunctionComponent, MutableRefObject, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from 'src/AppContext';
import UnsavedChangesModal from 'src/components/optimize/UnsavedChangesModal';
import { getErrorMsgForToast } from 'src/components/toastErrorHandler';
import { MeasurementViewModel } from 'src/graphql/ViewModels';
import { useDensityCorrection } from 'src/graphql/customHooks/colorCollection/useDensityCorrection';
import { useLabCorrection } from 'src/graphql/customHooks/colorCollection/useLabCorrection';
import useFormatter from 'src/shared/useFormatter';
import { useUnSavedChangesmodal } from 'src/shared/useUnSavedChangesModal';
import { getEditDensitiesEvent, getEditMediaLabEvent, getEditSolidsLabEvent } from 'src/tracking';
import styled, { useTheme } from 'styled-components';
import { ColorCorrection } from '../VisualizationContainer';
import ActionBar from './ActionBar';
import Legend, { Editables } from './Legend';
import { getSortedRowsForSpiderTable } from './calculationsForSpider';

export interface EditedInk {
    inkName: string;
    values: Editables;
}

export interface LegendContainerProps {
    offset: number;
    viewModels: Array<MeasurementViewModel>;
    activeColorCorrection: ColorCorrection;
    condition: string;
    onHoverMeasurement: (versionedId?: string) => void;
    onCloseColorCorrection: (correctedMeasurementId?: string) => void;
    onStartColorCorrection: () => void;
    diagramContainerRef: MutableRefObject<any>;
    isHistoryMode: boolean;
    hasReference: boolean;
};

const LegendContainer: FunctionComponent<LegendContainerProps> = props => {
    const { t } = useTranslation();
    const theme: GmgTheme = useTheme();
    const { formatNumber } = useFormatter();
    const { measurementSettings, trackEvent } = useContext(AppContext);
    const [editedInks, setEditedInks] = useState<Array<EditedInk>>([]);
    const updateLabMutation = useLabCorrection(props.viewModels[0].id, props.condition);
    const updateDensitiesMutation = useDensityCorrection(props.viewModels[0].id, props.condition);

    const sortedRows = getSortedRowsForSpiderTable({
        measurements: props.viewModels,
        editedInksState: editedInks,
        deltaCalculator: measurementSettings.deltaE === 'DE76' ? DeltaE.deltaE76 : DeltaE.deltaE00,
        formatNumber,
        theme,
    });

    const handleClickReset = () => {
        setEditedInks([]);
    };

    const handleClickClose = () => {
        handleClickReset();
        props.onCloseColorCorrection();
    };

    const handleClickApply = async () => {
        switch (props.activeColorCorrection?.mode) {
            case 'media': trackEvent(getEditMediaLabEvent()); break;
            case 'solids': trackEvent(getEditSolidsLabEvent()); break;
            case 'density': trackEvent(getEditDensitiesEvent()); break;
            default: throw new Error('activeColorCorrection unSupported');
        }

        const pendingPromise = props.activeColorCorrection?.mode === 'density'
            ? updateDensitiesMutation.mutateAsync(editedInks.map(item => ({
                ink: item.inkName,
                density: parseFloat(item.values.d.value.replace(',', '.')),
            })))
            : updateLabMutation.mutateAsync(editedInks.map(item => ({
                ink: item.inkName,
                l: parseFloat(item.values.l.value.replace(',', '.')),
                a: parseFloat(item.values.a.value.replace(',', '.')),
                b: parseFloat(item.values.b.value.replace(',', '.')),
            })));
        props.onStartColorCorrection();

        void toast.promise(pendingPromise as Promise<any>, {
            loading: t('Common.Toast.applying_correction', 'Your corrections are being applied ...'),
            success: () => {
                setEditedInks([]);
                props.onCloseColorCorrection(props.viewModels[0].id);
                return t('Common.Toast.success_saved_changes', 'Your changes have been saved successfully.');
            },
            error: (response: any) => {
                setEditedInks([]);
                props.onCloseColorCorrection(undefined);
                return getErrorMsgForToast(response, t);
            },
        });
    };

    const handleChangeColorCorrections = (changedInk: string, values: Editables) => {
        setEditedInks(prev => {
            return [
                ...prev.filter(item => item.inkName !== changedInk),
                { inkName: changedInk, values },
            ];
        });
    };

    useUnSavedChangesmodal(<UnsavedChangesModal />, handleClickApply, props.diagramContainerRef, editedInks.length === 0);

    const isActionBarVisible =
        props.activeColorCorrection !== undefined &&
        props.activeColorCorrection.isLoading === false;

    return (
        <GridContainer>
            {isActionBarVisible &&
                <DIV>
                    <ActionBar
                        onResetClick={handleClickReset}
                        onCloseClick={handleClickClose}
                        onApplyClick={handleClickApply}
                        haveInksBeenEdited={editedInks.length > 0}
                    />
                </DIV>
            }
            <Legend
                measurements={props.viewModels.map(vm => ({
                    versionedId: vm.versionedId,
                    name: props.isHistoryMode ? vm.versionLabel : vm.name,
                }))}
                onHoverMeasurement={props.onHoverMeasurement}
                offset={props.offset}
                data={sortedRows}
                showDeltas={props.viewModels.length === 2}
                activeColorCorrection={props.activeColorCorrection}
                onChangeInkValues={handleChangeColorCorrections}
                hasReference={props.hasReference}
            />
        </GridContainer>
    );
};
const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 48px 1fr;
`;

const DIV = styled.div`
    display: flex;
    align-items: center;
    justify-content: right;
    margin-right: -4px; 
`;

export default LegendContainer;