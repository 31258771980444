import { toast } from '@gmg/gmg-react-components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import AppContext from 'src/AppContext';
import { AutoOptimizeInput, AutoOptimizePayload, CompleteAutoOptimizePayload, TableViewInput } from 'src/graphql/generated/types';
import { MeasurementViewModel } from 'src/graphql/ViewModels';

const autoOptimizeMutation = `mutation AutoOptimize($input: AutoOptimizeInput!) {
    autoOptimize(input: $input) {
        measurementIds
        measurementName
        transactionId
    }
  }`;

const onCompleteAutoOptimize = `subscription onCompleteAutoOptimize($transactionId: String!) {
	onCompleteAutoOptimize(transactionId: $transactionId) {
        transactionId
        measurementName
        measurementId
        succeeded
	  }
  }`;

interface AutoOptimizeMutationParams {
    measurements: Array<Pick<MeasurementViewModel, 'id' | 'name'>>;
    targetName: string | undefined;
    loadingToastId: string;
}
const handleSubscriptionError = (loadingToastId: string, error?: any) => { // we have to change when error handling is defined
    toast.dismiss(loadingToastId);
    toast.error('Auto optimization was not successful'); // TODO: text is requested from Ch. Endres
};


export const useAutoOptimization = (folderId: string, onFinish: (id: string | undefined, newMeasurementName?: string) => void) => {
    const { t } = useTranslation();
    const context = useContext(AppContext);
    const queryClient = useQueryClient();
    const { pathname } = useLocation();
    const isUserInCompareView = pathname !== '/inspect';

    return useMutation(
        async (params: AutoOptimizeMutationParams) => {
            await context.populateCacheForAppSynch?.();

            const transactionId = Date.now().toString();

            const qraphqlObserver = context.api.graphql({
                query: onCompleteAutoOptimize,
                variables: { transactionId },
            }) as any; // TODO how is this handled in amplify V6 ?

            const subscription = qraphqlObserver.subscribe({
                next: async (payload: any) => {
                    const response = payload.data.onCompleteAutoOptimize as CompleteAutoOptimizePayload;

                    if (response.succeeded) {
                        toast.dismiss(params.loadingToastId);

                        if (params.measurements.length < 2) {
                            await queryClient.invalidateQueries({
                                predicate: q =>
                                    (q.queryKey[0] === 'getMeasurementTableView' && (q.queryKey[1] as TableViewInput).id === response.measurementId && (q.queryKey[1] as TableViewInput).version === undefined) ||
                                    (q.queryKey[0] === 'getMeasurementHistory' && q.queryKey[1] === response.measurementId) ||
                                    (q.queryKey[0] === 'folderContent' && q.queryKey[1] === folderId),

                                type: 'all', // also inactive
                            });

                            onFinish(response.measurementId!);

                            toast.success(t('AutoOptimizeToasts.singleFile_success_desc', {
                                defaultValue: 'We have successfully optimized {{fileName}}',
                                fileName: params.measurements[0].name,
                            }));
                        } else {
                            await queryClient.invalidateQueries(['folderContent', folderId]);

                            if (isUserInCompareView) {
                                onFinish(response.measurementId!, response.measurementName!);
                            }

                            toast.success(t('AutoOptimizeToasts.multiFile_success_desc', {
                                defaultValue: 'We have successfully created and selected auto-optimized {{fileName}}!',
                                fileName: response.measurementName,
                            }));
                        }
                    } else {
                        handleSubscriptionError(params.loadingToastId);
                    }

                    subscription.unsubscribe();
                },
                error: (error: any) => {
                    onFinish(undefined);
                    handleSubscriptionError(params.loadingToastId, error);
                    subscription.unsubscribe();
                },
            });

            const input: AutoOptimizeInput = {
                measurementIds: params.measurements.map(item => item.id),
                transactionId,
                measurementName: params.targetName,
            };

            const response = await (context.api.graphql({ query: autoOptimizeMutation, variables: { input } }) as Promise<{
                data: {
                    autoOptimize: AutoOptimizePayload;
                };
            }>);

            return response;
        },
    );
};