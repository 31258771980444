import { MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { Fragment, FunctionComponent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from 'src/AppContext';
import { draggablePoint } from 'src/shared/DraggablePointModule';
import { getHighlightedColor } from 'src/shared/getHighlightedColor';
import useFormatter from 'src/shared/useFormatter';
import { getTonalValueAutoCorrectionEvent } from 'src/tracking';
import styled, { useTheme } from 'styled-components';
import DraggablePoint, { IDraggablePoint } from './DraggablePoint';

export interface TonalValueGridProps {
    diagramSize: number;
    scalingFactor: number;
    data: Array<{
        versionedMeasurementId: string;
        hex: string;
        lineDefinition: {
            original: string;
            edited: string | undefined;
        };
        points: Array<IDraggablePoint['point']>;
    }>;
    highlightedMeasurement?: string | undefined;
    isDuplicatesVisible: boolean;
    onChangeTonalValue: (index: string, value: string) => void;
    isColorCorrectionActive: boolean;
};

const TonalValueGrid: FunctionComponent<TonalValueGridProps> = (props) => {
    const { t } = useTranslation();
    const { trackEvent } = useContext(AppContext);
    const { formatNumber } = useFormatter();
    const theme = useTheme();
    const sensors = useSensors(useSensor(MouseSensor));// https://github.com/clauderic/dnd-kit/issues/208
    const axisItems = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    const { DndContext } = draggablePoint();

    return (
        <DndContext
            sensors={sensors}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
            onDragEnd={(e) => {
                const differenceAfterDrop = (e.delta.y * -1) / props.diagramSize * 100;
                const updatedTonalValue = e.active.data.current.currentValue + differenceAfterDrop;
                const increasedValueMoreThanMax = differenceAfterDrop > 0 && updatedTonalValue > e.active.data.current.range.max;
                const decreasedValueLessthanMin = differenceAfterDrop < 0 && updatedTonalValue < e.active.data.current.range.min;
                let newValue = updatedTonalValue;

                if (increasedValueMoreThanMax) {
                    newValue = e.active.data.current.range.max;
                    trackEvent(getTonalValueAutoCorrectionEvent('diagram'));
                }
                if (decreasedValueLessthanMin) {
                    newValue = e.active.data.current.range.min;
                    trackEvent(getTonalValueAutoCorrectionEvent('diagram'));
                }

                props.onChangeTonalValue(formatNumber(e.active.data.current.index)!, formatNumber(newValue)!);
            }}

        >
            <Grid width={props.diagramSize}>
                {[...Array(100)].map((_, index) => <div key={`c${index}`} />)}
                {
                    axisItems.map((item, index) => (
                        <YAxisItem
                            key={`y${index}`}
                            className="px10_400_weak"
                            index={index}
                            width={props.diagramSize}
                        >
                            {item}
                        </YAxisItem>
                    ))
                }
                {
                    axisItems.map((item, index) => (
                        <XAxisItem
                            key={`x${index}`}
                            className="px10_400_weak"
                            index={index}
                            length={item.toString().length}
                            width={props.diagramSize}
                        >
                            {item}
                        </XAxisItem>
                    ))
                }

                <XaxisCaption className="px10_400_weak">{t('TonalValue.xAxis_title', 'Ink Value')} (%)</XaxisCaption>
                <YaxisCaption className="px10_400_weak">{t('TonalValue.yAxis_title', 'Tonal Value')} (%)</YaxisCaption>

                <Svg version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <line className="axis" x1="0" y1="100%" x2="100%" y2="100%" />
                    <line className="axis" x1="0" y1="0" x2="0" y2="100%" />
                    {props.data.map(m =>
                        <Fragment key={m.versionedMeasurementId}>
                            {m.lineDefinition.edited !== undefined &&
                                <path
                                    key={`${m.hex}-edited-solid`}
                                    stroke={m.hex}
                                    strokeWidth={1 * props.scalingFactor}
                                    fill="none"
                                    d={m.lineDefinition.edited}
                                />
                            }
                            <path
                                key={`${m.hex}-original-solid`}
                                stroke={props.highlightedMeasurement === m.versionedMeasurementId
                                    ? getHighlightedColor(m.hex, theme)
                                    : m.hex
                                }
                                strokeWidth={(props.highlightedMeasurement === m.versionedMeasurementId ? 1.4 : 1) * props.scalingFactor}
                                fill="none"
                                d={m.lineDefinition.original}
                                strokeDasharray={m.lineDefinition.edited ? 4 : 0}
                            />

                            {props.isDuplicatesVisible && (
                                m.points.map(point => (
                                    <DraggablePoint
                                        key={point.id}
                                        point={point}
                                        scalingFactor={props.scalingFactor}
                                        hex={m.hex}
                                        isColorCorrectionActive={props.isColorCorrectionActive}
                                    />
                                ))
                            )}
                        </Fragment>,
                    )}
                </Svg>
            </Grid>
        </DndContext>
    );
};

const Grid = styled.div<{ width: number }>`
    display: grid;
    position: relative;
    grid-template-columns: repeat(10, ${props => props.width / 10}px);
    grid-auto-rows: ${props => props.width / 10}px;
    border-right: solid 1px ${props => props.theme.colors.greyContrastSemiLower};
    border-bottom: solid 1px ${props => props.theme.colors.greyContrastSemiLower};
    margin: 14px 48px 50px 28px;
    cursor: default;
    
    div {
        height: ${props => props.width / 10 - 1}px;
        border-top: solid 1px ${props => props.theme.colors.greyContrastSemiLower};
        border-left: solid 1px ${props => props.theme.colors.greyContrastSemiLower};
    }

    span {
        position: absolute;
    }
`;
const YAxisItem = styled.span.attrs<{ index: number; width: number }>(props => ({
    style: {
        bottom: `${props.index * props.width / 10 - 7}px`,
    },
})) <{ index: number; width: number }>`
    right: ${props => props.width + 6}px;
`;

const XAxisItem = styled.span.attrs<{ index: number; length: number; width: number }>(props => ({
    style: {
        left: `${props.index * props.width / 10 - 2 - props.length * 2}px`,
    },
})) <{ index: number; length: number; width: number }>`
    bottom: -22px;
`;

const XaxisCaption = styled.span`
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
`;

const YaxisCaption = styled.span`
    top: 50%;
    transform:  rotate(-90deg);
    left: -70px;
`;

const Svg = styled.svg`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: visible;

    line.axis {
        stroke: ${props => props.theme.colors.greyContrastMedium};
        stroke-width: 1;
    }
`;

export default TonalValueGrid;