import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Amplify } from 'aws-amplify';
import { extend } from 'colord';
import mixPlugin from 'colord/plugins/mix';
import { createBrowserHistory } from 'history';
import { enableMapSet } from 'immer';
import Plausible from 'plausible-tracker';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { TrackJS } from 'trackjs';
import { IConfig, getConfig } from './Config';
import AppContainer from './components/AppContainer';
import './i18n';

// immerjs configuration
enableMapSet();
// colord plugin registration
extend([mixPlugin]);

// Set up the browser history with the updated location (minus the # sign)
// see https://stackoverflow.com/questions/51218979/react-router-doesnt-work-in-aws-s3-bucket which refers to https://via.studio/journal/hosting-a-reactjs-app-with-routing-on-aws-s3
// We check to see if the url is in #!/something format
const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) {
    // If it is, we grab the /something portion of the url and use the browser history API to replace the url.
    createBrowserHistory().replace(path);
}

const container = document.getElementById('root')!;

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity, // data will never be considered stale
            retry: false,
        },
    },
});
void fetch('/config.json')
    .then(async (response) => {

        const json = await response.json();
        const configuration: IConfig = getConfig(json);

        Amplify.configure(configuration.amplify);

        if (configuration.trackJs) {
            TrackJS.install(configuration.trackJs);
        }

        const plausible = Plausible({
            domain: configuration.tracking.domain,
            trackLocalhost: true,
        });
        plausible.enableAutoPageviews();

        // give cypress the info if feature is enabled or not without having to use the DOM
        // sessionStorage.setItem('disableHistory', configuration.featureFlags.disableHistory.toString());

        const root = createRoot(container);
        root.render(
            <React.StrictMode>
                <QueryClientProvider client={queryClient}>
                    <AppContainer
                        config={configuration}
                        trackEvent={!configuration.tracking.enabled
                            ? () => { }
                            : event => plausible.trackEvent(event.name, {
                                props: {
                                    ...event.additionalProperties ?? {},
                                },
                            })}
                    />
                    {/* <ReactQueryDevtools initialIsOpen={false} position='bottom-left' /> */}
                </QueryClientProvider>
            </React.StrictMode>,
        );
    });

//
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
