import { Tooltip } from '@gmg/gmg-react-components';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

export interface ToolBarItemProps {
    icon: JSX.Element;
    label: string;
    isActive: boolean;
    isDisabled?: boolean;
    isLabelRequired: boolean; // is the name good enough ?
    onClick: (e: any) => void;
    isHidden?: boolean;
};

const ToolBarItem: FunctionComponent<ToolBarItemProps> = props => {

    return (
        <Container isHidden={props.isHidden}>
            <Tooltip
                message={props.label}
                disabled={props.isLabelRequired}
            >
                <Item
                    isActive={props.isActive}
                    disabled={props.isDisabled}
                    onClick={(e) => {
                        e.stopPropagation();
                        !props.isDisabled && props.onClick(e);
                    }}
                    className={props.isDisabled ? 'px10_400_weak' : 'px10_400_normal'}
                    aria-label={props.label}
                    aria-disabled={props.isDisabled}
                >
                    <IconWrapper>
                        {props.icon}
                    </IconWrapper>
                    {props.isLabelRequired && <span role='menuitem' >{props.label}</span>}
                </Item>
            </Tooltip>
        </Container>
    );
};

export const Container = styled.div<{isHidden?: boolean}>`
    display: ${props => props.isHidden ? 'none' : 'block'};
    position: relative;
    cursor: pointer;
    border-radius:  ${props => props.theme.radii.default}px;
`;

export const Item = styled.div<{ isActive: boolean; disabled?: boolean }>`
    display: flex;
    align-items: center;
    height: 32px;
    border-radius:  ${props => props.theme.radii.default}px;
    border: 1px solid ${props => props.isActive && !props.disabled
        ? props.theme.colors.highlight
        : 'transparent'};
    background-color: ${props => props.isActive && !props.disabled
        ? props.theme.colors.greyContrastLowest
        : props.theme.colors.backgroundPaper};
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    
    &:hover {
        background-color: ${props => props.disabled ? props.theme.colors.backgroundPaper : props.theme.colors.greyContrastLowest};
        svg {
            color: ${props => props.disabled ? props.theme.colors.textWeak : props.theme.colors.textContrast};
        }
    }
    span {
        padding-right: 12px;
    }
`;

export const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;

    svg {
        width: 16px;
        height: 16px;
    }
`;

export default ToolBarItem;