import { Box, Flex, GmgTheme, Toggle, Tooltip } from '@gmg/gmg-react-components';
import { FunctionComponent, MutableRefObject, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { diagramFactorCalculator } from 'src/shared/diagramFactorCalculator';
import styled, { useTheme } from 'styled-components';
import { useImmer } from 'use-immer';
import { ReactComponent as IconAverage } from '../../../assets/icon_average.svg';
import { ReactComponent as IconConnectors } from '../../../assets/icon_spider-web.svg';
import { ReactComponent as IconSpider } from '../../../assets/icons_spider-grid.svg';
import { ColorCorrection, Measurements } from '../VisualizationContainer';
import ColorSelector from './ColorSelector';
import LegendContainer from './LegendContainer';
import SpiderGrid from './SpiderGrid';
import { calculateDataForSpiderGrid, getColorsByCharacteristics } from './calculationsForSpider';

interface SpiderProps {
    measurementContext: Measurements;
    activeColorCorrection: ColorCorrection;
    onHighlightMeasurement: (versionedId?: string) => void;
    onResetViewModel: (id: string) => void;
    onCloseColorCorrection: (correctedMeasurementId?: string) => void;
    onStartColorCorrection: () => void;
    diagramContainerRef: MutableRefObject<any>;
    isHistoryMode: boolean;
}

export interface ColorItem {
    name: string;
    sortIndex: number;
    isOverprint: boolean;
    isSelected: boolean;
}

const Spider: FunctionComponent<SpiderProps> = props => {
    const theme: GmgTheme = useTheme();
    const { t } = useTranslation();

    const [isGradientCircleVisible, setIsGradientCircleVisible] = useState<boolean>(true);
    const [isDuplicatesVisible, setIsDuplicatesVisible] = useState<boolean>(true);
    const [isConnectorsVisible, setIsConnectorsVisible] = useState<boolean | undefined>();
    const [colors, setColors] = useImmer<Array<ColorItem>>([]);
    const scalingFactor = useMemo(() => diagramFactorCalculator(360), []);

    useEffect(() => {
        // color selector handling in case measurements change:
        // keep the selection if colors match exactly
        const characteristicsByColor = getColorsByCharacteristics(props.measurementContext.viewmodels);

        // check for length detects cases where new measurement has a subset of the colors of the old one.
        // this would not be detected by the logic below.
        let isColorPaletteDifferent = characteristicsByColor.length !== colors.length;

        const newColorState = characteristicsByColor.map(characteristic => {
            // if color already was available, just take it over.
            const existingColor = colors.find(c => c.name === characteristic.colorName);
            if (existingColor) {
                return existingColor;
            } else {
                isColorPaletteDifferent = true;
                return {
                    isOverprint: characteristic.type === 'symmetricOverprint',
                    name: characteristic.colorName,
                    sortIndex: characteristic.sortIndex,
                    isSelected: true,
                };
            }
        });

        if (isColorPaletteDifferent) {
            setColors(newColorState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.measurementContext.viewmodels]);

    useEffect(() => {
        // handle implicite effects of color changes
        if (!colors.length) {
            return;
        }

        // At the beginning, show spider web if any of the measurements has overprints.
        if (isConnectorsVisible === undefined) {
            setIsConnectorsVisible(!!colors.find(color => color.isOverprint));
            return;
        }

        // we never enable spider web implicitely
        if (isConnectorsVisible === false) {
            return;
        }

        // If we have no data for overprints any more(removed last measurment with overprints), we hide the spider web
        if (!colors.find(color => color.isOverprint)) {
            setIsConnectorsVisible(false);
            return;
        }

        // If user deselected required inks (C|M|Y|Overprints) we hide the spider web.
        if (!colors.find(c => c.name === 'Yellow')?.isSelected ||
            !colors.find(c => c.name === 'Cyan')?.isSelected ||
            !colors.find(c => c.name === 'Magenta')?.isSelected ||
            !colors.find(c => c.isOverprint)?.isSelected
        ) {
            setIsConnectorsVisible(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [colors]);

    if (!props.measurementContext.viewmodels.length) return null;

    const handleToggleConnectorsVisibility = () => {
        // no overprints => button stays disabled
        if (!colors.find(color => color.isOverprint)) {
            return;
        }

        // If user activates spider web we force activation of for web plotting required inks (C|M|Y|Overprints)
        if (!isConnectorsVisible) {
            const colorsToActivate = ['Yellow', 'Cyan', 'Magenta', 'Red (M + Y)', 'Green (C + Y)', 'Blue (C + M)'];

            setColors(colorState => {
                colorState.forEach(c => {
                    if (colorsToActivate.includes(c.name) && !c.isSelected) {
                        c.isSelected = true;
                    }
                });
            });
        }

        setIsConnectorsVisible(!isConnectorsVisible);
    };

    const spiderGridData = calculateDataForSpiderGrid(props.measurementContext.viewmodels, colors, isDuplicatesVisible, isConnectorsVisible, theme);

    return (
        <Flex flexDirection="column" alignItems="flex-start" mt="6px">
            <ColorSelector
                colors={colors}
                onChangeSelection={setColors}
            />
            <Flex>
                <GridContainer>
                    <Flex pl="28px" gap="8px" alignItems="end">
                        <Box>
                            <Toggle
                                onClick={() => { setIsGradientCircleVisible(!isGradientCircleVisible); }}
                                isOn={isGradientCircleVisible}
                                aria-pressed={isGradientCircleVisible}
                                aria-label={'Toggle gradient'}
                            >
                                <Tooltip message={isGradientCircleVisible
                                    ? t('Common.ToggleBtnLabels.icon_hideLab_tip', 'Hide L*a*b* circle')
                                    : t('Common.ToggleBtnLabels.icon_showLab_tip', 'Show L*a*b* circle')}
                                >
                                    <IconSpider />
                                </Tooltip>
                            </Toggle>
                        </Box>
                        <Box>
                            <Toggle
                                onClick={handleToggleConnectorsVisibility}
                                isOn={!!isConnectorsVisible}
                                aria-pressed={!!isConnectorsVisible}
                                aria-label={'Toggle spider web'}
                            >
                                <Tooltip message={isConnectorsVisible
                                    ? t('Common.ToggleBtnLabels.icon_hideconnectors_tip', 'Hide spider web')
                                    : t('Common.ToggleBtnLabels.icon_showconnectors_tip', 'Show spider web')}
                                >
                                    <IconConnectors />
                                </Tooltip>
                            </Toggle>
                        </Box>
                        <Box>
                            <Toggle
                                onClick={() => { setIsDuplicatesVisible(!isDuplicatesVisible); }}
                                isOn={isDuplicatesVisible}
                                aria-pressed={isDuplicatesVisible}
                                aria-label={'Toggle view'}
                            >
                                <Tooltip message={isDuplicatesVisible
                                    ? t('Common.ToggleBtnLabels.icon_hidefulcrums_tip', 'Hide fulcrums')
                                    : t('Common.ToggleBtnLabels.icon_showfulcrums_tip', 'Show fulcrums')}
                                >
                                    <IconAverage />
                                </Tooltip>
                            </Toggle>
                        </Box>
                    </Flex>
                    <SpiderGrid
                        data={spiderGridData}
                        highlightedMeasurement={props.measurementContext.highlightedId}
                        isDuplicatesVisible={isDuplicatesVisible}
                        isGradientCircleVisible={isGradientCircleVisible}
                        scalingFactor={scalingFactor}
                    />

                </GridContainer>
                <LegendContainer
                    diagramContainerRef={props.diagramContainerRef}
                    viewModels={props.measurementContext.viewmodels}
                    condition={props.measurementContext.condition}
                    hasReference={ props.isHistoryMode ? false : props.measurementContext.hasReference}
                    isHistoryMode={props.isHistoryMode}
                    onHoverMeasurement={props.onHighlightMeasurement}
                    activeColorCorrection={props.activeColorCorrection}
                    onCloseColorCorrection={props.onCloseColorCorrection}
                    onStartColorCorrection={props.onStartColorCorrection}
                    offset={(400 * scalingFactor) + 611} /* calc all elements: Appframe 72, SiderBar 320, padding 2, Frame 2x12, 76px distance from the Frame on left + on the right 40 + 77 spiderGrid margins + SpiderGrid */
                />
            </Flex>
        </Flex >
    );
};

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 48px 1fr;
`;

export default Spider;