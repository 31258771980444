import { Tooltip } from '@gmg/gmg-react-components';
import { FunctionComponent } from 'react';
import { useOutsideClick } from 'src/shared/useOutsideClick';
import styled from 'styled-components';
import FlyOutMenu from './FlyOutMenu';
import { Container, IconWrapper, Item, ToolBarItemProps } from './ToolBarItem';

export interface ToolBarItemWithFlyoutMenuProps extends ToolBarItemProps {
    onRootClose: () => void;
    children: React.ReactNode;
};

const ToolBarItemWithFlyoutMenu: FunctionComponent<ToolBarItemWithFlyoutMenuProps> = props => {

    const ref = useOutsideClick(props.onRootClose, !props.isActive);

    return (
        <Container isHidden={props.isHidden}>
            <Tooltip
                message={props.label}
                disabled={props.isLabelRequired}
            >
                <Item
                    isActive={props.isActive}
                    disabled={props.isDisabled}
                    onClick={(e) => {
                        e.stopPropagation();
                        !props.isDisabled && props.onClick(e);
                    }}
                    className={props.isDisabled ? 'px10_400_weak' : 'px10_400_normal'}
                    aria-label={props.label}
                    aria-disabled={props.isDisabled}
                >
                    <IconWrapper>
                        {props.icon}
                    </IconWrapper>
                    {props.isLabelRequired && <span role='menuitem' >{props.label}</span>}
                </Item>
            </Tooltip>
            <MenuContainer isOpen={props.isActive} ref={ref}>
                <FlyOutMenu headline={props.label}>
                    {props.children}
                </FlyOutMenu>
            </MenuContainer>
        </Container>
    );
};

const MenuContainer = styled.div<{ isOpen: boolean }>`
    position: absolute;
    z-index: -1;
    top: 0;
    left: 100%;
    width: 240px;
    display: ${props => props.isOpen ? 'block' : 'none'};
    transform: ${props => props.isOpen ? 'translateX(8px) translateY(0px)' : 'translateX(-100%)'};
`;

export default ToolBarItemWithFlyoutMenu;