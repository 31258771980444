import { useContext } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import AppContext from 'src/AppContext';
import { CreateAveragedMeasurementInput, MeasurementOptimizationPayload } from '../../generated/types';


const mutation = `mutation createAveragedMeasurement($input: CreateAveragedMeasurementInput!) {
    createAveragedMeasurement(input: $input) {
      measurementId
      measurementName
    }
  }`;

interface AveragingParams {
    sourceMeasurementIds: Array<string>;
    folderId: string;
    useSmartAveraging: boolean;
    targetName: string;
}

export const useCreateMeasurementWithAveraging = () => {
    const context = useContext(AppContext);
    const queryClient = useQueryClient();

    return useMutation(
        async (params: AveragingParams) => {
            await context.populateCacheForAppSynch?.();

            const input: CreateAveragedMeasurementInput = {
                measurementIds: params.sourceMeasurementIds,
                useSmartAveraging: params.useSmartAveraging,
                targetName: params.targetName,
            };

            const response = await (context.api.graphql({
                query: mutation,
                variables: { input },
            }) as Promise<{
                data: {
                    createAveragedMeasurement: MeasurementOptimizationPayload;
                };
            }>);

            return {
                id: response.data.createAveragedMeasurement.measurementId,
                name: response.data.createAveragedMeasurement.measurementName,
            };
        },
        {
            onSettled: async (_, __, variables) => {
                await queryClient.invalidateQueries(['folderContent', variables.folderId]);
            },
        },
    );
};