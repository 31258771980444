import { toast } from '@gmg/gmg-react-components';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateFolder } from 'src/graphql/customHooks/useCreateFolder';
import { useEditFolder } from 'src/graphql/customHooks/useEditFolder';
import { getErrorMsgForToast } from '../toastErrorHandler';
import CreateOrRenameFolderModal from './CreateOrRenameFolderModal';

export interface CreateOrRenameFolderModelContainerProps {
    parentFolderId: string;
    siblingFolderNames: Array<string>;
    folderId?: string;
    currentName?: string;
    onClose?: () => void;
};

const CreateOrRenameFolderModalContainer: FunctionComponent<CreateOrRenameFolderModelContainerProps> = props => {
    const { t } = useTranslation();

    const createFolderMutation = useCreateFolder(props.parentFolderId);
    const renameFolderMutation = useEditFolder(props.parentFolderId);

    const useCase = props.folderId
        ? 'rename'
        : 'add';

    const handleSubmit = async (name: string) => {

        const pendingPromise = useCase === 'rename'
            ? renameFolderMutation.mutateAsync({
                id: props.folderId!,
                name,
            })
            : createFolderMutation.mutateAsync(name);

        const action = useCase === 'rename'
            ? t('CreateOrRenameFolderModalContainer.renameAction', 'renamed')
            : t('CreateOrRenameFolderModalContainer.createAction', 'created');

        void toast.promise(pendingPromise, {
            loading: t(
                'CreateOrRenameFolderModalContainer.toast_loading_desc',
                '...', { action }),
            success: t(
                'CreateOrRenameFolderModalContainer.toast_success_desc',
                'Your folder has been successfully created/renamed.', { action }),
            error: response => getErrorMsgForToast(response, t),
        });

        props.onClose?.();
    };

    return (
        <CreateOrRenameFolderModal
            parentFolderId={props.folderId!} /* root folders cannot be created, i.e. we always have a parent folder id */
            onSubmit={handleSubmit}
            siblingFolderNames={props.siblingFolderNames}
            useCase={useCase}
            onClose={props.onClose!}
            currentName={props.currentName}
        />);
};

export default CreateOrRenameFolderModalContainer;