import { InputTypeTextReduced, Box } from '@gmg/gmg-react-components';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

interface FormElementProps {
    variant?: 'smallLeft' | 'smallRight';
    dataProperty: string;
    placeholder: string;
    registerElement: object;
    label?: string;
    validationError?: string;
    error?: string;
    defaultValue?: string;
    // HTML list attribute, used for pre-defined options
    list?: string;
}

interface ContainerProps {
    variant?: 'smallLeft' | 'smallRight';
}

const FormElement: FunctionComponent<FormElementProps> = props => (
    <Box width={props.variant ? '50%' : '100%'} >
        <LabelContainer variant={props.variant}>
            <StyledLabel
                className="px10_600_normal"
                htmlFor={props.dataProperty}>
                {props.label}
            </StyledLabel>
        </LabelContainer>
        <TextBoxContainer variant={props.variant}>
            <InputTypeTextReduced
                {...props.registerElement}
                width={props.variant ? '50%' : '100%'}
                placeholder={props.placeholder}
                id={props.dataProperty}
                list={props.list}
                error={props.validationError}
                defaultValue={props.defaultValue}
            />
        </TextBoxContainer>
    </Box>);

const LabelContainer = styled.div<ContainerProps>`
    box-sizing: border-box;
    border-bottom: 1px solid ${props => props.theme.colors.greyContrastSemiLower};
    padding-top: 0;
    padding-right: ${({ variant }) =>
        variant === 'smallLeft' ? '16px' : '24px'};
    padding-bottom: 4px;
    padding-left:  ${({ variant }) =>
        variant === 'smallRight' ? '16px' : '24px'};
`;

const StyledLabel = styled.label`
    box-sizing: border-box;
    height: 20px;
`;

const TextBoxContainer = styled.div<ContainerProps>`
    box-sizing: border-box;
    margin-bottom: 24px;
    height: 36px;
    padding-top: 8px;
    padding-right: ${({ variant }) =>
        variant === 'smallLeft' ? '16px' : '24px'};
    padding-bottom:0;
    padding-left: ${({ variant }) =>
        variant === 'smallRight' ? '16px' : '24px'};
`;

export default FormElement;