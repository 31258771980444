import { Flex } from '@gmg/gmg-react-components';
import styled from 'styled-components';


export const Page = styled(Flex)`
    break-after: always;
    break-inside: avoid;
    -webkit-break-after: always;
    -webkit-break-inside: avoid;
    width: 220mm;
    height: 310mm;
    min-height: 310mm;
    background-color: white;

    &:last-child {
        break-after: auto;   
        -webkit-break-after: auto;   
    }
`;
export const Divider = styled.div`
    width: 100%;
    height: 1px;
    margin: 40px 0;
    box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.3);
    
    @media print{
       display: none;
    }
`;

export const InfoGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 33%);
    grid-column-gap: 16px;
    grid-template-rows: auto;
`;