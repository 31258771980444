import { DeltaE } from '@gmg/gmg-colorsmath';
import { directionStatus } from '@gmg/gmg-react-components';
import objectHash from 'object-hash';
import { FunctionComponent, useContext, useState } from 'react';
import AppContext from 'src/AppContext';
import useFormatter from 'src/shared/useFormatter';
import styled from 'styled-components';
import { MeasurementViewModel } from '../../../graphql/ViewModels';
import Header from './Header';
import RowVirtualizer from './RowVirtualizer';
import { getPropertyNameFromMeasurement } from './createPropertyName';
import { getSortedList } from './getSortedList';

export interface SortingOrder {
    direction: directionStatus;
    propertyName: string;
}
export interface TableProps {
    measurements: Array<MeasurementViewModel>;
    condition: string;
    isHistoryMode: boolean;
    hasReference: boolean;
}

interface PatchData {
    hash: string;
    inkValues: Record<string, number | undefined>;
    versionedMeasurementId: string;
    measurementName: string;
    page: number;
    rowIndex: number | undefined;
    row: string;
    columnIndex: number | undefined;
    column: string;
    lab: Array<number | undefined>;
    hex: string;
}

export interface TableRowData extends Record<string, any> { //  Record is used to type dynamic properties
    key: string;
    page: number;
    row: string;
    column: string;
    columnIndex: number;
    rowIndex: number;
    deltaE: number | undefined;
    dL: number | undefined;
    da: number | undefined;
    db: number | undefined;
}

const deltaLab = (lab1: [number, number, number], lab2: [number, number, number]): [number, number, number] => [
    lab2[0] - lab1[0],
    lab2[1] - lab1[1],
    lab2[2] - lab1[2],
];

const Table: FunctionComponent<TableProps> = ({ measurements, condition, isHistoryMode, hasReference }) => {
    const { formatNumber } = useFormatter();

    const deltaESettings = useContext(AppContext).measurementSettings.deltaE;

    const calculateForDeltaE = deltaESettings === 'DE76'
        ? DeltaE.deltaE76
        : DeltaE.deltaE00;

    const showDeltaValues = measurements.length === 2;

    const [sortingStatus, setSortingStatus] = useState<SortingOrder>({ propertyName: 'page', direction: 'ASC' });

    if (!measurements.length) return null;

    const onSortBtnClick = (btnLabel: string) => setSortingStatus(
        {
            direction: sortingStatus.direction === 'ASC' ? 'DESC' : 'ASC',
            propertyName: btnLabel,
        },
    );

    // The hex values can be different for each measurement.
    // However, this is not important as the hex value is used only for a visual effect.
    // So the first hex value will be taken for each ink.
    const mergedInksFromMeasurements = new Map(measurements
        .map(measurement => Array.from((measurement.inks)))
        .flat());

    const measurementNames = new Map(measurements.map(measurement => [
        measurement.versionedId,
        isHistoryMode ? measurement.versionLabel : measurement.name,
    ]));

    const listOfAllPatches = measurements.map(measurement =>
        measurement.patches.map(singlePatch => {

            // filter out all 0 ink values as they are not relevant
            const nonZeroInkValues = Object.fromEntries(
                singlePatch.inkValues
                    .filter(ink => ink.value !== 0)
                    .map(inkObj => [inkObj.name, inkObj.value]),
            );

            const getInkValues = () => {
                const inksValueObj: PatchData['inkValues'] = {};

                Array.from(mergedInksFromMeasurements.keys())
                    .forEach(inkName => {
                        inksValueObj[inkName] = singlePatch.inkValues.find(
                            // if there is no value for an ink, the ink is not printed, so the
                            // actual ink value is 0
                            ink => ink.name === inkName)?.value ?? 0;
                    });

                return inksValueObj;
            };

            const patch: PatchData = {
                // create hash from nonzero ink values as a unique id for this particular ink combination
                // The Table view should be independent from the ink sequence. Therefore, the Object properties will be ordered before hashing.
                hash: objectHash(nonZeroInkValues, { algorithm: 'md5', unorderedObjects: true }),
                inkValues: getInkValues(),
                versionedMeasurementId: measurement.versionedId,
                measurementName: isHistoryMode ? measurement.versionLabel : measurement.name,
                page: singlePatch.pageIndex! + 1,
                rowIndex: singlePatch.rowIndex,
                row: singlePatch.rowLabel,
                columnIndex: singlePatch.columnIndex,
                column: singlePatch.columnLabel,
                lab: singlePatch.value.lab,
                hex: singlePatch.value.hex,
            };

            return patch;
        }),
    ).flat();

    const getRowsByHash = (hashValue: string) => listOfAllPatches.filter(item => item.hash === hashValue);

    const tableViewModel = Array.from(new Set(listOfAllPatches.map(patch => patch.hash)))
        .map(hash => {

            const rowsByHash: Array<PatchData> = getRowsByHash(hash);

            const mergedRow: TableRowData = {
                key: hash,
                // page, row, and column are not shown if multiple measurements are selected.
                // The ink values are identical if the hash is identical.
                // Therefore, it is sufficient to just take the values from the first measurement.
                page: rowsByHash[0].page,
                column: rowsByHash[0].column,
                columnIndex: rowsByHash[0].columnIndex!,
                row: rowsByHash[0].row,
                rowIndex: rowsByHash[0].rowIndex!,
                ...rowsByHash[0].inkValues,
                deltaE: undefined,
                dL: undefined,
                da: undefined,
                db: undefined,
            };

            const labValuesForDeltaCalculation: Array<[number, number, number]> = [];

            // generate dynamic properties for each measurement
            measurements.forEach(m => {
                const lValue = rowsByHash.find(row => row.versionedMeasurementId === m.versionedId)?.lab[0];
                const aValue = rowsByHash.find(row => row.versionedMeasurementId === m.versionedId)?.lab[1];
                const bValue = rowsByHash.find(row => row.versionedMeasurementId === m.versionedId)?.lab[2];

                // get lab values for delta E calculation
                // It is not sufficient to check the length of the labValues array
                // as there might be 3 or more measurements, but only 2 Lab values from those measurements.
                if (showDeltaValues && lValue && aValue && bValue) {
                    labValuesForDeltaCalculation.push([lValue, aValue, bValue]);
                }

                mergedRow[getPropertyNameFromMeasurement('l', m.versionedId)] = lValue;
                mergedRow[getPropertyNameFromMeasurement('a', m.versionedId)] = aValue;
                mergedRow[getPropertyNameFromMeasurement('b', m.versionedId)] = bValue;
                mergedRow[getPropertyNameFromMeasurement('hex', m.versionedId)] = rowsByHash.find(row => row.versionedMeasurementId === m.id)?.hex;
            });

            if (labValuesForDeltaCalculation.length === 2) {
                mergedRow.deltaE = calculateForDeltaE(labValuesForDeltaCalculation[0], labValuesForDeltaCalculation[1]);
                const [dL, da, db] = deltaLab(labValuesForDeltaCalculation[0], labValuesForDeltaCalculation[1]);
                mergedRow.dL = dL;
                mergedRow.da = da;
                mergedRow.db = db;
            }

            return mergedRow;
        });

    const sortedTableViewModels = getSortedList(tableViewModel, sortingStatus.propertyName, sortingStatus.direction);

    let deltaEAverage: string = '';
    let deltaEMax: string = '';
    if (showDeltaValues) {
        const nonUndefinedDeltaEList: Array<number> = tableViewModel
            .filter(row => row.deltaE !== undefined)
            .map(row => row.deltaE!);
        const max = Math.max(...nonUndefinedDeltaEList);
        const average = nonUndefinedDeltaEList.reduce((prev, curr) => prev + curr, 0) / nonUndefinedDeltaEList.length;

        deltaEMax = formatNumber(max)!;
        deltaEAverage = formatNumber(average)!;
    }

    return (
        <DIV className='horizontal-scrollbar'>
            <Header
                measurementNames={measurementNames}
                inks={Array.from(mergedInksFromMeasurements.keys())}
                onSortBtnClick={onSortBtnClick}
                sortingStatus={sortingStatus}
                deltaEAverage={deltaEAverage}
                deltaEMax={deltaEMax}
                hasReference={hasReference}
            />
            <RowVirtualizer
                rows={sortedTableViewModels}
                condition={condition}
                inks={mergedInksFromMeasurements}
                measurementIds={Array.from(measurementNames.keys())}
                formatNumber={formatNumber} // performance! we pass this because otherwise useFormatter would be called thousands of times inside RowVirtualizer
            />
        </DIV>
    );
};

const DIV = styled.div`
/* appFrame 72 + sideBar 320 + 3gap + BorderedBox 24 + 4 padding + 32 Toolbar => 455 + 60 ( 30 px from both side)*/
    max-width: calc(100vw - 515px);
    cursor: default;
    padding-top: 20px;
`;

export default Table;


