import { ModalType, ThemeType } from '@gmg/gmg-react-components';
import { FunctionComponent } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Language, MeasurementSettings } from 'src/AppContext';
import Frame from './Frame';
import Report from './report/Report';
import { ApplicationError } from './ErrorMsg';

export interface AppProps {
    errorType: undefined | ApplicationError;
    modal?: ModalType;
    signOut: () => void;
    theme: ThemeType;
    onChangeTheme: (theme: ThemeType) => void;
    onChangeLanguage: (language: Language) => void;
    onChangeMeasurementParams: (key: keyof MeasurementSettings, value: string) => void;
    environmentName: string;
};


const App: FunctionComponent<AppProps> = props => (
    <BrowserRouter>
        <Routes>
            <Route path='/report' element={<Report />} />

            <Route path='/' element={<Navigate to="/inspect" replace />} /> {/* note that server side redirection recommended instead, see https://github.com/remix-run/react-router/blob/main/docs/upgrading/v5.md#remove-redirects-inside-switch */}
            <Route path='/inspect/*' element={<Frame {...props} />} />

            <Route path="*" element={<div style={{ color: 'white' }}>No match. TODO: UI for this</div>} />
        </Routes>
    </BrowserRouter>
);

export default App;
