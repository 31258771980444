import { ButtonGroup, Flex, Spinner, notificationToast, toast } from '@gmg/gmg-react-components';
import { FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { MeasurementViewModel } from 'src/graphql/ViewModels';
import { MeasurementRequest, useMeasurement } from 'src/graphql/customHooks/useMeasurement';
import styled from 'styled-components';
import AppContext, { MeasurementSettings } from '../../AppContext';
import { FileAction, NecessaryFileData } from '../Inspect';
import Main from '../Main';
import GetPremiumUpgradeTeaser from '../notifications/GetPremiumUpgradeTeaser';
import MissingProcessParamsTeaser from '../notifications/MissingProcessParamsTeaser';
import ToolBar from '../optimize/ToolBar';
import ProcessParamsModalContainer from '../optimize/processParams/ProcessParamsModalContainer';
import FilePicker from './FilePicker';
import HistoryToolbarItem from './HistoryToolbarItem';
import SecondaryNav from './SecondaryNav';
import Chart from './chart/Chart';
import Dotgain from './dotgain/Dotgain';
import Spider from './spider/Spider';
import Table from './table/Table';
import TonalValue from './tonalvalue/TonalValue';
import { PathItem } from '../measurements/BreadCrumb';
import { getFileHistoryClickEvent } from 'src/tracking';

export interface VisualizationContainerProps {
    files: Map<string, NecessaryFileData>;
    onFileAction: (action: FileAction) => void;
    onChangeMeasurementParams: (type: keyof MeasurementSettings, value: string) => void;
    path: Array<PathItem>;
}

export type Variant = 'chart' | 'diagram';
export type ColorCorrectionMode = 'media' | 'solids' | 'density' | 'tonalValue' | 'dotgain';
export type ColorCorrection = {
    mode: ColorCorrectionMode;
    isLoading: boolean;
    measurementId: string;
} | undefined;

interface MarkedFileInFilePickerState {
    id: string;
    requestedVersion: number | undefined;
    isLatestVersion: boolean;
    viewModel: MeasurementViewModel | undefined;
    toBeFetched: boolean; // marks that a measurements has to be initially fetched or re-fetched. Set to false when measurement has been loaded
};

export interface Measurements {
    viewmodels: MeasurementViewModel[];
    condition: string;
    hasReference: boolean;
    highlightedId: string | undefined;
}

export interface ReportData {
    selectedCondition: string;
    items: Array<{
        id: string;
        name: string;
        isSelected: boolean;
        isReference: boolean;
    }>;
}

const VisualizationContainer: FunctionComponent<VisualizationContainerProps> = props => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [selectedColorCorrection, setSelectedColorCorrection] = useState<ColorCorrection>();
    const [referenceMeasurementId, setReferenceMeasurementId] = useState<string | undefined>(undefined);
    const { measurementSettings, featureFlags, isUnlimitedLicenseAvailable, onShowModal, trackEvent } = useContext(AppContext);
    const diagramContainerRef = useRef(null);
    const fileIds = Array.from(props.files.keys());

    const defaultState: MarkedFileInFilePickerState = {
        id: fileIds[0],
        requestedVersion: undefined, // undefined means latest
        isLatestVersion: true,
        viewModel: undefined,
        toBeFetched: true,
    };
    const [markedFilesInFilePicker, setMarkedFilesInFilePicker] =
        useState<Array<MarkedFileInFilePickerState>>([defaultState]);

    const getAvailableConditionsFromViewModels = (): Array<string> => {
        const availableConditions: Array<string> = [];
        markedFilesInFilePicker
            .filter(item => item.viewModel !== undefined)
            .map(item => item.viewModel!)
            .forEach(viewModel => {
                viewModel.availableConditions.forEach(condition => {
                    if (!availableConditions.includes(condition)) {
                        availableConditions.push(condition);
                    }
                });
            });
        return availableConditions;
    };

    const [selectedCondition, setSelectedCondition] = useState<string>(Array.from(props.files.values())[0].conditions[0]);

    const [isHistoryMode, setIsHistoryMode] = useState<boolean>(false);

    const [highlightedMeasurement, setHighlightedMeasurement] = useState<string | undefined>(undefined);

    const variant: Variant = location.pathname.toLowerCase().includes('table') || location.pathname.toLowerCase().includes('chart')
        ? 'chart'
        : 'diagram';

    const requests = markedFilesInFilePicker
        .filter(({ viewModel: vm, toBeFetched }) =>
            toBeFetched === true ||
            vm!.variant !== variant ||
            (vm!.condition !== selectedCondition && vm!.availableConditions.indexOf(selectedCondition) > -1))
        .map<MeasurementRequest>(item => {
        const necessaryFileData = props.files.get(item.id)!;
        return {
            id: item.id,
            variant,
            outdatedVersion: item.requestedVersion,
            condition: necessaryFileData.conditions.indexOf(selectedCondition) > -1
                ? selectedCondition
                : necessaryFileData.conditions[0],
            folderId: necessaryFileData.folderId,
            isEditable: necessaryFileData.isEditable,
        };
    });
    const handleError = (id: string) => {
        setMarkedFilesInFilePicker(prev => prev.filter(x => x.id !== id));
        toast.error(t('Common.Toast.Error', 'Error'));
    };
    const queryResults = useMeasurement(requests, handleError);

    // we display the spinner only on initial render of this component and on switches to chart or table
    // because without spinners it's easier for the user to recognize differences
    const isLoading = markedFilesInFilePicker.every(({ viewModel }) => viewModel === undefined || viewModel.variant !== variant);

    useEffect(() => {
        if (!queryResults) return;

        queryResults.forEach(result => {
            if (!result.data) return;

            setMarkedFilesInFilePicker(prev => prev.map(request => {
                const isMatch = isHistoryMode
                    ? request.requestedVersion === result.data.version // in history mode, id is always the same and thus does not matter, we have to check version
                    : request.id === result.data.id; // in "normal" mode, id must match

                return isMatch
                    ? { ...request, viewModel: result.data, toBeFetched: false }
                    : request;
            }));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryResults]);

    useEffect(() => {
        // The application settings are used in useMeasurement.
        // When the application settings are changed, the measurementViewModels are reset
        // so that the component is rerendered and useMeasurement is triggered.
        setMarkedFilesInFilePicker(prev => prev.map(item => ({ ...item, viewModel: undefined, toBeFetched: true })));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [measurementSettings]);

    useEffect(() => {
        // check conditions
        const conditionsFromViewModels = getAvailableConditionsFromViewModels();
        if (conditionsFromViewModels.length > 0 && !conditionsFromViewModels.includes(selectedCondition)) { // conditionsFromViewModels.length > 0 means that data is still loading
            // avoid leaving a condition selected that was solely available in the removed file.
            setSelectedCondition(conditionsFromViewModels[0]);
        }

        // In ColorCorrection mode if the user did not change any values and select
        // the second measurement the Correction is not available anymore, therefore is set to undefined
        if (markedFilesInFilePicker.length > 1) {
            setSelectedColorCorrection(undefined);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [markedFilesInFilePicker]);

    // check for triggering missing process parameters notification
    const singleMarkedMeasurementId =
        featureFlags.disableColorCorrections === false &&
            isHistoryMode === false &&
            markedFilesInFilePicker.length === 1 &&
            markedFilesInFilePicker[0].viewModel !== undefined
            ? markedFilesInFilePicker[0].viewModel.id
            : undefined;
    useEffect(() => {
        if (singleMarkedMeasurementId === undefined) return;

        if (markedFilesInFilePicker[0].viewModel!.processParamsDefined === false && isUnlimitedLicenseAvailable) {
            notificationToast({
                id: 'missingProcessParamsNotification',
                content: <MissingProcessParamsTeaser
                    onClickhandler={(event: any) => {
                        handleSetProcessParamsBtnClick(event);
                        toast.dismiss('missingProcessParamsNotification');
                    }} />,
                variant: 'warning',
            });
        }
        if (!isUnlimitedLicenseAvailable) {
            notificationToast({
                id: 'getPremiumUpgradeNotification',
                content: <GetPremiumUpgradeTeaser
                    onClickhandler={(_event: any) => {
                        console.log('GetPremiumUpgradeTeaser button has been clicked');
                        toast.dismiss('missingProcessParamsNotification');
                    }} />,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleMarkedMeasurementId]);

    const measurementViewModels = markedFilesInFilePicker
        .filter(({ viewModel: vm }) => vm !== undefined && vm.variant === variant && vm.condition === selectedCondition)
        .map(item => item.viewModel!);

    const handleConditionChange = (index: number) => {
        setSelectedCondition(getAvailableConditionsFromViewModels()[index]);
    };

    const handleCreateFile = (newMeasurementId: string, newMeasurementName: string, conditions: Array<string>) => {
        props.onFileAction({
            type: 'selectAsFirst',
            id: newMeasurementId,
            necessaryFileData: {
                name: newMeasurementName,
                conditions,
                version: 0,
                isEditable: true,
                folderId: props.path[props.path.length - 1].id!,
            },
        });
        handleMarkedFilesChange([{ id: newMeasurementId }]);
    };

    const handleRemoveFromFilePicker = (id: string) => {
        props.onFileAction({ type: 'deselect', id });
        setMarkedFilesInFilePicker(prev => prev.filter(vm => vm.id !== id));
    };

    const handleAdditionalFileSelectionInFilePicker = (idsAndNecessaryFileData: Map<string, NecessaryFileData>) => {
        props.onFileAction({ type: 'select', idsAndNecessaryFileData });
    };

    const handleMarkedFilesChange = (newSelection: Array<{ id: string; versionObj?: { num: number; isLatestVersion: boolean } }>) => {

        setMarkedFilesInFilePicker(prev =>
            newSelection.map(item => prev.find(x => x.id === item.id && x.requestedVersion === item.versionObj?.num) ?? {
                id: item.id,
                requestedVersion: item.versionObj?.num,
                isLatestVersion: item.versionObj === undefined ? true : item.versionObj.isLatestVersion,
                viewModel: undefined,
                toBeFetched: true,
            }),
        );

        if (!markedFilesInFilePicker[0].viewModel?.processParamsDefined) {
            toast.dismiss('missingProcessParamsNotification');
        }
    };

    const handleToggleHistory = (measurementId?: string) => {
        const shouldHistoryBeDisplayed = !!measurementId;
        setIsHistoryMode(shouldHistoryBeDisplayed);
        if (shouldHistoryBeDisplayed) {
            handleMarkedFilesChange([{
                id: measurementId,
                versionObj: {
                    num: markedFilesInFilePicker.find(x => x.id === measurementId)?.viewModel?.version
                        ?? props.files.get(measurementId)!.version, // measurement not yet loaded? fall back to version from explorer
                    isLatestVersion: true,
                },
            }]);
        } else {
            handleMarkedFilesChange([{
                id: markedFilesInFilePicker[0].id,
            }]);
        }
    };

    const handleRefetchViewModel = (id: string) => {
        setMarkedFilesInFilePicker(prev => prev.map(item => ({
            ...item,
            toBeFetched: item.id === id ? true : item.toBeFetched,
        })));
    };

    const handleSetProcessParamsBtnClick = (ev: any) => {
        ev.stopPropagation();
        toast.dismiss('missingProcessParamsNotification');
        onShowModal('inputModal', {
            title: t('ProcessParam.modal_title', 'Process Parameters'),
            content: (
                <ProcessParamsModalContainer
                    measurementId={markedFilesInFilePicker[0].id}
                    isProcessParamsDefined={!!markedFilesInFilePicker[0].viewModel!.processParamsDefined}
                    folderId={markedFilesInFilePicker[0].viewModel!.folderId}
                    onSaveProcessParams={() => { handleRefetchViewModel(markedFilesInFilePicker[0].viewModel!.id); }}
                    inks={markedFilesInFilePicker[0].viewModel!.inks}
                    isEditable={markedFilesInFilePicker[0].viewModel!.isEditable}
                />),
        });
    };

    const handleToggleColorCorrection = (mode: ColorCorrectionMode | undefined) => {
        setSelectedColorCorrection(prev => {
            if (mode === undefined || prev?.mode === mode) {
                return undefined;
            }
            return {
                mode,
                isLoading: false,
                measurementId: markedFilesInFilePicker[0].id,
            };
        });
    };

    const handleStartColorCorrection = () => {
        setSelectedColorCorrection(prev => ({ ...prev!, isLoading: true }));
    };

    const handleCloseColorCorrection = (correctedMeasurementId?: string) => {
        if (correctedMeasurementId !== undefined) {
            handleRefetchViewModel(correctedMeasurementId);
        }
        handleToggleColorCorrection(undefined);
    };

    const handleHighlightedMeasurement = (versionedId?: string) => {
        // no highlighting in case color correction is active and in Char views
        if (selectedColorCorrection !== undefined || variant === 'chart') return;

        setHighlightedMeasurement(versionedId);
    };

    const viewModelsSortedByReference = [
        ...measurementViewModels.filter(vm => vm.id === referenceMeasurementId),
        ...measurementViewModels.filter(vm => vm.id !== referenceMeasurementId),
    ];

    const measurementContext: Measurements = {
        viewmodels: viewModelsSortedByReference,
        condition: selectedCondition,
        hasReference: !!referenceMeasurementId,
        highlightedId: highlightedMeasurement,
    };

    return (
        <Main
            secondaryNav={
                <SecondaryNav
                    onShowProcessParamsModal={handleSetProcessParamsBtnClick}
                    onChangeMeasurementParams={props.onChangeMeasurementParams}
                    numberOfMarkedFilesInFilePicker={markedFilesInFilePicker.length}
                    reportData={{
                        selectedCondition,
                        items: Array.from(props.files, ([id, { name }]) => ({ id, name }))
                            .map(({ id, name }) => ({
                                id,
                                name,
                                isSelected: !!markedFilesInFilePicker.find(f => f.id === id),
                                isReference: referenceMeasurementId === id,
                            })),
                    }}
                    isMeasurementLoading={isLoading}
                    onClickCloudFileBtn={() => {
                        props.onFileAction({ type: 'reset' });
                        toast.dismiss('autoOptimizeNotification');
                        toast.dismiss('missingProcessParamsNotification');
                        !isUnlimitedLicenseAvailable && toast.dismiss('getPremiumUpgradeNotification');
                        navigate('/inspect');
                    }}
                />}
        >
            <Flex flexGrow={1} gap="2px">
                <Flex width="320px" flexDirection="column">
                    <FilePicker
                        files={props.files}
                        onChangeMarkedFiles={handleMarkedFilesChange}
                        markedFiles={markedFilesInFilePicker.map(item => ({
                            id: item.id,
                            versionObj: isHistoryMode
                                ? {
                                    num: item.requestedVersion!,
                                    isLatestVersion: item.isLatestVersion,
                                }
                                : undefined,
                        }))}
                        selectedHistoricVersions={isHistoryMode
                            ? markedFilesInFilePicker.map(item => item.requestedVersion!)
                            : []
                        }
                        isHistoryDisplayed={isHistoryMode}
                        onToggleHistory={handleToggleHistory}
                        onRemoveFile={handleRemoveFromFilePicker}
                        path={props.path}
                        onHighlightMeasurement={handleHighlightedMeasurement}
                        activeColorCorrection={selectedColorCorrection}
                        onSelectAdditionalFiles={handleAdditionalFileSelectionInFilePicker}
                        referenceMeasurementId={referenceMeasurementId}
                        onSetReference={(id?: string) => setReferenceMeasurementId(id)}
                    />
                </Flex>
                {(
                    <Flex flexDirection="column" flexGrow={1} gap="2px">
                        <Frame>
                            <ToolBarContainer variant={variant} isVisible={!isLoading}>
                                <ToolBar
                                    viewModels={markedFilesInFilePicker
                                        .filter(item => item.viewModel !== undefined)
                                        .map(item => item.viewModel!)}
                                    onCreateFile={handleCreateFile}
                                    onResetViewModel={handleRefetchViewModel}
                                    unselectedFiles={fileIds
                                        .filter(id => markedFilesInFilePicker.map(m => m.id).indexOf(id) === -1)
                                        .map(id => ({ id, name: props.files.get(id)!.name }))
                                    }
                                    disabled={markedFilesInFilePicker.some(item => item.isLatestVersion === false)}
                                    onActivateColorCorrection={handleToggleColorCorrection}
                                    activeColorCorrection={selectedColorCorrection}
                                    folderId={props.path[props.path.length - 1].id!}
                                />
                                <HistoryToolbarItem
                                    onHistoryBtnClick={() => {
                                        if (isHistoryMode) {
                                            setIsHistoryMode(false);
                                        } else {
                                            trackEvent(getFileHistoryClickEvent());
                                            handleToggleHistory(markedFilesInFilePicker[0].id);
                                        }
                                    }}
                                    isDisabled={markedFilesInFilePicker.length !== 1 || markedFilesInFilePicker[0].viewModel?.folderId === 'STANDARDCHARACTERIZATIONS'}
                                    isHistoryDisplayed={isHistoryMode}
                                />
                            </ToolBarContainer>
                            {isLoading || !selectedCondition
                                ? (
                                    <Spinner
                                        label={t('Common.Toast.Loading', 'Loading ...')}
                                        labelPosition={'bottom'}
                                    />
                                ) : (
                                    <Flex
                                        flexGrow={1} // grow vertically
                                        justifyContent={variant === 'chart' ? 'center' : 'flex-start'}
                                        alignItems="center"
                                        minHeight="100%"
                                        ref={diagramContainerRef}
                                    >
                                        <Routes>
                                            <Route
                                                path="spider"
                                                element={<Spider
                                                    measurementContext={measurementContext}
                                                    onHighlightMeasurement={handleHighlightedMeasurement}
                                                    activeColorCorrection={selectedColorCorrection}
                                                    onCloseColorCorrection={handleCloseColorCorrection}
                                                    onResetViewModel={handleRefetchViewModel}
                                                    onStartColorCorrection={handleStartColorCorrection}
                                                    diagramContainerRef={diagramContainerRef}
                                                    isHistoryMode={isHistoryMode}
                                                />}
                                            />
                                            <Route
                                                path="tonalvalue"
                                                element={<TonalValue
                                                    measurementContext={measurementContext}
                                                    onHighlightMeasurement={handleHighlightedMeasurement}
                                                    activeColorCorrection={selectedColorCorrection}
                                                    diagramContainerRef={diagramContainerRef}
                                                    onCloseColorCorrection={handleCloseColorCorrection}
                                                    onStartColorCorrection={handleStartColorCorrection}
                                                    isHistoryMode={isHistoryMode}
                                                />}
                                            />
                                            <Route
                                                path="dotgain"
                                                element={<Dotgain
                                                    measurementContext={measurementContext}
                                                    onHighlightMeasurement={handleHighlightedMeasurement}
                                                    activeColorCorrection={selectedColorCorrection}
                                                    diagramContainerRef={diagramContainerRef}
                                                    onCloseColorCorrection={handleCloseColorCorrection}
                                                    onStartColorCorrection={handleStartColorCorrection}
                                                    isHistoryMode={isHistoryMode}
                                                />}
                                            />
                                            <Route
                                                path="chart"
                                                element={<Chart
                                                    measurements={viewModelsSortedByReference}
                                                    isHistoryMode={isHistoryMode}
                                                />}
                                            />
                                            <Route
                                                path="table"
                                                element={<Table
                                                    condition={selectedCondition}
                                                    measurements={viewModelsSortedByReference}
                                                    isHistoryMode={isHistoryMode}
                                                    hasReference={!!referenceMeasurementId}
                                                />}
                                            />
                                        </Routes>
                                    </Flex>)}
                        </Frame>
                        <Flex
                            bg="backgroundPaper"
                            alignItems="center"
                            justifyContent="center"
                            height="44px"
                        >
                            <ButtonGroup
                                buttons={getAvailableConditionsFromViewModels().map((c: string) => ({
                                    caption: c,
                                    isSelected: c === selectedCondition,
                                }))}
                                onClick={handleConditionChange}
                            />
                        </Flex>
                    </Flex>
                )}
            </Flex >
        </Main >
    );
};

const Frame = styled(Flex)`
    justify-content: center;
    align-items: center;
    border: 12px solid ${(props) => props.theme.colors.backgroundPaper};
    overflow: hidden;
    height: 100%;
    max-height: 100%;
    position: relative;
`;

const ToolBarContainer = styled.div<{ variant: Variant; isVisible: boolean }>`
    display: ${props => props.isVisible ? 'flex' : 'none'};  /* we always have to render the Toolbar in order to make optimization work  */
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    padding-left: 4px;
    margin-right: ${props => props.variant === 'diagram' ? '4rem' : 0};
`;

export default VisualizationContainer;