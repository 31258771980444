import { DndContext, DragEndEvent, closestCenter, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { InkSettings, ProcessParamViewModel, SingleVal } from 'src/graphql/ViewModels';
import { ScreeningType } from 'src/graphql/mapper/mapToProcessParamsViewModel';
import styled from 'styled-components';
import { Updater } from 'use-immer';
import InkSectionRow, { Row } from './InkSectionRow';


export interface InksSectionProps {
    hexValues: Map<string, string>;
    inkSettings: Array<InkSettings>;
    availableScreeningTypes: Array<ScreeningType>;
    onChangeInkName: (hash: string, newName: string) => void;
    setProcessParams: Updater<ProcessParamViewModel | undefined>;
    onChangeScreeningType: (hash: string, value: SingleVal) => void;
    onChangeRulingValue: (hash: string, value: string) => void;
    onChangeConfiguration: (hash: string, value: SingleVal) => void;
};

const InksSection: FunctionComponent<InksSectionProps> = (props) => {
    const { t } = useTranslation();
    const sensors = useSensors(useSensor(MouseSensor)); // https://github.com/clauderic/dnd-kit/issues/208

    const allInkNames: Array<string> = props.inkSettings.map(ink => !!ink.newName
        ? ink.newName
        : ink.inkName,
    );

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;

        if (active.id !== over!.id) {
            props.setProcessParams((prev) => {
                const activeIndex = prev!.inks.findIndex(ink => ink.hash === active.id);
                const overIndex = prev!.inks.findIndex(ink => ink.hash === over!.id);

                prev!.inks = arrayMove(prev!.inks, activeIndex, overIndex);
            });
        }
    };

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        >
            <Container>
                <HeaderRow>
                    <span>{t('InksSection.sequence_title', 'Sequence')}</span>
                    <span>{t('InksSection.screening_type_title', 'Screening type')}</span>
                    <span>{t('InksSection.ruling_title', 'Ruling')}</span>
                    <span>{t('InksSection.configuration_title', 'Configuration')}</span>
                </HeaderRow>

                <SortableContext
                    items={props.inkSettings.map(ink => ink.hash)}
                    strategy={verticalListSortingStrategy}
                >
                    <DIV role='rowgroup'>
                        {props.inkSettings
                            .map(ink => {
                                const hex = props.hexValues.get(ink.inkName);

                                return <InkSectionRow
                                    key={ink.hash}
                                    hash={ink.hash}
                                    hex={hex}
                                    inkName={ink.newName ?? ink.inkName}
                                    availableScreeningTypes={props.availableScreeningTypes}
                                    selectedScreeningType={ink?.screeningType}
                                    selectedScreeningRulingValue={ink?.screeningRulingValue}
                                    selectedScreeningConfiguration={ink?.screeningConfiguration}
                                    onChangeInkName={props.onChangeInkName}
                                    existingInkNames={allInkNames}
                                    onChangeScreeningType={props.onChangeScreeningType}
                                    onChangeRulingValue={props.onChangeRulingValue}
                                    onChangeConfiguration={props.onChangeConfiguration}
                                />;
                            })}
                    </DIV>
                </SortableContext>
            </Container>
        </DndContext>
    );
};

const Container = styled.div`
    max-width: 100%;
    max-height: 100%;
`;

const HeaderRow = styled(Row)`
    margin-bottom: 16px;
`;

const DIV = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px; 
    position: relative;
`;

export default InksSection;