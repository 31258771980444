import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import AppContext from 'src/AppContext';
import { CmykFilters, CmykMethods, ColorCorrectionPayload, TableViewInput, UpdateDensityInput } from '../../generated/types';

const mutation = `mutation UpdateDensity($input: UpdateDensityInput!) {
    updateDensity(input: $input) {
        measurementId
        succeeded
    }
}`;

export const useDensityCorrection = (measurementId: string, condition: string) => {
    const context = useContext(AppContext);
    const queryClient = useQueryClient();

    return useMutation(
        async (densities: Array<{ ink: string; density: number }>) => {
            await context.populateCacheForAppSynch?.();

            const input: UpdateDensityInput = {
                measurementId,
                condition,
                inkDensity: densities.map(item => ({
                    inkName: item.ink,
                    relativeDensity: item.density,
                })),
                filterCMYK: context.measurementSettings.filterCMYK as CmykFilters,
                methodSpot: context.measurementSettings.methodSpot as CmykMethods,
                methodCMYK: context.measurementSettings.methodCMYK as CmykMethods,
            };

            const response = await (context.api.graphql({
                query: mutation,
                variables: { input },
            }) as Promise<{
                data: {
                    updateDensity: ColorCorrectionPayload;
                };
            }>);

            return response.data;
        },
        {
            onSettled: async () => {
                await queryClient.invalidateQueries({
                    predicate: q =>
                        (q.queryKey[0] === 'getMeasurementTableView' && (q.queryKey[1] as TableViewInput).id === measurementId) ||
                        (q.queryKey[0] === 'getMeasurementHistory' && q.queryKey[1] === measurementId),
                    type: 'all', // also inactive
                });
            },
        },
    );
};