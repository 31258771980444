import { FC } from 'react';
import styled from 'styled-components';

export interface PatchSquareProps {
    size: string;
    hex1: string;
    hex2: string | undefined;
};

interface SquareProps {
    bg: string | undefined;
    size: string;
};

// use attrs to avoid creation of too many classes
const Square = styled.div.attrs<SquareProps>(props => ({
    style: {
        backgroundColor: props.bg || 'inherit',
    },
})) <SquareProps>`
  width: ${props => props.size};
  height: ${props => props.size};
`;

const PatchSquare: FC<PatchSquareProps> = props => {
    return !props.hex2
        ? (
            <Square
                bg={props.hex1}
                size={props.size}
            />
        )
        : (
            <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 16 16"
                width={props.size}
                height={props.size}
                xmlSpace="preserve"
            >
                <polygon fill={props.hex2} points="0.14,16 16,16 16,0.14" />
                <polygon fill={props.hex1} points="15.86,0 0,0 0,15.86" />
            </svg>);
};

export default PatchSquare;