import { Flex } from '@gmg/gmg-react-components';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Point } from '../Point';


interface GradientProps {
    calculatedAverages: Array<Point>;
    diagramSize: number;
}

const Gradient: FunctionComponent<GradientProps> = ({ calculatedAverages, diagramSize }) => {

    return (
        <Flex pl="28px">
            {calculatedAverages.map((avg, i) => i === calculatedAverages.length - 1
                ? null
                : <GradientColor
                    key={avg.id}
                    width={`${((calculatedAverages[i + 1].x - avg.x) * diagramSize / 100)}px`}
                    hexMin={avg.hex}
                    hexMax={calculatedAverages[i + 1].hex}
                />,
            )}
        </Flex>
    );
};

const GradientColor = styled.div<{ width: string; hexMin: string; hexMax: string }>`
    width: ${props => props.width};
    height: 30px;
    background-image: linear-gradient( 90deg, ${props => props.hexMin}, ${props => props.hexMax});
`;

export default Gradient;