import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import AppContext from 'src/AppContext';
import { GqlError } from '../GqlError';
import { mapToFolderContent } from '../mapper/mapToFolder';
import { GetFolderContentPayload } from '../generated/types';
import { FolderContent } from '../ViewModels';

// let's see if we solve delete / add with subscriptions...
// const deleteSubscription = `subscription DeleteSubscription {
// 	onDeleteMeasurement {
// 		data {
// 		  createdOn
// 		  folderId
// 		  id
// 		  metadata
// 		  name
// 		  version
// 		}
// 		notification {
// 		  message
// 		  succeeded
// 		  version
// 		}
// 	  }
//   }`;

// const addSubscription = `subscription AddSubscription {
// 	onAddMeasurement {
// 		data {
// 		  createdOn
// 		  folderId
// 		  id
// 		  metadata
// 		  name
// 		  version
// 		}
// 		notification {
// 		  message
// 		  succeeded
// 		  version
// 		}
// 	  }
//   }`;

export const useFolderContent = (folderId: string | '') => {
    const context = useContext(AppContext);
    const query = `query GetFolderContent {
      getFolderContent(folderId: "${folderId}") { ${folderId ? `
        folder {
          id
          isEditable
        }` : ''}
        subfolders {
          id
          name
          isTraversable
        }
        measurements {
          folderId
          id
          metadata
          name
          versionInformation {
            version
            timestamp
          }
        }
      }
	  }`;

    // useEffect(() => {
    // 	(async () => {
    // 		await context.populateCacheForAppSynch?.();
    // 	})();
    // 	// eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    // useEffect(() => {
    // 	const deleteSub = createSubscription(deleteSubscription, 'onDeleteMeasurement');
    // 	const addSub = createSubscription(addSubscription, 'onAddMeasurement');

    // 	return () => {
    // 		addSub.unsubscribe();
    // 		deleteSub.unsubscribe();
    // 	};
    // 	// eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    // const createSubscription = (subscription: string, rootPropertyName: string): any => {
    // 	const pubSubClient = API.graphql(graphqlOperation(subscription)) as Observable<any>;
    // 	return pubSubClient.subscribe(
    // 		(payload) => {
    // 			console.log('it is working');
    // 			// console.log(JSON.stringify(payload));
    // 			// const userId = (payload.value.data[rootPropertyName] as MutateMeasurementPayload).data?.userId;
    // 			queryClient.invalidateQueries("folderContent");

    // 		},
    // 		(error: any) => { console.log(JSON.stringify(error)); }
    // 	);
    // };

    return useQuery<FolderContent, any>(
        ['folderContent', folderId],
        async () => {
            await context.populateCacheForAppSynch?.();

            const response = await (context.api.graphql({ query, variables: { folderId: folderId || '' } }) as Promise<{
                data: {
                    getFolderContent: GetFolderContentPayload;
                };
                errors?: Array<GqlError>;
            }>);

            const folderContent = response.data.getFolderContent!;
            return mapToFolderContent(folderContent.folder, folderContent.subfolders!, folderContent.measurements!);
        },
        {
            enabled: true,
            refetchOnWindowFocus: false,
        },
    );
};
