import { IconCheckMark, IconReset, IconClose } from '@gmg/gmg-react-components';
import { FunctionComponent } from 'react';
import ToolBarItem from 'src/components/optimize/ToolBarItem';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export interface ActionBarProps {
    onResetClick: () => void;
    onCloseClick: () => void;
    onApplyClick: () => void;
    haveInksBeenEdited: boolean;
};

const ActionBar: FunctionComponent<ActionBarProps> = (props) => {
    const { t } = useTranslation();

    return (
        <ActionBarWrapper role='menubar'>
            <ToolBarItem
                icon={<IconCheckMark />}
                label={t('Common.ButtonLabels.applyBtn_title', 'Apply')}
                isLabelRequired={true}
                isActive={false}
                onClick={props.onApplyClick}
                isDisabled={!props.haveInksBeenEdited}
            />
            <ToolBarItem
                icon={<IconReset />}
                label={t('Common.ButtonLabels.reset_btn_title', 'Reset')}
                isLabelRequired={true}
                isActive={false}
                onClick={props.onResetClick}
                isDisabled={!props.haveInksBeenEdited}
            />
            <Divider />
            <ToolBarItem
                icon={<IconClose />}
                label={t('Common.ButtonLabels.close_btn_title', 'Close')}
                isLabelRequired={true}
                isActive={false}
                onClick={props.onCloseClick}
                isDisabled={false}
            />
        </ActionBarWrapper>
    );
};

const ActionBarWrapper = styled.div`
    display: flex;
    padding: 2px;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.3);
    background-color: ${props => props.theme.colors.backgroundPaper};
    border-radius:  ${props => props.theme.radii.default}px;
`;

const Divider = styled.span`
    display: block;
    width: 1px;
    min-height: 100%;
    margin-inline: 8px;
    background-color: ${props => props.theme.colors.background};
`;

export default ActionBar;