import { Checkbox, RadioButton } from '@gmg/gmg-react-components';
import { FC } from 'react';
import styled from 'styled-components';

interface SimpleColorSelectorProps {
    colors: Map<string, boolean>;
    onChangeSelection: (selectedColors: Array<string>) => void;
    isRadioSelection?: boolean;
}

const Div = styled.div`
    display: flex;

    >div {
        margin-left: 14px;
    }
`;

const SimpleColorSelector: FC<SimpleColorSelectorProps> = props => {

    const handleColorSelectionChange = (isChecked: boolean, clickedColor: string) => {
        if (props.isRadioSelection) {
            props.onChangeSelection([clickedColor]);
            return;
        }

        const selectedColors = isChecked
            ? [...Array.from(props.colors.entries())
                .filter(item => item[1] === true)
                .map(item => item[0]),
            clickedColor]
            : Array.from(props.colors.entries())
                .filter(item => item[1] === true && item[0] !== clickedColor)
                .map(item => item[0]);

        props.onChangeSelection(selectedColors);
    };

    return (
        <Div>
            {Array.from(props.colors.keys()).map((color, i) => (
                <div key={color}>
                    {!props.isRadioSelection
                        ? <Checkbox
                            id={color}
                            label={color}
                            checked={props.colors.get(color)}
                            onChange={(ev: any) => { handleColorSelectionChange(ev.target.checked, color); }}
                            name="colors"
                        />
                        : <RadioButton
                            label={color}
                            checked={props.colors.get(color)}
                            onChange={(ev: any) => { handleColorSelectionChange(ev.target.checked, color); }}
                            name="colors" />}
                </div>),
            )}
        </Div>
    );
};

export default SimpleColorSelector;
