import { notificationToast, toast } from '@gmg/gmg-react-components';
import { FunctionComponent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from 'src/AppContext';
import { ProcessParamViewModel } from 'src/graphql/ViewModels';
import { useGetProcessParams } from 'src/graphql/customHooks/useGetProcessParams';
import { useParamPrediction } from 'src/graphql/customHooks/useParamPrediction';
import { useSetProcessParameters } from 'src/graphql/customHooks/useSetProcessParameters';
import { mapToAvailableProcessParamsViewModel } from 'src/graphql/mapper/mapToProcessParamsViewModel';
import { processParameters } from 'src/graphql/testResponses';
import EnabledColorCorrectionNotification, { isEnabledColorCorrectionNotificationSkipped } from '../../notifications/EnabledColorCorrectionNotification';
import { getErrorMsgForToast } from '../../toastErrorHandler';
import ProcessParamsModal from './ProcessParamsModal';
export interface ProcessParamModalContainerProps {
    measurementId: string;
    folderId: string;
    inks: Map<string, string>;
    onClose?: () => void;
    onSaveProcessParams: () => void;
    isProcessParamsDefined: boolean;
    isEditable: boolean;
};

const ProcessParamsModalContainer: FunctionComponent<ProcessParamModalContainerProps> = props => {
    const { t } = useTranslation();
    const context = useContext(AppContext);
    const mutateProcessParameters = useSetProcessParameters(props.measurementId, props.folderId);

    const {
        data: predictedParams,
        fetchStatus: predictionFetchStatus,
        isError: isPredictionError,
    } = useParamPrediction(props.measurementId, context.user!.orgId, props.folderId, props.isProcessParamsDefined);

    const {
        data: fetchedParams,
        fetchStatus: getProcessParamsFetchStatus,
    } = useGetProcessParams(props.measurementId, props.isProcessParamsDefined);

    //TODO: implement hook to get the available process parameters from the measurement json schema
    const allAvailableProcessParams = mapToAvailableProcessParamsViewModel(processParameters);
    const fallbackParams = {
        printingProcess: undefined,
        printFinishing: undefined,
        substrateCategory: undefined,
        substrateType: undefined,
        filmMaterial: undefined,
        printDirection: {
            label: 'Front',
            value: '',
        },
        inks: Array.from(props.inks.entries(), ([inkName, hex]) => ({
            inkName,
            hash: `${inkName}-${hex}`,
            screeningType: undefined,
            screeningRulingValue: '150',
            screeningConfiguration: undefined,
        })),
    };

    const handleSubmit = (processParams: ProcessParamViewModel) => {
        const pendingPromise = mutateProcessParameters.mutateAsync({
            ...processParams,
            isBasedOnPrediction: !isPredictionError,
        });

        props.onClose?.();

        void toast.promise(pendingPromise, {
            loading: t('ProcessParam.toast_loading', 'Your changes are being saved …'),
            success: () => {
                props.onSaveProcessParams();

                if (!props.isProcessParamsDefined && !isEnabledColorCorrectionNotificationSkipped()) {
                    notificationToast({ content: <EnabledColorCorrectionNotification /> });
                }

                return t('ProcessParam.toast_success_desc', 'Your changes have been saved successfully.');
            },
            error: (response: any) => getErrorMsgForToast(response, t),
        });
    };

    return (
        <div>
            <ProcessParamsModal
                hexValues={props.inks}
                availableProcessParams={allAvailableProcessParams}
                data={props.isProcessParamsDefined
                    ? fetchedParams
                    : !isPredictionError
                        ? predictedParams
                        : fallbackParams
                }
                onSubmit={handleSubmit}
                onClose={props.onClose!}
                isProcessParamsDefined={props.isProcessParamsDefined}
                isLoading={predictionFetchStatus === 'fetching' || getProcessParamsFetchStatus === 'fetching'}
                isEditable={props.isEditable}
            />
        </div>
    );
};

export default ProcessParamsModalContainer;