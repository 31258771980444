import { Accordion, Button, Flex } from '@gmg/gmg-react-components';
import isObjEqual from 'lodash.isequal';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProcessParamViewModel, SingleVal } from 'src/graphql/ViewModels';
import { AvailableProcessParams, printDirection } from 'src/graphql/mapper/mapToProcessParamsViewModel';
import WrappedSpinner from 'src/shared/WrappedSpinner';
import styled from 'styled-components';
import { useImmer } from 'use-immer';
import InksSection from './InksSection';
import MediaSection from './MediaSection';
import PrintProcessSection from './PrintProcessSection';
import { ProcessParamsValidator, getValidator } from './processParamsValidation';


export interface ProcessParamModalProps {
    hexValues: Map<string, string>;
    availableProcessParams: AvailableProcessParams;
    onSubmit: (processParams: ProcessParamViewModel) => void;
    onClose: () => void;
    data: ProcessParamViewModel | undefined;
    isProcessParamsDefined: boolean;
    isLoading: boolean;
    isEditable: boolean;
};

const ProcessParamsModal: FunctionComponent<ProcessParamModalProps> = props => {
    const { t } = useTranslation();

    const [processParams, setProcessParams] = useImmer<ProcessParamViewModel | undefined>(undefined);
    const [programmaticallyOpenedSectionIndex, setProgrammaticallyOpenedSectionIndex] = useState<number | undefined>();


    useEffect(() => {
        if (props.data === undefined) return;

        setProcessParams(props.data);

        const validator = getValidator(props.data);
        if (validator.hasError) {
            triggerOpenSection(validator);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data]);

    const handleChangeProcessParams = (param: keyof Omit<ProcessParamViewModel, 'substrateCategory' | 'substrateType'>, value: SingleVal) => {
        setProcessParams(prev => ({
            ...prev!,
            [param]: value, // note that this is not type safe, e.g. changing the type of value to string does not make the compiler complain
        }));
    };

    const handleChangeSubstrateCategory = (category: SingleVal) => {
        if (category.value === processParams!.substrateCategory?.value) return; // ignore if unchanged

        setProcessParams(prev => ({
            ...prev!,
            substrateCategory: category,
            substrateType: undefined,
            filmMaterial: undefined,
        }));
    };

    const handleChangeSubstrateType = (selectedSubstrateType: SingleVal) => {
        const isNewTypeSelected = selectedSubstrateType.value !== processParams!.substrateType?.value;

        if (!isNewTypeSelected) return;

        setProcessParams(prev => {
            return {
                ...prev!,
                substrateType: selectedSubstrateType,
                printDirection: selectedSubstrateType.value.includes('transparent')
                    ? undefined
                    : printDirection.front,
            };
        });
    };

    const handleChangeScreeningType = (hash: string, payload: SingleVal) => {
        setProcessParams(prev => {
            const toBeChangedInk = prev!.inks.find(ink => ink.hash === hash)!;

            if (toBeChangedInk.screeningType?.value === payload.value) return;

            toBeChangedInk.screeningType = payload;
            toBeChangedInk.screeningConfiguration = undefined;
        });
    };
    const handleChangeRulingValue = (hash: string, value: string) => {
        setProcessParams(prev => {
            const toBeChangedInk = prev!.inks.find(ink => ink.hash === hash)!;
            toBeChangedInk.screeningRulingValue = value;
        });
    };
    const handleChangeConfiguration = (hash: string, payload: SingleVal) => {
        setProcessParams(prev => {
            const toBeChangedInk = prev!.inks.find(ink => ink.hash === hash)!;

            if (toBeChangedInk.screeningConfiguration?.value === payload.value) return;

            toBeChangedInk.screeningConfiguration = payload;
        });
    };

    const handleChangeInkName = (hash: string, newName: string) => {
        setProcessParams(prev => {
            const toBeChangedInk = prev!.inks.find(ink => ink.hash === hash)!;

            toBeChangedInk.newName = newName;
        });
    };

    const triggerOpenSection = (validator: ProcessParamsValidator) => {
        if (validator.hasProcessSectionError) {
            setProgrammaticallyOpenedSectionIndex(0);
            return;
        }
        if (validator.hasMediaSectionError) {
            setProgrammaticallyOpenedSectionIndex(1);
            return;
        }
        if (validator.hasInksSectionError) {
            setProgrammaticallyOpenedSectionIndex(2);
        }
    };

    const handleSaveClick = () => {
        const validator = getValidator(processParams!);
        if (validator.hasError) {
            triggerOpenSection(validator);
        } else {
            props.onSubmit(processParams!);
        }
    };

    const getLabels = (...args: Array<keyof Omit<ProcessParamViewModel, 'inks'>>) => {
        let labels: Array<string> = [];
        args.forEach(key => {
            const label = processParams![key]?.label;
            if (!!label) {
                labels.push(label);
            }
        });
        return labels.join(', ');
    };

    const validator = getValidator(processParams);

    // console.log(processParams?.inks);
    return (
        <Container isSmall={props.hexValues.size < 5}>
            <Description className='px12_400_weak' isDataLoading={props.isLoading}>
                {t('ProcessParam.modal_desc', 'To enable all tools of GMG Optimize, it is necessary that all fields are completed. While we have provided suggestions, it is important that you review and verify them.')}
            </Description>

            {props.isLoading
                ? <WrappedSpinner
                    label={props.isProcessParamsDefined
                        ? 'Getting your process params...' /* TODO: Sync With Christina E.! */
                        : t('ProcessParam.spinner_loading_desc', 'Hold on, the parameters are being analyzed ...')}
                    labelPosition='bottom'
                />
                : processParams && (<>
                    <Accordion
                        openSectionIndex={programmaticallyOpenedSectionIndex}
                        onSwitchSection={() => { setProgrammaticallyOpenedSectionIndex(undefined); }}
                        sections={[
                            {
                                title: t('ProcessParam.process_section_title', 'Process'),
                                subTitle: getLabels('printingProcess', 'printFinishing'),
                                hasError: validator.hasProcessSectionError,
                                content: <PrintProcessSection
                                    availablePrintingProcesses={props.availableProcessParams.printingProcesses}
                                    selectedPrintingProcess={processParams!.printingProcess}
                                    availableSurfaceFinishings={props.availableProcessParams.surfaceFinishings}
                                    selectedSurfaceFinishing={processParams!.printFinishing}
                                    onChangePrintingParam={handleChangeProcessParams}
                                />,
                            },
                            {
                                title: t('ProcessParam.media_section_title', 'Media'),
                                subTitle: getLabels('substrateCategory', 'substrateType', 'filmMaterial', 'printDirection'),
                                hasError: validator.hasMediaSectionError,
                                content: <MediaSection
                                    availableSubstrateCategories={props.availableProcessParams.substrateCategories}
                                    availablePrintDirections={props.availableProcessParams.printDirection}
                                    selectedSubstrateCategory={processParams!.substrateCategory}
                                    selectedSubstrateType={processParams!.substrateType}
                                    selectedFilmMaterial={processParams!.filmMaterial}
                                    selectedPrintDirection={processParams!.printDirection}
                                    onChangeSubstrateCategory={handleChangeSubstrateCategory}
                                    onChangeSubstrateType={handleChangeSubstrateType}
                                    onChangeMediaParams={handleChangeProcessParams}
                                />,
                            },
                            {
                                title: t('ProcessParam.inks_section_title', 'Inks'),
                                subTitle: Array.from(props.hexValues.keys()).join(', '),
                                hasError: validator.hasInksSectionError,
                                content: <InksSection
                                    inkSettings={processParams!.inks}
                                    hexValues={props.hexValues}
                                    availableScreeningTypes={props.availableProcessParams.screeningTypes}
                                    onChangeInkName={handleChangeInkName}
                                    setProcessParams={setProcessParams}
                                    onChangeScreeningType={handleChangeScreeningType}
                                    onChangeRulingValue={handleChangeRulingValue}
                                    onChangeConfiguration={handleChangeConfiguration}
                                />,
                            },
                        ]}
                    />
                    <Flex flexDirection='column' gap='8px' height='30px'>
                        {validator.hasError && (
                            <span className="px9_400_error">
                                {t('ProcessParam.msg_incomplete', 'Please enter the missing parameters in the highlighted sections.')}
                            </span>)}
                        {props.isProcessParamsDefined && props.data && (
                            <span className="px9_400_warning">
                                {t('ProcessParam.msg_warning', 'When changing the set parameters the measurement file will be reset to its state before any color correction was applied.')}
                            </span>)}
                    </Flex>

                    <ButtonContainer>
                        <Button
                            variant="primary"
                            type="submit"
                            onClick={handleSaveClick}
                            disabled={props.isLoading ||
                                props.data === undefined ||
                                props.isEditable === false ||
                                (props.isProcessParamsDefined && isObjEqual(processParams, props.data))
                            }
                        >
                            {t('Common.ButtonLabels.save_btn_title', 'Save')}
                        </Button>
                        <Button
                            variant="secondary"
                            onClick={() => { props.onClose(); }}
                        >
                            {t('Common.ButtonLabels.cancelBtn_title', 'Cancel')}
                        </Button>
                    </ButtonContainer>
                </>)
            }
        </Container>
    );
};

const Container = styled.div<{ isSmall: boolean }>`
    padding: 0 10px;
    min-height: ${props => props.isSmall ? 610 : 744}px;
    height: ${props => props.isSmall ? 600 : 744}px;
    position: relative;
    max-height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const Description = styled.div<{ isDataLoading: boolean }>`
    line-height: 1.4;
    margin-bottom: ${props => props.isDataLoading ? 100 : 0}px;
`;

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
    margin-top: auto;
`;

export default ProcessParamsModal;