import { Flex, Spinner, toast } from '@gmg/gmg-react-components';
import { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeleteVersion } from 'src/graphql/customHooks/useDeleteVersion';
import { useGetHistory } from 'src/graphql/customHooks/useGetHistory';
import { getErrorMsgForToast } from '../toastErrorHandler';
import History from './History';

export interface HistoryContainerProps {
    measurementName: string;
    measurementId: string;
    selectedVersions: Array<number>;
    onCheckboxClick: (selection: { id: string; versionObj: { num: number; isLatestVersion: boolean }}, action: 'select' | 'deselect') => void;
    onFileVersionClick: (selection: { id: string; versionObj: { num: number; isLatestVersion: boolean }}) => void;
    onClose: () => void;
    onHighlightMeasurement: (versionedId?: string) => void;
};

const HistoryContainer: FunctionComponent<HistoryContainerProps> = ({ onClose, ...props }) => {
    const { t } = useTranslation();

    const { data, isLoading, error } = useGetHistory(props.measurementId);

    const deleteVersion = useDeleteVersion(props.measurementId);

    useEffect(() => {
        if (!error) return;

        toast.error(t('History.history_error_desc', 'Sorry, it seems the file history is currently unavailable. Please try again later.'));
        onClose();
    }, [error, onClose, t]);

    useEffect(() => {
        if (!data) return;

        // when history content changes, always switch to the lastest version
        props.onFileVersionClick({
            id: props.measurementId,
            versionObj: {
                num: data.find(item => item.isLatestVersion)!.version,
                isLatestVersion: true,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const handleDeleteVersion = (version: number) => {
        const pendingPromise = deleteVersion.mutateAsync(version);
        props.onHighlightMeasurement();

        void toast.promise(pendingPromise, {
            loading: t('History.loading', 'Removing steps in history'),
            success: () => {
                props.onFileVersionClick({ id: props.measurementId, versionObj: { num: version - 1, isLatestVersion: true } });
                return t('History.success', 'You have successfully deleted the history step.');
            },
            error: response => getErrorMsgForToast(response, t),
        });
    };

    const handleFileClick = (version: number, isLatestVersion: boolean) => {
        props.onFileVersionClick({ id: props.measurementId, versionObj: { num: version, isLatestVersion } });
    };

    const handleCheckboxClick = (version: number, isLatestVersion: boolean, action: 'select' | 'deselect') => {
        props.onCheckboxClick({ id: props.measurementId, versionObj: { num: version, isLatestVersion } }, action);
    };

    const handleMouseMove = (id?: { version: number; versionedId?: string }) => {
        if (id === undefined) {
            props.onHighlightMeasurement();
            return;
        }
        if (props.selectedVersions.indexOf(id.version) > -1) {
            props.onHighlightMeasurement(id.versionedId);
        }
    };

    if (error) return null;

    if (isLoading) {
        return (
            <Flex mt="31px" justifyContent="start" pl="16px" bg='backgroundPaper'>
                <Spinner label={t('Common.Toast.Loading', 'Loading...')} labelPosition="right" svgSize="16px" />
            </Flex>
        );
    }

    return (
        <History
            measurementName={props.measurementName}
            history={data!}
            onClose={onClose}
            onFileVersionClick={handleFileClick}
            onCheckboxClick={handleCheckboxClick}
            selectedVersions={props.selectedVersions}
            onDeleteVersion={handleDeleteVersion}
            onMouseMove={handleMouseMove}
        />
    );
};

export default HistoryContainer;
