import { Button, toast } from '@gmg/gmg-react-components';
import { FunctionComponent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RangeSelector from '../visualization/RangeSelector';
import AppContext from '../../AppContext';
import { ActionBtnContainer, FlyOutContent, InteractiveContent } from './style';
import { getSmoothingEvent } from 'src/tracking';
import { getErrorMsgForToast } from '../toastErrorHandler';
import { useSmoothing } from 'src/graphql/customHooks/optimize/useSmoothing';

interface SmoothingProps {
    measurementId: string;
    measurementName: string;
    onStart: () => void;
    onFinish: (id: string | undefined) => void;
    folderId: string;
}

const Smoothing: FunctionComponent<SmoothingProps> = props => {
    const { trackEvent } = useContext(AppContext);

    const [smoothingIntensity, setSmoothingIntensity] = useState(5);
    const { t } = useTranslation();

    const smoothing = useSmoothing(props.measurementId, props.folderId);

    const handleClickSmoothingButton = () => {
        trackEvent(getSmoothingEvent(smoothingIntensity));

        props.onStart();

        const pendingPromise = smoothing.mutateAsync(smoothingIntensity);

        void toast.promise(pendingPromise, {
            loading: t('ToolBar.Smooth.toast_loading_desc', 'We are smoothing the data...'),
            success: _ => {
                // invalidating the corresponding query (in useRemoveOutliers) refetches the measurement,
                // but that alone isn't enough. Repaint has to be triggered by removing the viewmodel
                props.onFinish(props.measurementId);

                return t('ToolBar.Smooth.toast_success_desc', {
                    defaultValue: 'We have successfully smoothed {{fileName}}',
                    fileName: props.measurementName,
                });
            },
            error: response => {
                props.onFinish(undefined);
                return getErrorMsgForToast(response, t);
            },
        });
    };

    return (
        <>
            <FlyOutContent>
                <label className="px10_400_weak">
                    {t('ToolBar.Smooth.content_desc', 'This tool smoothes the data of the file which you selected. It has an impact on all colors within your measurement.')}
                </label>
                <InteractiveContent>
                    <label className="px10_400_normal">
                        {t('ToolBar.Smooth.rangeTitle_title', 'Smoothing strength')}
                    </label>
                    <RangeSelector onChangeSelection={setSmoothingIntensity} value={smoothingIntensity} />
                </InteractiveContent>
            </FlyOutContent>
            <ActionBtnContainer>
                <Button
                    variant="secondary"
                    size="small"
                    onClick={handleClickSmoothingButton}
                    aria-label='Smoother button'
                >
                    {t('Common.ButtonLabels.applyBtn_title', 'Apply')}
                </Button>
            </ActionBtnContainer>
        </>
    );
};

export default Smoothing;
