import { useContext } from 'react';
import { useQueries } from '@tanstack/react-query';
import AppContext from 'src/AppContext';
import { mapFromMeasurementSettingsViewModel } from 'src/graphql/mapper/mapToApplicationSettingsViewModel';
import { mapToMeasurementViewModel } from '../mapper/mapToMeasurementViewModel';
import { TableViewInput, TableView, PatchType } from '../generated/types';

export interface MeasurementRequest {
    id: string;
    condition: string;
    variant: 'diagram' | 'chart';
    outdatedVersion: number | undefined;
    isEditable: boolean;
    folderId: string;
};

export const useMeasurement = (requests: Array<MeasurementRequest>, onError: (id: string) => void) => {
    const context = useContext(AppContext);

    return useQueries({
        queries: requests.map(req => {
            const input: TableViewInput | undefined = context.measurementSettings !== undefined
                ? {
                    id: req.id,
                    version: req.outdatedVersion,
                    patchParams: {
                        condition: req.condition,
                        patchListParams: {
                            singleColorAndOverprintsOnly: req.variant === 'diagram',
                            type: req.variant === 'diagram'
                                ? 'calculatedAverage' as PatchType
                                : 'measured' as PatchType,
                        },
                        patchValueParams: mapFromMeasurementSettingsViewModel(context.measurementSettings),
                    },
                }
                : undefined;

            // Conditions are all measurement conditions available for the measurement file.
            // The response includes only the patches data for the input condition.
            const query = `query GetMeasurementTableView($input: TableViewInput!) {
                    getMeasurementTableView(input: $input) {
                        Id
                        Version
                        Name
                        HasProcessParamsDefined
                        Conditions
                        ColumnLabels
                        RowLabels
                        InkSet {
                          Color
                          Name
                        }
                        Patches {
                          Index
                          InkValues
                          Values {
                            Hex
                            Density
                            LCh
                            Lab
                            ToneValue
                          }
                        }
                      }
                    }`;

            return ({
                queryKey: ['getMeasurementTableView', input],
                queryFn: async () => {
                    await context.populateCacheForAppSynch?.();
                    const res = await (context.api.graphql({ query, variables: { input } }) as Promise<{
                        data: {
                            getMeasurementTableView: TableView;
                        };
                    }>);

                    return mapToMeasurementViewModel(res.data.getMeasurementTableView, req);
                },
                enabled: !!input,
                onError: () => { onError(req.id); },
            });
        }),
    });
};