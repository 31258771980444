import { Box, Flex, GmgTheme } from '@gmg/gmg-react-components';
import { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as PictoStormDark } from '../assets/graphic_cloud-storm-dark-255.svg';
import { ReactComponent as PictoStormLight } from '../assets/graphic_cloud-storm-light-255.svg';
import { ReactComponent as PictoLockDark } from '../assets/graphic_lock-dark-255.svg';
import { ReactComponent as PictoLockLight } from '../assets/graphic_lock-light-255.svg';

export type ApplicationError = 'unauthorized' | 'userWithoutOrg' | 'unhandled';

interface ErrorMsgProps {
    errorType: ApplicationError;
}

// Think about moving this to gmg-react-components
const ErrorMsg: FunctionComponent<ErrorMsgProps> = props => {
    const { t } = useTranslation();
    const theme: GmgTheme = useTheme();

    const getErrorContent = (error: ApplicationError) => {
        switch (error) {
            case 'unauthorized':
                return ({
                    imageDark: <PictoLockDark width={255} height={255} />,
                    imageLight: <PictoLockLight width={255} height={255} />,
                    title: t('ErrorMsg.unauthorizedTitle', 'Oops, it seems like you don\'t have access to this page.'),
                    subtitle: t('ErrorMsg.unauthorizedSub', 'The page you are trying to access has restricted permissions.'),
                });

            case 'userWithoutOrg':
                return ({
                    imageDark: <PictoLockDark width={255} height={255} />,
                    imageLight: <PictoLockLight width={255} height={255} />,
                    title: t('ErrorMsg.userWithoutOrgTitle', 'Oops, it seems like you are not a member of an organization yet.'),
                    subtitle: t('ErrorMsg.unauthorizedSub', 'The page you are trying to access has restricted permissions.'),
                });

            default:
                return ({
                    imageDark: <PictoStormDark width={255} height={255} />,
                    imageLight: <PictoStormLight width={255} height={255} />,
                    title: t('ErrorMsg.unhandledTitle', 'Oh no, we are sorry! It looks like something went wrong.'),
                    subtitle: t('ErrorMsg.unhandledSub', 'We are working on the problem, please try again later.'),
                });
        }
    };

    const content = getErrorContent(props.errorType);

    return (
        <Flex height="100%" bg="backgroundPaper" justifyContent="center">
            <Div>
                {theme.type === 'dark' ? content.imageDark : content.imageLight}
                <h1>{content.title}</h1>
                <Box className="px14_600_contrast" pt="24px">{content.subtitle}
                    <br />
                    <Trans t={t} i18nKey={'ErrorMsg.support'}>
                        If you need support, please contact the administrator of your organization or&nbsp;
                        <a
                            href="https://gmgcolor.com/support"
                            target="_blank"
                            rel="noreferrer"
                        >
                            GMG support.
                        </a>
                    </Trans>
                </Box>
            </Div>
        </Flex>
    );
};

const Div = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 480px;
    text-align: center;
    line-height: 1.4;
`;

export default ErrorMsg;