import { Select } from '@gmg/gmg-react-components';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CmykFilters, CmykMethods, Observers, Illuminants } from 'src/graphql/generated/types';
import styled from 'styled-components';
import AppContext, { DeltaETypes, MeasurementSettings } from '../AppContext';

export interface SettingsProps {
    onChangeMeasurementParams: (type: keyof MeasurementSettings, value: string) => void;
}


type SingleVal = { value: string; label: string };

const DIV = styled.div`
    width: 100%;
    background-color: ${props => props.theme.colors.backgroundPaper};
    padding-bottom: 24px;
`;
const LabelWrapper = styled.div`
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    align-items: center;
    height: 20px;
    padding: 0 24px 4px 24px;
    border-bottom: 1px solid ${props => props.theme.colors.greyContrastSemiLower};
`;
const SelectWrapper = styled(LabelWrapper)`
    padding-top: 10px;
    height: 80px;
    border-bottom: none;
    align-items: baseline;
`;
const P = styled.p`
    margin: 0 0 4px;
`;
const StyledSelectWrapper = styled(SelectWrapper)`
    height: 64px;
`;


const Settings: FC<SettingsProps> = props => {
    const { measurementSettings } = useContext(AppContext);
    const { t } = useTranslation();
    const DeltaEValues: Array<DeltaETypes> = ['DE00', 'DE76'];

    const filterCMYKOptions: Array<SingleVal> = Object.values(CmykFilters).map(value => ({
        value,
        label: t(`Settings.filterCMYK_menu.${value}`, value),
    }),
    );

    const filterDeltaEOptions: Array<SingleVal> = DeltaEValues.map(value => ({
        value,
        label: t(`Settings.deltaE_menu.${value}`, value),
    }),
    );

    const methodSpotAndCMYKOptions: Array<SingleVal> = Object.values(CmykMethods).map(value => ({
        value,
        label: t(`Settings.methodSpotAndCMYK_menu.${value}`, value),
    }),
    );

    const observerOptions: Array<SingleVal> = Object.values(Observers).map(value => ({
        value,
        label: t(`Settings.observer_menu.${value}`, `${value}`),
    }),
    );

    const illuminantOptions: Array<SingleVal> = Object.values(Illuminants).map(value => ({
        value,
        label: t(`Settings.illuminant_menu.${value}`, value),
    }),
    );

    return (
        <DIV>
            <LabelWrapper>
                <label
                    className="px10_600_normal"
                    htmlFor="method_CMYK and method_Spot">
                    {t('Settings.calculationMethod_title', 'Tone Value calculation method')}
                </label>
            </LabelWrapper>
            <SelectWrapper>
                <div>
                    <P className="px10_400_weak">{t('Settings.filterCMYK_desc', 'For CMYK')}</P>
                    <Select
                        name="method_CMYK"
                        value={methodSpotAndCMYKOptions.find(option => option.value === measurementSettings!.methodCMYK)}
                        options={methodSpotAndCMYKOptions}
                        onChange={newValue => props.onChangeMeasurementParams('methodCMYK', (newValue as SingleVal)!.value)}
                        isSearchable={false}
                        width="100%"
                    />
                </div>
                <div>
                    <P className="px10_400_weak">{t('Settings.filterSpot_desc', 'For spot colors')}</P>
                    <Select
                        name="method_Spot"
                        value={methodSpotAndCMYKOptions.find(option => option.value === measurementSettings!.methodSpot)}
                        options={methodSpotAndCMYKOptions}
                        onChange={(newValue) => props.onChangeMeasurementParams('methodSpot', (newValue as SingleVal)!.value)}
                        isSearchable={false}
                        width="100%"
                    />
                </div>

            </SelectWrapper>
            <LabelWrapper>
                <label
                    className="px10_600_normal"
                    htmlFor="observer">
                    {t('Settings.observer_lil', 'Observer')}
                </label>
                <label
                    className="px10_600_normal"
                    htmlFor="illuminant">
                    {t('Settings.illuminant_lil', 'Illuminant')}
                </label>
            </LabelWrapper>
            <StyledSelectWrapper>
                <Select
                    name="observer"
                    value={observerOptions.find(option => option.value === measurementSettings!.observer)}
                    options={observerOptions}
                    onChange={newValue => props.onChangeMeasurementParams('observer', (newValue as SingleVal)!.value)}
                    isSearchable={false}
                    width="100%"
                />
                <Select
                    name="illuminant"
                    value={illuminantOptions.find(option => option.value === measurementSettings!.illuminant)}
                    options={illuminantOptions}
                    onChange={newValue => props.onChangeMeasurementParams('illuminant', (newValue as SingleVal).value)}
                    isSearchable={false}
                    width="100%"
                />
            </StyledSelectWrapper>
            <LabelWrapper>
                <label
                    className="px10_600_normal"
                    htmlFor="filterCMYK">
                    {t('Settings.filterCMYK_lil', 'Density filter')}
                </label>
            </LabelWrapper>
            <SelectWrapper>
                <div>
                    <P className="px10_400_weak">{t('Settings.filterCMYK_desc', 'For CMYK')}</P>
                    <Select
                        name="filter_CMYK_Density"
                        value={filterCMYKOptions.find(option => option.value === measurementSettings!.filterCMYK)}
                        options={filterCMYKOptions}
                        onChange={newValue => props.onChangeMeasurementParams('filterCMYK', (newValue as SingleVal).value)}
                        isSearchable={false}
                        width="100%"
                    />
                </div>
                <div>
                    { /* density filter for spot colors not changeable, but visible. see #79517 */ }
                    <P className="px10_400_weak">{t('Settings.filterSpot_desc', 'For spot colors')}</P>
                    <Select
                        name="filter_Spot_Density"
                        options={[{ label: 'OpenColor Filter', value: '' }]}
                        value={{ label: 'OpenColor Filter', value: '' }}
                        isSearchable={false}
                        width="100%"
                        isDisabled
                    />
                </div>
            </SelectWrapper>
            <LabelWrapper>
                <label
                    className="px10_600_normal"
                    htmlFor="deltaE">
                    {t('Settings.deltaE_title', '∆E Method')}
                </label>
            </LabelWrapper>
            <SelectWrapper>
                <div>
                    <Select
                        name="deltaE"
                        value={filterDeltaEOptions.find(option => option.value === measurementSettings!.deltaE)}
                        options={filterDeltaEOptions}
                        onChange={newValue => props.onChangeMeasurementParams('deltaE', (newValue as SingleVal).value)}
                        isSearchable={false}
                        width="100%"
                    />
                </div>
            </SelectWrapper>
        </DIV>
    );
};

export default Settings;