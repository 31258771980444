import { IconDoubleArrowLeft, IconDoubleArrowRight } from '@gmg/gmg-react-components';
import { FunctionComponent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import AppContext from 'src/AppContext';
import { MeasurementViewModel } from 'src/graphql/ViewModels';
import styled from 'styled-components';
import { ReactComponent as AveragingIcon } from '../../assets/icon_create-average.svg';
import { ReactComponent as EditDensityIcon } from '../../assets/icon_edit-densities.svg';
import { ReactComponent as EditDotgainIcon } from '../../assets/icon_edit-dotgain.svg';
import { ReactComponent as EditMediaWhiteIcon } from '../../assets/icon_edit-media-whitepoint.svg';
import { ReactComponent as EditSolidsIcon } from '../../assets/icon_edit-solids.svg';
import { ReactComponent as EditTonaValueIcon } from '../../assets/icon_edit-tonal-value.svg';
import { ReactComponent as AutoOptimizeIcon } from '../../assets/icon_magic-stick.svg';
import { ReactComponent as RemoveOutlierIcon } from '../../assets/icon_remove-outliers.svg';
import { ReactComponent as RescaleIcon } from '../../assets/icon_rescale-grid.svg';
import { ReactComponent as SmootherIcon } from '../../assets/icon_smooth.svg';
import { ColorCorrection, ColorCorrectionMode } from '../visualization/VisualizationContainer';
import { isStructureIdentical } from '../visualization/chart/validateStructure';
import AutoOptimize from './AutoOptimize';
import Averaging from './Averaging';
import FunctionUnavailable from './FunctionUnavailable';
import RemoveOutliers from './RemoveOutliers';
import Rescale from './Rescale';
import Smoothing from './Smoothing';
import ToolBarItem from './ToolBarItem';
import ToolBarItemWithFlyoutMenu from './ToolBarItemWithFlyoutMenu';

export interface ToolBarProps {
    viewModels: Array<MeasurementViewModel>;
    onCreateFile: (newMeasurementId: string, newMeasurementName: string, conditions: Array<string>) => void;
    onResetViewModel: (measurementId: string) => void;
    disabled: boolean;
    unselectedFiles: Array<{ id: string; name: string }>;
    onActivateColorCorrection: (mode: ColorCorrectionMode | undefined) => void;
    activeColorCorrection: ColorCorrection;
    folderId: string;
}

const ToolBar: FunctionComponent<ToolBarProps> = props => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { isUnlimitedLicenseAvailable, featureFlags } = useContext(AppContext);
    const [indexOfActiveItem, setIndexOfExpandedItem] = useState<number | undefined>();
    const [isToolBarExpanded, setIsToolBarExpanded] = useState(false);
    const [isOptimizationLoading, setIsOptimizationLoading] = useState(false);

    const isGenerallyDisabled =
        props.disabled ||
        isOptimizationLoading ||
        props.activeColorCorrection?.isLoading ||
        props.viewModels.some(vm => vm.isEditable === false); // note that this is not 100% correct because for averaging(which creates a new file) edit permission would not be necessary.

    const handleToolBarItemClick = (index: number) => {
        if (index < 5) {
            props.onActivateColorCorrection(undefined);
        }
        setIndexOfExpandedItem(indexOfActiveItem === index ? undefined : index);
    };

    const handleNavigate = (destination: string) => {
        if (pathname.includes(destination)) return;
        navigate(destination);
    };

    const handleStartOptimization = () => {
        setIsOptimizationLoading(true);
        closeFlyOutMenu();
    };

    const handleFinishOptimization = (measurementId: string | undefined, newMeasurementName?: string) => {
        setIsOptimizationLoading(false);

        if (measurementId === undefined) return; // error cases

        if (newMeasurementName === undefined) {
            props.onResetViewModel(measurementId);
        } else {
            props.onCreateFile(measurementId, newMeasurementName!, props.viewModels[0].availableConditions);
        }
    };

    const closeFlyOutMenu = () => {
        setIndexOfExpandedItem(undefined);
    };

    if (props.viewModels[0] === undefined) {
        return null;
    }

    const isColorCorrectionDisabled = isGenerallyDisabled || props.viewModels.length > 1 || !props.viewModels[0].processParamsDefined;

    return (
        <DIV isLimitedView={!isUnlimitedLicenseAvailable}>
            <ToolBarWrapper aria-label='ToolBar'>
                <ToolbarController
                    isLabelRequired={isToolBarExpanded}
                    icon={isToolBarExpanded
                        ? <IconDoubleArrowLeft />
                        : <IconDoubleArrowRight />}
                    label={isToolBarExpanded
                        ? t('ToolBar.Collapse_toolbar_label', 'Collapse Toolbar')
                        : t('ToolBar.Expand_toolbar_label', 'Expand Toolbar')}
                    isActive={false}
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsToolBarExpanded(!isToolBarExpanded);
                        props.onActivateColorCorrection(undefined);
                    }}
                    isDisabled={isGenerallyDisabled}
                />
                <Optimizations>
                    <ToolBarItemWithFlyoutMenu
                        isLabelRequired={isToolBarExpanded}
                        icon={<AutoOptimizeIcon />}
                        label={t('ToolBar.AutoOptimize.title_title', 'Optimize automatically')}
                        isActive={indexOfActiveItem === 0}
                        onClick={() => {
                            handleToolBarItemClick(0);
                        }}
                        isDisabled={isGenerallyDisabled || !isStructureIdentical(props.viewModels)}
                        onRootClose={closeFlyOutMenu}
                        isHidden={!isUnlimitedLicenseAvailable}
                    >
                        {featureFlags.disableAutoOptimize
                            ? <FunctionUnavailable />
                            : (
                                <AutoOptimize
                                    measurements={props.viewModels}
                                    onStart={handleStartOptimization}
                                    onFinish={handleFinishOptimization}
                                    folderId={props.folderId}
                                />)}
                    </ToolBarItemWithFlyoutMenu>
                    <ToolBarItemWithFlyoutMenu
                        isLabelRequired={isToolBarExpanded}
                        icon={<RemoveOutlierIcon />}
                        label={t('ToolBar.RemoveOutlier.title_title', 'Remove Outliers')}
                        isActive={indexOfActiveItem === 1}
                        onClick={() => {
                            handleToolBarItemClick(1);
                        }}
                        isDisabled={isGenerallyDisabled || props.viewModels.length > 1}
                        onRootClose={closeFlyOutMenu}
                    >
                        {featureFlags.disableRemoveOutlier
                            ? <FunctionUnavailable />
                            : (
                                <RemoveOutliers
                                    measurementId={props.viewModels[0].id}
                                    measurementName={props.viewModels[0].name}
                                    folderId={props.viewModels[0].folderId}
                                    onStart={handleStartOptimization}
                                    onFinish={handleFinishOptimization}
                                />)}
                    </ToolBarItemWithFlyoutMenu>
                    <ToolBarItemWithFlyoutMenu
                        isLabelRequired={isToolBarExpanded}
                        icon={<SmootherIcon />}
                        label={t('ToolBar.Smooth.title_title', 'Smooth')}
                        isActive={indexOfActiveItem === 2}
                        onClick={() => {
                            handleToolBarItemClick(2);
                        }}
                        isDisabled={isGenerallyDisabled || props.viewModels.length > 1}
                        onRootClose={closeFlyOutMenu}
                        isHidden={!isUnlimitedLicenseAvailable}
                    >
                        {featureFlags.disableSmoothing
                            ? <FunctionUnavailable />
                            : (
                                <Smoothing
                                    measurementId={props.viewModels[0].id}
                                    measurementName={props.viewModels[0].name}
                                    folderId={props.viewModels[0].folderId}
                                    onStart={handleStartOptimization}
                                    onFinish={handleFinishOptimization}
                                />)}

                    </ToolBarItemWithFlyoutMenu>
                    {/* Temporary hide the whole Rescale Toolbar Item 'cos of Unavailability */}
                    {!featureFlags.disableRescale && <ToolBarItemWithFlyoutMenu
                        isLabelRequired={isToolBarExpanded}
                        icon={<RescaleIcon />}
                        label={t('ToolBar.Rescale.title_title', 'Rescale')}
                        isActive={indexOfActiveItem === 3}
                        onClick={() => {
                            handleToolBarItemClick(3);
                        }}
                        isDisabled={isGenerallyDisabled || props.viewModels.length > 1}
                        onRootClose={closeFlyOutMenu}
                        isHidden={!isUnlimitedLicenseAvailable}
                    >
                        {featureFlags.disableRescale
                            ? <FunctionUnavailable />
                            : (
                                <Rescale
                                    measurementId={props.viewModels[0].id}
                                    measurementName={props.viewModels[0].name}
                                    unselectedFiles={props.unselectedFiles}
                                    folderId={props.viewModels[0].folderId}
                                    onStart={handleStartOptimization}
                                    onFinish={handleFinishOptimization}
                                />)}
                    </ToolBarItemWithFlyoutMenu>}
                    <ToolBarItemWithFlyoutMenu
                        isLabelRequired={isToolBarExpanded}
                        icon={<AveragingIcon />}
                        label={t('ToolBar.Averaging.title_title', 'Create Average')}
                        isActive={indexOfActiveItem === 4}
                        onClick={() => {
                            handleToolBarItemClick(4);
                        }}
                        isDisabled={isGenerallyDisabled || props.viewModels.length < 2 || !isStructureIdentical(props.viewModels)}
                        onRootClose={closeFlyOutMenu}
                        isHidden={!isUnlimitedLicenseAvailable}
                    >
                        {featureFlags.disableAveraging
                            ? <FunctionUnavailable />
                            : (
                                <Averaging
                                    measurements={props.viewModels}
                                    onStart={closeFlyOutMenu}
                                    onFinish={handleFinishOptimization}
                                    folderId={props.folderId}
                                />)}
                    </ToolBarItemWithFlyoutMenu>
                </Optimizations>
                {isUnlimitedLicenseAvailable && <Divider />}
                {!featureFlags.disableColorCorrections
                    && <ColorCorrections>
                        <ToolBarItem
                            isLabelRequired={isToolBarExpanded}
                            icon={<EditMediaWhiteIcon />}
                            label={t('ToolBar.ColorCorrections.edit_mediaWhite_label', 'Edit media white point')}
                            isActive={indexOfActiveItem === 5 && props.activeColorCorrection?.mode === 'media'}
                            onClick={() => {
                                handleNavigate('spider');
                                handleToolBarItemClick(5);
                                props.onActivateColorCorrection('media');
                            }}
                            isDisabled={isColorCorrectionDisabled}
                            isHidden={!isUnlimitedLicenseAvailable}
                        />
                        <ToolBarItem
                            isLabelRequired={isToolBarExpanded}
                            icon={<EditSolidsIcon />}
                            label={t('ToolBar.ColorCorrections.edit_solids_label', 'Edit solids')}
                            isActive={indexOfActiveItem === 6 && props.activeColorCorrection?.mode === 'solids'}
                            onClick={() => {
                                handleNavigate('spider');
                                handleToolBarItemClick(6);
                                props.onActivateColorCorrection('solids');
                            }}
                            isDisabled={isColorCorrectionDisabled}
                            isHidden={!isUnlimitedLicenseAvailable}
                        />
                        <ToolBarItem
                            isLabelRequired={isToolBarExpanded}
                            icon={<EditDensityIcon />}
                            label={t('ToolBar.ColorCorrections.edit_densities_label', 'Edit densities')}
                            isActive={indexOfActiveItem === 7 && props.activeColorCorrection?.mode === 'density'}
                            onClick={() => {
                                handleNavigate('spider');
                                handleToolBarItemClick(7);
                                props.onActivateColorCorrection('density');
                            }}
                            isDisabled={isColorCorrectionDisabled}
                            isHidden={!isUnlimitedLicenseAvailable}
                        />
                        <ToolBarItem
                            isLabelRequired={isToolBarExpanded}
                            icon={<EditTonaValueIcon />}
                            label={t('ToolBar.ColorCorrections.edit_tonalValue_label', 'Edit tonal value')}
                            isActive={indexOfActiveItem === 8 && props.activeColorCorrection?.mode === 'tonalValue'}
                            onClick={() => {
                                handleNavigate('tonalvalue');
                                handleToolBarItemClick(8);
                                props.onActivateColorCorrection('tonalValue');
                            }}
                            isDisabled={isColorCorrectionDisabled}
                            isHidden={!isUnlimitedLicenseAvailable}
                        />
                        <ToolBarItem
                            isLabelRequired={isToolBarExpanded}
                            icon={<EditDotgainIcon />}
                            label={t('ToolBar.ColorCorrections.edit_dotGain_label', 'Edit dot gain')}
                            isActive={indexOfActiveItem === 9 && props.activeColorCorrection?.mode === 'dotgain'}
                            onClick={() => {
                                handleNavigate('dotgain');
                                handleToolBarItemClick(9);
                                props.onActivateColorCorrection('dotgain');
                            }}
                            isDisabled={isColorCorrectionDisabled}
                            isHidden={!isUnlimitedLicenseAvailable}
                        />
                    </ColorCorrections>
                }
            </ToolBarWrapper>
        </DIV >
    );
};

const DIV = styled.div<{ isLimitedView: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    position: absolute;
    top: calc(50% - ${props => props.isLimitedView ? 42 : 180}px); // 180px = (toolbar height / 2)
`;

const ToolBarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.3);
    padding: 4px;
    background-color: ${props => props.theme.colors.backgroundPaper};
    border-radius:  ${props => props.theme.radii.default}px;
    margin-left: 2px;
`;

const ToolbarController = styled(ToolBarItem)`
    margin-bottom: 24px;
`;

const Divider = styled.span`
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.background};
`;

const Optimizations = styled.div``;
const ColorCorrections = styled.div``;

export default ToolBar;
