import { Flex, Tooltip } from '@gmg/gmg-react-components';
import { Fragment, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PatchType } from '../../../graphql/ViewModels';
import NumberCell from '../NumberCell';
import { ColorCorrection } from '../VisualizationContainer';
import { ReactComponent as ReferenceIcon } from '../../../assets/icon_referenceChecked.svg';

interface Editable {
    value: string;
    hasChanged: boolean;
}
export type Editables = {
    l: Editable;
    a: Editable;
    b: Editable;
    d: Editable;
}
export interface LegendInkRow {
    inkName: string;
    type: PatchType;
    hex: string;
    mixed: string;
    hasInkBeenChanged: boolean | undefined;
    values: Array<{ versionedId: string } & Editables>;
    delta: string | undefined;
}
interface LegendProps {
    measurements: Array<{ name: string; versionedId: string }>;
    data: Array<LegendInkRow>;
    showDeltas: boolean;
    offset: number;
    activeColorCorrection: ColorCorrection;
    onHoverMeasurement: (versionedId?: string) => void;
    onChangeInkValues: (toBeEditedInkName: string, values: Editables) => void;
    hasReference: boolean;
};

const Legend: FunctionComponent<LegendProps> = props => {
    const { t } = useTranslation();

    return (
        <Container offset={props.offset}>
            <ColorNamesTable numberOfRows={props.data.length}>
                <thead>
                    <tr>
                        <th colSpan={2}>&nbsp;</th>
                    </tr>
                    <tr>
                        <th />
                        <th className="px10_400_weak">
                            {t('Legend.name_title', 'Name')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {props.data.map(row => (
                        <tr key={row.inkName}>
                            <td>
                                <Square size="14px" color={row.hex} />
                            </td>
                            <td>
                                {row.inkName.replace(/pantone/i, 'P.')}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </ColorNamesTable>

            <div className="horizontal-scrollbar">
                <Table>
                    <thead>
                        <tr>
                            {props.measurements.map((measurement, i) => (
                                <Fragment key={measurement.versionedId} >
                                    <TH
                                        colSpan={4}
                                        className="px10_400_weak"
                                        onMouseEnter={() => { props.onHoverMeasurement(measurement.versionedId); }}
                                        onMouseLeave={() => { props.onHoverMeasurement(); }}
                                    >
                                        <Tooltip message={measurement.name}>
                                            <Flex alignItems='center' justifyContent='center' pl="4px" pr="4px" gap="4px">
                                                {i === 0 && props.hasReference &&
                                                    <ReferenceIcon aria-label="Reference indicator" />
                                                }
                                                <span>
                                                    {measurement.name}
                                                </span>
                                            </Flex>

                                        </Tooltip>
                                    </TH>
                                </Fragment>))}
                            {props.showDeltas && <th />/* to fill place For ΔE */}
                        </tr>
                        <tr>
                            {props.measurements.map((m, i) => (
                                <Fragment key={m.versionedId}>
                                    <th>L</th>
                                    <th>a</th>
                                    <th>b</th>
                                    <BorderedTH
                                        isLastItem={props.measurements.length - 1 === i && !props.showDeltas}
                                    >
                                        D
                                    </BorderedTH>
                                </Fragment>
                            ))}
                            {props.showDeltas && <th>ΔE</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {props.data.map(colorRow => (

                            <tr key={colorRow.inkName}>

                                {colorRow.values.map(({ l, a, b, d, versionedId: id }) => {
                                    let isLabInEditMode = false;
                                    let isDensityInEditMode = false;
                                    switch (colorRow.type) {
                                        case 'media':
                                            isLabInEditMode = props.activeColorCorrection?.mode === 'media';
                                            break;
                                        case 'singleColor':
                                            isLabInEditMode = props.activeColorCorrection?.mode === 'solids';
                                            isDensityInEditMode = props.activeColorCorrection?.mode === 'density';
                                            break;
                                        default:
                                            break;
                                    }

                                    const labMode = {
                                        isTextBox: isLabInEditMode,
                                        readonly: !isLabInEditMode || Boolean(props.activeColorCorrection?.isLoading),
                                    };
                                    const densityMode = {
                                        isTextBox: isDensityInEditMode,
                                        readonly: !isDensityInEditMode || Boolean(props.activeColorCorrection?.isLoading),
                                    };

                                    return (
                                        <Fragment key={id}>
                                            <ColoredTD bgColor={colorRow.mixed}>
                                                <NumberCell
                                                    value={l.value}
                                                    isHighligted={l.hasChanged}
                                                    bgColor={colorRow.mixed}
                                                    mode={labMode}
                                                    label={`${colorRow.inkName}_L`}
                                                    onChange={value => {
                                                        props.onChangeInkValues(colorRow.inkName, {
                                                            l: { value, hasChanged: true },
                                                            a,
                                                            b,
                                                            d,
                                                        });
                                                    }}
                                                />
                                            </ColoredTD>
                                            <ColoredTD bgColor={colorRow.mixed}>
                                                <NumberCell
                                                    value={a.value}
                                                    isHighligted={a.hasChanged}
                                                    bgColor={colorRow.mixed}
                                                    mode={labMode}
                                                    label={`${colorRow.inkName}_a`}
                                                    onChange={value => {
                                                        props.onChangeInkValues(colorRow.inkName, {
                                                            l,
                                                            a: { value, hasChanged: true },
                                                            b,
                                                            d,
                                                        });
                                                    }}
                                                />
                                            </ColoredTD>
                                            <ColoredTD bgColor={colorRow.mixed}>
                                                <NumberCell
                                                    value={b.value}
                                                    isHighligted={b.hasChanged}
                                                    bgColor={colorRow.mixed}
                                                    mode={labMode}
                                                    label={`${colorRow.inkName}_b`}
                                                    onChange={value => {
                                                        props.onChangeInkValues(colorRow.inkName, {
                                                            l,
                                                            a,
                                                            b: { value, hasChanged: true },
                                                            d,
                                                        });
                                                    }}
                                                />
                                            </ColoredTD>
                                            <ColoredTD bgColor={colorRow.mixed}>
                                                <NumberCell
                                                    value={d.value}
                                                    isHighligted={d.hasChanged}
                                                    bgColor={colorRow.mixed}
                                                    mode={densityMode}
                                                    label={`${colorRow.inkName}_d`}
                                                    onChange={value => {
                                                        props.onChangeInkValues(colorRow.inkName, {
                                                            l,
                                                            a,
                                                            b,
                                                            d: { value, hasChanged: true },
                                                        });
                                                    }}
                                                />
                                            </ColoredTD>
                                        </Fragment>
                                    );

                                })}
                                {colorRow.delta !== undefined && (
                                    <ColoredTD bgColor={colorRow.mixed}>
                                        {colorRow.delta}
                                    </ColoredTD>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </Container >
    );
};

const Container = styled.div<{ offset: number }>`
    display: flex;
    height: fit-content;
    margin-top: 14px;
    max-width: calc(100vw - ${props => props.offset}px);   /* see calc in parent Component*/
    cursor: default;
    grid-row: 2 / 3; // we say explicit that Legend ist in the second row regardless from the existence of Actionbar!
`;

const ColorNamesTable = styled.table<{ numberOfRows: number }>`
    max-height: ${props => (props.numberOfRows * 32) + 64}px; /*thead = 64 see below */
    border-spacing: 0;
    thead {
        height: 64px;
        th {
            padding: 0px;
            text-align: left;
        }
    }
    td {
        padding: 5px 10px;
        text-align: right;
        height: 32px;
        box-sizing: border-box;
        /* The Square 34px (1st td in a row)
         and all other items could take max 94px in order to get a total 128px for Table */
        max-width: 94px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    td:last-child {
        padding: 4px 20px 4px 0;
        text-align: left;
        white-space: nowrap;
    }
`;

const Table = styled.table`
    width: max-content;
    border-spacing: 0;

    thead {
        tr:first-child {
            th:not(:last-child) {
                border-right: 1px solid ${props => props.theme.colors.background};
            }
        }
    } 
    th, td {
        height: 32px;
        box-sizing: border-box;
        text-align: center;
    }

    th {
        width: 56px;
        padding: 6px 0;
        background-color: ${props => props.theme.colors.backgroundPaper};
    }
    
    td {
        max-width: 56px;
        box-sizing: border-box;
        padding: 4px 0 4px 4px;
    }

    svg {
        width: 16px;
        height: 16px;
    }
`;

const TH = styled.th`
    min-width: 180px;
    max-width: 192px;

    svg {
        min-height: 16px;
        min-width: 16px;
        max-height: 16px;
        max-width: 16px;
    }

    span {
        max-width: 100%;    
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 1.6;
    }
`;

const BorderedTH = styled.th<{ isLastItem?: boolean }>`
    border-right:1px solid ${props => props.theme.colors.background};
    border-left:1px solid ${props => props.theme.colors.background};

    &:not(:first-child ){
        border-right: ${props => (props.isLastItem ? 'none' : `1px solid ${props.theme.colors.background}`)}; ;
    }
`;

const ColoredTD = styled.td<{ bgColor: string }>`
    background-color: ${props => props.bgColor};
`;

const Square = styled.div<{ size: string; color: string }>`
    width: ${props => props.size};
    height: ${props => props.size};
    background-color: ${props => props.color};
    border: 0.5px solid ${props => props.theme.colors.greyContrastHighest};
    box-sizing: border-box;
`;

export default Legend;
