import { FunctionComponent } from 'react';
import styled from 'styled-components';

export interface InkInfoRowProps {
    isOdd: boolean;
    colorHex: string;
    colorName: string;
    screeningType: string | undefined;
    rulingValue: string | undefined;
    configuration: string | undefined;
    labdValues: {
        l: string;
        a: string;
        b: string;
        d: string;
    };
};

const InfoInkRow: FunctionComponent<InkInfoRowProps> = (props) => {

    const unit = !props.screeningType
        ? ''
        : props.screeningType.includes('FM')
            ? 'µ'
            : 'dpi';

    return (
        <InkRowGrid isOdd={props.isOdd}>
            <NameContainer>
                <Square size="14px" color={props.colorHex} />
                <span className="px12_400_normal">{props.colorName}</span>
            </NameContainer>
            <Labd>
                <span className="px12_400_normal">{props.labdValues.l}</span>
                <span className="px12_400_normal">{props.labdValues.a}</span>
                <span className="px12_400_normal">{props.labdValues.b}</span>
                <span className="px12_400_normal">{props.labdValues.d}</span>
            </Labd>
            <span className="px12_400_normal">{props.screeningType ?? '-'}</span>
            <span className="px12_400_normal">{props.rulingValue ?? '-'} {unit}</span>
            <span className="px12_400_normal">{props.configuration ?? '-'}</span>
        </InkRowGrid>
    );
};

const Square = styled.div<{ size: string; color: string }>`
    width: ${props => props.size};
    height: ${props => props.size};
    min-width: ${props => props.size};
    min-height: ${props => props.size};
    background-color: ${props => props.color};
    border: 0.5px solid ${props => props.theme.colors.greyContrastHighest};
    box-sizing: border-box;
`;

export const NameContainer = styled.div`
    display: grid;
    grid-template-columns: 48px 100%;
`;

export const Labd = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 44px);
    grid-column-gap: 24px;
    text-align: center;
    margin-right: 40px;
`;

export const InkRowGrid = styled.div<{ isOdd: boolean }>`
    width: 100%;
    display: grid;
    grid-template-columns: 140px max-content repeat(3, 1fr);
    align-items: stretch;
    column-gap: 24px;
    max-height: 100%;
    cursor: default;
    background-color: ${props => props.isOdd ? props.theme.colors.greyContrastLowest : 'unset'};
    margin-bottom: 4px;
    padding: 6px;
    margin-left: -6px;
`;

export default InfoInkRow;