import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { MeasurementVersionViewModel } from 'src/graphql/ViewModels';
import styled from 'styled-components';
import HistoryRow, { HistoryRowGrid } from './HistoryRow';
import Footer from './shared/Footer';
import Section from './shared/Section';
import { Page } from './shared/SharedComponents';

export interface HistoryPageProps {
    header: JSX.Element;
    measurementHistory: Array<MeasurementVersionViewModel> | undefined;
};

const HistoryPage: FunctionComponent<HistoryPageProps> = (props) => {
    const { t } = useTranslation();

    if (!props.measurementHistory) return null;

    return (
        <Page flexDirection="column">
            {props.header}
            <Section title="File history">
                <HistoryHeader>
                    <span>{t('Report.historyPage_date_title', 'Date')}</span>
                    <span>{t('Report.historyPage_action_title', 'Action')}</span>
                    <span>{t('Report.historyPage_details_title', 'Details')}</span>
                </HistoryHeader>
                {props.measurementHistory.map((history, index) => {
                    const atttibutes = history.params.map(
                        ({ key, value }) => ` ${t(`History.keys.${key}_desc` as any)} ${value}`,
                    );

                    return <HistoryRow
                        key={`${history.timestamp}-${index}`}
                        isOdd={index % 2 === 0}
                        date={history.timestamp}
                        entry={t(`History.reasons.${history.reason}_desc`, history.reason)}
                        attributes={atttibutes}
                    />;
                })}
            </Section>
            <Footer />
        </Page>
    );
};

const HistoryHeader = styled(HistoryRowGrid)`
    margin-bottom: 4px;
    color: ${props => props.theme.colors.greyContrastMediumHigher};
`;

export default HistoryPage;