import { DeltaE } from '@gmg/gmg-colorsmath';
import { ButtonGroup, Flex } from '@gmg/gmg-react-components';
import React, { FunctionComponent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from 'src/AppContext';
import styled from 'styled-components';
import { MeasurementViewModel, Patch } from '../../../graphql/ViewModels';
import { generatePatchId } from '../../../graphql/mapper/mapToMeasurementViewModel';
import PatchSquare from '../PatchSquare';
import StructureIncompatible from './StructureIncompatible';
import Tooltip from './Tooltip';
import { isStructureIdentical as isStructureIdenticalFn } from './validateStructure';

export interface ChartProps {
    measurements: Array<MeasurementViewModel>;
    isHistoryMode: boolean;
}

interface ChartPatch extends Patch {
    isGap: boolean;
}

const Chart: FunctionComponent<ChartProps> = props => {
    const firstMeasurement = props.measurements[0];
    const [selectedPageIndex, setSelectedPageIndex] = useState<number>(0);
    const [tooltipField, setTooltipField] = useState<string | undefined>();
    const tooltipContainerElement = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    const deltaESettings = useContext(AppContext).measurementSettings.deltaE;
    const calculateDeltaE = deltaESettings === 'DE76'
        ? DeltaE.deltaE76
        : DeltaE.deltaE00;

    const onMouseMove = useCallback((e: MouseEvent) => {
        if (!tooltipContainerElement.current) return;

        tooltipContainerElement.current.style.left = `${e.clientX - 650}px`;
        tooltipContainerElement.current.style.top = `${e.pageY + 30}px`;
    }, [tooltipContainerElement]);

    useEffect(() => {
        document.addEventListener('mousemove', onMouseMove);
        return () => {
            document.removeEventListener('mousemove', onMouseMove);
        };
    }, [onMouseMove]);

    if (!props.measurements.length) {
        return null;
    }

    if (!isStructureIdenticalFn(props.measurements)) {
        return <StructureIncompatible />;
    }

    let patchSize = Math.floor(700 / firstMeasurement.columnLabels.length);
    if (patchSize > 30) {
        patchSize = 30;
    }
    if (patchSize < 16) {
        patchSize = 16;
    }

    const patchesWithGaps: Array<ChartPatch> = [];

    Array.from(Array(firstMeasurement.pageCount)).forEach((_, pageIndex) => {
        firstMeasurement.rowLabels.forEach((__, rowIndex) => {
            firstMeasurement.columnLabels.forEach((___, colIndex) => {
                const patch = firstMeasurement.patches.find(p => p.pageIndex === pageIndex && p.rowIndex === rowIndex && p.columnIndex === colIndex);
                patchesWithGaps.push(patch
                    ? { ...patch, isGap: false }
                    : { // create a dummy patch for the gap
                        id: generatePatchId(false, pageIndex, rowIndex, colIndex, []),
                        pageIndex,
                        rowIndex,
                        rowLabel: '',
                        columnIndex: colIndex,
                        columnLabel: '',
                        inkValues: [],
                        value: { hex: '', lab: [NaN, NaN, NaN], lch: [NaN, NaN, NaN], density: 0, toneValue: 0 },
                        characteristic: undefined,
                        isCalculatedAverage: false,
                        isGap: true,
                    });
            });
        });
    });

    const calculateHex2 = (patch: Patch): string | undefined => {
        const { rowIndex, columnIndex } = patch;

        if (props.measurements.length !== 2) return undefined;
        const correspondingPatchFromSecondMeasurement = props.measurements[1].patches
            .find(p => p.pageIndex === selectedPageIndex && p.rowIndex === rowIndex && p.columnIndex === columnIndex);

        return correspondingPatchFromSecondMeasurement
            ? correspondingPatchFromSecondMeasurement.value?.hex
            : undefined;
    };

    const calculateInks = (patch: Patch): Array<{ inkName: string; hex: string; percentage: number }> =>
        Array.from(props.measurements[0].inks.keys())
            .map(inkName => ({
                inkName,
                hex: props.measurements[0].inks.get(inkName)!,
                percentage: patch.inkValues.find(inkValue => inkValue.name === inkName)!.value,
            }));

    return (
        <Flex flexDirection="column"> {/* additional flex container needed to get page buttons and chart aligned left */}
            <PagesButtonGroup
                buttons={Array.from(Array(firstMeasurement.pageCount)).map((_, index) => ({
                    caption: `${t('Chart.pagesBtnCaption_title', 'Page')} ${index + 1}`,
                    isSelected: index === selectedPageIndex,
                }))}
                onClick={setSelectedPageIndex}
                indent={patchSize}
            />
            <ChartGrid
                className='vertical-scrollbar'
                columns={firstMeasurement.columnLabels.length + 1}
                onMouseLeave={() => { setTooltipField(undefined); }}
            >
                {
                    firstMeasurement.rowLabels.map((label, currentRowIndex) => (
                        <React.Fragment key={`${currentRowIndex}_${label}`}>
                            <YAxisItem
                                onMouseEnter={() => { setTooltipField(undefined); }}
                                aria-label={`Row ${label}`}
                                size={patchSize}
                            >
                                <AxisCaption className="px10_400_normal">{label}</AxisCaption>
                            </YAxisItem>
                            {
                                patchesWithGaps
                                    .filter(patch => patch.pageIndex === selectedPageIndex && patch.rowIndex === currentRowIndex)
                                    .map(patch => patch.isGap
                                        ? <div key={patch.id} />
                                        : (
                                            <SquareContainer
                                                key={patch.id}
                                                aria-label={`Patch ${patch.columnLabel}${patch.rowLabel}`}
                                                onMouseEnter={() => { setTooltipField(patch.id); }}
                                                size={patchSize}
                                            >
                                                <PatchSquare
                                                    size="100%"
                                                    hex1={patch.value.hex}
                                                    hex2={calculateHex2(patch)}
                                                />
                                            </SquareContainer>
                                        ),
                                    )
                            }
                        </React.Fragment>),
                    )}
                <div />
                {
                    firstMeasurement.columnLabels.map(label => (
                        <XAxisItem
                            key={`${label}`}
                            onMouseEnter={() => { setTooltipField(undefined); }}
                            aria-label={`Column ${label}`}
                            size={patchSize}
                        >
                            <AxisCaption className="px10_400_weak">{label}</AxisCaption>
                        </XAxisItem>
                    ))
                }
                <div style={{ position: 'absolute' }} ref={tooltipContainerElement}>
                    {tooltipField && (
                        <Tooltip
                            field={`${firstMeasurement.patches.find(patch => patch.id === tooltipField)!.columnLabel}${firstMeasurement.patches.find(patch => patch.id === tooltipField)!.rowLabel}`}
                            hex1={firstMeasurement.patches.find(patch => patch.id === tooltipField)!.value.hex}
                            hex2={calculateHex2(firstMeasurement.patches.find(patch => patch.id === tooltipField)!)}
                            measurements={new Map(props.measurements.map(m => [
                                props.isHistoryMode ? m.versionLabel : m.name,
                                m.patches.find(patch => patch.id === tooltipField)!.value.lab,
                            ]))}
                            inks={calculateInks(firstMeasurement.patches.find(patch => patch.id === tooltipField)!)}
                            calculateDeltaE={calculateDeltaE}
                        />)}
                </div>
            </ChartGrid>
        </Flex>
    );
};

const YAxisItem = styled.div<{ size: number }>`
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    position: relative;
    border: solid 1.5px ${props => props.theme.colors.background};
    background-color: ${props => props.theme.colors.greyContrastMediumHigher};

    &:hover {
        background-color: ${props => props.theme.colors.greyContrastMediumLower};
    }
`;

const XAxisItem = styled.div<{ size: number }>`
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    position: relative;
    border: solid 1.5px transparent;
`;

const AxisCaption = styled.span`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const SquareContainer = styled.div<{ size: number }>`
    position: relative;
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    border: 1.5px solid ${props => props.theme.colors.background};

    &:hover {
        border-color: ${props => props.theme.colors.highlight};
    }
`;

const PagesButtonGroup = styled(ButtonGroup) <{ indent: number }>`
    margin-bottom: 8px;
    margin-left: ${props => props.indent}px;
`;

const ChartGrid = styled.div<{ columns: number }>`
    display: inline-grid;
    grid-template-columns: repeat(${props => props.columns}, max-content);
    overflow: auto;
    max-height: calc(100vh - 220px);
    max-width: calc(100vw - 420px);
    padding-right: 12px;
    cursor: default;
`;

export default Chart;