import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import AppContext from 'src/AppContext';
import { CmykFilters, CmykMethods, ColorCorrectionPayload, TableViewInput, UpdateTonalValueOrDotGainInput } from '../../generated/types';

const mutation = `mutation UpdateDotGain($input: UpdateTonalValueOrDotGainInput!) {
    updateDotGain(input: $input) {
        measurementId
        succeeded
    }
}`;

export interface DotgainMutationInput {
    measurementId: string;
    graphs: Array<{
        ink: string;
        values: Array<{ index: number; percentage: number }>;
    }>;
}

export const useDotgainValueCorrection = (condition: string) => {
    const context = useContext(AppContext);
    const queryClient = useQueryClient();

    return useMutation(
        async (params: DotgainMutationInput) => {
            await context.populateCacheForAppSynch?.();

            const input: UpdateTonalValueOrDotGainInput = {
                measurementId: params.measurementId,
                condition,
                filterCMYK: context.measurementSettings.filterCMYK as CmykFilters,
                methodSpot: context.measurementSettings.methodSpot as CmykMethods,
                methodCMYK: context.measurementSettings.methodCMYK as CmykMethods,
                graphs: params.graphs,
            };

            const response = await (context.api.graphql({
                query: mutation,
                variables: { input },
            }) as Promise<{
                data: {
                    updateDotGain: ColorCorrectionPayload;
                };
            }>);

            return response.data;
        },
        {
            onSettled: async (_, __, variables) => {
                await queryClient.invalidateQueries({
                    predicate: q =>
                        (q.queryKey[0] === 'getMeasurementTableView' && (q.queryKey[1] as TableViewInput).id === variables.measurementId) ||
                        (q.queryKey[0] === 'getMeasurementHistory' && q.queryKey[1] === variables.measurementId),
                    type: 'all', // also inactive
                });
            },
        },
    );
};