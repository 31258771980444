import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import AppContext from 'src/AppContext';
import { ClientType, MutateMeasurementInput, MutateMeasurementPayload } from '../generated/types';

interface UseMultipleFileUploadParams {
    uploadUrls: Array<{ fileName: string; url: string }> | undefined;
    contentToBeUploaded: Map<string, string>;
    folderId: string;
}
interface MutationFunctionParams {
    uploadUrl: string;
    content: string;
    folderId: string;
}

const mutationQuery = `mutation AddMeasurement($input: MutateMeasurementInput!) {
    addMeasurement(input: $input) {
        data {
          name
        }
      }
    }`;

export const useFileUpload = () => {
    const context = useContext(AppContext);

    return useMutation(
        async ({ uploadUrl, content, folderId }: MutationFunctionParams) => {

            const uploadResult = await fetch(uploadUrl, { method: 'PUT', body: content });

            if (uploadResult.status !== 200) {
                throw new Error(`File can't be uploaded. Status: ${uploadResult.status}, message: ${uploadResult.statusText}`);
            }

            await context.populateCacheForAppSynch?.();

            const input: MutateMeasurementInput = {
                folderId,
                uploadUrl,
                clientType: ClientType.Webapp,
            };

            const response = await (context.api.graphql({ query: mutationQuery, variables: { input } }) as Promise<{
                data: {
                    addMeasurement: MutateMeasurementPayload;
                };
            }>);

            if (!response.data.addMeasurement.data?.name) {
                throw new Error('addMeasurement failed');
            }

            return response.data.addMeasurement.data.name;
        });
};

export const useMultipleFileUpload = () => {

    const queryClient = useQueryClient();
    const { mutateAsync } = useFileUpload();

    return useMutation(
        async ({ uploadUrls, contentToBeUploaded, folderId }: UseMultipleFileUploadParams) => {
            const mutationResults = await Promise.allSettled(
                uploadUrls!.map(({ url, fileName }) => mutateAsync({
                    uploadUrl: url,
                    content: contentToBeUploaded.get(fileName)!,
                    folderId,
                })),
            );

            const isSomeRejected = mutationResults.some(res => res.status === 'rejected');

            if (isSomeRejected) {
                let fileNames: Array<string> = [];

                if (uploadUrls!.length === 1) {
                    fileNames.push(uploadUrls![0].fileName);
                } else {
                    const successfullyUploadedFiles = (mutationResults
                        .filter(r => r.status === 'fulfilled') as PromiseFulfilledResult<string>[])
                        .map(r => r.value);

                    Array.from(contentToBeUploaded.keys())
                        .forEach(fileNameWithExtension => {
                            const isSucceded = successfullyUploadedFiles.find(fileName => fileNameWithExtension.startsWith(fileName));
                            if (!isSucceded) {
                                fileNames.push(fileNameWithExtension);
                            }
                        });
                }

                throw new Error(fileNames.join(','));
            }

            return 'success';
        },
        {
            onSettled: async (_, __, variables) => { // also in case of errors, reload the list
                await queryClient.invalidateQueries(['folderContent', variables.folderId]);
            },
        },
    );
};