import { MeasurementViewModel } from '../../../graphql/ViewModels';

const isStructureIdentical = (measurements: Array<MeasurementViewModel>) => {
    if (measurements.length < 2) return true;

    const pageCountOfTemplate = measurements[0].pageCount;
    const rowTemplate = measurements[0].rowLabels;
    const columnTemplate = measurements[0].columnLabels;
    const inkTemplate = Array.from(measurements[0].inks.keys());
    const conditionsTemplate = measurements[0].availableConditions;

    return measurements.every(m => {
        const inks = Array.from(m.inks.keys());

        if (m.pageCount !== pageCountOfTemplate ||
            m.rowLabels.length !== rowTemplate.length ||
            m.columnLabels.length !== columnTemplate.length ||
            inks.length !== inkTemplate.length ||
            m.availableConditions.length !== conditionsTemplate.length
        ) {
            return false;
        }
        if (!m.rowLabels.every((rowLabel, index) => rowLabel === rowTemplate[index])) {
            return false;
        }
        if (!m.columnLabels.every((columnLabel, index) => columnLabel === columnTemplate[index])) {
            return false;
        }
        if (!inks.every(ink => inkTemplate.includes(ink))) {
            return false;
        }
        if (!m.availableConditions.every(condition => conditionsTemplate.includes(condition))) {
            return false;
        }

        return true;
    });
};

export { isStructureIdentical };
