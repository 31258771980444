import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import AppContext from 'src/AppContext';
import { OptimizationPayload, RemoveOutliersInput, TableViewInput } from '../../generated/types';

const mutation = `mutation RemoveOutliers($input: RemoveOutliersInput!) {
    removeOutliers(input: $input) {
        measurementId
        succeeded
    }
}`;

export const useRemoveOutliers = (measurementId: string, folderId: string) => {
    const context = useContext(AppContext);
    const queryClient = useQueryClient();

    return useMutation(
        async () => {
            await context.populateCacheForAppSynch?.();

            const input: RemoveOutliersInput = {
                measurementId,
            };

            const response = await (context.api.graphql({
                query: mutation,
                variables: { input },
            }) as Promise<{
                data: {
                    removeOutliers: OptimizationPayload;
                };
            }>);

            return response.data.removeOutliers.succeeded;
        },
        {
            onSettled: async () => {
                await queryClient.invalidateQueries({
                    predicate: q =>
                        (q.queryKey[0] === 'getMeasurementTableView' && (q.queryKey[1] as TableViewInput).id === measurementId && (q.queryKey[1] as TableViewInput).version === undefined) ||
                        (q.queryKey[0] === 'getMeasurementHistory' && q.queryKey[1] === measurementId) ||
                        (q.queryKey[0] === 'folderContent' && q.queryKey[1] === folderId),

                    type: 'all', // also inactive
                });
            },
        },
    );
};