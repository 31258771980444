import { InkSettings, ProcessParamViewModel, SingleVal } from '../ViewModels';
import { ProcessParamsPayload } from '../generated/types';
import { processParameters } from '../testResponses'; // TODO!
import generateHash from 'object-hash';

export interface ScreeningType {
    label: string;
    value: string;
    configurations: Array<SingleVal> | undefined;
}
export interface SubstrateCategory {
    label: string;
    value: string;
    substrateTypes: Array<SubstrateType>;
    filmMaterial: Array<SingleVal> | undefined;
}
interface SubstrateType {
    label: string;
    value: string;
}

export interface AvailableProcessParams {
    printingProcesses: Array<SingleVal>;
    surfaceFinishings: Array<SingleVal>;
    substrateCategories: Array<SubstrateCategory>;
    screeningTypes: Array<ScreeningType>;
    printDirection: Array<SingleVal>;
}
interface PrintDirection {
    front: SingleVal;
    reverse: SingleVal;
}

export const printDirection: PrintDirection = {
    front: {
        label: 'Front',
        value: '',
    },
    reverse: {
        label: 'Reverse',
        value: 'true',
    },
};

export const mapToScreenTypeViewModel = (processParams: Array<any>): Array<ScreeningType> => {
    return processParams
        .map((source: any) => ({
            label: source.label,
            value: source.key,
            configurations: mapScreenTypeConfig(source.configurations),
        }));
};

const mapScreenTypeConfig = (arr: Array<{ label: string; value: number }> | null): undefined | Array<SingleVal> => {
    if (!arr) return undefined;
    return arr.map(({ label, value }) => ({ label, value: `${value}` }));
};

export const mapToSubstrateCategoryViewModel = (substrateCategories: Array<any>): Array<SubstrateCategory> => {
    return substrateCategories
        .map((source: any) => ({
            label: source.label,
            value: source.key,
            substrateTypes: mapSubstrateTypes(source.substrateTypes),
            filmMaterial: source.filmMaterial ? mapToSingleValueArray(source.filmMaterial) : undefined,
        }));
};

const mapSubstrateTypes = (arr: Array<{
    label: string;
    key: string;
}>): Array<SubstrateType> => {

    return arr.map(({ label, key }) => ({
        label,
        value: key,
    }));
};

const mapToSingleValueArray = (arr: Array<any>): Array<SingleVal> => arr.map(({ label, key }) => ({ label, value: key }));

export const mapToAvailableProcessParamsViewModel = (arr: any): AvailableProcessParams => {
    return {
        printingProcesses: mapToSingleValueArray(arr.printingProcesses),
        surfaceFinishings: mapToSingleValueArray(arr.surfaceFinishings),
        substrateCategories: mapToSubstrateCategoryViewModel(arr.substrateCategories),
        screeningTypes: mapToScreenTypeViewModel(arr.screenTypes),
        printDirection: [printDirection.front, printDirection.reverse],
    };
};

const getSingleValue = (prediction: string | undefined | null, availableParams: Array<SingleVal>) => {

    if (!!prediction && !!availableParams) {
        const { label, value } = availableParams.find(type => type.value === prediction)!;
        return { label, value };
    }

    return undefined;
};

export const mapToProcessParamsViewModel = (source: ProcessParamsPayload): ProcessParamViewModel => {
    const availableParams = mapToAvailableProcessParamsViewModel(processParameters);

    const printingProcess = getSingleValue(source.printProcess, availableParams.printingProcesses);
    const printFinishing = getSingleValue(source.finishing, availableParams.surfaceFinishings);
    const substrate = availableParams.substrateCategories.find(cat => cat.value === source.substrate.category)!;

    const substrateType = getSingleValue(source.substrate.type, substrate.substrateTypes);
    const filmMaterial = getSingleValue(source.substrate.filmMaterial, substrate.filmMaterial!);

    const result = {
        printingProcess: printingProcess!,
        printFinishing: printFinishing!,
        substrateCategory: { label: substrate.label, value: substrate.value },
        substrateType: substrateType,
        filmMaterial: filmMaterial,
        printDirection: source.isReversePrinting
            ? printDirection.reverse
            : printDirection.front,
        inks: source.inks
            .sort((a, b) => a.sequenceIndex - b.sequenceIndex)
            .map<InkSettings>(ink => {

            const screeningType = availableParams.screeningTypes.find(type => type.value.toUpperCase() === ink.screen.type.toUpperCase());

            return {
                inkName: ink.name,
                hash: generateHash({ sequenceIndex: ink.sequenceIndex }),
                screeningType: screeningType
                    ? { label: screeningType.label, value: screeningType.value }
                    : undefined,
                screeningRulingValue: ink.screen.ruling > 0
                    ? ink.screen.ruling.toString()
                    : '',
                screeningConfiguration: screeningType?.configurations?.find(config => config.value === ink.screen.configuration.toString()),
            };
        }),
    };

    return result;
};
