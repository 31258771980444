import { Flex } from '@gmg/gmg-react-components';
import { FunctionComponent } from 'react';
import { formatDate } from 'src/shared/format';
import styled from 'styled-components';
import { ReactComponent as GMGLogo } from '../../../assets/GMG-Logo-sRGB.svg';

export interface PageFooterProps {};

const Footer: FunctionComponent<PageFooterProps> = () => {

    return (
        <DIV>
            <StyledFlex>
                <span className='px10_400_weak'>GMG GmbH & Co. KG</span>
                <GMGLogo />
            </StyledFlex>
            <span className='px10_400_weak'> {formatDate(new Date())}</span>
        </DIV>
    );
};

const DIV = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    padding: 8px 16px 0;
    border-top: 1px solid ${props => props.theme.colors.greyContrastMediumHigher};
`;

const StyledFlex = styled(Flex)`
    width: max-content;
    gap: 8px;
    align-items: center;

    svg {
        width: 32px;
    }
`;

export default Footer;