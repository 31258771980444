import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import AppContext from 'src/AppContext';
import { TableViewInput, RescaleInput, OptimizationPayload } from '../../generated/types';

const mutation = `mutation Rescale($input: RescaleInput!) {
    rescale(input: $input) {
        measurementId
        succeeded
    }
}`;

export const useRescale = (measurementId: string, folderId: string) => {
    const context = useContext(AppContext);
    const queryClient = useQueryClient();

    return useMutation(
        async (templateMeasurementId: string) => {
            await context.populateCacheForAppSynch?.();

            const input: RescaleInput = {
                measurementId,
                templateMeasurementId,
            };

            const response = await (context.api.graphql({
                query: mutation,
                variables: { input },
            }) as Promise<{
                data: {
                    rescale: OptimizationPayload;
                };
            }>);

            return response.data.rescale.succeeded;
        },
        {
            onSettled: async (_, __, input) => {
                await queryClient.invalidateQueries({
                    predicate: q =>
                        (q.queryKey[0] === 'getMeasurementTableView' && (q.queryKey[1] as TableViewInput).id === measurementId && (q.queryKey[1] as TableViewInput).version === undefined) ||
                        (q.queryKey[0] === 'getMeasurementHistory' && q.queryKey[1] === measurementId) ||
                        (q.queryKey[0] === 'folderContent' && q.queryKey[1] === folderId),
                    type: 'all', // also inactive
                });
            },
        },
    );
};