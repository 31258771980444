import { MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { Fragment, FunctionComponent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from 'src/AppContext';
import { draggablePoint } from 'src/shared/DraggablePointModule';
import { getHighlightedColor } from 'src/shared/getHighlightedColor';
import useFormatter from 'src/shared/useFormatter';
import { getDotGainAutoCorrectionEvent } from 'src/tracking';
import styled, { useTheme } from 'styled-components';
import DraggablePoint from '../tonalvalue/DraggablePoint';
import { EditedPoint } from './Dotgain';
import { DotGainGridData } from './calculationsForDotgain';


export interface DotGainGridProps {
    diagramWidth: number;
    diagramHeight: number;
    data: Array<DotGainGridData>;
    highlightedMeasurement?: string | undefined;
    scalingFactor: number;
    isPointsVisible: boolean;
    yAxisOffset: number;
    isMethodSctv: boolean;
    isColorCorrectionActive: boolean;
    onChangeInkValue: (editedPoint: EditedPoint) => void;
};

const DotGainGrid: FunctionComponent<DotGainGridProps> = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { formatNumber } = useFormatter();
    const { trackEvent } = useContext(AppContext);
    const sensors = useSensors(useSensor(MouseSensor));// https://github.com/clauderic/dnd-kit/issues/208
    const { DndContext } = draggablePoint();

    const xAxisItems = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    const yAxisItems = props.isMethodSctv
        ? [30, 25, 20, 15, 10, 5, 0, -5, -10, -15, -20]
        : [45, 40, 35, 30, 25, 20, 15, 10, 5, 0, -5];

    return (
        <DndContext
            sensors={sensors}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
            onDragEnd={(e) => {

                const differenceAfterDrop = (e.delta.y * -1) / props.diagramHeight * 50;
                const updatedDotGainValue = e.active.data.current.currentValue + differenceAfterDrop;
                const increasedValueMoreThanMax = differenceAfterDrop > 0 && updatedDotGainValue > e.active.data.current.range.max;
                const decreasedValueLessthanMin = differenceAfterDrop < 0 && updatedDotGainValue < e.active.data.current.range.min;
                let newValue = updatedDotGainValue;

                if (increasedValueMoreThanMax) {
                    newValue = e.active.data.current.range.max;
                    trackEvent(getDotGainAutoCorrectionEvent('diagram'));
                }
                if (decreasedValueLessthanMin) {
                    newValue = e.active.data.current.range.min;
                    trackEvent(getDotGainAutoCorrectionEvent('diagram'));
                }

                props.onChangeInkValue(new EditedPoint({
                    ink: e.active.data.current.inkName!,
                    index: e.active.data.current.index,
                    percentageText: formatNumber(newValue)!,
                }));
            }}

        >
            <Grid width={props.diagramWidth} height={props.diagramHeight}>
                {[...Array(100)].map((_, index) => <div key={`c${index}`} />)}
                {
                    yAxisItems.map((item, index) => (
                        <YAxisItem
                            key={`y${index}`}
                            className="px10_400_weak"
                            index={index}
                            width={props.diagramWidth}
                            height={props.diagramHeight}
                        >
                            {item}
                        </YAxisItem>
                    ))
                }
                {
                    xAxisItems.map((item, index) => (
                        <XAxisItem
                            key={`x${index}`}
                            className="px10_400_weak"
                            index={index}
                            length={item.toString().length}
                            width={props.diagramWidth}
                        >
                            {item}
                        </XAxisItem>
                    ))
                }

                <XaxisCaption className="px10_400_weak">{t('Dotgain.xAxis_title', 'Ink Value')} (%)</XaxisCaption>
                <YaxisCaption className="px10_400_weak">{t('Dotgain.yAxis_title', 'Dotgain')} (%)</YaxisCaption>

                <Svg version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <line className="axis" x1="0" y1={`${props.yAxisOffset * 10}%`} x2="100%" y2={`${props.yAxisOffset * 10}%`} />
                    <line className="axis" x1="0" y1="0" x2="0" y2="100%" />
                    {props.data.map(m => (
                        m.inks.map(({ hex, lineDefinition, editedLineDefinition, points, nameOfInk }) => (
                            <Fragment key={`${m.versionedMeasurementId}_${nameOfInk}`}>
                                {editedLineDefinition && <path
                                    stroke={hex}
                                    strokeWidth={props.scalingFactor}
                                    fill="none"
                                    d={editedLineDefinition}
                                />}
                                <path
                                    stroke={props.highlightedMeasurement === m.versionedMeasurementId
                                        ? getHighlightedColor(hex, theme)
                                        : hex
                                    }
                                    strokeWidth={(props.highlightedMeasurement === m.versionedMeasurementId ? 1.4 : 1) * props.scalingFactor}
                                    fill="none"
                                    strokeDasharray={editedLineDefinition ? 4 : ''}
                                    d={lineDefinition}
                                />
                                {props.isPointsVisible && points!.map(point => (
                                    <DraggablePoint
                                        key={point.id}
                                        point={point}
                                        scalingFactor={props.scalingFactor}
                                        hex={hex}
                                        isColorCorrectionActive={props.isColorCorrectionActive}
                                    />
                                ))}
                            </Fragment>))
                    ))}
                </Svg>
            </Grid>
        </DndContext>
    );
};

const Grid = styled.div<{ width: number; height: number }>`
    display: grid;
    position: relative;
    grid-template-columns: repeat(10, ${props => props.width / 10}px);
    border-right: solid 1px ${props => props.theme.colors.greyContrastSemiLower};
    border-bottom: solid 1px ${props => props.theme.colors.greyContrastSemiLower};
    margin: 14px 48px 50px 28px;
    cursor: default;

    div {
        height: ${props => props.height / 10 - 1}px;
        border-top: solid 1px ${props => props.theme.colors.greyContrastSemiLower};
        border-left: solid 1px ${props => props.theme.colors.greyContrastSemiLower};
    }

    span {
        position: absolute;
    }
`;

const YAxisItem = styled.span.attrs<{ index: number; width: number; height: number }>(props => ({
    style: {
        top: `${props.index * props.height / 10.1 - 4}px`,
    },
})) <{ index: number; width: number; height: number }>`
    right: ${props => props.width + 6}px;
`;

const XAxisItem = styled.span.attrs<{ index: number; length: number; width: number }>(props => ({
    style: {
        left: `${props.index * props.width / 10 - 2 - props.length * 2}px`,
    },
})) <{ index: number; length: number; width: number }>`
    bottom: -22px;
`;

const XaxisCaption = styled.span`
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
`;

const YaxisCaption = styled.span`
    top: 60%;
    transform: rotate(-90deg);
    transform-origin: bottom left;
    left: -24px;
`;

const Svg = styled.svg`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: visible;

    line.axis {
        stroke: ${props => props.theme.colors.greyContrastMedium};
        stroke-width: 1;
    }
`;

export default DotGainGrid;