import React, { ReactNode } from 'react';
import AppContext, { IAppContext } from '../AppContext';

interface ErrorBoundaryProps {
    children: ReactNode;
}

interface ErrorBoundaryState {
    error: Error | undefined;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {

    static contextType = AppContext;

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { error };
    }

    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { error: undefined };
    }


    componentDidCatch(error: Error, errorInfo: any) {
        // Here is the place for e.g. logging the error to an error reporting service
    }


    componentDidUpdate(_: ErrorBoundaryProps, prevState: ErrorBoundaryState) {
        if (this.state.error && !prevState.error) {
            (this.context as IAppContext).onError(this.state.error);
        }
    }

    render() {
        // You can render any custom fallback UI
        // if (this.state.error) {
        //     return <h1 data-testid='errorboundary'>Something went wrong</h1>;
        // }

        /*
        if (this.state.error) {
            // You can render any custom fallback UI

            console.error(this.state.error);

            // set modal element here instead of error

            return React.Children.map(this.props.children, child =>
                React.cloneElement(child as React.ReactElement<any>, { error: this.state.error })
            );
        }*/

        return this.props.children;
    }
}

export default ErrorBoundary;