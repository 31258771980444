import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as StarOutlined } from '../../assets/icon_star-outlined.svg';
import { Button } from '@gmg/gmg-react-components';

interface TeaserContextProps {
    onClickhandler: (event?: any) => void;
}

const GetPremiumUpgradeTeaser: FunctionComponent<TeaserContextProps> = (props) => {
    const { t } = useTranslation();

    return (
        <Main>
            <StarOutlined width={22} height={22} />
            <Content>
                <span className="px12_600_normal">
                    {t('Teaser.PremiumUpgrade.teaser_title_title', 'Your benefits with our premium upgrade')}
                </span>
                <span className="px10_400_normal">
                    {t('Teaser.PremiumUpgrade.content_main_desc',
                        'From enhanced functionality to advanced customization options - upgrade to our premium package and use our entire feature set that will ease the way of profile creation.')}
                </span>
                <CTA
                    variant='secondarySmall'
                    aria-label="Get premium upgrade"
                    onClick={props.onClickhandler}
                >
                    {t('Teaser.PremiumUpgrade.action_btn_desc', 'Get premium upgrade')}
                </CTA>
            </Content>
        </Main>
    );
};

const Main = styled.div`
    display: flex;
    margin: auto 0;
    gap: 16px;
    padding: 0px 0px 16px 0px;
`;

const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
`;

const CTA = styled(Button)`
    margin-top: 16px;
`;

export default GetPremiumUpgradeTeaser;