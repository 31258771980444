import { FC } from 'react';
import styled from 'styled-components';

interface RangeSelectorProps {
    onChangeSelection: (range: number) => void;
    value: number;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 6px;
`;

const SPAN = styled.span`
  width: 24px;
  min-width:24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow:2;
  border: 1px solid ${(props) => props.theme.colors.greyContrastHigh};
  border-radius: ${(props) => props.theme.radii.default}px;
  &:hover {
    border-color: ${(props) => props.theme.colors.textContrast};
  }
`;
const CustomRangeInput = styled.input<{ value: number }>`
  height: 3px;
  width: 100%;
  flex-grow:1;
  background: ${(props) => props.theme.colors.greyContrastMediumHigher};
  background-image: linear-gradient(
    ${(props) => props.theme.colors.highlight},
    ${(props) => props.theme.colors.highlight}
  );
  border-radius: ${(props) => props.theme.radii.default}px;
  -webkit-appearance: none;
  background-size: calc(${(props) => (props.value - 1) * 11.11}%);
  background-repeat: no-repeat;
`;

const RangeSelector: FC<RangeSelectorProps> = (props) => {
    return (
        <Wrapper>
            <CustomRangeInput
                type="range"
                min="1"
                max="10"
                value={props.value}
                onChange={(e) => props.onChangeSelection(+e.target.value)}
            />
            <SPAN className="px10_400_normal">{props.value}</SPAN>
        </Wrapper>
    );
};

export default RangeSelector;
